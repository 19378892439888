import styled from 'styled-components';
import { ChartWrapper } from '../../../components/chart/Chart.styled';
import { COLORS } from '../../../styles/constants/colors';

const ChartsDetailedReportWrapper = styled.div`
  .charts {
    color: ${COLORS.DARK_BLUE};
    display: flex;
    gap: 20px;
    flex-direction: column;

    .chart-row {
      gap: 25px;

      &.single-child {
        grid-template-columns: 1fr;
      }

      &.double-child {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }

      ${ChartWrapper} {
        border: 1px solid ${COLORS.LIGHT_BLUE};
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;

export { ChartsDetailedReportWrapper };
