import styled from 'styled-components';
import { ButtonWrapper } from '../../../../components/button/Button.styled';
import { TextFieldWrapper } from '../../../../components/form/text-field/TextField.styled';
import { COLORS } from '../../../../styles/constants/colors';
import { MultipleColumnsTableWrapper } from '../../../../components/table/multiple-columns-table/MultipleColumnsTable.styled';
import { BODY } from '../../../../styles/helpers/typography';

const EditReferenceDatasWrapper = styled.div`
  padding: 25px 10px;
  display: flex;
  flex-direction: column;
  gap: 35px;
  color: ${COLORS.DARK_BLUE};

  ${ButtonWrapper} {
    width: max-content;
  }

  .table {
    overflow: scroll;
    border-radius: 10px;

    p {
      ${BODY.S_REGULAR}
      text-align: start;
      word-break: normal;
      white-space: normal;
      hyphens: auto;
    }

    ${MultipleColumnsTableWrapper} {
      overflow: auto;
      ${BODY.M_MEDIUM}

      .label {
        color: ${COLORS.DARK_BLUE}80;
        max-width: 200px;
      }

      ${TextFieldWrapper} {
        input {
          ${BODY.S_REGULAR}
        }
      }

      .actions {
        display: flex;
        gap: 10px;
        width: max-content;
        margin: 0 auto;
      }
    }
  }
`;

export { EditReferenceDatasWrapper };
