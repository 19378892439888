import { useContext } from 'react';
import { ISimulator } from '../../../../types/app';
import SimulatorLayout from '../../layout/SimulatorLayout';
import { InsulatingMattressesWrapper } from './InsulatingMattresses.styled';
import {
  InsulatingMattressesContext,
  InsulatingMattressesContextProps,
} from '../../../../context/simulators/InsulatingMattressesContext';

export interface InsulatingMattressesProps {
  simulator: ISimulator;
}

const InsulatingMattresses: React.FC<InsulatingMattressesProps> = ({ simulator }) => {
  const { forms, resultTableRows, resultCharts } = useContext(
    InsulatingMattressesContext,
  ) as InsulatingMattressesContextProps;

  return (
    <InsulatingMattressesWrapper>
      <SimulatorLayout
        simulator={simulator}
        forms={forms}
        resultTableRows={resultTableRows}
        resultCharts={resultCharts}
      />
    </InsulatingMattressesWrapper>
  );
};

export default InsulatingMattresses;
