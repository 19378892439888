import styled, { css } from 'styled-components';
import { BODY } from '../../../../../styles/helpers/typography';
import { COLORS } from '../../../../../styles/constants/colors';

const EstimatedGainsWrapper = styled.div<{ backgroundcolor?: string; valuescolor?: string }>`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .estimated-gains-title {
    ${BODY.S_SEMI_BOLD}
  }

  .estimated-gains {
    width: auto;
    display: flex;
    gap: 15%;
    background: #0084ed1a;
    padding: 10px 20px;
    border-radius: 6px;
    flex-wrap: wrap;

    ${({ backgroundcolor }) =>
      backgroundcolor &&
      css`
        background: ${backgroundcolor};
      `}

    .gains {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .value {
      color: ${COLORS.BRIGHT_BLUE};
      ${BODY.M_BOLD}

      ${({ valuescolor }) =>
        valuescolor &&
        css`
          color: ${valuescolor};
        `}
    }

    .unit {
      ${BODY.M_REGULAR}
    }
  }
`;

export { EstimatedGainsWrapper };
