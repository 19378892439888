import Navbar from '../../../components/navbar/Navbar';
import Sidebar from '../../../components/sidebar/Sidebar';
import ScrollToTop from '../../../layout/scroll-to-top/ScrollToTop';
import { IComponentWithChildren } from '../../../types/app';
import { AdminPageLayoutWrapper } from './AdminPageLayout.styled';

export interface AdminPageLayoutProps extends IComponentWithChildren {
  title?: string;
}

const AdminPageLayout: React.FC<AdminPageLayoutProps> = ({ children, title }) => {
  return (
    <AdminPageLayoutWrapper>
      <Navbar title={title} showPdfDownload={false} />
      <Sidebar isAdminView />
      <ScrollToTop />
      <div className="page">{children}</div>
    </AdminPageLayoutWrapper>
  );
};

export default AdminPageLayout;
