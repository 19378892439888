import { useEffect, useRef } from 'react';
import { IForm } from '../../../types/app';
import { isNumber } from '../../../utils';
// import { isNumber } from '../../../utils';
import { TextFieldWrapper } from './TextField.styled';

export interface TextFieldPros {
  label?: string;
  placeholder?: string;
  value?: string;
  unit?: string;
  onChange?(e: string): void;
  type?: IForm.TextType;
  className?: string;
  controlled?: boolean;
  required?: boolean;
  disabled?: boolean;
}

const TextField: React.FC<TextFieldPros> = ({
  label,
  placeholder,
  value,
  unit,
  onChange,
  type,
  className,
  controlled,
  required,
  disabled,
}) => {
  const valueRef = useRef(value);

  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  return (
    <TextFieldWrapper className={className}>
      {label && (
        <div className="label">
          {label} {required && <span className="required">*</span>}
        </div>
      )}
      <div className={`input-wrapper ${disabled ? 'disabled' : ''}`}>
        {controlled ? (
          <input
            spellCheck={false}
            required={required}
            type={type}
            onChange={(e) => {
              if (disabled) return;
              if (type === 'number') {
                if (isNumber(e.target.value) || !e.target.value) {
                  onChange && onChange(e.target.value);
                  valueRef.current = e.target.value;
                }
              } else {
                onChange && onChange(e.target.value);
                valueRef.current = e.target.value;
              }
            }}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
          />
        ) : (
          <input
            spellCheck={false}
            type={type === 'number' ? 'number' : 'text'}
            onChange={(e) => {
              if (disabled) return;
              if (type === 'number') {
                if (isNumber(e.target.value) || !e.target.value) {
                  onChange && onChange(e.target.value);
                  valueRef.current = e.target.value;
                }
              } else {
                onChange && onChange(e.target.value);
                valueRef.current = e.target.value;
              }
            }}
            placeholder={placeholder}
            defaultValue={valueRef.current}
            disabled={disabled}
          />
        )}
        {unit && <div className="unit">{unit}</div>}
      </div>
    </TextFieldWrapper>
  );
};

export default TextField;
