import styled from 'styled-components';
import { COLORS } from '../../../styles/constants/colors';
import { ModalTitle } from '../Modal.styled';
import { ButtonWrapper } from '../../button/Button.styled';

const DefaultModalLayoutWrapper = styled.div`
  position: relative;
  width: 600px;
  max-width: 100%;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 20px;
  color: ${COLORS.DARK_BLUE};

  ${ModalTitle} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
    background: #0084ED1A;
    gap: 20px;

    p {
      margin: 0;
      flex: 1;
    }

    .icon {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .content {
    padding: 10px 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    p {
      margin: 0;
      flex: 1;
    }

    ${ButtonWrapper} {
      margin: 0 auto;
    }
  }
`;

export { DefaultModalLayoutWrapper };
