import styled from 'styled-components';
import { BODY } from '../../../styles/helpers/typography';
import { ButtonWrapper } from '../../../components/button/Button.styled';

const SimulatorBrowseAxeWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: end;
  flex-wrap: wrap;
  
  .cta {
    display: flex;
    justify-content: end;
    ${BODY.M_BOLD}

    ${ButtonWrapper} {
        width: 150px;
        max-width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
  }
`;

export { SimulatorBrowseAxeWrapper };
