import styled from 'styled-components';
import TableWrapper from '../../../components/table/Tables.styled';
import { COLORS } from '../../../styles/constants/colors';
import { BODY } from '../../../styles/helpers/typography';

const TitleTableDetailedReport = styled.div`
  text-align: start;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left-title {
    display: flex;
    gap: 20px;
    align-items: center;

    .title {
      ${BODY.L_MEDIUM}
    }

    .icon {
      color: ${COLORS.WHITE};
      background: ${COLORS.BRIGHT_BLUE};
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
    }
  }

  .right-title {
    .label {
      ${BODY.S_REGULAR}
      background: ${COLORS.LIGHT_BLUE};
      padding: 7px 10px;
      border-radius: 4px;
    }
  }
`;

const TableDetailedReportWrapper = styled.div`
  width: 100%;
  color: ${COLORS.DARK_BLUE};

  ${TableWrapper} {
    .right-td {
      font-weight: bold;
    }
  }
`;

export { TableDetailedReportWrapper, TitleTableDetailedReport };
