import { useContext } from 'react';
import { ISimulator } from '../../../../types/app';
import SimulatorLayout from '../../layout/SimulatorLayout';
import { IndustrialSiteWrapper } from './IndustrialSite.styled';
import {
  IndustrialSiteContext,
  IndustrialSiteContextProps,
} from '../../../../context/simulators/IndustrialSiteContext';
export interface IndustrialSiteProps {
  simulator: ISimulator;
}

const IndustrialSite: React.FC<IndustrialSiteProps> = ({ simulator }) => {
  const { resultCharts, resultTableRows, forms } = useContext(
    IndustrialSiteContext,
  ) as IndustrialSiteContextProps;

  return (
    <IndustrialSiteWrapper>
      <SimulatorLayout
        simulator={simulator}
        forms={forms}
        resultTableRows={resultTableRows}
        resultCharts={resultCharts}
      />
    </IndustrialSiteWrapper>
  );
};

export default IndustrialSite;
