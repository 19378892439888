import { createContext, useContext, useEffect, useState } from 'react';
import { IComponentWithChildren, ISIMULATORS_NAME } from '../../../types/app';
import { IndustrialSiteContext, IndustrialSiteContextProps } from '../IndustrialSiteContext';
import { MonitoringContext, MonitoringContextProps } from '../MonitoringContext';
import { CompressedAirContext, CompressedAirContextProps } from '../CompressedAirContext';
import { HeatRecoveryContext, HeatRecoveryContextProps } from '../HeatRecoveryContext';
import {
  RoomAirDestratificationContext,
  RoomAirDestratificationContextProps,
} from '../RoomAirDestratificationContext';
import {
  MicroModulatingBurnerContext,
  MicroModulatingBurnerContextProps,
} from '../MicroModulatingBurnerContext';
import {
  InsulatingMattressesContext,
  InsulatingMattressesContextProps,
} from '../InsulatingMattressesContext';
import { LedLightingContext, LedLightingContextProps } from '../LedLightingContext';
import { HeatPumpContext, HeatPumpContextProps } from '../HeatPumpContext';
import {
  ElectricSpeedVariationContext,
  ElectricSpeedVariationContextProps,
} from '../ElectricSpeedVariationContext';
import {
  HighPerformanceEnginesContext,
  HightPerformanceEnginesContextProps,
} from '../HighPerformanceEnginesContext';
import { MotorDriveContext, MotorDriveContextProps } from '../MotorDriveContext';

export interface SimulatorsSummaryContextProps {
  contexts?: {
    [x in ISIMULATORS_NAME]: any;
  };
}

const SimulatorsSummaryContext = createContext<SimulatorsSummaryContextProps | undefined>(
  undefined,
);

const SimulatorsSummaryProvider: React.FC<IComponentWithChildren> = ({ children }) => {
  const [contexts, setContexts] = useState<{
    [x in ISIMULATORS_NAME]: any;
  }>();

  const industrialSiteContext = useContext(IndustrialSiteContext) as IndustrialSiteContextProps;
  const monitoringContext = useContext(MonitoringContext) as MonitoringContextProps;
  const compressedAirContext = useContext(CompressedAirContext) as CompressedAirContextProps;
  const heatRecoveryContext = useContext(HeatRecoveryContext) as HeatRecoveryContextProps;
  const roomAirDestratificationContext = useContext(
    RoomAirDestratificationContext,
  ) as RoomAirDestratificationContextProps;
  const microModulatingBurnerContext = useContext(
    MicroModulatingBurnerContext,
  ) as MicroModulatingBurnerContextProps;
  const insulatingMattressesContext = useContext(
    InsulatingMattressesContext,
  ) as InsulatingMattressesContextProps;
  const ledLightingContext = useContext(LedLightingContext) as LedLightingContextProps;
  const heatPumpContext = useContext(HeatPumpContext) as HeatPumpContextProps;
  const electricSpeedVariationContext = useContext(
    ElectricSpeedVariationContext,
  ) as ElectricSpeedVariationContextProps;
  const highPerformanceEnginesContext = useContext(
    HighPerformanceEnginesContext,
  ) as HightPerformanceEnginesContextProps;
  const motorDriveContext = useContext(MotorDriveContext) as MotorDriveContextProps;

  useEffect(() => {
    setContexts({
      INDUSTRIAL_SITE: industrialSiteContext,
      MONITORING: monitoringContext,
      COMPRESSED_AIR: compressedAirContext,
      HEAT_RECOVERY: heatRecoveryContext,
      ROOM_AIR_DESTRATIFICATION: roomAirDestratificationContext,
      MICRO_MODULATING_BURNER: microModulatingBurnerContext,
      INSULATING_MATTRESSES: insulatingMattressesContext,
      LED_LIGHTING: ledLightingContext,
      HEAT_PUMP: heatPumpContext,
      ELECTRIC_SPEED_VARIATION: electricSpeedVariationContext,
      HIGH_PERFORMANCE_ENGINES: highPerformanceEnginesContext,
      MOTOR_DRIVE: motorDriveContext,
    });
  }, [
    industrialSiteContext,
    monitoringContext,
    compressedAirContext,
    heatRecoveryContext,
    roomAirDestratificationContext,
    microModulatingBurnerContext,
    insulatingMattressesContext,
    ledLightingContext,
    heatPumpContext,
    electricSpeedVariationContext,
    highPerformanceEnginesContext,
    motorDriveContext,
  ]);

  return (
    <SimulatorsSummaryContext.Provider
      value={{
        contexts: contexts,
      }}
    >
      {children}
    </SimulatorsSummaryContext.Provider>
  );
};

export { SimulatorsSummaryContext, SimulatorsSummaryProvider };
