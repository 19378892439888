import axios from 'axios';
import { API } from '../global';
import { IAdmin } from '../types/app';

const authenticateAdmin = async (
  username: string,
  password: string,
): Promise<IAdmin | undefined> => {
  try {
    const res = await axios.post(`${API.BASE_URL}${API.ROUTES?.AUTH.AUTHENTICATE_ADMIN}`, {
      username: username,
      password: password,
    });
    const datas = res?.data?.datas;

    return {
      id: datas.id,
      username: datas.username,
      token: datas.token,
    };
  } catch (err) {
    console.error(err);
    throw new Error("Erreur lors de l'authentification");
  }
};

const getAdminDetails = async (token: string): Promise<IAdmin> => {
  try {
    const res = await axios.get(`${API.BASE_URL}${API.ROUTES?.ADMIN.DETAILS.GET}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const datas = res?.data?.datas;
    if (!datas || !datas?.id || !datas?.username) throw new Error('Admin is not recognized');
    return {
      id: datas.id,
      username: datas.username,
      token: datas.token,
    };
  } catch (err) {
    console.error(err);
    throw new Error('Admin is not recognized');
  }
};

export { authenticateAdmin, getAdminDetails };
