import { ICONS, ROUTES } from '../../../global';
import { NavDetailedReportWrapper } from './NavDetailedReport.styled';
import Logo from '../../../assets/images/logo.png';
import { ISimulatorEstimatedEconomy, IsViewerOnlyProps } from '../../../types/app';
import { formatNumber } from '../../../utils';
import useCustomNavigate from '../../../hooks/useCustomNavigate';
import Tag from '../../../components/tag/Tag';
import { COLORS } from '../../../styles/constants/colors';

export interface NavDetailedReportProps extends IsViewerOnlyProps {
  estimatedEconomyProps: ISimulatorEstimatedEconomy;
}

const NavDetailedReport: React.FC<NavDetailedReportProps> = ({
  estimatedEconomyProps,
  isViewerOnly,
}) => {
  const { estimatedEconomy } = estimatedEconomyProps;
  const navigate = useCustomNavigate();

  return (
    <NavDetailedReportWrapper>
      <div className="logo-wrapper">
        <img
          onClick={() => navigate(ROUTES.DASHBOARD)}
          src={Logo}
          className="logo"
          alt="logo actemium"
        />
      </div>
      <div className="nav-left">
        {isViewerOnly && (
          <Tag label={'Mode vue'} backgroundColor={COLORS.LIGHT_BLUE} color={COLORS.DARK_BLUE} />
        )}
        <div className="results">
          <div className="title">
            {!isViewerOnly ? 'Mes résultats détaillés:' : 'Résultats détaillés'}
          </div>
          <div className="financial-gain gain">
            <span className="icon">{ICONS?.ESTIMATED_GAIN?.icon}</span>
            <span className="label">Gains estimés:</span>
            <span className="value">
              {formatNumber(estimatedEconomy?.financial?.value as number)}
            </span>
            <span className="unit">{estimatedEconomy?.financial?.unit}</span>
          </div>
          <div className="energy-gain gain">
            <span className="icon">{ICONS?.ENERGY_ECONOMY?.icon}</span>
            <span className="label">Économies d'énergie:</span>
            <span className="value">{formatNumber(estimatedEconomy?.energy?.value as number)}</span>
            <span className="unit">{estimatedEconomy?.energy?.unit}</span>
          </div>
          <div className="decarbonation gain">
            <span className="icon">{ICONS?.DECREASED_EMISSION?.icon}</span>
            <span className="label">Décarbonation:</span>
            <span className="value">
              {formatNumber(estimatedEconomy?.decarbonation?.value as number)}
            </span>
            <span className="unit">{estimatedEconomy?.decarbonation?.unit}</span>
          </div>
        </div>
      </div>
      <div className="nav-right">
        <div className="icon" onClick={() => navigate(ROUTES.DASHBOARD)}>
          {ICONS.BACK?.icon}
        </div>
      </div>
    </NavDetailedReportWrapper>
  );
};

export default NavDetailedReport;
