import { useContext } from 'react';
import { ISimulator } from '../../../../types/app';
import SimulatorLayout from '../../layout/SimulatorLayout';
import { HeatPumpWrapper } from './HeatPump.styled';
import {
  HeatPumpContext,
  HeatPumpContextProps,
} from '../../../../context/simulators/HeatPumpContext';

export interface HeatPumpProps {
  simulator: ISimulator;
}

const HeatPump: React.FC<HeatPumpProps> = ({ simulator }) => {
  const { forms, resultTableRows, resultCharts } = useContext(
    HeatPumpContext,
  ) as HeatPumpContextProps;

  return (
    <HeatPumpWrapper>
      <SimulatorLayout
        simulator={simulator}
        forms={forms}
        resultTableRows={resultTableRows}
        resultCharts={resultCharts}
      />
    </HeatPumpWrapper>
  );
};

export default HeatPump;
