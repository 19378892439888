import { createContext, useEffect, useState } from 'react';
import { IComponentWithChildren } from '../types/app';

export interface IModalConf {
  component?: any;
  opened?: boolean;
  onClose?: () => void;
}

export interface ModalContextProps {
  updateModalConf: (conf?: IModalConf) => void;
  modalConf: IModalConf | undefined;
  openedModal: boolean;
}

const ModalContext = createContext<ModalContextProps | undefined>(undefined);

const ModalProvider: React.FC<IComponentWithChildren> = ({ children }) => {
  const [modalConf, setModalConf] = useState<IModalConf>();
  const [openedModal, setOpenedModal] = useState<boolean>(false);

  const updateModalConf = (modalConf?: IModalConf) => {
    if (modalConf?.component) {
      setModalConf({
        component: modalConf.component,
        onClose: modalConf.onClose || undefined,
        opened: true,
      });
    } else {
      setModalConf({});
    }
  };

  useEffect(() => {
    setOpenedModal(!!modalConf?.opened);
  }, [modalConf]);

  return (
    <ModalContext.Provider
      value={{
        modalConf: modalConf,
        openedModal: openedModal,
        updateModalConf: updateModalConf,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider };
