import styled from 'styled-components';
import { COLORS } from '../../styles/constants/colors';
import { BREAKPOINTS } from '../../styles/constants/devices';

const PageLayoutWrapper = styled.div`
  position: relative;
  color: ${COLORS.DARK_BLUE};

  .page-layout-children {
    padding-top: 63px;
    min-height: 100vh;
    background-color: #F9FCFF;
    overflow: auto;

    @media (${BREAKPOINTS.S}) {
      padding-left: 20px;
      padding-right: 20px;
    }

    @media (${BREAKPOINTS.L}) {
      padding-left: 125px;
      padding-top: 63px;
      padding-right: 0;
    }
  }
`;

export { PageLayoutWrapper };
