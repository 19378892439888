import { useEffect, useRef } from 'react';
import Chart, { ChartProps } from '../../../components/chart/Chart';
import { ChartsDetailedReportWrapper } from './ChartsDetailedReport.styled';

export interface ChartsDetailedReportProps {
  charts: ChartProps[][];
  getRef?: (e: any) => any;
}

const ChartsDetailedReport: React.FC<ChartsDetailedReportProps> = ({ charts, getRef }) => {
  const refs = useRef<Array<any>>([]);
  useEffect(() => {
    getRef && getRef(refs.current);
    // eslint-disable-next-line
  }, [refs]);

  return (
    <ChartsDetailedReportWrapper>
      {charts && charts.length > 0 && (
        <div className="charts">
          {charts.map((charts2, key) => {
            return (
              <div
                key={key}
                className={`chart-row ${charts2.length < 2 ? 'single-child' : 'double-child'}`}
              >
                {charts2.map((chart, key2) => {
                  return (
                    <Chart
                      key={key2}
                      {...chart}
                      getRef={(e) => {
                        refs.current.push(e);
                        // getRef && getRef(refs.current);
                      }}
                    />
                  );
                })}
              </div>
            );
          })}
        </div>
      )}
    </ChartsDetailedReportWrapper>
  );
};

export default ChartsDetailedReport;
