import React from 'react';
import { IForm } from '../../types/app';
import { FormWrapper } from './Form.styled';
import TextField from './text-field/TextField';
import SelectBox from './select-box/SelectBox';

const Form: React.FC<IForm.IForm> = ({ header, fields, onChange, className, disabled }) => {
  const onChangeForm = (field: IForm.IField, newField: IForm.IField, fieldIndex: number) => {
    let updatedFields: Array<IForm.IField> = fields || [];
    updatedFields = updatedFields.map((field, index) => {
      if (index === fieldIndex) {
        return newField;
      }
      return field;
    });
    onChange && onChange(updatedFields);
  };

  return (
    <FormWrapper className={`${className} ${disabled && 'disabled'}`}>
      <>{header && header}</>
      {fields && fields.length > 0 && (
        <div className="form-fields">
          {fields.map((field: IForm.IField, index) => {
            return (
              <React.Fragment key={index}>
                {field.type === 'textfield' && (
                  <TextField
                    placeholder="0"
                    className="field"
                    label={field.label}
                    unit={field.unit}
                    onChange={(e) => {
                      if (!disabled) {
                        field.onChange && field.onChange(e);
                        let newField: IForm.IField = {
                          ...field,
                          value: e,
                        };
                        onChangeForm(field, newField, index);
                      }
                    }}
                    value={(field.value as string) || ''}
                    type="number"
                    controlled={field.controlled}
                    disabled={disabled}
                  />
                )}
                {field.type === 'selectbox' && (
                  <SelectBox
                    className="field"
                    placeholder="Choisir dans la liste"
                    label={field.label}
                    unit={field.unit}
                    onChange={(newOptions) => {
                      field.onChange && field.onChange(newOptions);
                      let newField: IForm.IField = {
                        ...field,
                        options: newOptions,
                        value: newOptions.find((option) => option.selected)?.value,
                      };
                      onChangeForm(field, newField, index);
                    }}
                    value={(field.value as string) || ''}
                    options={field.options || []}
                    disabled={disabled}
                  />
                )}
              </React.Fragment>
            );
          })}
        </div>
      )}
    </FormWrapper>
  );
};

export default Form;
