import Button from '../../../../components/button/Button';
import Card from '../../../../components/card/Card';
import Tag from '../../../../components/tag/Tag';
import { COLORS } from '../../../../styles/constants/colors';
import { SingleSimulatorWrapper } from './SingleSimulator.styled';
import { ReactComponent as IconTimer } from '../../../../assets/icons/icon-timer.svg';
import { ISimulator, ISimulatorRequiredDatas } from '../../../../types/app';
import EstimatedGains from './estimated-gains/EstimatedGains';
import { SIMULATORS_NAMES } from '../../../../global';
import useCustomNavigate from '../../../../hooks/useCustomNavigate';

export interface SingleSimulatorProps {
  simulator: ISimulator;
  onClick?: () => any;
  datas: ISimulatorRequiredDatas;
}

const SingleSimulator: React.FC<SingleSimulatorProps> = ({ simulator, onClick, datas }) => {
  const navigate = useCustomNavigate();

  return (
    <SingleSimulatorWrapper
      onClick={() => {
        onClick && onClick();
        simulator.route && navigate(simulator.route);
      }}
      theme={simulator.code === SIMULATORS_NAMES.MONITORING.name ? 'yellow' : 'blue'}
    >
      <Card
        theme={simulator.code === SIMULATORS_NAMES.MONITORING.name ? 'yellow' : 'blue'}
        title={
          <>
            {simulator?.icon.icon} {simulator?.icon?.name}
          </>
        }
      >
        {simulator.labels && (
          <div className="tags">
            {simulator.labels.map((label, key) => {
              return (
                <Tag
                  label={label}
                  key={key}
                  backgroundColor={
                    simulator.code === SIMULATORS_NAMES.MONITORING.name
                      ? `${COLORS.GREEN}66`
                      : `${COLORS.LIGHT_BLUE}66`
                  }
                />
              );
            })}
          </div>
        )}
        <div className="simulator-card-status">
          {isNaN(Number(datas.energyGainMwhPerYear)) ||
          isNaN(Number(datas.financialGainPerYear)) ||
          (Number(datas.energyGainMwhPerYear) === 0 && Number(datas.financialGainPerYear) === 0) ? (
            <Button>
              <>
                <IconTimer />
                <p>Pas encore commencé</p>
              </>
            </Button>
          ) : (
            <EstimatedGains
              backgroundColor={
                simulator.code === SIMULATORS_NAMES.MONITORING.name ? '#C6D2004D' : ''
              }
              valuesColor={
                simulator.code === SIMULATORS_NAMES.MONITORING.name ? COLORS.DARK_BLUE : ''
              }
              energy={{
                unit: 'MWh/an',
                value: datas.energyGainMwhPerYear,
              }}
              money={{
                unit: '€/an',
                value: datas.financialGainPerYear,
              }}
            />
          )}
        </div>
      </Card>
    </SingleSimulatorWrapper>
  );
};

export default SingleSimulator;
