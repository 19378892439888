import axios from 'axios';
import { API } from '../global';
import { IReferenceData, IReferenceVersion } from '../types/app';

const formatReferenceVersions = (datas: any[]): IReferenceVersion[] => {
  return datas.map((data) => {
    return {
      id: data.id,
      version: data.version,
      isCurrent: data.is_current,
      creationDate: data.creation_date,
    };
  });
};

const formatReferenceDatas = (datas: any[]): IReferenceData[] => {
  return datas.map((data) => {
    return {
      id: data.id,
      code: data.code,
      referenceDataId: data.reference_data_id,
      comment: data.comment,
      description: data.description,
      source: data.source,
      unit: data.unit,
      value: data.value,
    };
  });
};

const getReferenceVersions = async (
  adminToken: string,
): Promise<IReferenceVersion[] | undefined> => {
  try {
    if (!adminToken) return;
    const res = await axios.get(`${API.BASE_URL}${API.ROUTES.ADMIN.REFERENCE_DATAS.VERSIONS.GET}`, {
      headers: {
        Authorization: `Bearer ${adminToken}`,
      },
    });
    return formatReferenceVersions(res.data?.datas);
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

const getReferenceDatasByVersionId = async (
  version_id: number,
  adminToken: string,
): Promise<IReferenceData[] | undefined> => {
  try {
    if (!adminToken) return;
    const res = await axios.get(
      `${API.BASE_URL}${API.ROUTES.ADMIN.REFERENCE_DATAS.GET}?version_id=${version_id}`,
      {
        headers: {
          Authorization: `Bearer ${adminToken}`,
        },
      },
    );
    const datas = res.data?.datas;
    return formatReferenceDatas(datas);
    // return formatReferenceVersions(res.data?.datas);
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

const updateReferenceDataDetailsById = async (
  adminToken: string,
  id: number,
  description: string,
  value: number,
  unit: string,
  source: string,
  comment: string,
): Promise<boolean | undefined> => {
  try {
    const res = await axios.post(
      `${API.BASE_URL}${API.ROUTES.ADMIN.REFERENCE_DATAS.UPDATE}`,
      {
        id,
        description,
        value,
        unit,
        source,
        comment,
      },
      {
        headers: {
          Authorization: `Bearer ${adminToken}`,
        },
      },
    );
    if (res.status === 200) return true;
  } catch (err) {
    console.error(err);
  }
  return;
};

const setCurrentVersion = async (versionId: number, adminToken: string) => {
  try {
    const res = await axios.post(
      `${API.BASE_URL}${API.ROUTES.ADMIN.REFERENCE_DATAS.VERSIONS.SET_CURRENT}`,
      {
        versionId: versionId,
      },
      {
        headers: {
          Authorization: `Bearer ${adminToken}`,
        },
      },
    );
    if (res.status === 200) return true;
  } catch (err) {
    console.error(err);
  }
};

const AdminReferenceDatasApi = {
  getReferenceVersions,
  getReferenceDatasByVersionId,
  updateReferenceDataDetailsById,
  setCurrentVersion,
};

export { AdminReferenceDatasApi };
