import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { IComponentWithChildren, ISIMULATORS_NAME, IUser, IUserDetails } from '../../types/app';
import {
  getUserTokenFromLocalstorage,
  getViewOnlyUserTokenFromLocalstorage,
  skipIntro,
} from '../../utils';
import { UserApi } from '../../api/User.api';
import useDelayedUpdate from '../../hooks/useDelayedUpdate';
import { LoadingContext, LoadingContextProps } from '../LoadingContext';
import toast from 'react-hot-toast';
import useConnection from '../../hooks/useConnection';
import { ROUTES, SIMULATORS_NAMES } from '../../global';

export interface UserContextProps {
  userToken: string;
  user?: IUser;
  isVisitorView: boolean;
  isAdminView: boolean | undefined;
  setIsAdminView: (e: boolean) => void;
  updateUserDetails: (details: IUserDetails) => void;
  updateUserSimulatorDatas: (
    userToken: string,
    code: ISIMULATORS_NAME,
    newDatas: object,
  ) => Promise<any>;
  updateUserReferenceData: (
    userToken: string,
    referenceDataId: number,
    value: number,
  ) => Promise<any>;
  reinitializeUserReferenceDatas: (userToken: string) => Promise<any>;
  initUserDatas: (userToken: string) => Promise<void>;
  loadingUserDatas: boolean;
  updateToast: (
    status: 'loading' | 'success' | 'error' | 'blank',
    message: string,
    id: string,
  ) => void;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);
const UserProvider: React.FC<IComponentWithChildren> = ({ children }) => {
  const [userToken, setUserToken] = useState('');
  const [user, setUser] = useState<IUser>();
  const [isVisitorView, setIsVisitorView] = useState<boolean>(false);
  const [loadingUserDatas, setLoadingUserDatas] = useState<boolean>(true);
  const [isAdminView, setIsAdminView] = useState<boolean>();

  const { setIsLoading, isLoading } = useContext(LoadingContext) as LoadingContextProps;
  const { internetWorking } = useConnection();

  const updateUserDetails = (details: IUserDetails) => {
    setUser({
      ...user,
      details: details,
    });
  };

  useEffect(() => {
    try {
      const visitorPage = window.location.pathname.includes(ROUTES.VIEW.INDEX);
      const isAdminViewTmp = window.location.pathname.includes(ROUTES.ADMIN.INDEX);
      const skipedIntro = skipIntro();
      if (!skipedIntro) return;
      if (!visitorPage && !isAdminViewTmp) {
        const token = localStorage.getItem(getUserTokenFromLocalstorage());
        const visitorViewToken = localStorage.getItem(getViewOnlyUserTokenFromLocalstorage());
        if (visitorViewToken) {
          UserApi.getUserDetails(visitorViewToken)
            .then((res) => {
              res && setUserToken(visitorViewToken as string);
              setIsVisitorView(true);
            })
            .catch(() => {
              UserApi.generateUserToken().then((res) => {
                if (!res) {
                  console.error('Could not generate token');
                  return;
                }
                return setUserToken(res);
              });
            });
          return;
        }
        if (!token) {
          UserApi.generateUserToken().then((res) => {
            if (!res) {
              console.error('Could not generate token');
              return;
            }
            return setUserToken(res);
          });
        } else {
          UserApi.getUserDetails(token)
            .then((res) => res && setUserToken(token as string))
            .catch(() => {
              UserApi.generateUserToken().then((res) => {
                if (!res) {
                  console.error('Could not generate token');
                  return;
                }
                return setUserToken(res);
              });
            });
        }
      }
    } catch (err) {
    } finally {
    }
  }, [isAdminView]);

  useEffect(() => {
    if (userToken) {
      if (isVisitorView) {
        localStorage.setItem(getViewOnlyUserTokenFromLocalstorage(), userToken);
      } else {
        localStorage.setItem(getUserTokenFromLocalstorage(), userToken);
      }
    }
  }, [userToken, isVisitorView]);

  const initUserDatas = async (userToken: string) => {
    try {
      if (userToken) {
        const user = await UserApi.getUserDetails(userToken);
        if (user) {
          setUser({
            ...user,
            simulatorDatas: user.simulatorDatas?.map((simulatorData) => {
              return {
                ...simulatorData,
                name: SIMULATORS_NAMES[simulatorData.code as ISIMULATORS_NAME].title,
              };
            }),
          });
        }
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (userToken || internetWorking) {
      initUserDatas(userToken);
    }
  }, [userToken, internetWorking]);

  useDelayedUpdate(
    () => {
      if (user) setLoadingUserDatas(false);
    },
    [user],
    500,
  );

  const updateToast = (
    status: 'loading' | 'success' | 'error' | 'blank',
    message: string,
    id: string,
  ) => {
    // if (toasts.find((t: Toast) => t.type === status && t.id === id && t.message === message))
    //   return;
    if (status === 'loading') {
      toast.loading(message, {
        id: id,
        duration: Infinity,
      });
    } else if (status === 'success') {
      toast.success(message, {
        id: id,
        duration: 1000,
      });
    } else if (status === 'error') {
      toast.error(message, {
        id: id,
        duration: 1000,
      });
    } else if (status === 'blank') {
      toast(message, {
        id: id,
        duration: 1000,
      });
    }
    // setTimeout(() => {
    //   toast.dismiss(id);
    // }, 1000);
  };

  const updateUserSimulatorDatas = useCallback(
    async (userToken: string, code: ISIMULATORS_NAME, newDatas: object) => {
      if (userToken && !isLoading && internetWorking) {
        setIsLoading(true);
        updateToast('loading', 'Enregistrement des données ...', 'updateUserSimulatorDatas');
        UserApi.updateUserSimulatorDatas(userToken, code, newDatas)
          .then(() => {
            updateToast('success', 'Données enregistrées', 'updateUserSimulatorDatas');
            setIsLoading(false);
          })
          .catch((err) => console.error(err));
      }
    },
    [internetWorking, isLoading, setIsLoading],
  );

  const updateUserReferenceData = async (
    userToken: string,
    referenceDataId: number,
    value: number,
  ) => {
    if (userToken && !isLoading && internetWorking) {
      setIsLoading(true);
      updateToast('loading', 'Enregistrement des données ...', 'updateUserSimulatorDatas');
      UserApi.updateUserReferenceData(userToken, referenceDataId, value)
        .then(() => {
          updateToast('success', 'Données enregistrées', 'updateUserSimulatorDatas');
          setIsLoading(false);
        })
        .catch((err) => console.error(err));
    }
  };

  const reinitializeUserReferenceDatas = async (userToken: string) => {
    if (userToken && !isLoading && internetWorking) {
      setIsLoading(true);
      updateToast('loading', 'Enregistrement des données ...', 'updateUserSimulatorDatas');

      try {
        await UserApi.reinitializeUserReferenceDatas(userToken);
        updateToast('success', 'Données enregistrées', 'updateUserSimulatorDatas');
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <UserContext.Provider
      value={{
        userToken,
        user,
        isVisitorView,
        isAdminView,
        setIsAdminView,
        updateUserDetails,
        updateUserSimulatorDatas,
        updateUserReferenceData,
        loadingUserDatas,
        initUserDatas,
        reinitializeUserReferenceDatas,
        updateToast,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
