import { IIcon } from '../../types/app';
import { IconWrapper } from './Icon.styled';

export interface IconProps {
  icon: IIcon | undefined;
  className?: string;
  selected?: boolean;
  onClick?: any;
}

const Icon: React.FC<IconProps> = ({ icon, className, selected, onClick }) => {
  return (
    <IconWrapper
      onClick={() => {
        onClick && onClick();
      }}
      className={`${className} ${selected && 'selected'}`}
    >
      {icon && icon.icon}
    </IconWrapper>
  );
};

export default Icon;
