import styled from 'styled-components';
import { MultipleColumnsTableWrapper } from '../../../../components/table/multiple-columns-table/MultipleColumnsTable.styled';
import { COLORS } from '../../../../styles/constants/colors';
import { BODY } from '../../../../styles/helpers/typography';

const ReferenceDatasListWrapper = styled.div`
  padding: 25px 10px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  ${MultipleColumnsTableWrapper} {
    ${BODY.S_REGULAR}
    color: ${COLORS.DARK_BLUE};

    .version-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      padding: 5px;
    }

    .version-flag {
      width: 25px;
      height: 25px;
      margin: 0 auto;
      border-radius: 50px;
      position: relative;

      // &:after {
      //   content: '';
      //   position: absolute;
      //   width: 10px;
      //   height: 10px;
      //   border-radius: 50px;
      //   background-color: ${COLORS.WHITE};
      //   top: 50%;
      //   left: 50%;
      //   transform: translate(-55%, -42%);
      // }

      &.is-current-version {
        background-color: ${COLORS.GREEN};
      }
      &.not-current-version {
        background-color: red;
      }
    }

    .version-flag-txt {
      &.is-current-version {
        color: ${COLORS.GREEN};
        ${BODY.S_SEMI_BOLD};
      }
    }

    .actions {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: space-around;
      margin: 0 auto;
    }

    .set-as-current {
      max-width: max-content;
      margin: 0 auto;

      .is-current-version {
        color: ${COLORS.WHITE};
        background-color: ${COLORS.GREEN};
        ${BODY.S_SEMI_BOLD};
      }
    }
  }
`;

export { ReferenceDatasListWrapper };
