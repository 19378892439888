import { css } from 'styled-components';

const H0 = css`
  font-weight: 600;
  font-size: 70px;
  line-height: 101.15px;
`;

const H1 = css`
  font-weight: 600;
  font-size: 50px;
  line-height: 60px;
`;

const H2 = css`
  font-weight: 600;
  font-size: 40px;
  line-height: 52px;
`;

const H3_SEMI_BOLD = css`
  font-weight: 600;
  font-size: 28px;
  line-height: 36.4px;
`;

const H3_MEDIUM = css`
  font-weight: 500;
  font-size: 28px;
  line-height: 36.4px;
`;

const H4 = css`
  font-weight: 600;
  font-size: 24px;
  line-height: 31.2px;
`;

const H5 = css`
  font-weight: 500;
  font-size: 22px;
  line-height: 28.6px;
`;

const bodyXl = css`
  font-size: 20px;
`;

const bodyXlSemibold = css`
  ${bodyXl}
  font-weight: 600;
  line-height: 28px;
`;

const bodyXlMedium = css`
  ${bodyXl}
  font-weight: 500;
  line-height: 28px;
`;

const bodyL = css`
  font-size: 18px;
`;

const bodyLBold = css`
  ${bodyL}
  font-weight: 700;
  line-height: 25.2px;
`;

const bodyLSemibold = css`
  ${bodyL}
  font-weight: 600;
  line-height: 25.2px;
`;

const bodyLMedium = css`
  ${bodyL}
  font-weight: 500;
  line-height: 25.2px;
`;

const bodyLRegular = css`
  ${bodyL}
  font-weight: 400;
  line-height: 25.2px;
`;

const bodyM = css`
  font-size: 16px;
`;

const bodyMBold = css`
  ${bodyM}
  font-weight: 500;
  line-height: 24px;
`;

const bodyMMedium = css`
  ${bodyM}
  font-weight: 500;
  line-height: 24px;
`;

const bodyMRegular = css`
  ${bodyM}
  font-weight: 400;
  line-height: 24px;
`;

const bodyS = css`
  font-size: 14px;
`;

const bodySSemibold = css`
  ${bodyS}
  font-weight: 600;
  line-height: 20px;
`;

const bodySMedium = css`
  ${bodyS}
  font-weight: 500;
  line-height: 21px;
`;

const bodySRegular = css`
  ${bodyS}
  font-weight: 400;
  line-height: 21px;
`;

const bodyXS = css`
  font-size: 12px;
`;
const bodyXSSemibold = css`
  ${bodyXS}
  font-weight: 500;
  line-height: 14px;
`;

const bodyXSRegular = css`
  ${bodyXS}
  font-weight: 400;
  line-height: 18px;
`;

const bodyXXS = css`
  font-size: 10px;
`;

const bodyXXSBold = css`
  ${bodyXXS}
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.5px;
`;

const BODY = {
  XL_SEMI_BOLD: bodyXlSemibold,
  XL_MEDIUM: bodyXlMedium,
  L_BOLD: bodyLBold,
  L_SEMI_BOLD: bodyLSemibold,
  L_MEDIUM: bodyLMedium,
  L_REGULAR: bodyLRegular,
  M_BOLD: bodyMBold,
  M_MEDIUM: bodyMMedium,
  M_REGULAR: bodyMRegular,
  S_SEMI_BOLD: bodySSemibold,
  S_MEDIUM: bodySMedium,
  S_REGULAR: bodySRegular,
  XS_SEMI_BOLD: bodyXSSemibold,
  XS_REGULAR: bodyXSRegular,
  XXS_BOLD: bodyXXSBold,
};

export { H0, H1, H2, H3_MEDIUM, H3_SEMI_BOLD, H4, H5, BODY };
