import styled from 'styled-components';
import { CardTitle, CardWrapper } from '../../../components/card/Card.styled';
import { BODY } from '../../../styles/helpers/typography';
import { COLORS } from '../../../styles/constants/colors';

const ContactUsCardWrapper = styled.div`
  ${CardWrapper} {
    background-color: ${COLORS.WHITE};
    border: 1px solid #0084ed60;
    box-shadow: 1px 4px 14px 6px #dae8f9;
    min-height: max-content;
    display: flex;
    flex-direction: column;

    ${CardTitle} {
      ${BODY.L_SEMI_BOLD}
    }

    .contact-us-card-description {
      margin: 15px 0;
      ${BODY.S_REGULAR}
    }
  }
`;

export { ContactUsCardWrapper };
