import { createContext, useContext, useEffect, useState } from 'react';
import { IComponentWithChildren, IForm, ISimulatorRequiredDatas } from '../../types/app';
import { FormTitle } from '../../components/form/Form.styled';
import { ChartProps } from '../../components/chart/Chart';
import { ITableRow } from '../../components/table/Table';
import { ReferenceDatasContext, ReferenceDatasContextProps } from '../ReferenceDatasContext';
import { COLORS } from '../../styles/constants/colors';
import { getChartPropsFromRoiMatrix } from '../../utils';
import { SimulatorsContext, SimulatorsContextProps } from './SimulatorsContext';
import { UserContext, UserContextProps } from '../user/UserContext';

import useDelayedUpdate from '../../hooks/useDelayedUpdate';

export interface ElectricSpeedVariationContextProps extends ISimulatorRequiredDatas {
  enginesPower: number;
  enginesNumber: number;
  operatingTime: number;
  forms: IForm.IForm[];
  consummedEnergy: number;
  economizedEnergy: number;
  reducedConsumption: number;
  estimatedReductionInEmission: number;
  financialGain: number;
  investmentInMaterials: number;
  roi: number;
  resultTableRows: ITableRow[];
  roiMatrix: number[][];
  resultCharts: ChartProps[][];
}

const ElectricSpeedVariationContext = createContext<ElectricSpeedVariationContextProps | undefined>(
  undefined,
);

const ElectricSpeedVariationProvider: React.FC<IComponentWithChildren> = ({ children }) => {
  const { CARBON_CONTENT_PER_KWH_ELECTRICITY, AVERAGE_ELECTRICITY_PRICE_PER_MWH } = useContext(
    ReferenceDatasContext,
  ) as ReferenceDatasContextProps;
  const { getSimulatorByCode, simulators, editSimulatorDatas } = useContext(
    SimulatorsContext,
  ) as SimulatorsContextProps;
  const { userToken, updateUserSimulatorDatas } = useContext(UserContext) as UserContextProps;

  const [enginesPower, setEnginesPower] = useState<number>(0);
  const [enginesNumber, setEnginesNumber] = useState<number>(0);
  const [operatingTime, setOperatingTime] = useState<number>(0);
  const [forms, setForms] = useState<IForm.IForm[]>([]);

  const [consummedEnergy, setConsummedEnergy] = useState<number>(0);
  const [economizedEnergy, setEconomizedEnergy] = useState<number>(0);
  const [reducedConsumption, setReducedConsumption] = useState<number>(0);
  const [estimatedReductionInEmission, setEstimatedReductionInEmission] = useState<number>(0);
  const [financialGain, setFinancialGain] = useState<number>(0);
  const [investmentInMaterials, setInvestmentInMaterials] = useState<number>(0);
  const [roi, setRoi] = useState<number>(0);
  const [resultTableRows, setResultTableRows] = useState<ITableRow[]>([]);

  const [roiMatrix, setRoiMatrix] = useState<number[][]>([]);
  const [resultCharts, setResultCharts] = useState<ChartProps[][]>([]);

  const [updateDatas, setUpdateDatas] = useState<object>({});

  const [allowRequest, setAllowRequest] = useState<boolean>(false);
  // forms
  useEffect(() => {
    setForms([
      {
        onChange: () => setAllowRequest(true),
        header: <FormTitle>Remplissez les champs suivants :</FormTitle>,
        fields: [
          {
            label: "Puissance de l'ensemble des moteurs sans VEV",
            name: '',
            type: 'textfield',
            textType: 'number',
            unit: 'kW',
            value: enginesPower,
            onChange: (e) => setEnginesPower(Number(e)),
          },
          {
            label: 'Nombre de moteurs sans VEV',
            name: '',
            type: 'textfield',
            textType: 'number',
            unit: 'moteurs',
            value: enginesNumber,
            onChange: (e) => setEnginesNumber(Number(e)),
          },
          {
            label: 'Temps de fonctionnement',
            name: '',
            type: 'textfield',
            textType: 'number',
            unit: 'heures/an',
            value: operatingTime,
            onChange: (e) => setOperatingTime(Number(e)),
          },
        ],
      },
    ]);
  }, [enginesPower, enginesNumber, operatingTime]);

  // consumed energy
  useEffect(() => {
    setConsummedEnergy((enginesPower * operatingTime) / 1000);
  }, [enginesPower, operatingTime]);

  // economized energy
  useEffect(() => {
    setEconomizedEnergy((consummedEnergy * ((30 - 15) / 2)) / 100);
  }, [consummedEnergy]);

  // reduced consuption
  useEffect(() => {
    setReducedConsumption(consummedEnergy - economizedEnergy);
  }, [consummedEnergy, economizedEnergy]);

  // emission reduction
  useEffect(() => {
    setEstimatedReductionInEmission(economizedEnergy * CARBON_CONTENT_PER_KWH_ELECTRICITY?.value);
  }, [economizedEnergy, CARBON_CONTENT_PER_KWH_ELECTRICITY?.value]);

  // financial gain
  useEffect(() => {
    setFinancialGain(economizedEnergy * AVERAGE_ELECTRICITY_PRICE_PER_MWH?.value);
  }, [economizedEnergy, AVERAGE_ELECTRICITY_PRICE_PER_MWH?.value]);

  // investment
  useEffect(() => {
    setInvestmentInMaterials(147.94 * enginesPower + 1614.3 * enginesNumber);
  }, [enginesPower, enginesNumber]);

  // roi
  useEffect(() => {
    setRoi(investmentInMaterials / financialGain);
  }, [investmentInMaterials, financialGain]);

  // result table rows
  useEffect(() => {
    setResultTableRows([
      {
        label: 'Energie consommée par les moteurs sans VEV',
        unit: 'MWh/an',
        value: `${consummedEnergy}`,
      },
      {
        label: "Economie d'énergie moyenne avec installation VEV",
        unit: 'MWh/an',
        value: `${economizedEnergy}`,
      },
      {
        label: 'Consommation réduite',
        unit: 'MWh/an',
        value: `${reducedConsumption}`,
      },
      {
        label: "Baisse d'émissions estimée",
        unit: 'T CO2/an',
        value: `${estimatedReductionInEmission}`,
      },
      {
        label: "Gain financier estimé grâce à l'économie d'énergie",
        unit: '€/an',
        value: `${financialGain}`,
      },
      {
        label: 'Investissement matériel VEV',
        unit: '€',
        value: `${investmentInMaterials}`,
      },
      {
        label: 'ROI (le ROI n’inclut pas les primes et aides (CEE,…))',
        unit: 'ans',
        value: `${roi}`,
      },
      // {
      //   label: 'ROI',
      //   unit: 'mois',
      //   value: `${roi * 12}`,
      // },
    ]);
  }, [
    consummedEnergy,
    economizedEnergy,
    reducedConsumption,
    estimatedReductionInEmission,
    financialGain,
    investmentInMaterials,
    roi,
  ]);

  // roi matrix
  useEffect(() => {
    const investment = -investmentInMaterials;
    const initialEconomy = 0;
    const firstYearRoi = investment + initialEconomy;

    let tmpMatrix: number[][] = [
      [initialEconomy, financialGain],
      [firstYearRoi, financialGain + firstYearRoi],
    ];

    const yearsCount = 8;
    for (let i = 2; i < yearsCount; i++) {
      tmpMatrix[0] = [...tmpMatrix[0], tmpMatrix[0][i - 1] * (1 + 8 / 100)];
      tmpMatrix[1] = [...tmpMatrix[1], tmpMatrix[1][i - 1] + tmpMatrix[0][i]];
    }
    setRoiMatrix(tmpMatrix);
  }, [investmentInMaterials, financialGain]);

  // result charts
  useEffect(() => {
    setResultCharts([
      [
        {
          chartType: 'bar',
          chartTitle: 'Gains énergétiques MWh/an',
          labels: ['Energie consommée par les moteurs sans VEV', 'Consommation réduite'],
          labelsColors: [COLORS.WHITE, COLORS.WHITE],
          labelsBackgroundColors: [COLORS.BRIGHT_BLUE, COLORS.DARK_BLUE],
          datas: {
            labels: ['', ''],
            datasets: [
              {
                data: [consummedEnergy, reducedConsumption],
                backgroundColor: [COLORS.BRIGHT_BLUE, COLORS.DARK_BLUE],
                maxBarThickness: 50,
              },
            ],
          },
          defaultUnit: 'MWh/an',
          yUnit: 'MWh/an',
        },
      ],
      [getChartPropsFromRoiMatrix(roiMatrix[1])],
    ]);
  }, [consummedEnergy, reducedConsumption, roiMatrix]);

  // // estimated gains
  // useEffect(() => {
  //   editSimulatorEstimatedGain('ELECTRIC_SPEED_VARIATION', {
  //     energyEconomy: economizedEnergy,
  //     financialEconomy: financialGain,
  //   });

  // }, [economizedEnergy, financialGain]);

  useEffect(() => {
    const sim = getSimulatorByCode('ELECTRIC_SPEED_VARIATION');
    if (sim) {
      setEnginesPower(Number(sim?.datas?.enginesPower));
      setEnginesNumber(Number(sim?.datas?.enginesNumber));
      setOperatingTime(Number(sim?.datas?.operatingTime));
    }
    // eslint-disable-next-line
  }, [simulators]);

  useEffect(() => {
    if (userToken) {
      const newDatas = {
        enginesPower,
        enginesNumber,
        operatingTime,
      };
      setUpdateDatas(newDatas);
    }
  }, [
    userToken,
    enginesPower,
    enginesNumber,
    operatingTime,
    economizedEnergy,
    financialGain,
    estimatedReductionInEmission,
  ]);

  const updateUserSimulatorDatasFunc = () => {
    // Your logic to update the user simulator datas
    allowRequest &&
      updateDatas &&
      updateUserSimulatorDatas(userToken, 'ELECTRIC_SPEED_VARIATION', updateDatas).then(() => {
        editSimulatorDatas('ELECTRIC_SPEED_VARIATION', updateDatas);
      });
  };

  useDelayedUpdate(updateUserSimulatorDatasFunc, [updateDatas], 250);

  return (
    <ElectricSpeedVariationContext.Provider
      value={{
        enginesPower,
        enginesNumber,
        operatingTime,
        forms,
        consummedEnergy,
        economizedEnergy,
        reducedConsumption,
        estimatedReductionInEmission,
        financialGain,
        investmentInMaterials,
        roi,
        resultTableRows,
        roiMatrix,
        resultCharts,
        energyGainMwhPerYear: economizedEnergy,
        kgCo2EmissionAvoidedPerYear: estimatedReductionInEmission * 1000,
        financialGainPerYear: financialGain,
        estimatedBudget: investmentInMaterials,
        estimatedRoi: roi,
      }}
    >
      {children}
    </ElectricSpeedVariationContext.Provider>
  );
};

export { ElectricSpeedVariationContext, ElectricSpeedVariationProvider };
