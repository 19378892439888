import React, { useEffect, useState } from 'react';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image as ImageBalise,
  Font,
} from '@react-pdf/renderer';
import { COLORS } from '../../../styles/constants/colors';
import { ISIMULATORS_NAME, ISimulator, ISimulatorEstimatedEconomy } from '../../../types/app';
import { FiltersDetailedReportType, ISimulatorsDatas } from '../DetailedReport';
import { RESPONSABILITY_NOTE, SIMULATOR_LABELS_TYPE } from '../../../global';
import PdfTable from './pdf-table/PdfTable';
import { ChartProps } from '../../../components/chart/Chart';
import fontMedium from './fonts/Jost-Medium.ttf';
import fontBold from './fonts/Jost-Bold.ttf';
import Logo from '../../../assets/images/logo-actemium.png';
import { ITableRow } from '../../../components/table/Table';
import { getSimulatorDatasByCode } from '../../../utils';
import PdfSimulatorsRecapTable, {
  ISimulatorRecap,
} from './pdf-simulators-recap-table/PdfSimulatorsRecapTable';
import PdfChart from './pdf-chart/PdfChart';

Font.register({
  family: 'Jost',
  fonts: [
    {
      src: fontMedium,
    },
    {
      src: fontBold,
      fontWeight: 'bold',
    },
  ],
});

export interface PdfDetailedReportProps {
  simulators: ISimulator[];
  totalSimulatorsEconomy: ISimulatorEstimatedEconomy;
  simulatorsDatas: ISimulatorsDatas;
  simulatorsRecap: ISimulatorRecap[];
  graphsImages?: any[];
}

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#f9fcff',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    width: '100%',
    height: '100%',
    padding: 20,
    color: COLORS.DARK_BLUE,
    fontFamily: 'Jost',
  },
  note: {
    fontSize: 11,
    textAlign: 'center',
    color: `${COLORS.DARK_BLUE}80`,
    margin: '20px 0',
    fontWeight: 'normal',
    backgroundColor: 'white',
    borderRadius: 16,
    padding: 20,
    border: `1pt solid ${COLORS.LIGHT_BLUE}`,
  },
  titlePage: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 30,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
  },
  titleSection: {
    fontSize: 25,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
  },
  section: {
    padding: 10,
    width: '100%',
    fontSize: 15,
  },
  sectionTitleWrapper: {
    marginBottom: 50,
    padding: 15,
    backgroundColor: COLORS.LIGHT_BLUE,
  },
  sectionTitle: {
    fontSize: 15,
    textAlign: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  logo: {
    width: 170,
    maxWwidth: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  detailedResults: {
    display: 'flex',
    gap: 20,
    marginBottom: 10,
  },
  detailedResultsTitle: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    position: 'absolute',
    top: 5,
    left: 5,
  },
  gains: {
    display: 'flex',
    gap: 20,
    fontSize: 10,
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  gain: {
    fontSize: 12,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
  gainImage: {
    width: 15,
    height: 15,
  },
  gainText: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  gainValue: {
    fontWeight: 'normal',
  },
});

const formatSimulatorDatasChartArrays = (simDatasCharts: ChartProps[][]): ChartProps[] => {
  const res: ChartProps[] = [];
  for (let i = 0; i < simDatasCharts.length; i++) {
    for (let j = 0; j < simDatasCharts[i].length; j++) {
      res.push(simDatasCharts[i][j]);
    }
  }
  return res;
};

const PdfDetailedReport: React.FC<PdfDetailedReportProps> = ({
  simulators,
  simulatorsDatas,
  simulatorsRecap,
  graphsImages,
}) => {
  const [filteredSimulators, setFilteredSimulators] = useState<ISimulator[]>();

  useEffect(() => {
    if (!simulators) return;
    setFilteredSimulators(
      simulators.filter(
        (sim) =>
          sim.code === 'INDUSTRIAL_SITE' ||
          (getSimulatorDatasByCode(sim.code as ISIMULATORS_NAME, simulatorsDatas)?.financialGain &&
            getSimulatorDatasByCode(sim.code as ISIMULATORS_NAME, simulatorsDatas)?.energyGain &&
            getSimulatorDatasByCode(sim.code as ISIMULATORS_NAME, simulatorsDatas)?.co2Gain),
      ),
    );
  }, [simulators, simulatorsDatas]);

  const generateSimulator = (filter: FiltersDetailedReportType) => {
    const checkShowFilter = (
      filter: FiltersDetailedReportType,
      labels: SIMULATOR_LABELS_TYPE[],
    ): boolean => {
      // if (filter === 'gainfinancier') return true;
      // else if (filter === 'decarbonation') return labels.includes('Décarbonation');
      // else if (filter === 'economiedenergie') return labels.includes('Efficacité énergétique');
      return true;
    };

    return (
      <>
        {filteredSimulators &&
          filteredSimulators.map((item, key) => {
            let simDatasCharts: ChartProps[] = [];
            try {
              simDatasCharts = formatSimulatorDatasChartArrays(
                simulatorsDatas[item.code as any]?.resultCharts,
              );
            } catch (err) {}
            return (
              <React.Fragment key={key}>
                {checkShowFilter(filter, item.labels) && (
                  <>
                    {/* <View style={styles.section}> */}
                    <View break />
                    {simulatorsDatas && simulatorsDatas[item.code as any] && (
                      <PdfTable
                        header={<Text>{item.name}</Text>}
                        rows={
                          !!simulatorsDatas[item.code as any]
                            ? simulatorsDatas[item.code as any]?.resultTableRows
                            : []
                        }
                        highlightRows={[
                          filter === 'gainfinancier' &&
                          simulatorsDatas[item.code as ISIMULATORS_NAME]?.financialGain
                            ? {
                                ...(simulatorsDatas[item.code as ISIMULATORS_NAME]
                                  ?.financialGain as ITableRow),
                              }
                            : { label: '' },
                          filter === 'economiedenergie' &&
                          simulatorsDatas[item.code as ISIMULATORS_NAME]?.energyGain
                            ? {
                                ...(simulatorsDatas[item.code as ISIMULATORS_NAME]
                                  ?.energyGain as ITableRow),
                              }
                            : { label: '' },
                          filter === 'decarbonation' &&
                          simulatorsDatas[item.code as ISIMULATORS_NAME]?.co2Gain
                            ? {
                                ...(simulatorsDatas[item.code as ISIMULATORS_NAME]
                                  ?.co2Gain as ITableRow),
                              }
                            : { label: '' },
                        ]}
                      />
                    )}
                    {simDatasCharts && graphsImages && (
                      <>
                        {simDatasCharts?.map((chart: ChartProps, index: number) => {
                          try {
                            const imageUrl = graphsImages[key][index];
                            return (
                              <React.Fragment key={key}>
                                {imageUrl && index === 0 && <View break />}
                                {imageUrl && (
                                  <PdfChart
                                    key={key}
                                    chartTitle={chart.chartTitle || ''}
                                    chartImage={imageUrl}
                                    chartLabels={chart.labels || []}
                                    chartLabelBackgroundColors={chart.labelsBackgroundColors || []}
                                  />
                                )}
                              </React.Fragment>
                            );
                          } catch (err) {
                            return <React.Fragment key={index}></React.Fragment>;
                          } finally {
                          }
                        })}
                      </>
                    )}
                  </>
                )}
              </React.Fragment>
            );
          })}
      </>
    );
  };

  return (
    <Document title="Resutats détaillés de votre simulation">
      <Page size="A4" style={styles.page}>
        <View style={styles.detailedResults}>
          <View style={styles.detailedResultsTitle}>
            <ImageBalise style={styles.logo} src={Logo} />
          </View>
        </View>

        {
          //Grand titre
        }
        <View style={styles.titlePage}>
          <Text style={styles.title}>Résultats détaillés de la simulation</Text>
        </View>
        <View break />
        <>
          {
            // Gain estimé
          }
          <View style={styles.titlePage}>
            <View style={styles.titleSection}>
              <ImageBalise
                style={{
                  width: 30,
                  height: 30,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                src={require('../../../assets/images/coins.png')}
              />
              <Text>Gains estimés</Text>
            </View>
          </View>
          {generateSimulator('gainfinancier')}
        </>
        <>
          {
            // Économies d'énergie
          }
          <View style={styles.titlePage} break>
            <View style={styles.titleSection}>
              <ImageBalise
                style={{
                  width: 30,
                  height: 30,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                src={require('../../../assets/images/leaf.png')}
              />
              <Text>Économies d'énergie</Text>
            </View>
          </View>
          {generateSimulator('economiedenergie')}
        </>
        <>
          {
            // Décarbonation
          }
          <View style={styles.titlePage} break>
            <View style={styles.titleSection}>
              <ImageBalise
                style={{
                  width: 30,
                  height: 30,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                src={require('../../../assets/images/decrease.png')}
              />
              <Text>Décarbonation</Text>
            </View>
          </View>
          {generateSimulator('decarbonation')}
        </>
        <>
          {
            //Récapitulatif
          }
          <View style={styles.titlePage} break>
            <View style={styles.titleSection}>
              <Text>Tableau récapitulatif</Text>
            </View>
          </View>
          <View break />
          <PdfSimulatorsRecapTable
            simulatorsRecap={simulatorsRecap.filter(
              (simRecap) => simRecap.simulator.code !== 'INDUSTRIAL_SITE',
            )}
          />
        </>
        <Text break style={styles.note}>
          {RESPONSABILITY_NOTE}
        </Text>
      </Page>
    </Document>
  );
};

export default PdfDetailedReport;
