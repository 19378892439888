import DefaultModalLayout from '../../../../../components/Modal/default-Layout/DefaultModalLayout';
import { PopupSuccessContactWrapper } from './PopupSuccessContact.styled';
import Olivier from '../../../../../assets/images/olivier.png';
import { ICONS, ROUTES } from '../../../../../global';
import { useContext } from 'react';
import { ModalContext, ModalContextProps } from '../../../../../context/ModalContext';
import useCustomNavigate from '../../../../../hooks/useCustomNavigate';
import { testShowDownloadPdf } from '../../../../../utils';
import {
  SimulatorsContext,
  SimulatorsContextProps,
} from '../../../../../context/simulators/SimulatorsContext';
import {
  SimulatorsSummaryContext,
  SimulatorsSummaryContextProps,
} from '../../../../../context/simulators/summary/SimulatorsSummaryContext';

const PopupSuccessContact: React.FC = () => {
  const { updateModalConf } = useContext(ModalContext) as ModalContextProps;
  const navigate = useCustomNavigate();
  const { simulators } = useContext(SimulatorsContext) as SimulatorsContextProps;
  const { contexts } = useContext(SimulatorsSummaryContext) as SimulatorsSummaryContextProps;

  return (
    <PopupSuccessContactWrapper>
      <DefaultModalLayout
        title="Merci pour ces précieuses informations !"
        showCloseIcon
        cta={{
          ...(contexts && testShowDownloadPdf(simulators, contexts)
            ? {
                label: 'Revenir sur mon rapport détaillé',
                onClick: () => {
                  navigate(ROUTES.DETAILED_REPORT);
                  updateModalConf();
                },
              }
            : {
                label: 'Fermer',
                onClick: () => {
                  updateModalConf();
                },
              }),
        }}
      >
        <div className="body">
          <div className="photo">
            <img src={Olivier} alt="olivier" className="olivier" />
            <span className="icon-check">{ICONS.CHECK.icon}</span>
          </div>
          <div className="small-title">
            « Vous avez désormais complété notre formulaire de prise de contact. Nous allons
            examiner vos données et vous recontacter rapidement. Vous allez recevoir votre rapport
            détaillé en complément par email. »
          </div>
        </div>
      </DefaultModalLayout>
    </PopupSuccessContactWrapper>
  );
};

export default PopupSuccessContact;
