import Table, { TableProps } from '../../../components/table/Table';
import { IIcon } from '../../../types/app';
import { TableDetailedReportWrapper, TitleTableDetailedReport } from './TableDetailedReport.styled';

export interface TableDetailedReportProps extends TableProps {
  header?: {
    icon: IIcon;
    title: string;
    labels: string[];
  };
  isEligibleCEE?: boolean;
}

const TableDetailedReport: React.FC<TableDetailedReportProps> = ({
  rows,
  header,
  highlightRow,
  isEligibleCEE,
}) => {
  return (
    <TableDetailedReportWrapper>
      <Table
        header={
          <TitleTableDetailedReport>
            <div className="left-title">
              <div className="icon">{header?.icon?.icon}</div>
              <div className="title">{header?.title}</div>
            </div>
            {isEligibleCEE && (
              <div className="right-title">
                <div className="label">Eligible CEE</div>
              </div>
            )}
          </TitleTableDetailedReport>
        }
        rows={rows}
        highlightRow={highlightRow}
      />
    </TableDetailedReportWrapper>
  );
};

export default TableDetailedReport;
