import React, { createContext, useContext, useEffect, useState } from 'react';
import { IComponentWithChildren, IReferenceData, REFERENCE_DATA_NAME_TYPE } from '../types/app';
// import jsonReferenceDatas from '../data-reference-v1.0.json';
// import { REFERENCE_DATAS_LOCALSTORAGE, REFERENCE_FIELD_NAMES } from '../global';
import { UserContext, UserContextProps } from './user/UserContext';
// import { UserContext, UserContextProps } from './user/UserContext';
// import { UserApi } from '../api/User.api';

export interface IReferenceDatas {
  ELECTRICITY_CONSUMPTIONS: IReferenceData;
  ELECTRICITY_PRICE: IReferenceData;
  AVERAGE_GAS_PRICE: IReferenceData;
  RECOVERABLE_ENERGY_PERCENTAGE_COMPRESSED_AIR: IReferenceData;
  ELECTRICITY_DISSIPATED_HEAT_PERCENTAGE_CHILLER: IReferenceData;
  CARBON_CONTENT_PER_KWH_ELECTRICITY: IReferenceData;
  CARBON_CONTENT_PER_KWH_GAZ: IReferenceData;
  AVERAGE_GAS_PRICE_PER_MWH: IReferenceData;
  AVERAGE_ELECTRICITY_PRICE_PER_MWH: IReferenceData;
  NATURAL_GAS_TRENDS: IReferenceData;
  NATURAL_ELECTRICITY_TRENDS: IReferenceData;
}

export interface ReferenceDatasContextProps extends IReferenceDatas {
  initializeDatasFromJsonFile?: () => any;
  updateReferenceDatas: (referenceCode: REFERENCE_DATA_NAME_TYPE, newValue: number) => void;
  referenceDatas: IReferenceDatas;
}

export const REFERENCE_DATA_NAMES = [
  'ELECTRICITY_CONSUMPTIONS',
  'ELECTRICITY_PRICE',
  'AVERAGE_GAS_PRICE',
  'RECOVERABLE_ENERGY_PERCENTAGE_COMPRESSED_AIR',
  'ELECTRICITY_DISSIPATED_HEAT_PERCENTAGE_CHILLER',
  'CARBON_CONTENT_PER_KWH_ELECTRICITY',
  'CARBON_CONTENT_PER_KWH_GAZ',
  'AVERAGE_GAS_PRICE_PER_MWH',
  'AVERAGE_ELECTRICITY_PRICE_PER_MWH',
  'NATURAL_GAS_TRENDS',
  'NATURAL_ELECTRICITY_TRENDS',
] as Array<REFERENCE_DATA_NAME_TYPE>;

const ReferenceDatasContext = createContext<ReferenceDatasContextProps | undefined>(undefined);
const ReferenceDatasProvider: React.FC<IComponentWithChildren> = ({ children }) => {
  const [referenceDatas, setReferenceDatas] = useState<IReferenceDatas>();

  const { user } = useContext(UserContext) as UserContextProps;

  // useEffect(() => {
  //   if (userToken) {
  //     UserApi.getUserDetails(userToken).then((e) => {});
  //   }
  // }, [userToken]);

  // const formatJsonFieldToReferenceData = useCallback(
  //   (field: keyof typeof jsonReferenceDatas): IReferenceData => {
  //     return {
  //       comment: jsonReferenceDatas[field].comment,
  //       description: jsonReferenceDatas[field].description,
  //       source: jsonReferenceDatas[field].source,
  //       unit: jsonReferenceDatas[field].unit,
  //       // eslint-disable-next-line
  //       value: eval(jsonReferenceDatas[field].value) || 0,
  //     };
  //   },
  //   [],
  // );

  // useEffect(() => {
  //   if (referenceDatas) {
  //     localStorage.setItem(REFERENCE_DATAS_LOCALSTORAGE, JSON.stringify(referenceDatas));
  //   }
  // }, [referenceDatas]);

  // const getReferenceDatasFromJsonFile = useCallback((): IReferenceDatas => {
  //   // let res: IReferenceDatas | undefined = undefined;

  //   // REFERENCE_DATA_NAMES.map((refDataName) => {});

  //   return {
  //     ELECTRICITY_CONSUMPTIONS: formatJsonFieldToReferenceData(
  //       REFERENCE_FIELD_NAMES.ELECTRICITY_CONSUMPTIONS,
  //     ),
  //     ELECTRICITY_PRICE: formatJsonFieldToReferenceData(REFERENCE_FIELD_NAMES.ELECTRICITY_PRICE),
  //     AVERAGE_GAS_PRICE: formatJsonFieldToReferenceData(REFERENCE_FIELD_NAMES.AVERAGE_GAS_PRICE),
  //     RECOVERABLE_ENERGY_PERCENTAGE_COMPRESSED_AIR: formatJsonFieldToReferenceData(
  //       REFERENCE_FIELD_NAMES.RECOVERABLE_ENERGY_PERCENTAGE_COMPRESSED_AIR,
  //     ),
  //     ELECTRICITY_DISSIPATED_HEAT_PERCENTAGE_CHILLER: formatJsonFieldToReferenceData(
  //       REFERENCE_FIELD_NAMES.ELECTRICITY_DISSIPATED_HEAT_PERCENTAGE_CHILLER,
  //     ),
  //     CARBON_CONTENT_PER_KWH_ELECTRICITY: formatJsonFieldToReferenceData(
  //       REFERENCE_FIELD_NAMES.CARBON_CONTENT_PER_KWH_ELECTRICITY,
  //     ),
  //     CARBON_CONTENT_PER_KWH_GAZ: formatJsonFieldToReferenceData(
  //       REFERENCE_FIELD_NAMES.CARBON_CONTENT_PER_KWH_GAZ,
  //     ),
  //     AVERAGE_GAS_PRICE_PER_MWH: formatJsonFieldToReferenceData(
  //       REFERENCE_FIELD_NAMES.AVERAGE_GAS_PRICE_PER_MWH,
  //     ),
  //     AVERAGE_ELECTRICITY_PRICE_PER_MWH: formatJsonFieldToReferenceData(
  //       REFERENCE_FIELD_NAMES.AVERAGE_ELECTRICITY_PRICE_PER_MWH,
  //     ),
  //     NATURAL_GAS_TRENDS: formatJsonFieldToReferenceData(REFERENCE_FIELD_NAMES.NATURAL_GAS_TRENDS),
  //     NATURAL_ELECTRICITY_TRENDS: formatJsonFieldToReferenceData(
  //       REFERENCE_FIELD_NAMES.NATURAL_ELECTRICITY_TRENDS,
  //     ),
  //   };
  // }, [formatJsonFieldToReferenceData]);

  // const isValidReferenceData = (data: IReferenceData): data is IReferenceData => {
  //   return (
  //     typeof data === 'object' &&
  //     'description' in data &&
  //     'value' in data &&
  //     'unit' in data &&
  //     'source' in data &&
  //     'comment' in data
  //   );
  // };

  // const getSingleReferenceData = useCallback(
  //   (field: keyof typeof jsonReferenceDatas, data: IReferenceData): IReferenceData => {
  //     return isValidReferenceData(data)
  //       ? data
  //       : formatJsonFieldToReferenceData(REFERENCE_FIELD_NAMES[field]);
  //   },
  //   [formatJsonFieldToReferenceData],
  // );

  // const isReferenceDataNameType = (key: string): key is REFERENCE_DATA_NAME_TYPE => {
  //   return REFERENCE_DATA_NAMES.includes(key as REFERENCE_DATA_NAME_TYPE);
  // };

  // const checkMissingReferenceFromLocalStorage = useCallback(
  //   (datas: IReferenceDatas): IReferenceDatas => {
  //     if (datas) {
  //       REFERENCE_DATA_NAMES.forEach((name) => {
  //         if (!datas[name]) {
  //           console.log(`${name} doesn't exist`);
  //           datas = {
  //             ...datas,
  //             [name]: formatJsonFieldToReferenceData(REFERENCE_FIELD_NAMES[name]),
  //           };
  //         }
  //       });
  //     }
  //     return datas;
  //   },
  //   [formatJsonFieldToReferenceData],
  // );

  // const getReferenceDatasFromLocalStorage = useCallback((): IReferenceDatas | undefined => {
  //   try {
  //     const datas: IReferenceDatas = JSON.parse(
  //       localStorage.getItem(REFERENCE_DATAS_LOCALSTORAGE) as string,
  //     );
  //     let res: IReferenceDatas | any = undefined;
  //     if (datas) {
  //       Object.keys(datas).forEach((key) => {
  //         if (isReferenceDataNameType(key)) {
  //           res = {
  //             ...res,
  //             [key]: getSingleReferenceData(key as keyof typeof jsonReferenceDatas, datas[key]),
  //           };
  //         }
  //       });
  //     }
  //     res = checkMissingReferenceFromLocalStorage(res);
  //     return res;
  //   } catch (err) {
  //     return undefined;
  //   }
  // }, [checkMissingReferenceFromLocalStorage, getSingleReferenceData]);

  // const initializeDatasFromJsonFile = useCallback(() => {
  //   const referenceDatasFromJsonFile = getReferenceDatasFromJsonFile();
  //   setReferenceDatas(referenceDatasFromJsonFile);
  //   localStorage.setItem(REFERENCE_DATAS_LOCALSTORAGE, JSON.stringify(referenceDatasFromJsonFile));
  // }, [getReferenceDatasFromJsonFile]);

  // useEffect(() => {
  //   const datasFromLocalStorage = getReferenceDatasFromLocalStorage();
  //   if (datasFromLocalStorage) setReferenceDatas(datasFromLocalStorage);
  //   else {
  //     initializeDatasFromJsonFile();
  //   }
  // }, [
  //   getReferenceDatasFromJsonFile,
  //   getReferenceDatasFromLocalStorage,
  //   initializeDatasFromJsonFile,
  // ]);

  const updateReferenceDatas = (referenceCode: REFERENCE_DATA_NAME_TYPE, newValue: number) => {
    if (!referenceDatas) return;
    setReferenceDatas({
      ...(referenceDatas as IReferenceDatas),
      [referenceCode]: {
        ...referenceDatas[referenceCode],
        value: newValue,
      } as IReferenceData,
    });
  };

  useEffect(() => {
    if (user && user?.referenceDatas) {
      setReferenceDatas(user.referenceDatas);
    }
  }, [user]);

  return (
    <ReferenceDatasContext.Provider
      value={
        {
          ...referenceDatas,
          referenceDatas,
          updateReferenceDatas,
          // initializeDatasFromJsonFile,
        } as ReferenceDatasContextProps
      }
    >
      {children}
    </ReferenceDatasContext.Provider>
  );
};

export { ReferenceDatasContext, ReferenceDatasProvider };
