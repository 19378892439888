import styled from 'styled-components';
import { COLORS } from '../../styles/constants/colors';
import { BODY } from '../../styles/helpers/typography';

const ChartWrapper = styled.div`
  background-color: ${COLORS.WHITE};
  padding: 20px;
  border-radius: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
  // max-height: 500px;

  .chart-title {
    ${BODY.S_SEMI_BOLD}
    text-align: center;
    margin: 0;
  }

  .chart-labels {
    display: flex;
    gap: 10px;
    justify-content: center;
    text-transform: uppercase;
    ${BODY.XS_SEMI_BOLD}
    font-weight: bolder;

    .chart-label {
      padding: 4px 7px;
      border-radius: 5px;
    }
  }

  .chart {
    max-height: 400px;
    max-width: 100%;
  }
  // align-items: center;
`;

export { ChartWrapper };
