import { useContext } from 'react';
import { ISimulator } from '../../../../types/app';
import SimulatorLayout from '../../layout/SimulatorLayout';
import { MonitoringWrapper } from './Monitoring.styled';
import {
  MonitoringContext,
  MonitoringContextProps,
} from '../../../../context/simulators/MonitoringContext';

export interface MonitoringProps {
  simulator?: ISimulator;
}

const Monitoring: React.FC<MonitoringProps> = ({ simulator }) => {
  const { forms, resultTableRows, resultCharts } = useContext(
    MonitoringContext,
  ) as MonitoringContextProps;

  return (
    <MonitoringWrapper>
      {simulator && (
        <SimulatorLayout
          forms={forms}
          resultTableRows={(resultTableRows && resultTableRows) || []}
          resultCharts={resultCharts}
          simulator={simulator}
        />
      )}
    </MonitoringWrapper>
  );
};

export default Monitoring;
