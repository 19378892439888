import styled from 'styled-components';
import { BODY } from '../../styles/helpers/typography';
import { COLORS } from '../../styles/constants/colors';
import { BREAKPOINTS } from '../../styles/constants/devices';

const NavbarWrapper = styled.nav`
  height: 61px;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 998;
  background-color: ${COLORS.WHITE};
  border: 1px solid #0028541a;

  .logo {
    width: 60px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;

    @media (${BREAKPOINTS.L}) {
      border-right: 1px solid #0028541a;
    }

    &:hover {
      cursor: pointer;
    }

    img {
      width: 40px;
    }
  }

  .nav {
    display: flex;
    flex: 1;
    align-items: center;
    padding: 0 0 0 20px;
    gap: 7px;
    justify-content: space-between;

    .nav-left {
      display: flex;
      align-items: center;
      gap: 10px;

      .icon {
        color: ${COLORS.DARK_BLUE};
        display: none;

        @media (${BREAKPOINTS.L}) {
          display: flex;
        }
      }

      .title {
        ${BODY.XL_MEDIUM}
        color: ${COLORS.DARK_BLUE};
        display: flex;
        align-items: center;
        gap: 10px;

        .view-mode-tag {
          display: flex;
          align-items: center;
          gap: 10px;
          ${BODY.S_SEMI_BOLD}
        }
      }
    }

    .download-report-nav {
      display: none;
      cursor: pointer;
      background: ${COLORS.BRIGHT_BLUE};
      height: 100%;
      justify-content: center;
      align-items: center;
      padding: 0 20px;
      color: ${COLORS.WHITE};
      ${BODY.M_BOLD}
      transition: .2s linear;
      user-select: none;

      &: hover {
        background: ${COLORS.DARK_BLUE};
      }

      @media (${BREAKPOINTS.L}) {
        border-right: 1px solid #0028541a;
        display: flex;
      }
    }

    .nav-right {
      padding-right: 20px;
      &:hover {
        cursor: pointer;
      }

      @media (${BREAKPOINTS.L}) {
        display: none;
      }
    }
  }
`;

export { NavbarWrapper };
