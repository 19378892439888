import { useEffect, useState } from 'react';
import { SwitcherWrapper } from './Switcher.styled';

export interface SwitcherProps {
  disabled?: boolean;
  onSwitch?: (e: boolean) => void;
  label?: string;
}

const Switcher: React.FC<SwitcherProps> = ({ disabled, onSwitch, label }) => {
  const [disabledState, setDisableState] = useState<boolean | undefined>(disabled);

  useEffect(() => {
    setDisableState(disabled);
  }, [disabled]);

  return (
    <SwitcherWrapper>
      <div
        className={`toggle-switch ${disabledState && 'disabled'}`}
        onClick={() => {
          onSwitch && onSwitch(disabledState === true ? true : false);
          setDisableState(!disabledState);
        }}
      >
        <div className="switch-ball" />
      </div>
      {label && <div className="switch-label">{label}</div>}
    </SwitcherWrapper>
  );
};

export default Switcher;
