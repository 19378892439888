import { useContext, useEffect, useState } from 'react';
import { AdminWrapper } from './Admin.styled';
import { getAdminTokenFromLocalstorage } from '../../utils';
import { getAdminDetails } from '../../api/Admin.api';
import toast from 'react-hot-toast';
import { APP_TITLE, ROUTES } from '../../global';
import { Loader } from '../../App.styled';
import Progress from '../../components/progress/Progress';
import { UserContext, UserContextProps } from '../../context/user/UserContext';
import { Route, Routes } from 'react-router-dom';
import NotFound from '../404/NotFound';
import UsersList from './users/list/UsersList';
import ReferenceDatasList from './reference-datas/list/ReferenceDatasList';
import { AdminContext, AdminContextProps } from '../../context/admin/AdminContext';
import EditReferenceDatas from './reference-datas/edit/EditReferenceDatas';

export interface AdminProps {}

const Admin: React.FC = () => {
  const [authChecked, setAuthChecked] = useState<boolean>(false);
  const { setIsAdminView } = useContext(UserContext) as UserContextProps;
  const { setAdmin } = useContext(AdminContext) as AdminContextProps;

  useEffect(() => {
    document.title = `Admin - ${APP_TITLE}`;
  }, []);

  useEffect(() => {
    const checkAdminAuth = async () => {
      try {
        const adminToken = localStorage.getItem(getAdminTokenFromLocalstorage()) || '';
        const adminDetails = await getAdminDetails(adminToken);
        if (adminDetails) {
          setAdmin({
            ...adminDetails,
            token: adminToken,
          });
          toast.success("Vous êtes connecté en tant qu'administrateur", {
            id: 'auth',
          });
          setIsAdminView(true);
          setAuthChecked(true);
        } else {
          setAuthChecked(false);
          setIsAdminView(false);
          window.location.href = ROUTES.ADMIN.LOGIN;
        }
      } catch (err) {
        setAuthChecked(false);
        setIsAdminView(false);
        window.location.href = ROUTES.ADMIN.LOGIN;
      }
    };
    checkAdminAuth();
  }, [setIsAdminView, setAdmin]);
  return (
    <>
      {!authChecked ? (
        <Loader>
          <Progress />
        </Loader>
      ) : (
        <AdminWrapper>
          <Routes>
            <Route path={ROUTES.ADMIN.HOME} element={<UsersList />} />
            <Route path={ROUTES.ADMIN.USERS.LIST} element={<UsersList />} />
            <Route path={ROUTES.ADMIN.REFERENCE_DATAS.LIST} element={<ReferenceDatasList />} />
            <Route path={`${ROUTES.ADMIN.REFERENCE_DATAS.EDIT}/:version_id`} element={<EditReferenceDatas />} />
            <Route path={'*'} element={<NotFound />} />
          </Routes>
        </AdminWrapper>
      )}
    </>
  );
};

export default Admin;
