import styled from 'styled-components';
import { BODY } from '../../styles/helpers/typography';
import { COLORS } from '../../styles/constants/colors';

const ModalTitle = styled.div`
  ${BODY.XL_SEMI_BOLD}
  color: ${COLORS.DARK_BLUE};
  display: flex;
  align-items: center;

  .close-icon {
    height: 20px;
    width: 20px;

    svg {
      height: 20px;
      width: 20px;
    }

    &:hover {
      cursor: pointer;
    }
  }
`;

const ModalWrapper = styled.div`
  position: relative;
`;

export { ModalWrapper, ModalTitle };
