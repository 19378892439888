import styled from 'styled-components';
import { ModalTitle } from '../../../../../components/Modal/Modal.styled';
import { COLORS } from '../../../../../styles/constants/colors';
import { BODY } from '../../../../../styles/helpers/typography';
import { ButtonWrapper } from '../../../../../components/button/Button.styled';
import { BREAKPOINTS } from '../../../../../styles/constants/devices';
import { ProgressWrapper } from '../../../../../components/progress/Progress.styled';

const PopupContactUsWrapper = styled.div`
  width: 800px;
  max-width: 90%;
  max-height: 80vh;
  padding: 5%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: ${COLORS.DARK_BLUE};

  ${ModalTitle} {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      margin: 0;
      flex: 1;
    }
  }

  .contact-header {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: space-between;

    .olivier-img {
      width: 110px;
    }

    .explanations {
      ${BODY.M_REGULAR}
      max-width: 90%;
      padding: 15px;
      border-radius: 10px;
      background-color: #0084ed1a;
    }
  }

  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 50px;

    .form-col {
      display: flex;
      flex-direction: column;
      gap: 25px;
    }

    @media (${BREAKPOINTS.M}) {
      padding-bottom: 0;

      .form-col {
        flex-direction: row;
      }
    }
  }

  ${ButtonWrapper} {
    width: max-content;
    margin: 0 auto;
    padding-top: 8px;
    padding-bottom: 8px;

    &.disabled {
      background-color: ${COLORS.BLUE_GRAY};
    }
  }

  ${ProgressWrapper} {
    margin: 0 auto;
  }
`;

export { PopupContactUsWrapper };
