import PageLayout from '../../layout/page-layout/PageLayout';
import { NotFoundWrapper } from './NotFound.styled';

const NotFound: React.FC = () => {
  return (
    <PageLayout>
      <NotFoundWrapper></NotFoundWrapper>
    </PageLayout>
  );
};

export default NotFound;
