import styled from 'styled-components';
import { BODY, H4 } from '../../../../styles/helpers/typography';

const SingleReferenceInformationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  max-width: 100%;
  background: black;
  padding: 20px;
  border: 1px solid #d3e6fc4d;
  border-radius: 6px;
  background: linear-gradient(0deg, rgb(211 230 252 / 5%), rgb(211 230 252 / 4%)),
    linear-gradient(0deg, rgb(211 230 252 / 0%), rgba(211, 230, 252, 0.1));

  .price {
    display: flex;
    max-width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    .left {
      ${BODY.L_MEDIUM}
    }

    .right {
      display: flex;
      gap: 10px;
      align-items: center;

      .value {
        ${H4}
        font-weight: 700;
        line-height: 34.68px;
      }
    }
  }

  .trends {
    display: flex;
    max-width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    .left {
      ${BODY.S_REGULAR}
    }

    .right {
      display: flex;
      gap: 10px;
      align-items: center;
      ${BODY.S_MEDIUM}
    }
  }
`;

export { SingleReferenceInformationsWrapper };
