import { ChartProps } from './components/chart/Chart';
import { IReferenceDatas } from './context/ReferenceDatasContext';
import { IndustrialSiteContextProps } from './context/simulators/IndustrialSiteContext';
import { API, NAVIGATION, ROUTES, SIMULATORS_NAMES } from './global';
import { ISimulatorDatas, ISimulatorsDatas } from './pages/detailed-report/DetailedReport';
import { COLORS } from './styles/constants/colors';
import {
  INavigation,
  ISIMULATORS_NAME,
  ISimulator,
  ISimulatorEstimatedEconomy,
  ISimulatorRequiredDatas,
} from './types/app';

export function getViewOnlyUserTokenFromLocalstorage(): string {
  const combinedString = 'vt_' + API.BASE_URL;
  return combinedString;
}

export function getUserTokenFromLocalstorage(): string {
  const combinedString = 'token_' + API.BASE_URL;
  return combinedString;
}

export function getAdminTokenFromLocalstorage(): string {
  const combinedString = 'adtoken' + API.BASE_URL;
  return combinedString;
}

export const getNavigationFromRoute = (route: string): INavigation | undefined => {
  return (
    Object.values(NAVIGATION).find((navigation) => navigation.route === route) || {
      iconInformations: {
        icon: '',
        name: '404 - Page non trouvée',
      },
      route: ROUTES.NOT_FOUND,
    }
  );
};

export const skipIntro = () => {
  return localStorage.getItem('skipIntro') === '1' ? true : false;
};

export const kebabCase = (str: string): string => {
  return str.replace(/[A-Z]/g, (match) => `-${match.toLowerCase()}`);
};

export const snakeCase = (str: string): string => {
  return str.toLowerCase().replaceAll(' ', '_');
};

export const isNumber = (value: string): boolean => {
  // Check if the value is empty
  if (value === '') {
    return false;
  }

  // Check if the value can be converted to a number
  const numericValue = Number(value);
  if (!isNaN(numericValue)) {
    // Check if the minus sign appears only once at the beginning
    if (value[0] === '-' || value[0] === '+') {
      return value.indexOf('-', 1) === -1 || value.indexOf('+', 1) === -1;
    }
    return true;
  }

  // Check if the value is just a minus sign
  return value === '-' || value === '+';
};

export const formatNumber = (number: string | number) => {
  if (typeof number === 'string') {
    if (isNumber(number)) {
      return Number(number).toLocaleString('fr', {
        compactDisplay: 'short',
      });
    } else {
      return number; // Return the original string if it is not a valid number
    }
  } else {
    return number.toLocaleString('fr', {
      compactDisplay: 'short',
    });
  }
};

export const defaultRound = (value: number): number => {
  return Math.round(value * 100) / 100;
};

export const customRound = (value: number, digits: number): number => {
  const factor = Math.pow(10, digits);
  return Math.round(value * factor) / factor;
};

export const customRoundDown = (value: number, digits: number): number => {
  const factor = Math.pow(10, digits);
  return Math.floor(value * factor) / factor;
};

export const customRoundUp = (value: number, digits: number): number => {
  const factor = Math.pow(10, digits);
  return Math.ceil(value * factor) / factor;
};

export const editBodyOverflow = (overflow: 'auto' | 'hidden' | 'scroll'): void => {
  const body = document.body;
  body.style.overflowY = overflow;
};

// export const sortNumbersArray = (array: number[]) => {
//   let tmpArr = [...array];
//   for (let i = 0; i < tmpArr.length / 2; i++) {
//     for(let j = i; j < tmpArr.length / 2; j++){

//     }
//   }
// };

export const getValueFrom2DMatrix = (
  twoDMatrix: number[][],
  arrayIndexToLookup: number,
  arrayIndexValueToReturn: number,
  valueToSearch: number,
): number => {
  if (twoDMatrix.length <= arrayIndexToLookup || twoDMatrix.length <= arrayIndexValueToReturn)
    return 0;

  // Maybe before next lines, we need to sort the array
  let index = -1;

  for (let i = 0; i < twoDMatrix[arrayIndexToLookup].length; i++) {
    const value = twoDMatrix[arrayIndexToLookup][i];
    if (`${value}` === `${valueToSearch}`) {
      index = i;
      break;
    }
    if (value > valueToSearch) {
      index = i - 1;
      break;
    }
  }

  if (index < 0) return 0;
  return twoDMatrix[arrayIndexValueToReturn][index] || 0;
};

export const getChartPropsFromRoiMatrix = (roiMatrix: number[]): ChartProps => {
  return {
    chartType: 'bar',
    chartTitle: 'Retour sur investissement',
    datas: {
      labels: roiMatrix && roiMatrix?.map((roi, index) => `Année ${index}`),
      datasets: [
        {
          data: (roiMatrix && roiMatrix) || [],
          backgroundColor: roiMatrix?.map(
            (roi, index) => `${roi < 0 ? COLORS.LIGHT_BLUE : COLORS.BRIGHT_BLUE}`,
          ),
          maxBarThickness: 50,
        },
      ],
    },
    defaultUnit: '€',
    yUnit: '€',
  };
};

export const formatEstimatedEconomy = (
  energy: number,
  financial: number,
  decarbonation: number,
): ISimulatorEstimatedEconomy => {
  const estimatedEconomyObj: ISimulatorEstimatedEconomy = {
    estimatedEconomy: {
      energy: {
        unit: 'MWh/an',
        value: Number(energy),
      },
      financial: {
        unit: '€/an',
        value: Number(financial),
      },
      decarbonation: {
        unit: 'T CO2/an',
        value: Number(decarbonation),
      },
    },
  };
  return estimatedEconomyObj;
};

export const calculateTotalFinancialValue = (
  simulators: ISimulator[] | undefined,
  contexts: {
    [x in ISIMULATORS_NAME]: any;
  },
): number => {
  return simulators
    ? simulators.reduce(
        (total, simulator) =>
          total +
          (contexts[simulator?.code as ISIMULATORS_NAME] as ISimulatorRequiredDatas)
            .financialGainPerYear,
        0,
      )
    : 0;
};

export const calculateTotalEnergyValue = (
  simulators: ISimulator[] | undefined,
  contexts: {
    [x in ISIMULATORS_NAME]: any;
  },
): number => {
  return simulators
    ? simulators.reduce(
        (total, simulator) =>
          total +
          (contexts[simulator?.code as ISIMULATORS_NAME] as ISimulatorRequiredDatas)
            .energyGainMwhPerYear,
        0,
      )
    : 0;
};

export const calculateTotalSimulatorEstimatedEconomy = (
  simulators: ISimulator[],
  contexts: {
    [x in ISIMULATORS_NAME]: any;
  },
): ISimulatorEstimatedEconomy => {
  let financialTotal = 0;
  let decarbonationTotal = 0;
  let energyTotal = 0;

  for (let i = 0; i < simulators.length; i++) {
    financialTotal +=
      (contexts[simulators[i]?.code as ISIMULATORS_NAME] as ISimulatorRequiredDatas)
        .financialGainPerYear || 0;
    // if (simulators[i].labels.includes('Décarbonation'))
    decarbonationTotal +=
      (contexts[simulators[i]?.code as ISIMULATORS_NAME] as ISimulatorRequiredDatas)
        .kgCo2EmissionAvoidedPerYear || 0;
    // if (simulators[i].labels.includes('Efficacité énergétique'))
    energyTotal +=
      (contexts[simulators[i]?.code as ISIMULATORS_NAME] as ISimulatorRequiredDatas)
        .energyGainMwhPerYear || 0;
  }

  decarbonationTotal = decarbonationTotal / 1000;

  const res: ISimulatorEstimatedEconomy = {
    estimatedEconomy: {
      financial: {
        unit: '€/an',
        value: Number(financialTotal),
      },
      decarbonation: {
        unit: 'T CO2/an',
        value: Number(decarbonationTotal),
      },
      energy: {
        unit: 'MWh/an',
        value: Number(energyTotal),
      },
    },
  };
  return res;
};

export const getSimulatorDatasByCode = (
  code: ISIMULATORS_NAME,
  simulatorsDatas: ISimulatorsDatas,
): ISimulatorDatas | undefined => {
  try {
    return simulatorsDatas[code];
  } catch {
    return undefined;
  }
};

export const removeAccents = (inputString: string): string => {
  return inputString.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const isValidEmail = (email: string): boolean => {
  // Regular expression for a basic email validation
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  // Test the email against the regex
  return emailRegex.test(email);
};

export const filterSimulatorsForDetailedReport = (
  industrialSiteContext: IndustrialSiteContextProps,
  sim: ISimulator,
): boolean => {
  const isIndustrialSite =
    sim.code === 'INDUSTRIAL_SITE' &&
    !isNaN(Number(industrialSiteContext?.annualElectricityConsumption)) &&
    !isNaN(Number(industrialSiteContext?.annualGazConsumption)) &&
    Number(industrialSiteContext?.annualElectricityConsumption) !== 0 &&
    Number(industrialSiteContext?.annualGazConsumption) !== 0;

  const isOtherSite =
    sim.code !== SIMULATORS_NAMES.INDUSTRIAL_SITE.name &&
    sim.datas?.estimatedEconomy?.financial.value !== 0 &&
    !!sim.datas?.estimatedEconomy?.financial.value;

  return isIndustrialSite || isOtherSite;
};

export const checkReferenceDatasAreModified = (referenceDatas: IReferenceDatas) => {
  if (!referenceDatas) return false;
  const res = Object.values(referenceDatas).find((ref) => {
    return ref?.value !== ref?.defaultValue;
  });
  return !!res;
};

export const formatDateString = (dateString: string, separator?: string): string => {
  const originalDate = new Date(dateString);
  const tmpSeparator = separator ? separator : '/';

  const day = String(originalDate.getDate()).padStart(2, '0');
  const month = String(originalDate.getMonth() + 1).padStart(2, '0');
  const year = originalDate.getFullYear();

  return `${day}${tmpSeparator}${month}${tmpSeparator}${year}`;
};

export const onQuitViewMode = () => {
  localStorage.removeItem(getViewOnlyUserTokenFromLocalstorage());
  localStorage.setItem('skipIntro', localStorage.getItem('tmpSkipIntro') || '0');
  localStorage.removeItem('tmpSkipIntro');
  window.location.href = ROUTES.HOME;
};

export const checkTextNull = (text?: string) => {
  return !(text && text !== undefined && text !== null && text !== 'null');
};

export const getSimulatorContextByCode = (
  code: ISIMULATORS_NAME | undefined,
  contexts:
    | {
        [x in ISIMULATORS_NAME]: any;
      }
    | undefined,
) => {
  return contexts ? contexts[code as ISIMULATORS_NAME] : undefined;
};

// export function sortSimulators(items: ISimulator[], order: ISIMULATORS_NAME[]): ISimulator[] {
//   return items.slice().sort((a, b) => {
//     const indexA = order.indexOf(a.code as ISIMULATORS_NAME);
//     const indexB = order.indexOf(b.code as ISIMULATORS_NAME);
//     return indexA - indexB;
//   });
// }

export function sortSimulators(items: ISimulator[], order: ISIMULATORS_NAME[]): ISimulator[] {
  return items.slice().sort((a, b) => {
    const indexA = order.indexOf(a.code as ISIMULATORS_NAME);
    const indexB = order.indexOf(b.code as ISIMULATORS_NAME);

    // If a code is not found in the order array, assign a high index value
    const normalizedIndexA = indexA === -1 ? order.length : indexA;
    const normalizedIndexB = indexB === -1 ? order.length : indexB;

    return normalizedIndexA - normalizedIndexB;
  });
}

export function testShowDownloadPdf(
  simulators: ISimulator[],
  contexts: {
    [x in ISIMULATORS_NAME]: any;
  },
) {
  return (
    simulators.filter((sim) => {
      const simContext = getSimulatorContextByCode(sim?.code, contexts);
      return (
        !!(simContext as ISimulatorRequiredDatas).financialGainPerYear &&
        !!(simContext as ISimulatorRequiredDatas).kgCo2EmissionAvoidedPerYear &&
        !!(simContext as ISimulatorRequiredDatas).energyGainMwhPerYear
      );
    }).length > 0
  );
}
