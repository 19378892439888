import { useContext } from 'react';
import {
  MotorDriveContext,
  MotorDriveContextProps,
} from '../../../../context/simulators/MotorDriveContext';
import { ISimulator } from '../../../../types/app';
import SimulatorLayout from '../../layout/SimulatorLayout';
import { MotorDriveWrapper } from './MotorDrive.styled';

export interface MotorDriveProps {
  simulator: ISimulator;
}

const MotorDrive: React.FC<MotorDriveProps> = ({ simulator }) => {
  const { forms, resultTableRows, resultCharts } = useContext(
    MotorDriveContext,
  ) as MotorDriveContextProps;

  return (
    <MotorDriveWrapper>
      <SimulatorLayout
        simulator={simulator}
        forms={forms}
        resultTableRows={resultTableRows}
        resultCharts={resultCharts}
      />
    </MotorDriveWrapper>
  );
};

export default MotorDrive;
