import styled from 'styled-components';
import { COLORS } from '../../../styles/constants/colors';
import { BODY, H2 } from '../../../styles/helpers/typography';
import { ButtonWrapper } from '../../../components/button/Button.styled';

const ThankingWrapper = styled.div`
  background: ${COLORS.LIGHT_BLUE}80;
  padding: 20px;
  border-radius: 25px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 85vh;
  width: 95vw;
  max-width: 80%;
  max-height: 100%;
  gap: 20%;
  padding: 50px 10%;

  .logo {
    position: absolute;
    top: 5%;
    left: 5%;
    font-size: 20px;
    display: flex;
    align-items: center;
    gap: 10px;

    img {
      &.logo-img {
        width: 50px;
      }
      &.logo-txt {
        width: 150px;
        max-width: 100%;
      }
    }
  }

  .close-icon {
    position: absolute;
    top: 20px;
    right: 20px;

    &:hover {
      cursor: pointer;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;
    height: 100%;
    text-align: center;
    color: ${COLORS.DARK_BLUE};
    z-index: 2;

    .photo {
      position: relative;
      width: max-content;

      img {
        &.olivier {
          width: 136px;
          max-width: 100%;
        }
      }
      .icon-check {
        position: absolute;
        top: -10px;
        right: -10px;
      }
    }
    .big-title {
      ${H2}
    }
    .small-title {
      ${BODY.L_REGULAR}
      max-width: 50ch;
    }

    ${ButtonWrapper} {
      .icon {
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        color: ${COLORS.DARK_BLUE};
      }
    }
  }

  .illustration-plant {
    width: 150px;
    max-width: 100%;
    position: absolute;
    bottom: 0;
    right: 10%;
    user-select: none;
  }
`;

export { ThankingWrapper };
