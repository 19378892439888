import styled from 'styled-components';
import { COLORS } from '../../styles/constants/colors';
import { BODY } from '../../styles/helpers/typography';

const TableWrapper = styled.table`
  width: 100%;
  background-color: ${COLORS.WHITE};
  border-radius: 16px;
  border: 1px solid ${COLORS.LIGHT_BLUE};
  border-spacing: 0;

  thead {
    tr {
      th {
        border-bottom: 1px solid ${COLORS.LIGHT_BLUE};
        padding: 10px;
      }
    }
  }

  tr {
    &.last-tr {
      td {
        border-bottom: none;
      }
    }

    &.highlight {
      background: ${COLORS.DARK_BLUE};
      color: ${COLORS.WHITE};
      ${BODY.S_SEMI_BOLD}

      td {
        width: 50%;
        border-bottom: 1px solid ${COLORS.LIGHT_BLUE};
        padding: 8px 12px 8px 12px;

        &.left-td {
          border-bottom-left-radius: 10px;
        }

        &.right-td {
          border-bottom-right-radius: 10px;
        }
      }
    }
  }

  td {
    width: 50%;
    border-bottom: 1px solid ${COLORS.LIGHT_BLUE};
    padding: 8px 12px 8px 12px;
    ${BODY.S_REGULAR}

    &.left-td {
      border-right: 1px solid ${COLORS.LIGHT_BLUE};
    }

    &.right-td {
      letter-spacing: 1px;
    }
  }
`;

export default TableWrapper;
