import styled from 'styled-components';
import { COLORS } from '../../../styles/constants/colors';
import { H4 } from '../../../styles/helpers/typography';
import { ProgressWrapper } from '../../../components/progress/Progress.styled';
import { BREAKPOINTS } from '../../../styles/constants/devices';

const AuthAdminWrapper = styled.div`
  display: flex;
  background-color: inherit;
  min-height: 90vh;
  padding-top: 50px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 500px;
  max-width: 90%;
  margin: 0 auto;

  .auth-wrapper {
    gap: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    border-radius: 50px;
    max-width: 100%;
    box-shadow: ${COLORS.DARK_BLUE}10 0 4px 32px 9px;

    @media (${BREAKPOINTS.L}) {
      padding: 50px;
    }
  }

  .logo {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 160px;
    max-width: 100%;
  }

  h4 {
    ${H4}
    margin: 0;
    color: ${COLORS.DARK_BLUE};
  }

  ${ProgressWrapper} {
    display: flex;
    align-items: center;
  }
`;

export { AuthAdminWrapper };
