import { useContext } from 'react';
import { ISimulator } from '../../../../types/app';
import SimulatorLayout from '../../layout/SimulatorLayout';
import { RoomAirDestratificationWrapper } from './RoomAirDestratification.styled';
import {
  RoomAirDestratificationContext,
  RoomAirDestratificationContextProps,
} from '../../../../context/simulators/RoomAirDestratificationContext';

export interface RoomAirDestratificationProps {
  simulator: ISimulator;
}
const RoomAirDestratification: React.FC<RoomAirDestratificationProps> = ({ simulator }) => {
  const { forms, resultTableRows, resultCharts } = useContext(
    RoomAirDestratificationContext,
  ) as RoomAirDestratificationContextProps;

  return (
    <RoomAirDestratificationWrapper>
      <SimulatorLayout
        simulator={simulator}
        forms={forms}
        resultTableRows={resultTableRows}
        resultCharts={resultCharts}
      />
    </RoomAirDestratificationWrapper>
  );
};

export default RoomAirDestratification;
