import { SingleReferenceInformationsWrapper } from './SingleReferenceInformations.styled';

export interface IInformations {
  title: string;
  value: number;
  unit: string;
}

export interface SingleReferenceInformationsProps {
  price: IInformations;
  trends: IInformations;
}

const SingleReferenceInformations: React.FC<SingleReferenceInformationsProps> = ({
  price,
  trends,
}) => {
  return (
    <SingleReferenceInformationsWrapper>
      <div className="price">
        <div className="left">{price.title}</div>
        <div className="right">
          <div className="value">{price.value}</div>
          <div className="unit">{price.unit}</div>
        </div>
      </div>
      <div className="trends">
        <div className="left">{trends.title}</div>
        <div className="right">
          <div className="value">{trends.value}</div>
          <div className="unit">{trends.unit}</div>
        </div>
      </div>
    </SingleReferenceInformationsWrapper>
  );
};

export default SingleReferenceInformations;
