import { createContext, useContext, useEffect, useState } from 'react';
import {
  IComponentWithChildren,
  IForm,
  ISimulatorRequiredDatas,
  REFERENCE_DATA_NAME_TYPE,
} from '../../types/app';
import {
  IReferenceDatas,
  ReferenceDatasContext,
  ReferenceDatasContextProps,
} from '../ReferenceDatasContext';
import { SimulatorsContext, SimulatorsContextProps } from './SimulatorsContext';

import { UserContext, UserContextProps } from '../user/UserContext';
import useDelayedUpdate from '../../hooks/useDelayedUpdate';
import { ITableRow } from '../../components/table/Table';
import { ChartProps } from '../../components/chart/Chart';
import { COLORS } from '../../styles/constants/colors';
import { FormTitle } from '../../components/form/Form.styled';
import Info from '../../components/info/Info';
import { ReferenceDatasForm } from '../../pages/simulator/simulators/industrial-site/IndustrialSite.styled';
import { ICONS } from '../../global';
import { checkReferenceDatasAreModified } from '../../utils';
import useConnection from '../../hooks/useConnection';
export interface IndustrialSiteContextProps extends ISimulatorRequiredDatas {
  compressedAir: number;
  cold: number;
  lighting: number;
  heating: number;
  annualGazConsumption: number;
  annualElectricityConsumption: number;
  forms: IForm.IForm[];
  co2EmissionLinkedToGazConsumption: number;
  co2EmissionLinkedToElectricityConsumption: number;
  annualEstimatedBills: Array<Array<number>>;
  resultTableRows: ITableRow[];
  resultCharts: ChartProps[][];
  setCompressedAir: (value: number) => any;
  setCold: (value: number) => any;
  setLighting: (value: number) => any;
  setHeating: (value: number) => any;
  setAnnualGazConsumption: (value: number) => any;
  setAnnualElectricityConsumption: (value: number) => any;
  setCo2EmissionLinkedToGazConsumption: (value: number) => any;
  setCo2EmissionLinkedToElectricityConsumption: (value: number) => any;
  setAnnualEstimatedBills: (value: Array<Array<number>>) => any;
}

const IndustrialSiteContext = createContext<IndustrialSiteContextProps | undefined>(undefined);
const IndustrialSiteProvider: React.FC<IComponentWithChildren> = ({ children }) => {
  const {
    userToken,
    updateUserSimulatorDatas,
    updateUserReferenceData,
    reinitializeUserReferenceDatas,
    user,
    initUserDatas,
  } = useContext(UserContext) as UserContextProps;
  const { simulators, getSimulatorByCode, editSimulatorDatas } = useContext(
    SimulatorsContext,
  ) as SimulatorsContextProps;
  const { internetWorking } = useConnection();
  const [compressedAir, setCompressedAir] = useState(0);
  const [cold, setCold] = useState(0);
  const [lighting, setLighting] = useState(0);
  const [heating, setHeating] = useState(0);
  const [fields, setFields] = useState<Array<IForm.IField>>();
  const [referenceDatasFields, setReferenceDatasFields] = useState<Array<IForm.IField>>();
  const [annualEstimatedBills, setAnnualEstimatedBills] = useState<Array<Array<number>>>([[]]);
  const [annualGazConsumption, setAnnualGazConsumption] = useState(0);
  const [annualElectricityConsumption, setAnnualElectricityConsumption] = useState(0);
  const [co2EmissionLinkedToGazConsumption, setCo2EmissionLinkedToGazConsumption] = useState(0);
  const [co2EmissionLinkedToElectricityConsumption, setCo2EmissionLinkedToElectricityConsumption] =
    useState(0);

  const [forms, setForms] = useState<IForm.IForm[]>([]);
  const [resultTableRows, setResultTableRows] = useState<ITableRow[]>([]);
  const [resultCharts, setResultCharts] = useState<ChartProps[][]>([]);
  const [disableReferenceDatasEdit, setDisableReferenceDatasEdit] = useState<boolean>(true);

  useEffect(() => {
    setDisableReferenceDatasEdit(
      !checkReferenceDatasAreModified(user?.referenceDatas as IReferenceDatas),
    );
  }, [user]);

  const [allowRequest, setAllowRequest] = useState(false);
  useEffect(() => {
    if (internetWorking) setAllowRequest(false);
  }, [internetWorking]);
  const [updateDatas, setUpdateDatas] = useState<object>({});
  const {
    AVERAGE_GAS_PRICE_PER_MWH,
    AVERAGE_ELECTRICITY_PRICE_PER_MWH,
    NATURAL_GAS_TRENDS,
    NATURAL_ELECTRICITY_TRENDS,
    CARBON_CONTENT_PER_KWH_GAZ,
    CARBON_CONTENT_PER_KWH_ELECTRICITY,
    updateReferenceDatas,
    referenceDatas,
  } = useContext(ReferenceDatasContext) as ReferenceDatasContextProps;

  const [averageGazPrice, setAverageGazPrice] = useState(AVERAGE_GAS_PRICE_PER_MWH?.value);
  const [averageElectricityPrice, setAverageElectricityPrice] = useState(
    AVERAGE_ELECTRICITY_PRICE_PER_MWH?.value,
  );
  const [naturalGazTrends, setNaturalGazTrends] = useState(NATURAL_GAS_TRENDS?.value);
  const [naturalElectricityTrends, setNaturalElectricityTrends] = useState(
    NATURAL_ELECTRICITY_TRENDS?.value,
  );

  useEffect(() => {
    setAverageGazPrice(AVERAGE_GAS_PRICE_PER_MWH?.value);
    setAverageElectricityPrice(AVERAGE_ELECTRICITY_PRICE_PER_MWH?.value);
    setNaturalGazTrends(NATURAL_GAS_TRENDS?.value);
    setNaturalElectricityTrends(NATURAL_ELECTRICITY_TRENDS?.value);
  }, [
    AVERAGE_GAS_PRICE_PER_MWH?.value,
    AVERAGE_ELECTRICITY_PRICE_PER_MWH?.value,
    NATURAL_GAS_TRENDS?.value,
    NATURAL_ELECTRICITY_TRENDS?.value,
    user,
  ]);

  useEffect(() => {
    AVERAGE_GAS_PRICE_PER_MWH &&
      AVERAGE_ELECTRICITY_PRICE_PER_MWH &&
      NATURAL_GAS_TRENDS &&
      NATURAL_ELECTRICITY_TRENDS &&
      setReferenceDatasFields([
        {
          label: 'Prix du gaz naturel',
          name: 'AVERAGE_GAS_PRICE_PER_MWH',
          value: averageGazPrice,
          type: 'textfield',
          textType: 'number',
          unit: AVERAGE_GAS_PRICE_PER_MWH?.unit,
          onChange: (e) => setAverageGazPrice(Number(e) || 0),
          controlled: true,
        },
        {
          label: "Prix de l'électricité",
          name: 'AVERAGE_ELECTRICITY_PRICE_PER_MWH',
          value: averageElectricityPrice,
          type: 'textfield',
          textType: 'number',
          unit: AVERAGE_ELECTRICITY_PRICE_PER_MWH.unit,
          onChange: (e) => setAverageElectricityPrice(Number(e) || 0),
          controlled: true,
        },
        {
          label: "Tendance d'évolution du gaz naturel",
          name: 'NATURAL_GAS_TRENDS',
          value: naturalGazTrends,
          type: 'textfield',
          textType: 'number',
          unit: NATURAL_GAS_TRENDS.unit,
          onChange: (e) => setNaturalGazTrends(Number(e) || 0),
          controlled: true,
        },
        {
          label: "Tendance d'évolution de l'électricité",
          name: 'NATURAL_ELECTRICITY_TRENDS',
          value: naturalElectricityTrends,
          type: 'textfield',
          textType: 'number',
          unit: NATURAL_ELECTRICITY_TRENDS.unit,
          onChange: (e) => setNaturalElectricityTrends(Number(e) || 0),
          controlled: true,
        },
      ]);
  }, [
    AVERAGE_GAS_PRICE_PER_MWH,
    AVERAGE_ELECTRICITY_PRICE_PER_MWH,
    NATURAL_GAS_TRENDS,
    NATURAL_ELECTRICITY_TRENDS,
    averageGazPrice,
    averageElectricityPrice,
    naturalGazTrends,
    naturalElectricityTrends,
  ]);

  useEffect(() => {
    setFields([
      {
        label: 'Consommation annuelle de gaz naturel du site',
        name: 'gazconsumption',
        type: 'textfield',
        unit: 'MWh/an',
        value: annualGazConsumption,
        textType: 'number',
        onChange: (e) => setAnnualGazConsumption(Number(e)),
      },
      {
        label: "Consommation annuelle d'électricité du site",
        name: 'electricityconsumption',
        type: 'textfield',
        unit: 'MWh/an',
        value: annualElectricityConsumption,
        textType: 'number',
        onChange: (e) => setAnnualElectricityConsumption(Number(e)),
      },
    ]);
  }, [
    annualGazConsumption,
    annualElectricityConsumption,
    setAnnualElectricityConsumption,
    setAnnualGazConsumption,
  ]);

  const [actionLoading, setActionLoading] = useState<boolean>(false);
  useEffect(() => {
    setForms([
      {
        fields: fields || [],
        onChange: (updatedFields) => {
          setAllowRequest(true);
          setFields(updatedFields);
        },
        header: (
          <>
            <FormTitle>Remplissez les champs suivants :</FormTitle>
          </>
        ),
      },
      {
        fields: referenceDatasFields || [],
        onChange: (updatedFields) => {
          setReferenceDatasFields(updatedFields);
          setAllowRequest(true);
        },
        header: (
          <ReferenceDatasForm>
            <FormTitle>
              Les résultats sont basés sur les informations de référence :{' '}
              <div className="actions">
                <Info
                  isLoading={actionLoading}
                  id="info-reference"
                  toolTip={`Les données initiales sont basées sur des estimations, marché SPOT. ${
                    !user?.details?.isViewerOnly
                      ? 'Vous pouvez modifier ces données si nécessaire.'
                      : ''
                  }`}
                />
                {!user?.details?.isViewerOnly && (
                  <>
                    {disableReferenceDatasEdit ? (
                      <Info
                        isLoading={actionLoading}
                        id="edit"
                        toolTip="Éditer"
                        onClick={async () => {
                          setDisableReferenceDatasEdit(false);
                        }}
                      >
                        {ICONS.EDIT?.icon}
                      </Info>
                    ) : (
                      <Info
                        isLoading={actionLoading}
                        id="reset-reference-datas"
                        toolTip="Réinitialiser"
                        onClick={async () => {
                          setActionLoading(true);
                          try {
                            await reinitializeUserReferenceDatas(userToken);
                            initUserDatas(userToken);
                          } catch (e) {
                          } finally {
                            setActionLoading(false);
                          }
                          // setDisableReferenceDatasEdit(true);
                        }}
                      >
                        {ICONS.RESET?.icon}
                      </Info>
                    )}
                  </>
                )}
              </div>
            </FormTitle>
          </ReferenceDatasForm>
        ),
        className: 'reference-datas-form',
        disabled: disableReferenceDatasEdit,
      },
    ]);
  }, [
    fields,
    referenceDatasFields,
    disableReferenceDatasEdit,
    reinitializeUserReferenceDatas,
    initUserDatas,
    userToken,
    referenceDatas,
    actionLoading,
    user,
  ]);

  useEffect(() => {
    let tmpEstimatedBills = [];
    tmpEstimatedBills[0] = [(annualGazConsumption * AVERAGE_GAS_PRICE_PER_MWH?.value) / 1000];
    tmpEstimatedBills[1] = [
      (annualElectricityConsumption * AVERAGE_ELECTRICITY_PRICE_PER_MWH?.value) / 1000,
    ];
    tmpEstimatedBills[2] = [tmpEstimatedBills[0][0] + tmpEstimatedBills[1][0]];
    for (let i = 1; i < 5; i++) {
      tmpEstimatedBills[0] = [
        ...tmpEstimatedBills[0],
        tmpEstimatedBills[0][i - 1] * (1 + NATURAL_GAS_TRENDS?.value / 100),
      ];
      tmpEstimatedBills[1] = [
        ...tmpEstimatedBills[1],
        tmpEstimatedBills[1][i - 1] * (1 + NATURAL_ELECTRICITY_TRENDS?.value / 100),
      ];
      tmpEstimatedBills[2] = [
        ...tmpEstimatedBills[2],
        tmpEstimatedBills[0][i] + tmpEstimatedBills[1][i],
      ];
    }
    setAnnualEstimatedBills(tmpEstimatedBills);
  }, [
    annualGazConsumption,
    annualElectricityConsumption,
    AVERAGE_GAS_PRICE_PER_MWH?.value,
    AVERAGE_ELECTRICITY_PRICE_PER_MWH?.value,
    NATURAL_GAS_TRENDS?.value,
    NATURAL_ELECTRICITY_TRENDS?.value,
  ]);

  useEffect(() => {
    setCo2EmissionLinkedToGazConsumption(annualGazConsumption * CARBON_CONTENT_PER_KWH_GAZ?.value);
  }, [
    annualGazConsumption,
    CARBON_CONTENT_PER_KWH_GAZ?.value,
    setCo2EmissionLinkedToGazConsumption,
  ]);

  useEffect(() => {
    setCo2EmissionLinkedToElectricityConsumption(
      annualElectricityConsumption * CARBON_CONTENT_PER_KWH_ELECTRICITY?.value,
    );
    // eslint-disable-next-line
  }, [
    annualElectricityConsumption,
    CARBON_CONTENT_PER_KWH_GAZ?.value,
    setCo2EmissionLinkedToElectricityConsumption,
  ]);

  useEffect(() => {
    setResultTableRows([
      {
        label: 'Air comprimé',
        value: `${compressedAir}`,
        unit: 'MWh/an (électricité)',
      },
      {
        label: 'Froid',
        value: `${cold}`,
        unit: 'MWh/an (électricité)',
      },
      {
        label: 'Éclairage',
        value: `${lighting}`,
        unit: 'MWh/an (électricité)',
      },
      {
        label: 'Chauffage',
        value: `${heating}`,
        unit: 'MWh PCI/an (gaz naturel)',
      },
    ]);
  }, [compressedAir, cold, lighting, heating]);

  useEffect(() => {
    setResultCharts([
      [
        {
          chartType: 'bar',
          chartTitle: "Estimations des emissions de CO2 liées à vos consommations d'énergie",
          datas: {
            labels: ['Gaz', 'Électricité'],
            datasets: [
              {
                data: [
                  co2EmissionLinkedToGazConsumption,
                  co2EmissionLinkedToElectricityConsumption,
                ],
                backgroundColor: [COLORS.GAZ_BG, COLORS.ELECTRICITY_BG], // Colors for each segment
                maxBarThickness: 50,
              },
            ],
          },
          yUnit: 'TCo2',
        },
        {
          chartType: 'pie',
          chartTitle: 'Répartition énergétique',
          labels: ['Électricité', 'Gaz'],
          labelsBackgroundColors: [COLORS.ELECTRICITY_BG, COLORS.GAZ_BG],
          labelsColors: [COLORS.ELECTRICITY_LABEL, COLORS.GAZ_LABEL],
          datas: {
            datasets: [
              {
                label: '',
                data: [
                  (100 * annualGazConsumption) /
                    (annualElectricityConsumption + annualGazConsumption),
                  (100 * annualElectricityConsumption) /
                    (annualGazConsumption + annualElectricityConsumption),
                ],
                backgroundColor: [COLORS.GAZ_BG, COLORS.ELECTRICITY_BG], // Colors for each segment
                borderWidth: 0,
              },
            ],
          },
          defaultUnit: '%',
        },
      ],
      [
        {
          chartType: 'bar',
          yUnit: 'k€',
          chartTitle: 'Facture énergétique annuelle estimée',
          labels: ['Gaz (k€)', 'Electricité (k€)', 'Total (k€)'],
          labelsBackgroundColors: [
            COLORS.GAZ_BG,
            COLORS.ELECTRICITY_BG,
            COLORS.TOTAL_GAZ_ELECTRICITY_BG,
          ],
          labelsColors: [
            COLORS.GAZ_LABEL,
            COLORS.ELECTRICITY_LABEL,
            COLORS.TOTAL_GAZ_ELECTRICITY_LABEL,
          ],
          datas: {
            labels: ['1', '2', '3', '4', '5'].map((n) => `Année ${n}`),
            datasets: [
              {
                label: 'Gaz (k€)',
                data: annualEstimatedBills[0] || [],
                backgroundColor: [COLORS.GAZ_BG],
              },
              {
                label: 'Electricité (k€)',
                data: annualEstimatedBills[1] || [],
                backgroundColor: [COLORS.ELECTRICITY_BG],
              },
              {
                label: 'Total (k€)',
                data: annualEstimatedBills[2] || [],
                backgroundColor: [COLORS.TOTAL_GAZ_ELECTRICITY_BG],
              },
            ],
          },
          defaultUnit: 'k€',
        },
      ],
    ]);
  }, [
    annualElectricityConsumption,
    annualEstimatedBills,
    annualGazConsumption,
    co2EmissionLinkedToElectricityConsumption,
    co2EmissionLinkedToGazConsumption,
  ]);

  useEffect(() => {
    setCompressedAir(Math.round(annualElectricityConsumption * 0.71 * 0.12));
    setCold(Math.round(annualElectricityConsumption * 0.71 * 0.14));
    setLighting(Math.round(0.04 * annualElectricityConsumption));
    setHeating(Math.round(0.05 * annualGazConsumption));
    // eslint-disable-next-line
  }, [annualGazConsumption, annualElectricityConsumption]);

  useEffect(() => {
    const sim = getSimulatorByCode('INDUSTRIAL_SITE');
    if (sim) {
      setAnnualElectricityConsumption(Number(sim?.datas?.annualElectricityConsumption));
      setAnnualGazConsumption(Number(sim?.datas?.annualGazConsumption));
    }
    // eslint-disable-next-line
  }, [simulators]);

  useEffect(() => {
    if (userToken) {
      const newDatas = {
        annualElectricityConsumption,
        annualGazConsumption,
      };
      setUpdateDatas(newDatas);
    }
    // eslint-disable-next-line
  }, [userToken, annualElectricityConsumption, annualGazConsumption]);

  const updateSimulatorDatas = () => {
    allowRequest &&
      updateDatas &&
      updateUserSimulatorDatas(userToken, 'INDUSTRIAL_SITE', updateDatas).then(() => {
        editSimulatorDatas('INDUSTRIAL_SITE', updateDatas);
      });
  };

  const updateSimulatorReferenceDatas = async (
    referenceDataId: number,
    value: number,
    referenceCode: REFERENCE_DATA_NAME_TYPE,
  ) => {
    if (allowRequest && referenceDataId && value) {
      await updateUserReferenceData(userToken, referenceDataId, value);
      updateReferenceDatas(referenceCode, value);
      // initUserDatas(userToken);
    }
  };

  useDelayedUpdate(updateSimulatorDatas, [updateDatas], 250);

  useDelayedUpdate(
    () => {
      updateSimulatorReferenceDatas(
        AVERAGE_GAS_PRICE_PER_MWH?.referenceDataId,
        Number(averageGazPrice),
        'AVERAGE_GAS_PRICE_PER_MWH',
      );
    },
    [averageGazPrice],
    250,
  );

  useDelayedUpdate(
    () => {
      updateSimulatorReferenceDatas(
        AVERAGE_ELECTRICITY_PRICE_PER_MWH?.referenceDataId,
        Number(averageElectricityPrice),
        'AVERAGE_ELECTRICITY_PRICE_PER_MWH',
      );
    },
    [averageElectricityPrice],
    250,
  );

  useDelayedUpdate(
    () => {
      updateSimulatorReferenceDatas(
        NATURAL_GAS_TRENDS?.referenceDataId,
        Number(naturalGazTrends),
        'NATURAL_GAS_TRENDS',
      );
    },
    [naturalGazTrends],
    250,
  );

  useDelayedUpdate(
    () => {
      updateSimulatorReferenceDatas(
        NATURAL_ELECTRICITY_TRENDS?.referenceDataId,
        Number(naturalElectricityTrends),
        'NATURAL_ELECTRICITY_TRENDS',
      );
    },
    [naturalElectricityTrends],
    250,
  );

  return (
    <IndustrialSiteContext.Provider
      value={{
        compressedAir: compressedAir,
        cold: cold,
        lighting: lighting,
        heating: heating,
        annualGazConsumption,
        annualElectricityConsumption,
        co2EmissionLinkedToGazConsumption,
        co2EmissionLinkedToElectricityConsumption,
        annualEstimatedBills,
        resultTableRows,
        resultCharts,
        forms,
        energyGainMwhPerYear: 0,
        estimatedRoi: 0,
        financialGainPerYear: 0,
        kgCo2EmissionAvoidedPerYear: 0,
        estimatedBudget: 0,
        setCompressedAir: setCompressedAir,
        setCold: setCold,
        setLighting: setLighting,
        setHeating: setHeating,
        setAnnualGazConsumption,
        setAnnualElectricityConsumption,
        setCo2EmissionLinkedToGazConsumption,
        setCo2EmissionLinkedToElectricityConsumption,
        setAnnualEstimatedBills,
      }}
    >
      {children}
    </IndustrialSiteContext.Provider>
  );
};

export { IndustrialSiteContext, IndustrialSiteProvider };
