import { LandingWrapper } from './Landing.styled';
import Logo from '../../assets/images/logo.png';
import Illustration from '../../assets/images/illustration-intro.png';
import Button from '../../components/button/Button';
import { useLocation } from 'react-router-dom';
import { APP_TITLE, ROUTES } from '../../global';
import { useEffect, useState } from 'react';
import { skipIntro } from '../../utils';
import LogoText from '../../assets/images/logo-text.png';
import useCustomNavigate from '../../hooks/useCustomNavigate';

const Landing: React.FC = () => {
  const location = useLocation();
  const [loadingLanding, setLoadingLanding] = useState<boolean>(true);

  const navigate = useCustomNavigate();

  const updateIntroState = () => {
    localStorage.setItem('skipIntro', '1');
  };

  const navigateToNext = () => {
    const urlSearch = new URLSearchParams(location.search);
    const nextRoute = urlSearch.get('_next');
    updateIntroState();
    if (nextRoute) {
      window.location.href = nextRoute;
    } else window.location.href = ROUTES.DASHBOARD;
  };

  useEffect(() => {
    if (skipIntro()) {
      navigate(ROUTES.DASHBOARD);
    }
    setLoadingLanding(false);
  }, [navigate]);

  useEffect(() => {
    const afterElements: Array<any> = [];
    const handleItem = (item: any, index: any) => {
      if (index !== 0) {
        item.style.position = 'relative';
        const currentItem = document.querySelectorAll('.item')[index];
        const currentItemHeight = currentItem.clientHeight;
        const previousItem = document.querySelectorAll('.item')[index - 1];
        const previousItemHeight = previousItem.clientHeight;
        const afterElement = document.createElement('div');
        afterElement.className = 'item-connector connector';
        afterElement.style.content = "''";
        afterElement.style.position = 'absolute';
        afterElement.style.borderLeft = '2px solid #0178d6';
        afterElement.style.width = '20px';
        afterElement.style.left = '23px';
        afterElement.style.bottom = '44%';
        afterElement.style.height = `${currentItemHeight / 2 + previousItemHeight / 2}px`;
        item.appendChild(afterElement);
        afterElements.push(afterElement);
      }
    };

    const items = document.querySelectorAll('.item');

    items.forEach((item, index) => {
      handleItem(item, index);
    });

    const handleResize = () => {
      afterElements.forEach((afterElement, index) => {
        const currentItem = document.querySelectorAll('.item')[index + 1];
        const currentItemHeight = currentItem.clientHeight;
        const previousItem = document.querySelectorAll('.item')[index];
        const previousItemHeight = previousItem.clientHeight;
        afterElement.style.height = `${currentItemHeight / 2 + previousItemHeight / 2}px`;
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [loadingLanding]);

  useEffect(() => {
    document.title = `Landing - ${APP_TITLE}`;
  }, []);

  return (
    <LandingWrapper>
      <title>Landing</title>
      {!loadingLanding && (
        <>
          <div className="landing-left">
            <div className="logo">
              <img src={Logo} alt="logo" className="logo-img" />
              <img src={LogoText} alt="logo" className="logo-txt" />
            </div>
            <div className="title">
              Agir ensemble pour bâtir des usines performantes et durables
              {/* <p className="description">
                Les équipes d’Actemium propose des solutions bas carbone sur mesure, adaptées à
                chaque site industriel. Grâce à la densité de son réseau et à son expertise des
                process, les entreprises Actemium s’engagent, depuis les phases d’audits et
                d’études, jusqu’à la réalisation et au suivi des gains carbone
              </p> */}
            </div>
            <div className="illustration">
              <img src={Illustration} alt="illustration-landing" />
            </div>
          </div>
          <div className="landing-right">
            <div className="numerated-list">
              <div className="item">
                <div className="item-content">
                  <p className="title">Saisissez vos données</p>
                  <p className="description">
                    En moins de 5 minutes, renseignez l’ensemble des informations de votre site
                    industriel et évaluez vos axes d’efficacité énergétique. Vous n’avez pas toutes
                    les données ? Aucun soucis, tous les axes d’éfficacités proposés sont
                    indépendants les uns des autres !
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="item-content">
                  <p className="title">Consultez vos résulats</p>
                  <p className="description">
                    Le simulateur permet de vous projeter sur les actions à mettre en place, les
                    coûts associés et les bénéfices attendus
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="item-content">
                  <p className="title">Exportez votre simulation, contactez votre expert</p>
                  <p className="description">
                    Vous pouvez exporter votre simulation sur un axe d’efficacité spécifique ou
                    télécharger votre rapport détaillé. Nos conseillers restent à votre écoute pour
                    toutes questions.
                  </p>
                </div>
              </div>
            </div>
            <Button
              className="start-simulation-cta"
              onClick={() => {
                navigateToNext();
              }}
              label="Démarrer ma simulation"
              theme="dark"
              backgroundColor="#014289"
              hasIcon={true}
            />
          </div>
        </>
      )}
    </LandingWrapper>
  );
};

export default Landing;
