import styled from 'styled-components';
import { COLORS } from '../../styles/constants/colors';
import { BODY } from '../../styles/helpers/typography';
import { TextFieldWrapper } from './text-field/TextField.styled';

const FormWrapper = styled.div`
  border-radius: 16px;
  background-color: ${COLORS.LIGHT_BLUE}80;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .form-fields {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .field {
      justify-content: end;
    }
  }

  &.disabled {
    background-color: #00285408;

    ${TextFieldWrapper} {
      .input-wrapper {
        border-color: ${COLORS.DARK_GREY}30;
        background-color: transparent;

        &:hover {
          cursor: not-allowed;
        }

        input {
          background-color: transparent;
          pointer-events: none;
        }
      }
    }
  }
`;

const FormTitle = styled.h4`
  ${BODY.L_SEMI_BOLD}
  margin: 0;
`;

export { FormWrapper, FormTitle };
