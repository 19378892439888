import { useLocation } from 'react-router-dom';
import { ADMIN_NAVIGATION, ICONS, NAVIGATION, ROUTES } from '../../global';
import Icon from '../icon/Icon';
import { SidebarWrapper } from './Sidebar.styled';
import React, { useContext } from 'react';
import { NavigationContext, NavigationContextProps } from '../../context/NavigationContext';
import { ModalContext, ModalContextProps } from '../../context/ModalContext';
import ReinitializePopup from './popup/ReinitializePopup';
import useCustomNavigate from '../../hooks/useCustomNavigate';
import { IsViewerOnlyProps } from '../../types/app';
import { getAdminTokenFromLocalstorage } from '../../utils';

export interface SidebarProps extends IsViewerOnlyProps {
  className?: string;
  isAdminView?: boolean;
}

const menusToHideInMap = [ROUTES.HOME, ROUTES.RESET];

const Sidebar: React.FC<SidebarProps> = ({ className, isAdminView, isViewerOnly }) => {
  const navigate = useCustomNavigate();
  const location = useLocation();
  const { opened, setOpened } = useContext(NavigationContext) as NavigationContextProps;
  const { updateModalConf } = useContext(ModalContext) as ModalContextProps;

  return (
    <SidebarWrapper className={`${className} ${opened ? 'opened' : 'closed'}`} tabIndex={0}>
      {!isAdminView ? (
        <>
          <div
            className="nav-menu top-sidebar"
            onClick={() => {
              navigate(ROUTES.DASHBOARD);
            }}
          >
            <Icon
              icon={ICONS.HOME}
              selected={location.pathname === ROUTES.HOME || location.pathname === ROUTES.DASHBOARD}
            />
            <div className="menu-name">{ICONS.HOME.name}</div>
          </div>
          <div className="separator" />
        </>
      ) : (
        <>
          {/* Admin view */}
          {/* <div
            className="nav-menu top-sidebar"
            onClick={() => {
              navigate(ROUTES.ADMIN.INDEX);
            }}
          >
            <Icon icon={ICONS.HOME} selected={location.pathname === ROUTES.ADMIN.INDEX} />
            <div className="menu-name">{ICONS.ADMIN_HOME.name}</div>
          </div> */}
        </>
      )}

      {!isAdminView ? (
        <>
          <div className="center-sidebar">
            {Object.keys(NAVIGATION).map((navigationKey, key) => {
              const navigation = NAVIGATION[navigationKey];
              if (
                navigation.iconInformations?.icon &&
                !menusToHideInMap.includes(navigation.route as string)
              ) {
                return (
                  <div
                    key={key}
                    className="nav-menu"
                    onClick={() => {
                      navigation.route && navigate(navigation.route);
                    }}
                  >
                    <Icon
                      icon={navigation.iconInformations}
                      selected={location.pathname === navigation.route}
                    />
                    <div className="menu-name">{navigation.iconInformations.name}</div>
                  </div>
                );
              }
              return <React.Fragment key={key} />;
            })}
          </div>
          {!isViewerOnly && (
            <div
              className="nav-menu bottom-sidebar"
              onClick={async () => {
                updateModalConf({
                  component: <ReinitializePopup />,
                });
              }}
            >
              <div className="wrapper">
                <Icon icon={ICONS.RESET} />
                <div className="menu-name">{ICONS.RESET.name}</div>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <div className="center-sidebar">
            {Object.keys(ADMIN_NAVIGATION).map((navigationKey, key) => {
              const navigation = ADMIN_NAVIGATION[navigationKey];
              if (
                navigation.iconInformations?.icon &&
                !menusToHideInMap.includes(navigation.route as string)
              ) {
                return (
                  <div
                    key={key}
                    className="nav-menu"
                    onClick={() => {
                      setOpened(false);
                      navigation.route && navigate(navigation.route);
                    }}
                  >
                    <Icon
                      icon={navigation.iconInformations}
                      selected={location.pathname.includes(navigation.route as string)}
                    />
                    <div className="menu-name">{navigation.iconInformations.name}</div>
                  </div>
                );
              }
              return <React.Fragment key={key} />;
            })}
          </div>
          <div
            className="nav-menu bottom-sidebar"
            onClick={async () => {
              localStorage.removeItem(getAdminTokenFromLocalstorage());
              window.location.href = ROUTES.ADMIN.LOGIN;
            }}
          >
            <div className="wrapper">
              <Icon icon={ICONS.LOGOUT} />
              <div className="menu-name">{ICONS.LOGOUT.name}</div>
            </div>
          </div>
        </>
      )}
    </SidebarWrapper>
  );
};

export default Sidebar;
