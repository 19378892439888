import axios from 'axios';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { API } from '../global';

export interface IUseConnection {
  internetWorking: boolean;
}

function useConnection(): IUseConnection {
  const [internetWorking, setInternetWorking] = useState(true);
  const [internetSlow, setInternetSlow] = useState(false);

  useEffect(() => {
    const checkOnlineStatus = async () => {
      try {
        // const startTime = performance.now();
        await axios.get(`${API.BASE_URL}/check-connection`);
        // const endTime = performance.now();
        // const elapsedTime = endTime - startTime;
        if (!internetWorking || internetSlow) {
          toast.success('Connexion internet restaurée', {
            id: 'connexion',
            duration: 2500,
          });
          setInternetWorking(true);
          setInternetSlow(false);
        }
      } catch (err) {
        setInternetWorking(false);
        toast.error('Connexion perdue. Veuillez vérifier votre connexion internet.', {
          id: 'connexion',
          duration: Infinity,
        });
      }
    };
    const intervalId = setInterval(checkOnlineStatus, 5000);
    return () => clearInterval(intervalId);
  }, [internetWorking, internetSlow]);

  return {
    internetWorking,
  };
}

export default useConnection;
