import styled, { css } from 'styled-components';
import { BODY, H4 } from '../../../../styles/helpers/typography';
import { COLORS } from '../../../../styles/constants/colors';
import { ButtonWrapper } from '../../../../components/button/Button.styled';
import { MultipleColumnsTableWrapper } from '../../../../components/table/multiple-columns-table/MultipleColumnsTable.styled';
import { TextFieldWrapper } from '../../../../components/form/text-field/TextField.styled';

const FLAG = css`
  display: block;
  color: ${COLORS.WHITE};
  padding: 7px;
  border-radius: 4px;
  flex: 1;
  max-width: 100%;
  user-select: text;
  hyphens: auto;
  overflow-wrap: break-word;
  ${BODY.S_SEMI_BOLD}
`;

const FLAG_TRUE = css`
  ${FLAG}
  background-color: ${COLORS.BRIGHT_BLUE};
`;

const FLAG_FALSE = css`
  ${FLAG}
  background-color: ${COLORS.DARK_BLUE}30;
`;

const UsersListWrapper = styled.div`
  padding: 20px 0;
  color: ${COLORS.DARK_BLUE};
  display: flex;
  flex-direction: column;
  gap: 30px;

  ${ButtonWrapper} {
    font-size: 10px;
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  .title {
    ${H4}
  }

  .actions {
    display: flex;
    max-width: 100%;
    flex-direction: column;
    gap: 20px;

    .filters {
      display: flex;
      width: 100%;
      gap: 25px;
      flex-wrap: wrap;

      .single-filter {
        display: flex;
        width: 100%;
        gap: 10px;
        max-width: 400px;
        align-items: center;

        .filter-prefix {
          max-height: 100%;

          .filter-count {
            background: ${COLORS.BRIGHT_BLUE};
            color: ${COLORS.WHITE};
            padding: 10px 15px;
            border-radius: 5px;
            ${BODY.S_SEMI_BOLD}
          }
        }

        .filter-suffix {
          .remove-filter {
            background: transparent;
            color: red;
            padding: 10px 15px;
            border-radius: 5px;

            svg {
              width: 25px;
              height: 25px;
              stroke-width: 5px;
            }

            &:hover {
              opacity: 0.8;
            }

            &:active {
              opacity: 1;
            }
          }
        }
      }
    }

    .cta {
      display: flex;
      gap: 20px;
      align-items: center;

      .add-filter {
        ${ButtonWrapper} {
          &.disabled {
            background-color: ${COLORS.DARK_BLUE}20;
          }
        }
      }
    }
  }

  .reset-filters {
    width: max-content;
    ${ButtonWrapper} {
      &:hover {
        opacity: 0.8;
      }

      &:active {
        opacity: 1;
      }
    }
  }

  .search-by-email-container {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;

    ${TextFieldWrapper} {
      width: 300px;
      max-width: 100%;
    }

    ${ButtonWrapper} {
      font-size: 10px;
      padding: 5px 10px;
      border-radius: 5px;
    }
  }

  .list {
    padding-right: 20px;
    max-width: 100%;
    overflow: scroll;

    ${MultipleColumnsTableWrapper} {
      overflow: auto;
      margin: 0 auto;
      min-width: 100%;
      ${BODY.S_REGULAR}
    }

    .email {
      display: flex;
      align-items: center;
      justify-content: center;
      ${BODY.S_REGULAR};
    }

    a {
      text-decoration: underline;
      ${BODY.M_BOLD}
    }

    .icon-info {
      display: flex;
      width: max-content;
      margin: 0 auto;
      padding: 8px;
      border-radius: 4px;
      transition: all 0.1s linear;

      &:hover {
        cursor: pointer;
        background-color: ${COLORS.LIGHT_BLUE}80;
      }

      &:active {
        background-color: ${COLORS.LIGHT_BLUE};
      }
    }

    .flag-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      width: 150px;
      max-width: 100%;
      margin: 0 auto;

      .flag {
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: text;
        white-space: normal;
        text-wrap: break-word;
        flex: 1;
      }

      .flag::after {
        content: attr(data-text-fail);
        user-select: text;
        ${FLAG_FALSE}
      }

      .flag {
        &.true::after {
          content: attr(data-text-success);
          ${FLAG_TRUE}
        }
      }
    }
  }
`;

export { UsersListWrapper };
