import styled, { css } from 'styled-components';
import { BODY } from '../../styles/helpers/typography';

const TagWrapper = styled.div<{ color?: string; backgroundcolor?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  max-width: 100%;
  padding: 5px 10px;
  border-radius: 4px;
  font-family: 'JOST';
  ${BODY.S_REGULAR};

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}

  ${({ backgroundcolor }) =>
    backgroundcolor &&
    css`
      background-color: ${backgroundcolor};
    `}
`;

export { TagWrapper };
