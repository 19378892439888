import { createContext, useContext, useEffect, useState } from 'react';
import { IComponentWithChildren, IForm, ISimulatorRequiredDatas } from '../../types/app';
import { FormTitle } from '../../components/form/Form.styled';
import { ITableRow } from '../../components/table/Table';
import { ChartProps } from '../../components/chart/Chart';
import { customRoundUp, getChartPropsFromRoiMatrix } from '../../utils';
import { ReferenceDatasContext, ReferenceDatasContextProps } from '../ReferenceDatasContext';
import { COLORS } from '../../styles/constants/colors';
import { SimulatorsContext, SimulatorsContextProps } from './SimulatorsContext';
import { UserContext, UserContextProps } from '../user/UserContext';

import useDelayedUpdate from '../../hooks/useDelayedUpdate';

export interface HightPerformanceEnginesContextProps extends ISimulatorRequiredDatas {
  enginesPower: number;
  enginesNumber: number;
  oldEngineClass: number;
  newEngineClass: number;
  operatingTime: number;
  oldEngineClassOptions: IForm.IOption[];
  newEngineClassOptions: IForm.IOption[];
  forms: IForm.IForm[];
  oldEngineConsumption: number;
  newEngineConsumption: number;
  reducedConsumption: number;
  estimatedReductionInEmission: number;
  estimatedFinancialGain: number;
  roi: number;
  estimatedBudget: number;
  oldEngineEfficiency: number;
  newEngineEfficiency: number;
  resultTableRows: ITableRow[];
  roiMatrix: number[][];
  resultCharts: ChartProps[][];
}

const calculateEngineClassEfficiency = (
  engineClassIndex: number,
  enginesClassMatrix: number[][],
  enginesPower: number,
): number => {
  if (enginesClassMatrix.length < 4) return 0;
  const res =
    enginesClassMatrix[0][engineClassIndex] * Math.pow(Math.log10(enginesPower), 3) +
    enginesClassMatrix[1][engineClassIndex] * Math.pow(Math.log10(enginesPower), 2) +
    enginesClassMatrix[2][engineClassIndex] * Math.pow(Math.log10(enginesPower), 1) +
    enginesClassMatrix[3][engineClassIndex];
  return customRoundUp(res, 4);
};

const HighPerformanceEnginesContext = createContext<
  HightPerformanceEnginesContextProps | undefined
>(undefined);

const HighPerformanceEnginesProvider: React.FC<IComponentWithChildren> = ({ children }) => {
  const { CARBON_CONTENT_PER_KWH_ELECTRICITY, AVERAGE_ELECTRICITY_PRICE_PER_MWH } = useContext(
    ReferenceDatasContext,
  ) as ReferenceDatasContextProps;
  const { getSimulatorByCode, simulators, editSimulatorDatas, engineClassesMatrix } = useContext(
    SimulatorsContext,
  ) as SimulatorsContextProps;
  const { userToken, updateUserSimulatorDatas } = useContext(UserContext) as UserContextProps;

  const [enginesPower, setEnginesPower] = useState<number>(0);
  const [enginesNumber, setEnginesNumber] = useState<number>(0);
  const [oldEngineClass, setOldEngineClass] = useState<number>(0);
  const [newEngineClass, setNewEngineClass] = useState<number>(0);
  const [operatingTime, setOperatingTime] = useState<number>(0);
  const [oldEngineClassOptions, setOldEngineClassOptions] = useState<IForm.IOption[]>([]);
  const [newEngineClassOptions, setNewEngineClassOptions] = useState<IForm.IOption[]>([]);
  const [forms, setForms] = useState<IForm.IForm[]>([]);

  const [oldEngineConsumption, setOldEngineConsumption] = useState<number>(0);
  const [newEngineConsumption, setNewEngineConsumption] = useState<number>(0);
  const [reducedConsumption, setReducedConsumption] = useState<number>(0);
  const [estimatedReductionInEmission, setEstimatedReductionInEmission] = useState<number>(0);
  const [estimatedFinancialGain, setEstimatedFinancialGain] = useState<number>(0);
  const [roi, setRoi] = useState<number>(0);
  const [estimatedBudget, setEstimatedBudget] = useState<number>(0);
  // const [engineClassesMatrix, setEngineClassesMatrix] = useState<number[][]>([]);
  const [oldEngineEfficiency, setOldEngineEfficiency] = useState<number>(0);
  const [newEngineEfficiency, setNewEngineEfficiency] = useState<number>(0);
  const [resultTableRows, setResultTableRows] = useState<ITableRow[]>([]);

  const [roiMatrix, setRoiMatrix] = useState<number[][]>([]);
  const [resultCharts, setResultCharts] = useState<ChartProps[][]>([]);
  const [updateDatas, setUpdateDatas] = useState<object>({});

  //   old engine class options
  useEffect(() => {
    setOldEngineClassOptions(
      [0, 1, 2].map((number) => {
        return {
          label: `IE${number + 1}`,
          value: number,
          selected: number === oldEngineClass,
        };
      }),
    );
  }, [oldEngineClass]);

  //   new engine class options
  useEffect(() => {
    setNewEngineClassOptions(
      [1, 2, 3].map((number) => {
        return {
          label: `IE${number + 1}`,
          value: number,
          selected: number === newEngineClass,
        };
      }),
    );
  }, [newEngineClass]);

  //   old engine efficiency
  useEffect(() => {
    setOldEngineEfficiency(
      calculateEngineClassEfficiency(oldEngineClass, engineClassesMatrix, enginesPower) || 0,
    );
  }, [oldEngineClass, oldEngineClassOptions, engineClassesMatrix, enginesPower]);

  // new engine efficiency
  useEffect(() => {
    setNewEngineEfficiency(
      calculateEngineClassEfficiency(newEngineClass, engineClassesMatrix, enginesPower) || 0,
    );
  }, [newEngineClass, newEngineClassOptions, engineClassesMatrix, enginesPower]);

  const [allowRequest, setAllowRequest] = useState<boolean>(false);
  //   forms
  useEffect(() => {
    setForms([
      {
        onChange: () => setAllowRequest(true),
        header: <FormTitle>Remplissez les champs suivants :</FormTitle>,
        fields: [
          {
            label: "Puissance de l'ensemble des moteurs",
            name: '',
            type: 'textfield',
            textType: 'number',
            unit: 'kW',
            value: enginesPower,
            onChange: (e) => {
              setEnginesPower(Number(e));
            },
          },
          {
            label: 'Nombre de moteurs',
            name: '',
            type: 'textfield',
            textType: 'number',
            unit: 'moteurs',
            value: enginesNumber,
            onChange: (e) => setEnginesNumber(Number(e)),
          },
          {
            label: 'Classe ancien moteur',
            name: '',
            type: 'selectbox',
            unit: '',
            value: oldEngineClassOptions.find((opt) => opt.selected)?.value,
            options: oldEngineClassOptions,
            onChange: (e) => setOldEngineClass(e.find((val: any) => val?.selected === true)?.value),
          },
          {
            label: 'Classe nouveau moteur',
            name: '',
            type: 'selectbox',
            unit: '',
            value: newEngineClassOptions.find((opt) => opt.selected)?.value,
            options: newEngineClassOptions,
            onChange: (e) => setNewEngineClass(e.find((val: any) => val?.selected === true)?.value),
          },
          {
            label: 'Temps de fonctionnement',
            name: '',
            type: 'textfield',
            textType: 'number',
            unit: 'heures/an',
            value: operatingTime,
            onChange: (e) => setOperatingTime(Number(e)),
          },
        ],
      },
    ]);
  }, [
    enginesPower,
    enginesNumber,
    oldEngineClass,
    newEngineClass,
    oldEngineClassOptions,
    newEngineClassOptions,
    operatingTime,
  ]);

  //   old engine consumption
  useEffect(() => {
    setOldEngineConsumption((enginesPower * operatingTime) / 1000 / (oldEngineEfficiency / 100));
  }, [enginesPower, operatingTime, oldEngineEfficiency]);

  //   new engine consumption
  useEffect(() => {
    setNewEngineConsumption((enginesPower * operatingTime) / 1000 / (newEngineEfficiency / 100));
  }, [enginesPower, operatingTime, newEngineEfficiency]);

  //   reduced consumption
  useEffect(() => {
    setReducedConsumption(oldEngineConsumption - newEngineConsumption);
  }, [oldEngineConsumption, newEngineConsumption]);

  //   estimated reduction in emissions
  useEffect(() => {
    setEstimatedReductionInEmission(reducedConsumption * CARBON_CONTENT_PER_KWH_ELECTRICITY?.value);
  }, [reducedConsumption, CARBON_CONTENT_PER_KWH_ELECTRICITY?.value]);

  //  estimated financial gain
  useEffect(() => {
    setEstimatedFinancialGain(reducedConsumption * AVERAGE_ELECTRICITY_PRICE_PER_MWH?.value);
  }, [reducedConsumption, AVERAGE_ELECTRICITY_PRICE_PER_MWH?.value]);

  //   roi
  useEffect(() => {
    setRoi(estimatedBudget / estimatedFinancialGain);
  }, [estimatedBudget, estimatedFinancialGain]);

  //   estimated budget
  useEffect(() => {
    if (!newEngineClass || !(newEngineClass >= 1 && newEngineClass < 4)) return;
    const multiplierValueMatrix: number[][] = [
      [0, 0],
      [37.555, 108.18],
      [40.019, 250.55],
      [105.22, 302],
    ];
    const tmpEstimatedBudget =
      multiplierValueMatrix[newEngineClass][0] * enginesPower +
      multiplierValueMatrix[newEngineClass][1] * enginesNumber;
    setEstimatedBudget(tmpEstimatedBudget);
  }, [newEngineClass, enginesPower, enginesNumber]);

  // roi matrix
  useEffect(() => {
    const investment = -estimatedBudget;
    const initialEconomy = 0;
    const firstYearRoi = investment + initialEconomy;
    let tmpMatrix: number[][] = [
      [initialEconomy, estimatedFinancialGain],
      [firstYearRoi, firstYearRoi + estimatedFinancialGain],
    ];
    for (let i = 2; i < 8; i++) {
      tmpMatrix[0] = [...tmpMatrix[0], tmpMatrix[0][i - 1] * (1 + 8 / 100)];
      tmpMatrix[1] = [...tmpMatrix[1], tmpMatrix[1][i - 1] + tmpMatrix[0][i]];
    }
    setRoiMatrix(tmpMatrix);
  }, [estimatedBudget, estimatedFinancialGain]);

  //   result table rows
  useEffect(() => {
    setResultTableRows([
      {
        label: 'Consommation ancien moteur',
        unit: 'MWh/an',
        value: `${oldEngineConsumption}`,
      },
      {
        label: 'Consommation nouveau moteur',
        unit: 'MWh/an',
        value: `${newEngineConsumption}`,
      },
      {
        label: 'Réduction de consommation',
        unit: 'MWh/an',
        value: `${reducedConsumption}`,
      },
      {
        label: "Baisse d'émissions estimée",
        unit: 'T CO2/an',
        value: `${estimatedReductionInEmission}`,
      },
      {
        label: 'Gain financier estimé',
        unit: '€/an',
        value: `${estimatedFinancialGain}`,
      },
      {
        label: 'Enveloppe budgétaire estimée',
        unit: '€',
        value: `${estimatedBudget}`,
      },
      {
        label: 'ROI moyen pour cette action (le ROI n’inclut pas les primes et aides (CEE,…))',
        unit: 'ans',
        value: `${roi}`,
      },
    ]);
  }, [
    oldEngineConsumption,
    newEngineConsumption,
    reducedConsumption,
    estimatedReductionInEmission,
    estimatedFinancialGain,
    roi,
    estimatedBudget,
  ]);

  // result charts
  useEffect(() => {
    setResultCharts([
      [
        {
          chartType: 'bar',
          chartTitle: 'Gains énergétiques MWh/an',
          labels: ['Consommation ancien moteur', 'Consommation nouveau moteur'],
          labelsColors: [COLORS.WHITE, COLORS.WHITE],
          labelsBackgroundColors: [COLORS.BRIGHT_BLUE, COLORS.DARK_BLUE],
          datas: {
            labels: ['', ''],
            datasets: [
              {
                data: [oldEngineConsumption, newEngineConsumption],
                backgroundColor: [COLORS.BRIGHT_BLUE, COLORS.DARK_BLUE],
                maxBarThickness: 50,
              },
            ],
          },
          defaultUnit: 'MWh/an',
          yUnit: 'MWh/an',
        },
      ],
      [
        {
          ...getChartPropsFromRoiMatrix(roiMatrix[1]),
        },
      ],
    ]);
  }, [oldEngineConsumption, newEngineConsumption, roiMatrix]);

  useEffect(() => {
    const sim = getSimulatorByCode('HIGH_PERFORMANCE_ENGINES');
    if (sim) {
      setEnginesPower(Number(sim?.datas?.enginesPower));
      setEnginesNumber(Number(sim?.datas?.enginesNumber));
      setOldEngineClass(Number(sim?.datas?.oldEngineClass));
      setNewEngineClass(Number(sim?.datas?.newEngineClass));
      setOperatingTime(Number(sim?.datas?.operatingTime));
    }
    // eslint-disable-next-line
  }, [simulators]);

  useEffect(() => {
    if (userToken) {
      const newDatas = {
        enginesPower,
        enginesNumber,
        oldEngineClass,
        newEngineClass,
        operatingTime,
      };

      setUpdateDatas(newDatas);
    }
  }, [
    userToken,
    enginesPower,
    enginesNumber,
    oldEngineClass,
    newEngineClass,
    operatingTime,
    reducedConsumption,
    estimatedFinancialGain,
    estimatedReductionInEmission,
  ]);

  const updateUserSimulatorDatasFunc = () => {
    // Your logic to update the user simulator datas
    allowRequest &&
      updateDatas &&
      updateUserSimulatorDatas(userToken, 'HIGH_PERFORMANCE_ENGINES', updateDatas).then(() => {
        editSimulatorDatas('HIGH_PERFORMANCE_ENGINES', updateDatas);
      });
  };

  useDelayedUpdate(updateUserSimulatorDatasFunc, [updateDatas], 250);

  return (
    <HighPerformanceEnginesContext.Provider
      value={{
        enginesPower,
        enginesNumber,
        oldEngineClass,
        newEngineClass,
        operatingTime,
        forms,
        oldEngineConsumption,
        newEngineConsumption,
        reducedConsumption,
        estimatedReductionInEmission,
        estimatedFinancialGain,
        roi,
        estimatedBudget,
        resultTableRows,
        roiMatrix,
        resultCharts,
        newEngineClassOptions,
        newEngineEfficiency,
        oldEngineClassOptions,
        oldEngineEfficiency,
        energyGainMwhPerYear: reducedConsumption,
        kgCo2EmissionAvoidedPerYear: estimatedReductionInEmission * 1000,
        financialGainPerYear: estimatedFinancialGain,
        estimatedRoi: roi,
      }}
    >
      {children}
    </HighPerformanceEnginesContext.Provider>
  );
};

export { HighPerformanceEnginesContext, HighPerformanceEnginesProvider };
