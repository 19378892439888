import styled from 'styled-components';

const ProgressWrapper = styled.div`
  width: 100%;
  display : flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export { ProgressWrapper };
