import { Font, Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import { ISimulator, ISimulatorRequiredDatas } from '../../../../types/app';
import { customRound } from '../../../../utils';
import { COLORS } from '../../../../styles/constants/colors';
import bar0 from '../../../../assets/images/shapes/bar0.png';
import bar1 from '../../../../assets/images/shapes/bar1.png';
import bar2 from '../../../../assets/images/shapes/bar2.png';
import bar3 from '../../../../assets/images/shapes/bar3.png';
import bar4 from '../../../../assets/images/shapes/bar4.png';

import star0 from '../../../../assets/images/shapes/star0.png';
import starsemi from '../../../../assets/images/shapes/starsemi.png';
import star1 from '../../../../assets/images/shapes/star1.png';

export type DecarbonationIndiceStarsType = 0 | 0.5 | 1;

export interface ISimulatorRecap {
  simulator: ISimulator;
  recap: Omit<ISimulatorRequiredDatas, 'kgCo2EmissionAvoidedPerYear'> & {
    tCo2EmissionAvoidedPerYear: number;
    decarbonationIndice: number;
    tCo2BarsCount: number;
    decarbonationIndiceStars: DecarbonationIndiceStarsType;
  };
}

export interface PdfSimulatorsRecapTableProps {
  simulatorsRecap: ISimulatorRecap[];
}

export const chunkSubstr = (str: string, size: number) => {
  const numChunks = Math.ceil(str.length / size);
  const chunks = new Array(numChunks);

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size);
  }

  return chunks;
};

Font.registerHyphenationCallback((word) => {
  if (word.length > 8) {
    return chunkSubstr(word, 8);
  } else {
    return [word];
  }
});

const styles = StyleSheet.create({
  table: {
    backgroundColor: 'white',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  cell: {
    width: '20%',
    maxWidth: '20%',
    fontSize: 10,
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 5,
    borderTop: `1px solid ${COLORS.LIGHT_BLUE}`,
    borderRight: `1px solid ${COLORS.LIGHT_BLUE}`,
    padding: '5px 8px',
  },
  nameCell: {
    borderLeft: `1px solid ${COLORS.LIGHT_BLUE}`,
    width: '20%',
    alignItems: 'flex-start',
  },
  cellText: {
    maxWidth: '95%',
  },
});

const HEADERS: string[] = [
  'Action',
  'Gain énergétique (MWh/an)',
  'Emissions de CO2 évitées (T CO2/an)',
  'Gain financier estimé (k€)',
  'Coût de réalisation estimé (k€)',
  'ROI estimé (années)',
  'Indice de décarbonation (T CO2 évitée/an/k€ investi)',
];

const PdfSimulatorsRecapTable: React.FC<PdfSimulatorsRecapTableProps> = ({ simulatorsRecap }) => {
  return (
    <View style={styles.table}>
      {simulatorsRecap ? (
        <>
          <View style={styles.row}>
            {HEADERS.map((header, index) => {
              return (
                <View
                  key={index}
                  style={{
                    ...styles.cell,
                    ...(index === 0 && {
                      borderLeft: `1px solid ${COLORS.LIGHT_BLUE}`,
                      width: '20%',
                    }),
                  }}
                >
                  <Text>{header}</Text>
                </View>
              );
            })}
          </View>
          {simulatorsRecap.map((simRecap, key) => {
            const energyGain = customRound(simRecap.recap.energyGainMwhPerYear, 2);
            const co2Emission = customRound(simRecap.recap.tCo2EmissionAvoidedPerYear, 2);
            const financialGain = customRound(simRecap.recap.financialGainPerYear, 2);
            const estimatedBudget = customRound(simRecap.recap.estimatedBudget, 2);
            const estimatedRoi = customRound(simRecap.recap.estimatedRoi, 2);
            const decarbonationIndice = customRound(simRecap.recap.decarbonationIndice, 2);
            const barsCount = simRecap.recap.tCo2BarsCount;
            const starsCount = simRecap.recap.decarbonationIndiceStars;

            return (
              <View
                key={key}
                style={{
                  ...styles.row,
                  ...(key === simulatorsRecap.length - 1 && {
                    borderBottom: `1px solid ${COLORS.LIGHT_BLUE}`,
                  }),
                }}
              >
                <View
                  style={{
                    ...styles.cell,
                    ...styles.nameCell,
                  }}
                >
                  <Text style={styles.cellText}>{simRecap.simulator.name}</Text>
                </View>
                <View style={styles.cell}>
                  <Text style={styles.cellText}>{Number(energyGain).toLocaleString('fr')}</Text>
                </View>
                <View
                  style={{
                    ...styles.cell,
                    textAlign: 'left',
                  }}
                >
                  <Image
                    style={{
                      width: 20,
                    }}
                    src={
                      barsCount === 0
                        ? bar0
                        : barsCount === 1
                        ? bar1
                        : barsCount === 2
                        ? bar2
                        : barsCount === 3
                        ? bar3
                        : barsCount === 4
                        ? bar4
                        : bar0
                    }
                  />
                  <Text style={styles.cellText}>{Number(co2Emission).toLocaleString('fr')}</Text>
                </View>
                <View style={styles.cell}>
                  <Text style={styles.cellText}>{Number(financialGain).toLocaleString('fr')}</Text>
                </View>
                <View style={styles.cell}>
                  <Text style={styles.cellText}>
                    {Number(estimatedBudget).toLocaleString('fr')}
                  </Text>
                </View>
                <View style={styles.cell}>
                  <Text style={styles.cellText}>{Number(estimatedRoi).toLocaleString('fr')}</Text>
                </View>
                <View style={styles.cell}>
                  <Image
                    style={{
                      width: 20,
                    }}
                    src={
                      starsCount === 0
                        ? star0
                        : starsCount === 0.5
                        ? starsemi
                        : starsCount === 1
                        ? star1
                        : star0
                    }
                  />
                  <Text style={styles.cellText}>
                    {Number(decarbonationIndice).toLocaleString('fr')}
                  </Text>
                </View>
              </View>
            );
          })}
        </>
      ) : (
        <></>
      )}
    </View>
  );
};

export default PdfSimulatorsRecapTable;
