const S = 0;
const SM = 350;
const M = 600;
const L = 960;
const XL = 1280;
const XXL = 1440;

const BREAKPOINTS = {
  S: `min-width: ${S}px`,
  SM: `min-width: ${SM}px`,
  M: `min-width: ${M}px`,
  L: `min-width: ${L}px`,
  XL: `min-width: ${XL}px`,
  XXL: `min-width: ${XXL}px`,
};

export { BREAKPOINTS };
