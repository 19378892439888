import * as React from 'react';
import Box from '@mui/material/Box';
import ModalMui from '@mui/material/Modal';
import { ModalWrapper } from './Modal.styled';
import { ModalContext, ModalContextProps } from '../../context/ModalContext';

const style = {
  position: 'absolute' as 'absolute',
  maxWidth: '95%',
  maxHeight: '100%',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'max-content',
  bgcolor: 'background.paper',
  p: 0,
  borderRadius: 5,
  overflow: 'auto',
  outline: '0',
};

const Modal: React.FC = () => {
  const { openedModal, updateModalConf, modalConf } = React.useContext(
    ModalContext,
  ) as ModalContextProps;
  const handleClose = () => {
    if (modalConf?.onClose) return modalConf.onClose();
    updateModalConf();
  };

  return (
    <ModalWrapper>
      <ModalMui
        open={openedModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <>{modalConf?.component}</>
        </Box>
      </ModalMui>
    </ModalWrapper>
  );
};

export default Modal;
