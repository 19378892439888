import styled from 'styled-components';
import { COLORS } from '../../../styles/constants/colors';
import { BODY, H5 } from '../../../styles/helpers/typography';
import { BREAKPOINTS } from '../../../styles/constants/devices';

const SimulatorResultsWrapper = styled.div`
  background-color: ${COLORS.LIGHT_BLUE}50;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (${BREAKPOINTS.S}) {
    padding: 24px;
  }
  @media (${BREAKPOINTS.L}) {
    padding: 30px 60px;
  }

  .results {
    .title {
      ${H5}
      margin: 0 0 20px 0;
      display: flex;
      justify-content: space-between;

      p {
        margin: 0;
      }

      .export {
        ${BODY.M_REGULAR}
        color: ${COLORS.DARK_BLUE}50;
        display: flex;
        align-items: center;

        &:hover {
          cursor: pointer;
        }

        .icon {
          display: flex;
          align-items: center;
        }

        p {
          text-decoration: underline;
        }

        &.allow-export {
          color: ${COLORS.DARK_BLUE};
        }
      }
    }
  }

  .charts {
    display: flex;
    gap: 20px;
    flex-direction: column;

    .chart-row {
      gap: 10px;

      &.single-child {
        grid-template-columns: 1fr;
      }

      &.double-child {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }
  }
`;

export { SimulatorResultsWrapper };
