import { COLORS } from '../../styles/constants/colors';
import { IComponentWithChildren } from '../../types/app';
import Progress from '../progress/Progress';
import { InfoWrapper } from './Info.styled';
import { PlacesType, Tooltip } from 'react-tooltip';

export interface InfoProps extends IComponentWithChildren {
  toolTip: string;
  color?: 'default' | 'blue';
  onClick?: () => void;
  id?: string;
  place?: PlacesType;
  isLoading?: boolean;
}

const Info: React.FC<InfoProps> = ({ color, toolTip, children, onClick, id, place, isLoading }) => {
  const toolTipId = `${id ? id : 'my-tooltip'}`;

  return (
    <>
      {!isLoading ? (
        <>
          {!children ? (
            <InfoWrapper
              className={`${color && `${color}-color`} ${!color && 'default-color'}`}
              data-tooltip-id={`${toolTipId}`}
              data-tooltip-content={`${toolTip}`}
              onClick={() => onClick && onClick()}
            >
              i
            </InfoWrapper>
          ) : (
            <InfoWrapper
              className="children"
              data-tooltip-id={`${toolTipId}`}
              data-tooltip-content={`${toolTip}`}
              onClick={() => onClick && onClick()}
            >
              {children}
            </InfoWrapper>
          )}
          <Tooltip
            id={`${toolTipId}`}
            place={place || 'top'}
            opacity={1}
            border="1px solid white"
            style={{
              backgroundColor: 'white',
              color: COLORS.DARK_BLUE,
              fontSize: 14,
              maxWidth: 220,
              fontWeight: 'normal',
              zIndex: 1000,
              lineHeight: 1.7,
              outline: 'none',
              boxShadow: `0px 1px 8px 0px #6E6E6E1A`,
            }}
          />
        </>
      ) : (
        <>
          <Progress />
        </>
      )}
    </>
  );
};

export default Info;
