import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  CEEEligibleCard,
  CardBody,
  CardTitle,
  DetailedReportWrapper,
  DownloadDetailedReportCard,
} from './DetailedReport.styled';
import NavDetailedReport from './nav/NavDetailedReport';
import FiltersDetailedReport from './filters/FiltersDetailedReport';
import TotalTitle from './total-title/TotalTitle';
import { APP_TITLE, ICONS, RESPONSABILITY_NOTE, SIMULATORS_NAMES } from '../../global';
import TableDetailedReport from './table/TableDetailedReport';
import {
  SimulatorsContext,
  SimulatorsContextProps,
} from '../../context/simulators/SimulatorsContext';
import {
  CompressedAirContext,
  CompressedAirContextProps,
} from '../../context/simulators/CompressedAirContext';
import {
  ISIMULATORS_NAME,
  ISimulator,
  ISimulatorEstimatedEconomy,
  ISimulatorRequiredDatas,
  IsViewerOnlyProps,
} from '../../types/app';
import {
  MonitoringContext,
  MonitoringContextProps,
} from '../../context/simulators/MonitoringContext';
import { ITableRow } from '../../components/table/Table';
import { ChartProps } from '../../components/chart/Chart';
import {
  HeatRecoveryContext,
  HeatRecoveryContextProps,
} from '../../context/simulators/HeatRecoveryContext';
import {
  RoomAirDestratificationContext,
  RoomAirDestratificationContextProps,
} from '../../context/simulators/RoomAirDestratificationContext';
import {
  MicroModulatingBurnerContext,
  MicroModulatingBurnerContextProps,
} from '../../context/simulators/MicroModulatingBurnerContext';
import {
  InsulatingMattressesContext,
  InsulatingMattressesContextProps,
} from '../../context/simulators/InsulatingMattressesContext';
import {
  LedLightingContext,
  LedLightingContextProps,
} from '../../context/simulators/LedLightingContext';
import { HeatPumpContext, HeatPumpContextProps } from '../../context/simulators/HeatPumpContext';
import {
  ElectricSpeedVariationContext,
  ElectricSpeedVariationContextProps,
} from '../../context/simulators/ElectricSpeedVariationContext';
import {
  HighPerformanceEnginesContext,
  HightPerformanceEnginesContextProps,
} from '../../context/simulators/HighPerformanceEnginesContext';
import {
  MotorDriveContext,
  MotorDriveContextProps,
} from '../../context/simulators/MotorDriveContext';
import ChartsDetailedReport from './charts/ChartsDetailedReport';
import {
  IndustrialSiteContext,
  IndustrialSiteContextProps,
} from '../../context/simulators/IndustrialSiteContext';
import ScrollToTop from '../../layout/scroll-to-top/ScrollToTop';
import {
  calculateTotalSimulatorEstimatedEconomy,
  customRoundDown,
  getSimulatorContextByCode,
  getSimulatorDatasByCode,
  isValidEmail,
  onQuitViewMode,
  sortSimulators,
} from '../../utils';
import Button from '../../components/button/Button';
import Card from '../../components/card/Card';
import TextField from '../../components/form/text-field/TextField';
import ContactUsCard from '../dashboard/contact-us-card/ContactUsCard';
import { pdf } from '@react-pdf/renderer';
import PdfDetailedReport from './pdf/PdfDetailedReport';
import { saveAs } from 'file-saver';
import toast from 'react-hot-toast';
import { UserContext, UserContextProps } from '../../context/user/UserContext';
import { UserApi } from '../../api/User.api';
import { ModalContext, ModalContextProps } from '../../context/ModalContext';
import Thanking from './thanking/Thanking';
import useDelayedUpdate from '../../hooks/useDelayedUpdate';
import { Loader } from '../../App.styled';
import { LoadingContext, LoadingContextProps } from '../../context/LoadingContext';
import Progress from '../../components/progress/Progress';
import Notification from '../../components/notification/Notification';
import {
  SimulatorsSummaryContext,
  SimulatorsSummaryContextProps,
} from '../../context/simulators/summary/SimulatorsSummaryContext';
import {
  DecarbonationIndiceStarsType,
  ISimulatorRecap,
} from './pdf/pdf-simulators-recap-table/PdfSimulatorsRecapTable';

export interface DetailedReportProps extends IsViewerOnlyProps { }

export type FiltersDetailedReportType = 'gainfinancier' | 'economiedenergie' | 'decarbonation';

export interface ISimulatorDatas {
  simulatorCode: ISIMULATORS_NAME;
  resultTableRows: ITableRow[];
  resultCharts: ChartProps[][];
  financialGain?: ITableRow;
  energyGain?: ITableRow;
  co2Gain?: ITableRow;
}
export interface ISimulatorsDatas {
  [x: string]: ISimulatorDatas;
}

const formatFinancialGainTableRow = (
  code: ISIMULATORS_NAME,
  contexts: {
    [x in ISIMULATORS_NAME]: any;
  },
): ITableRow | undefined => {
  if ((contexts[code] as ISimulatorRequiredDatas).financialGainPerYear)
    return {
      label: 'Gain financié estimé',
      unit: '€/an',
      value: `${(contexts[code] as ISimulatorRequiredDatas).financialGainPerYear}`,
    };
  return undefined;
};

const formatCo2GainTableRow = (
  code: ISIMULATORS_NAME,
  contexts: {
    [x in ISIMULATORS_NAME]: any;
  },
): ITableRow | undefined => {
  if ((contexts[code] as ISimulatorRequiredDatas).kgCo2EmissionAvoidedPerYear)
    return {
      label: "Baisse d'émissions estimée",
      unit: 'T CO2/an',
      value: `${(contexts[code] as ISimulatorRequiredDatas).kgCo2EmissionAvoidedPerYear / 1000}`,
    };
  return undefined;
};

const formatEnergyGainTableRow = (
  code: ISIMULATORS_NAME,
  contexts: {
    [x in ISIMULATORS_NAME]: any;
  },
): ITableRow | undefined => {
  if ((contexts[code] as ISimulatorRequiredDatas).energyGainMwhPerYear)
    return {
      label: 'Gains énergétique',
      unit: 'MWh/an',
      value: `${(contexts[code] as ISimulatorRequiredDatas).energyGainMwhPerYear}`,
    };
  return undefined;
};

const DetailedReport: React.FC<DetailedReportProps> = ({ isViewerOnly }) => {
  const { simulators } = useContext(SimulatorsContext) as SimulatorsContextProps;
  const { user, userToken } = useContext(UserContext) as UserContextProps;
  const { updateModalConf } = useContext(ModalContext) as ModalContextProps;
  const [currentFilter, setCurrentFilter] = useState<FiltersDetailedReportType>('gainfinancier');
  const { contexts } = useContext(SimulatorsSummaryContext) as SimulatorsSummaryContextProps;
  const industrialSiteContext = useContext(IndustrialSiteContext) as IndustrialSiteContextProps;
  const monitoringContext = useContext(MonitoringContext) as MonitoringContextProps;
  const compressedAirContext = useContext(CompressedAirContext) as CompressedAirContextProps;
  const heatRecoveryContext = useContext(HeatRecoveryContext) as HeatRecoveryContextProps;
  const roomAirDestratificationContext = useContext(
    RoomAirDestratificationContext,
  ) as RoomAirDestratificationContextProps;
  const microModulatingBurnerContext = useContext(
    MicroModulatingBurnerContext,
  ) as MicroModulatingBurnerContextProps;
  const insulatingMattressesContext = useContext(
    InsulatingMattressesContext,
  ) as InsulatingMattressesContextProps;
  const ledLightingContext = useContext(LedLightingContext) as LedLightingContextProps;
  const heatPumpContext = useContext(HeatPumpContext) as HeatPumpContextProps;
  const electricSpeedVariationContext = useContext(
    ElectricSpeedVariationContext,
  ) as ElectricSpeedVariationContextProps;
  const highPerformanceEnginesContext = useContext(
    HighPerformanceEnginesContext,
  ) as HightPerformanceEnginesContextProps;
  const motorDriveContext = useContext(MotorDriveContext) as MotorDriveContextProps;

  const [filteredSimulators, setFilteredSimulators] = useState<ISimulator[]>();
  const [simulatorsDatas, setSimulatorsDatas] = useState<ISimulatorsDatas>();
  const [graphsB64Images, setGraphsB64Images] = useState<any[]>([]);
  const [isDownloadingReport, setIsDownloadingReport] = useState<boolean>(false);
  const [showGraphs, setShowGraphs] = useState<boolean>(true);
  const [totalSimulatorsEconomy, setTotalSimulatorsEconomy] =
    useState<ISimulatorEstimatedEconomy>();
  const [userEmail, setUserEmail] = useState<string>('');
  const [reportIsLoading, setReportIsLoading] = useState<boolean>(true);
  const { isLoading } = useContext(LoadingContext) as LoadingContextProps;

  const graphsRefs = useRef<any[]>([]);

  const checkShowSimulator = (simulator: ISimulator, filter: FiltersDetailedReportType) => {
    // if (filter === 'economiedenergie') {
    //   return simulator.labels.includes('Efficacité énergétique');
    // } else if (filter === 'decarbonation') {
    //   return simulator.labels.includes('Décarbonation');
    // }
    return true;
  };

  useEffect(() => {
    setUserEmail(user?.details?.email || '');
  }, [user]);

  const getGraphsBase64 = async (refs: any[]) => {
    let b64Arr: any[] = [];
    for (let i = 0; i < refs.length; i++) {
      let b64: any[] = [];
      for (let j = 0; j < refs[i].length; j++) {
        const ref = refs[i][j];
        b64.push(ref.toBase64Image('image/png', 1.0));
      }
      b64Arr.push(b64);
    }

    return b64Arr;
  };

  useEffect(() => {
    document.title = `Rapport détaillé - ${APP_TITLE}`;
  }, []);

  useEffect(() => {
    localStorage.setItem('showGraphs', `${showGraphs}`);
  }, [showGraphs]);

  useEffect(() => {
    if (!contexts) return;
    setSimulatorsDatas({
      [SIMULATORS_NAMES.INDUSTRIAL_SITE.name]: {
        simulatorCode: 'INDUSTRIAL_SITE',
        resultTableRows: industrialSiteContext.resultTableRows,
        resultCharts: industrialSiteContext.resultCharts,
      },
      [SIMULATORS_NAMES.MONITORING.name]: {
        simulatorCode: 'MONITORING',
        resultTableRows: monitoringContext.resultTableRows,
        resultCharts: monitoringContext.resultCharts,
        financialGain: formatFinancialGainTableRow('MONITORING', contexts),
        co2Gain: formatCo2GainTableRow('MONITORING', contexts),
        energyGain: formatEnergyGainTableRow('MONITORING', contexts),
      },
      [SIMULATORS_NAMES.COMPRESSED_AIR.name]: {
        simulatorCode: 'COMPRESSED_AIR',
        resultTableRows: compressedAirContext.resultTableRows,
        resultCharts: compressedAirContext.resultCharts,
        financialGain: formatFinancialGainTableRow('COMPRESSED_AIR', contexts),
        co2Gain: formatCo2GainTableRow('COMPRESSED_AIR', contexts),
        energyGain: formatEnergyGainTableRow('COMPRESSED_AIR', contexts),
      },
      [SIMULATORS_NAMES.HEAT_RECOVERY.name]: {
        simulatorCode: 'HEAT_RECOVERY',
        resultTableRows: heatRecoveryContext.resultTableRows,
        resultCharts: heatRecoveryContext.resultCharts,
        financialGain: formatFinancialGainTableRow('HEAT_RECOVERY', contexts),
        co2Gain: formatCo2GainTableRow('HEAT_RECOVERY', contexts),
        energyGain: formatEnergyGainTableRow('HEAT_RECOVERY', contexts),
      },
      [SIMULATORS_NAMES.ROOM_AIR_DESTRATIFICATION.name]: {
        simulatorCode: 'ROOM_AIR_DESTRATIFICATION',
        resultTableRows: roomAirDestratificationContext.resultTableRows,
        resultCharts: roomAirDestratificationContext.resultCharts,
        financialGain: formatFinancialGainTableRow('ROOM_AIR_DESTRATIFICATION', contexts),
        co2Gain: formatCo2GainTableRow('ROOM_AIR_DESTRATIFICATION', contexts),
        energyGain: formatEnergyGainTableRow('ROOM_AIR_DESTRATIFICATION', contexts),
      },
      [SIMULATORS_NAMES.MICRO_MODULATING_BURNER.name]: {
        simulatorCode: 'MICRO_MODULATING_BURNER',
        resultTableRows: microModulatingBurnerContext.resultTableRows,
        resultCharts: microModulatingBurnerContext.resultCharts,
        financialGain: formatFinancialGainTableRow('MICRO_MODULATING_BURNER', contexts),
        co2Gain: formatCo2GainTableRow('MICRO_MODULATING_BURNER', contexts),
        energyGain: formatEnergyGainTableRow('MICRO_MODULATING_BURNER', contexts),
      },
      [SIMULATORS_NAMES.INSULATING_MATTRESSES.name]: {
        simulatorCode: 'INSULATING_MATTRESSES',
        resultTableRows: insulatingMattressesContext.resultTableRows,
        resultCharts: insulatingMattressesContext.resultCharts,
        financialGain: formatFinancialGainTableRow('INSULATING_MATTRESSES', contexts),
        co2Gain: formatCo2GainTableRow('INSULATING_MATTRESSES', contexts),
        energyGain: formatEnergyGainTableRow('INSULATING_MATTRESSES', contexts),
      },
      [SIMULATORS_NAMES.LED_LIGHTING.name]: {
        simulatorCode: 'LED_LIGHTING',
        resultTableRows: ledLightingContext.resultTableRows,
        resultCharts: ledLightingContext.resultCharts,
        financialGain: formatFinancialGainTableRow('LED_LIGHTING', contexts),
        co2Gain: formatCo2GainTableRow('LED_LIGHTING', contexts),
        energyGain: formatEnergyGainTableRow('LED_LIGHTING', contexts),
      },
      [SIMULATORS_NAMES.HEAT_PUMP.name]: {
        simulatorCode: 'HEAT_PUMP',
        resultTableRows: heatPumpContext.resultTableRows,
        resultCharts: heatPumpContext.resultCharts,
        financialGain: formatFinancialGainTableRow('HEAT_PUMP', contexts),
        co2Gain: formatCo2GainTableRow('HEAT_PUMP', contexts),
        energyGain: formatEnergyGainTableRow('HEAT_PUMP', contexts),
      },
      [SIMULATORS_NAMES.ELECTRIC_SPEED_VARIATION.name]: {
        simulatorCode: 'ELECTRIC_SPEED_VARIATION',
        resultTableRows: electricSpeedVariationContext.resultTableRows,
        resultCharts: electricSpeedVariationContext.resultCharts,
        financialGain: formatFinancialGainTableRow('ELECTRIC_SPEED_VARIATION', contexts),
        co2Gain: formatCo2GainTableRow('ELECTRIC_SPEED_VARIATION', contexts),
        energyGain: formatEnergyGainTableRow('ELECTRIC_SPEED_VARIATION', contexts),
      },
      [SIMULATORS_NAMES.HIGH_PERFORMANCE_ENGINES.name]: {
        simulatorCode: 'HIGH_PERFORMANCE_ENGINES',
        resultTableRows: highPerformanceEnginesContext.resultTableRows,
        resultCharts: highPerformanceEnginesContext.resultCharts,
        financialGain: formatFinancialGainTableRow('HIGH_PERFORMANCE_ENGINES', contexts),
        co2Gain: formatCo2GainTableRow('HIGH_PERFORMANCE_ENGINES', contexts),
        energyGain: formatEnergyGainTableRow('HIGH_PERFORMANCE_ENGINES', contexts),
      },
      [SIMULATORS_NAMES.MOTOR_DRIVE.name]: {
        simulatorCode: 'MOTOR_DRIVE',
        resultTableRows: motorDriveContext.resultTableRows,
        resultCharts: motorDriveContext.resultCharts,
        financialGain: formatFinancialGainTableRow('MOTOR_DRIVE', contexts),
        co2Gain: formatCo2GainTableRow('MOTOR_DRIVE', contexts),
        energyGain: formatEnergyGainTableRow('MOTOR_DRIVE', contexts),
      },
    });
  }, [
    industrialSiteContext,
    monitoringContext,
    compressedAirContext,
    heatRecoveryContext,
    roomAirDestratificationContext,
    microModulatingBurnerContext,
    insulatingMattressesContext,
    ledLightingContext,
    heatPumpContext,
    electricSpeedVariationContext,
    highPerformanceEnginesContext,
    motorDriveContext,
    contexts,
  ]);

  // useEffect(() => {
  //   if (!simulators) return;
  //   setFilteredSimulators(
  //     simulators.filter(
  //       (sim) =>
  //         (sim.code === 'INDUSTRIAL_SITE' &&
  //           sim.datas &&
  //           ((getSimulatorContextByCode('INDUSTRIAL_SITE', contexts) as IndustrialSiteContextProps)
  //             .annualElectricityConsumption > 0 ||
  //             (getSimulatorContextByCode('INDUSTRIAL_SITE', contexts) as IndustrialSiteContextProps)
  //               .annualGazConsumption > 0)) ||
  //         (sim.code !== SIMULATORS_NAMES.INDUSTRIAL_SITE.name &&
  //           getSimulatorDatasByCode(
  //             sim?.code as ISIMULATORS_NAME,
  //             simulatorsDatas as ISimulatorsDatas,
  //           )?.financialGain &&
  //           getSimulatorDatasByCode(
  //             sim?.code as ISIMULATORS_NAME,
  //             simulatorsDatas as ISimulatorsDatas,
  //           )?.co2Gain &&
  //           getSimulatorDatasByCode(
  //             sim?.code as ISIMULATORS_NAME,
  //             simulatorsDatas as ISimulatorsDatas,
  //           )?.energyGain),
  //     ),
  //   );
  // }, [simulators, simulatorsDatas, contexts]);

  useEffect(() => {
    if (!simulators) return;
    setFilteredSimulators(
      simulators.filter(
        (sim) =>
          (sim.code === 'INDUSTRIAL_SITE' &&
            sim.datas &&
            ((getSimulatorContextByCode('INDUSTRIAL_SITE', contexts) as IndustrialSiteContextProps)
              .annualElectricityConsumption > 0 ||
              (getSimulatorContextByCode('INDUSTRIAL_SITE', contexts) as IndustrialSiteContextProps)
                .annualGazConsumption > 0)) ||
          (sim.code !== SIMULATORS_NAMES.INDUSTRIAL_SITE.name &&
            getSimulatorDatasByCode(
              sim?.code as ISIMULATORS_NAME,
              simulatorsDatas as ISimulatorsDatas,
            )?.financialGain &&
            getSimulatorDatasByCode(
              sim?.code as ISIMULATORS_NAME,
              simulatorsDatas as ISimulatorsDatas,
            )?.co2Gain &&
            getSimulatorDatasByCode(
              sim?.code as ISIMULATORS_NAME,
              simulatorsDatas as ISimulatorsDatas,
            )?.energyGain),
      ).sort((a, b) => {
        let aHasPriorityLabel : any = false;
        let bHasPriorityLabel : any = false;

        if (currentFilter === 'economiedenergie') {
          aHasPriorityLabel = a.labels.includes('Efficacité énergétique');
          bHasPriorityLabel = b.labels.includes('Efficacité énergétique');
        } else if (currentFilter === 'decarbonation') {
          aHasPriorityLabel = a.labels.includes('Décarbonation');
          bHasPriorityLabel = b.labels.includes('Décarbonation');
        }

        // Sort descending: items with priority label come first
        return bHasPriorityLabel ? 1 : aHasPriorityLabel ? -1 : 0;
      })
    );
  }, [simulators, simulatorsDatas, contexts, currentFilter]);

  useEffect(() => {
    filteredSimulators &&
      contexts &&
      setTotalSimulatorsEconomy(
        calculateTotalSimulatorEstimatedEconomy(filteredSimulators, contexts),
      );
  }, [filteredSimulators, contexts]);

  const [loadedB64, setLoadedB64] = useState<boolean>(false);

  const calculateMaxFieldFromSimulators = (
    simulatorsRecap: ISimulatorRecap[],
    fieldToCompare:
      | 'energyGainMwhPerYear'
      | 'tCo2EmissionAvoidedPerYear'
      | 'financialGainPerYear'
      | 'estimatedBudget'
      | 'estimatedRoi'
      | 'decarbonationIndice',
    simulatorsCodesToCompare: ISIMULATORS_NAME[],
  ) => {
    let max = 0;
    for (let i = 0; i < simulatorsRecap.length; i++) {
      const sim = simulatorsRecap[i];
      if (simulatorsCodesToCompare.includes(sim.simulator.code as ISIMULATORS_NAME)) {
        const fieldValue = sim.recap[fieldToCompare];
        if (fieldValue > max) max = fieldValue;
      }
    }
    return max;
  };

  const getBarsCountSet0 = useCallback((value: number, maxValue: number) => {
    const percentage = Math.round((value * 100) / maxValue);
    if (percentage < 20) return 0;
    if (percentage >= 80) return 4;
    if (percentage >= 60) return 3;
    if (percentage >= 40) return 2;
    if (percentage >= 20) return 1;
    return 0;
  }, []);

  const getBarsCountSet1 = useCallback((value: number, maxValue: number) => {
    const percentage = Math.round((value * 100) / maxValue);
    if (percentage < 100) return 0;
    if (percentage >= 100) return 4;
    return 0;
  }, []);

  const getBarsCountSet2 = useCallback((value: number, maxValue: number) => {
    const percentage = Math.round((value * 100) / maxValue);
    if (percentage < 100) return 0;
    if (percentage >= 100) return 4;
    return 0;
  }, []);

  const getBarsCountSet3 = useCallback((value: number, maxValue: number) => {
    const percentage = Math.round((value * 100) / maxValue);
    if (percentage < 100) return 0;
    if (percentage >= 100) return 4;
    return 0;
  }, []);

  const getStarsCountSet0 = useCallback(
    (value: number, maxValue: number): DecarbonationIndiceStarsType => {
      const percentage = (value * 100) / maxValue;
      if (percentage < 33) return 0;
      if (percentage >= 67) return 1;
      if (percentage >= 33) return 0.5;
      return 0;
    },
    [],
  );

  const getStarsCountSet1 = useCallback(
    (value: number, maxValue: number): DecarbonationIndiceStarsType => {
      const percentage = (value * 100) / maxValue;
      if (percentage < 67) return 0;
      if (percentage >= 67) return 1;
      return 0;
    },
    [],
  );

  const getStarsCountSet2 = useCallback(
    (value: number, maxValue: number): DecarbonationIndiceStarsType => {
      const percentage = (value * 100) / maxValue;
      if (percentage < 67) return 0;
      if (percentage >= 67) return 1;
      return 0;
    },
    [],
  );

  const getStarsCountSet3 = useCallback(
    (value: number, maxValue: number): DecarbonationIndiceStarsType => {
      const percentage = (value * 100) / maxValue;
      if (percentage < 67) return 0;
      if (percentage >= 67) return 1;
      return 0;
    },
    [],
  );

  const handleDownload = async (email: string) => {
    try {
      const simulatorsRecap: ISimulatorRecap[] = [];
      if (!filteredSimulators) return;
      const desiredOrder: ISIMULATORS_NAME[] = [
        'COMPRESSED_AIR',
        'HEAT_RECOVERY',
        'ROOM_AIR_DESTRATIFICATION',
        'MICRO_MODULATING_BURNER',
        'INSULATING_MATTRESSES',
        'LED_LIGHTING',
        'HEAT_PUMP',
        'MONITORING',
        'ELECTRIC_SPEED_VARIATION',
        'HIGH_PERFORMANCE_ENGINES',
        'MOTOR_DRIVE',
      ];

      contexts &&
        sortSimulators(filteredSimulators, desiredOrder).forEach((simulator) => {
          const similarDatas = getSimulatorContextByCode(
            simulator.code,
            contexts,
          ) as ISimulatorRequiredDatas;
          const energyGain = similarDatas.energyGainMwhPerYear;
          const tCo2Emission = similarDatas.kgCo2EmissionAvoidedPerYear / 1000;
          const kEurfinancialGain = similarDatas.financialGainPerYear / 1000;
          const kEurestimatedBudget = similarDatas.estimatedBudget / 1000;
          const estimatedRoi = customRoundDown(1 + kEurestimatedBudget / kEurfinancialGain, 0);
          const decarbonationIndice = tCo2Emission / kEurestimatedBudget;

          simulatorsRecap.push({
            simulator: simulator,
            recap: {
              energyGainMwhPerYear: energyGain,
              financialGainPerYear: kEurfinancialGain,
              tCo2EmissionAvoidedPerYear: tCo2Emission,
              estimatedBudget: kEurestimatedBudget,
              estimatedRoi: estimatedRoi,
              decarbonationIndice: decarbonationIndice,
              tCo2BarsCount: 0,
              decarbonationIndiceStars: 0,
            },
          });
        });

      const simulatorsToCompare0: ISIMULATORS_NAME[] = [
        'COMPRESSED_AIR',
        'HEAT_RECOVERY',
        'ROOM_AIR_DESTRATIFICATION',
        'MICRO_MODULATING_BURNER',
        'INSULATING_MATTRESSES',
        'LED_LIGHTING',
        'HEAT_PUMP',
      ];
      const simulatorsToCompare1: ISIMULATORS_NAME[] = ['MONITORING'];
      const simulatorsToCompare2: ISIMULATORS_NAME[] = [
        'ELECTRIC_SPEED_VARIATION',
        'HIGH_PERFORMANCE_ENGINES',
      ];
      const simulatorsToCompare3: ISIMULATORS_NAME[] = ['MOTOR_DRIVE'];

      const maxCo2EmissionSet0 = calculateMaxFieldFromSimulators(
        simulatorsRecap,
        'tCo2EmissionAvoidedPerYear',
        simulatorsToCompare0,
      );

      const maxCo2EmissionSet1 = calculateMaxFieldFromSimulators(
        simulatorsRecap,
        'tCo2EmissionAvoidedPerYear',
        simulatorsToCompare1,
      );
      const maxCo2EmissionSet2 = calculateMaxFieldFromSimulators(
        simulatorsRecap,
        'tCo2EmissionAvoidedPerYear',
        simulatorsToCompare2,
      );
      const maxCo2EmissionSet3 = calculateMaxFieldFromSimulators(
        simulatorsRecap,
        'tCo2EmissionAvoidedPerYear',
        simulatorsToCompare3,
      );

      const maxDecarbonationIndiceSet0 = calculateMaxFieldFromSimulators(
        simulatorsRecap,
        'decarbonationIndice',
        simulatorsToCompare0,
      );

      const maxDecarbonationIndiceSet1 = calculateMaxFieldFromSimulators(
        simulatorsRecap,
        'decarbonationIndice',
        simulatorsToCompare1,
      );
      const maxDecarbonationIndiceSet2 = calculateMaxFieldFromSimulators(
        simulatorsRecap,
        'decarbonationIndice',
        simulatorsToCompare2,
      );
      const maxDecarbonationIndiceSet3 = calculateMaxFieldFromSimulators(
        simulatorsRecap,
        'decarbonationIndice',
        simulatorsToCompare3,
      );

      const tmpSimulatorsRecap = simulatorsRecap.map((simRecap) => {
        let tmpTCo2BarsCount: number = 0;
        let tmpDecarbonationIndiceStars: DecarbonationIndiceStarsType = 0;

        if (simulatorsToCompare0.includes(simRecap.simulator.code as ISIMULATORS_NAME)) {
          tmpTCo2BarsCount = getBarsCountSet0(
            simRecap.recap.tCo2EmissionAvoidedPerYear,
            maxCo2EmissionSet0,
          );
          tmpDecarbonationIndiceStars = getStarsCountSet0(
            simRecap.recap.decarbonationIndice,
            maxDecarbonationIndiceSet0,
          );
        } else if (simulatorsToCompare1.includes(simRecap.simulator.code as ISIMULATORS_NAME)) {
          tmpTCo2BarsCount = getBarsCountSet1(
            simRecap.recap.tCo2EmissionAvoidedPerYear,
            maxCo2EmissionSet1,
          );
          tmpDecarbonationIndiceStars = getStarsCountSet1(
            simRecap.recap.decarbonationIndice,
            maxDecarbonationIndiceSet1,
          );
        } else if (simulatorsToCompare2.includes(simRecap.simulator.code as ISIMULATORS_NAME)) {
          tmpTCo2BarsCount = getBarsCountSet2(
            simRecap.recap.tCo2EmissionAvoidedPerYear,
            maxCo2EmissionSet2,
          );
          tmpDecarbonationIndiceStars = getStarsCountSet2(
            simRecap.recap.decarbonationIndice,
            maxDecarbonationIndiceSet2,
          );
        } else if (simulatorsToCompare3.includes(simRecap.simulator.code as ISIMULATORS_NAME)) {
          tmpTCo2BarsCount = getBarsCountSet3(
            simRecap.recap.tCo2EmissionAvoidedPerYear,
            maxCo2EmissionSet3,
          );
          tmpDecarbonationIndiceStars = getStarsCountSet3(
            simRecap.recap.decarbonationIndice,
            maxDecarbonationIndiceSet3,
          );
        }

        return {
          ...simRecap,
          recap: {
            ...simRecap.recap,
            tCo2BarsCount: tmpTCo2BarsCount,
            decarbonationIndiceStars: tmpDecarbonationIndiceStars,
          },
        };
      });

      setIsDownloadingReport(true);
      if (isViewerOnly) {
        const blob = await pdf(
          <PdfDetailedReport
            simulatorsDatas={simulatorsDatas as any}
            simulators={filteredSimulators}
            totalSimulatorsEconomy={totalSimulatorsEconomy as any}
            simulatorsRecap={tmpSimulatorsRecap}
            graphsImages={showGraphs ? graphsB64Images : []}
          />,
        ).toBlob();
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = `actemium_rapport_${email}.pdf`;

        // Simulate click event to trigger download
        downloadLink.click();

        // Cleanup
        URL.revokeObjectURL(downloadLink.href);
        toast.success('Rapport téléchargé avec succès');
        return;
      }
      updateModalConf({
        component: (
          <Thanking
            onDownloadReport={async () => {
              const blob = await pdf(
                <PdfDetailedReport
                  simulatorsDatas={simulatorsDatas as any}
                  simulators={filteredSimulators}
                  totalSimulatorsEconomy={totalSimulatorsEconomy as any}
                  simulatorsRecap={tmpSimulatorsRecap}
                  graphsImages={showGraphs ? graphsB64Images : []}
                />,
              ).toBlob();
              if (!isViewerOnly)
                UserApi.updateUserDetails(userToken as string, {
                  ...user?.details,
                  email: email,
                  wantFullReport: 1,
                }).then(async () => {
                  saveAs(blob, `actemium_rapport_${email}.pdf`);
                  updateModalConf();
                  toast.success('Rapport téléchargé avec succès');
                });
              else {
                saveAs(blob, `actemium_rapport_${email}.pdf`);
                updateModalConf();
                toast.success('Rapport téléchargé avec succès');
              }
            }}
          />
        ),
      });
    } catch (err) {
      console.error('Error generating PDF:', err);
      toast.error('Error generating PDF');
    } finally {
      setIsDownloadingReport(false);
    }
  };

  useEffect(() => {
    if (loadedB64 && graphsB64Images && graphsB64Images.length > 0) {
      handleDownload(userEmail);
      setLoadedB64(false);
    }
    // eslint-disable-next-line
  }, [loadedB64, graphsB64Images, userEmail]);

  useDelayedUpdate(
    () => {
      setReportIsLoading(false);
    },
    [simulatorsDatas, simulators, totalSimulatorsEconomy, graphsB64Images],
    500,
  );

  return (
    <DetailedReportWrapper>
      <ScrollToTop />
      <>
        {!reportIsLoading && !isLoading ? (
          <>
            {totalSimulatorsEconomy && (
              <NavDetailedReport
                estimatedEconomyProps={totalSimulatorsEconomy}
                isViewerOnly={user?.details?.isViewerOnly}
              />
            )}
            <div className="detailed-report-children" id="detailed-report-children">
              {isViewerOnly && (
                <Notification
                  type="info"
                  message={
                    <div className="viewer-only-notification">
                      Vous êtes actuellement en mode vue de la simulation {user?.details?.token}{' '}
                      {`( ID : ${user?.details?.id} ) `}. Cliquez{' '}
                      <span
                        className="quit-view-mode"
                        onClick={() => {
                          onQuitViewMode();
                        }}
                      >
                        ici
                      </span>{' '}
                      pour revenir sur votre session actuelle.
                    </div>
                  }
                />
              )}
              <div className="report-content">
                <div className="left-report-content">
                  <FiltersDetailedReport
                    currentFilter={currentFilter}
                    filters={[
                      {
                        label: 'Gains estimés',
                        name: 'gainfinancier',
                      },
                      {
                        label: "Économies d'énergies",
                        name: 'economiedenergie',
                      },
                      {
                        label: 'Décarbonation',
                        name: 'decarbonation',
                      },
                    ]}
                    hideGraphs={!showGraphs}
                    onToggleGraphsView={(e) => setShowGraphs(!e)}
                    onChange={(e) => {
                      setCurrentFilter(e);
                    }}
                  />
                  <div className="only-render">
                    {currentFilter && currentFilter === 'gainfinancier' ? (
                      <TotalTitle
                        icon={ICONS.ESTIMATED_GAIN}
                        title="Total gains estimés"
                        unit={totalSimulatorsEconomy?.estimatedEconomy?.financial.unit || ''}
                        value={totalSimulatorsEconomy?.estimatedEconomy?.financial.value || 0}
                      />
                    ) : currentFilter === 'economiedenergie' ? (
                      <TotalTitle
                        icon={ICONS.ENERGY_ECONOMY}
                        title="Économie d'énergie"
                        unit={totalSimulatorsEconomy?.estimatedEconomy?.energy.unit || ''}
                        value={totalSimulatorsEconomy?.estimatedEconomy?.energy.value || 0}
                      />
                    ) : (
                      currentFilter === 'decarbonation' && (
                        <TotalTitle
                          icon={ICONS.DECREASED_EMISSION}
                          title="Baisse d'émissions"
                          unit={totalSimulatorsEconomy?.estimatedEconomy?.decarbonation.unit || ''}
                          value={totalSimulatorsEconomy?.estimatedEconomy?.decarbonation.value || 0}
                        />
                      )
                    )}
                  </div>
                  {filteredSimulators &&
                    contexts &&
                    filteredSimulators?.map((simulator, key) => {
                      const simDatas = getSimulatorDatasByCode(
                        simulator?.code as ISIMULATORS_NAME,
                        simulatorsDatas as ISimulatorsDatas,
                      );
                      return (
                        <div
                          key={key}
                          className={`single-detail ${checkShowSimulator(simulator, currentFilter) ? '' : 'hide'
                            }`}
                        >
                          <TableDetailedReport
                            key={key}
                            rows={(simDatas && (simDatas?.resultTableRows as ITableRow[])) || []}
                            isEligibleCEE={!!simulator?.isEligibleForCEE}
                            header={{
                              icon: simulator?.icon,
                              labels: [],
                              title: simulator?.name,
                            }}
                            highlightRow={
                              currentFilter === 'gainfinancier' &&
                                !!getSimulatorDatasByCode(
                                  simulator?.code as ISIMULATORS_NAME,
                                  simulatorsDatas as ISimulatorsDatas,
                                )?.financialGain
                                ? getSimulatorDatasByCode(
                                  simulator?.code as ISIMULATORS_NAME,
                                  simulatorsDatas as ISimulatorsDatas,
                                )?.financialGain
                                : currentFilter === 'economiedenergie' &&
                                  !!getSimulatorDatasByCode(
                                    simulator?.code as ISIMULATORS_NAME,
                                    simulatorsDatas as ISimulatorsDatas,
                                  )?.energyGain
                                  ? getSimulatorDatasByCode(
                                    simulator?.code as ISIMULATORS_NAME,
                                    simulatorsDatas as ISimulatorsDatas,
                                  )?.energyGain
                                  : currentFilter === 'decarbonation' &&
                                    !!getSimulatorDatasByCode(
                                      simulator?.code as ISIMULATORS_NAME,
                                      simulatorsDatas as ISimulatorsDatas,
                                    )?.co2Gain
                                    ? getSimulatorDatasByCode(
                                      simulator?.code as ISIMULATORS_NAME,
                                      simulatorsDatas as ISimulatorsDatas,
                                    )?.co2Gain
                                    : undefined
                            }
                          />
                          {simDatas?.resultCharts && (
                            <div
                              style={
                                !showGraphs
                                  ? {
                                    position: 'absolute',
                                    visibility: 'hidden',
                                    userSelect: 'none',
                                  }
                                  : {}
                              }
                            >
                              <ChartsDetailedReport
                                charts={simDatas?.resultCharts}
                                getRef={(e) => graphsRefs.current.push(e)}
                              />
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
                <div className="right-report-content">
                  <Card theme="darkBlue">
                    <DownloadDetailedReportCard>
                      <CardTitle>
                        <span className="icon">{ICONS?.DOWNLOAD?.icon}</span> Télécharger
                        {!isViewerOnly ? ' votre ' : ' son '}
                        rapport détaillé
                      </CardTitle>
                      <CardBody>
                        {!isViewerOnly ? 'Votre ' : 'Le '}rapport comprend :
                        <ul>
                          <li>
                            {!isViewerOnly ? 'Votre ' : 'Le '} rapport détaillé avec tableau
                            comparatif
                          </li>
                          <li>Une aide décisionnel</li>
                          <li>Des conseils personnalisés</li>
                        </ul>
                      </CardBody>
                      <div className="input-section">
                        <TextField
                          placeholder="Saisir votre adresse email"
                          value={userEmail}
                          onChange={(email) => {
                            !isViewerOnly && setUserEmail(email);
                          }}
                          disabled={isViewerOnly}
                        />
                        <Button
                          hasIcon
                          label={isDownloadingReport ? 'Chargement ...' : 'Télécharger'}
                          theme="dark"
                          onClick={async () => {
                            if (!userEmail || !isValidEmail(userEmail)) {
                              toast.error('Veuillez saisir un email valide');
                            } else {
                              if (showGraphs) {
                                const graphs = await getGraphsBase64(graphsRefs.current);
                                setGraphsB64Images(graphs);
                                setLoadedB64(true);
                              } else handleDownload(userEmail);
                            }
                          }}
                        />
                      </div>
                    </DownloadDetailedReportCard>
                  </Card>
                  <CEEEligibleCard>
                    <Card theme="brightBlue">
                      <CardTitle>
                        <span className="icon">{ICONS?.CEE?.icon}</span>
                        Elligible CEE
                      </CardTitle>
                      <CardBody>
                        Les travaux éligibles aux CEE sont remboursés par le Dispositif des
                        Certificats d’économies d’énergie.
                      </CardBody>
                      <a href="/">En savoir plus</a>
                    </Card>
                  </CEEEligibleCard>
                  {!isViewerOnly && <ContactUsCard />}
                  <p className="extra-explications">{RESPONSABILITY_NOTE}</p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <Loader>
              <Progress />
            </Loader>
          </>
        )}
      </>
    </DetailedReportWrapper>
  );
};

export default DetailedReport;
