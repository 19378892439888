import styled from 'styled-components';
import { BREAKPOINTS } from '../../styles/constants/devices';
import { NavDetailedReportWrapper } from './nav/NavDetailedReport.styled';
import { FiltersDetailedReportWrapper } from './filters/FiltersDetailedReport.styled';
import { COLORS } from '../../styles/constants/colors';
import { TableDetailedReportWrapper } from './table/TableDetailedReport.styled';
import TableWrapper from '../../components/table/Tables.styled';
import { BODY, H5 } from '../../styles/helpers/typography';
import { TextFieldWrapper } from '../../components/form/text-field/TextField.styled';
import { ButtonWrapper } from '../../components/button/Button.styled';
import { CardWrapper } from '../../components/card/Card.styled';
import { ContactUsCardWrapper } from '../dashboard/contact-us-card/ContactUsCard.styled';
import { TotalTitleWrapper } from './total-title/TotalTitle.styled';

const CardTitle = styled.div`
  ${BODY.L_SEMI_BOLD}
  display: flex;
  gap: 10px;
  align-items: center;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const CardBody = styled.div`
  ${BODY.S_MEDIUM}

  ul {
    padding: 0 0 0 14px;
    list-style: none;
    padding-inline-start: 0;

    li::marker {
      display: none;
    }

    li {
      position: relative;

      &::before {
        content: '';
        display: inline-block;
        height: 8px;
        width: 8px;
        margin-right: 8px;
        background-color: ${COLORS.GREEN};
        border-radius: 50px;
      }
    }
  }
`;

const CEEEligibleCard = styled.div`
  ${CardWrapper} {
    min-height: unset;
    padding: 20px 10px 0 10px;

    .card-children {
      display: flex;
      flex-direction: column;
      gap: 20px;

      ${CardBody} {
        ${BODY.S_REGULAR}
      }

      a {
        text-decoration: underline;
        color: ${COLORS.WHITE};
        ${BODY.M_BOLD}
      }
    }
  }
`;

const DownloadDetailedReportCard = styled.div`
  padding: 20px 10px 0 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .input-section {
    display: flex;
    align-items: center;
    background-color: ${COLORS.WHITE};
    padding: 5px;
    border-radius: 50px;

    ${TextFieldWrapper} {
      width: 50%;

      .input-wrapper {
        border: none;
      }
    }
    ${ButtonWrapper} {
      width: 50%;
      flex: 1;
    }
  }
`;

const DetailedReportWrapper = styled.div`
  padding: 70px 10px 20px 10px;
  max-width: 100%;
  min-height: 80vh;
  position: relative;

  .only-pdf {
    display: none;
  }

  .detailed-report-children {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .single-detail {
      display: flex;
      flex-direction: column;
      gap: 20px;

      &.hide {
        display: none;
      }
    }

    .report-content {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .left-report-content {
        display: flex;
        flex-direction: column;
        gap: 30px;
      }

      .right-report-content {
        display: flex;
        gap: 20px;
        flex-direction: column;

        ${ContactUsCardWrapper} {
          color: ${COLORS.DARK_BLUE};
        }

        .extra-explications {
          color: ${COLORS.DARK_BLUE}60;
          ${BODY.S_REGULAR}
        }
      }

      @media (${BREAKPOINTS.XL}) {
        flex-direction: row;

        .left-report-content {
          width: 70%;
        }

        .right-report-content {
          flex: 1;
          max-width: 90%;
          padding-top: 65px;
        }
      }
    }
  }

  @media (${BREAKPOINTS.L}) {
    padding: 100px 50px 100px 200px;
  }

  @media (${BREAKPOINTS.M}) {
    padding-left: 50px;
  }

  @media print {
    ${NavDetailedReportWrapper} {
      position: absolute;
      display: flex;
      justify-content: center;

      .nav-right {
        display: none;
      }
    }

    ${FiltersDetailedReportWrapper} {
      display: none;
    }

    .detailed-report-children {
      .only-render {
        display: none;
      }

      .only-pdf {
        display: flex;
      }

      .report-content {
        .left-report-content {
          width: 100%;
          margin: 0 auto;

          .total-titles {
            display: flex;
            flex-direction: column;

            ${TotalTitleWrapper} {
              color: ${COLORS.DARK_BLUE};
            }
          }

          ${TableDetailedReportWrapper} {
            .icon {
              color: ${COLORS.BRIGHT_BLUE};
              background: ${COLORS.BRIGHT_BLUE};
            }
            ${TableWrapper} {
              .highlight {
                color: ${COLORS.DARK_BLUE};
                ${H5}
              }
            }
          }
        }

        .right-report-content {
          display: none;
        }
      }

      .single-detail {
        page-break-after: always;
        &.hide {
          display: flex;
        }

        .single-detail-title {
          display: flex;
          gap: 20px;
          margin: 50px auto;
          color: ${COLORS.DARK_BLUE};
          min-height: 100vh;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 5rem;

          .icon {
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              width: 10rem;
              height: 10rem;
            }
          }
        }
      }
    }
  }
`;

export { DetailedReportWrapper, DownloadDetailedReportCard, CEEEligibleCard, CardTitle, CardBody };
