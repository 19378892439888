import { ProgressBar } from 'react-loader-spinner';
import { ProgressWrapper } from './Progress.styled';
import { COLORS } from '../../styles/constants/colors';

const Progress: React.FC = () => {
  return (
    <ProgressWrapper>
      <ProgressBar
        visible
        width={60}
        height={60}
        barColor={`${COLORS.BRIGHT_BLUE}`}
        borderColor={'transparent'}
        wrapperClass="progress-bar"
      />
    </ProgressWrapper>
  );
};

export default Progress;
