import { ICONS } from '../../../global';
import { ThankingWrapper } from './Thanking.styled';
import Logo from '../../../assets/images/logo.png';
import LogoText from '../../../assets/images/logo-text.png';
import IllustrationPlant from '../../../assets/images/illustration-plant.png';
import Button from '../../../components/button/Button';
import { useContext, useState } from 'react';
import Progress from '../../../components/progress/Progress';
import { ModalContext, ModalContextProps } from '../../../context/ModalContext';

export interface ThankingProps {
  onDownloadReport?: () => Promise<any>;
}

const Thanking: React.FC<ThankingProps> = ({ onDownloadReport }) => {
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const { updateModalConf } = useContext(ModalContext) as ModalContextProps;

  return (
    <ThankingWrapper>
      <div className="logo">
        <img src={Logo} alt="logo" className="logo-img" />
        <img src={LogoText} alt="logo" className="logo-txt" />
      </div>
      <span
        className="close-icon"
        onClick={() => {
          updateModalConf();
        }}
      >
        {ICONS.MENU_CLOSE.icon}
      </span>
      <div className="body">
        <div className="photo">
          <img src={Logo} alt="olivier" className="olivier" />
          <span className="icon-check">{ICONS.CHECK.icon}</span>
        </div>
        <div className="big-title">Nous vous remercions pour ces informations !</div>
        <div className="small-title">
          Nous vous invitons maintenant à télécharger notre rapport complet pour découvrir plus en
          détails notre analyse.
        </div>
        {!isDownloading ? (
          <Button
            theme="dark"
            onClick={async () => {
              setIsDownloading(true);
              onDownloadReport && (await onDownloadReport());
              setIsDownloading(false);
            }}
          >
            Télécharger mon rapport détaillé{' '}
            <span className="icon">{ICONS.DOWNLOAD_ARROW?.icon}</span>
          </Button>
        ) : (
          <Progress />
        )}
      </div>
      <img src={IllustrationPlant} alt="illustration plante" className="illustration-plant" />
    </ThankingWrapper>
  );
};

export default Thanking;
