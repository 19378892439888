import { createContext, useContext, useEffect, useState } from 'react';
import { IComponentWithChildren, IForm, ISimulatorRequiredDatas } from '../../types/app';
import { FormTitle } from '../../components/form/Form.styled';
import { customRound, customRoundDown } from '../../utils';
import { ReferenceDatasContext, ReferenceDatasContextProps } from '../ReferenceDatasContext';
import { ITableRow } from '../../components/table/Table';
import { ChartProps } from '../../components/chart/Chart';
import { COLORS } from '../../styles/constants/colors';
import { SimulatorsContext, SimulatorsContextProps } from './SimulatorsContext';
import { UserContext, UserContextProps } from '../user/UserContext';

import useDelayedUpdate from '../../hooks/useDelayedUpdate';

export interface LedLightingContextProps extends ISimulatorRequiredDatas {
  electricityConsumptionDedicatedForLighting: number;
  energyGainRateLedConversion: number;
  forms: IForm.IForm[];

  energyGain: number;
  co2Gain: number;
  estimatedFinancialGain: number;
  roi: number;
  estimatedBudget: number;
  resultTableRows: ITableRow[];

  roiMatrix: number[][];
  initialAndFinalConsumptionMatrix: number[][];
  resultCharts: ChartProps[][];
}

const LedLightingContext = createContext<LedLightingContextProps | undefined>(undefined);

const LedLightingProvider: React.FC<IComponentWithChildren> = ({ children }) => {
  const {
    CARBON_CONTENT_PER_KWH_ELECTRICITY,
    AVERAGE_ELECTRICITY_PRICE_PER_MWH,
    NATURAL_ELECTRICITY_TRENDS,
  } = useContext(ReferenceDatasContext) as ReferenceDatasContextProps;
  const { getSimulatorByCode, simulators, editSimulatorDatas } = useContext(
    SimulatorsContext,
  ) as SimulatorsContextProps;
  const { userToken, updateUserSimulatorDatas } = useContext(UserContext) as UserContextProps;

  const [
    electricityConsumptionDedicatedForLighting,
    setElectricityConsumptionDedicatedForLighting,
  ] = useState<number>(0);

  const [energyGainRateLedConversion, setEnergyGainRateLedConversion] = useState<number>(0);
  const [forms, setForms] = useState<IForm.IForm[]>([]);

  const [energyGain, setEnergyGain] = useState<number>(0);
  const [co2Gain, setCo2Gain] = useState<number>(0);
  const [estimatedFinancialGain, setEstimatedFinancialGain] = useState<number>(0);
  const [roi, setRoi] = useState<number>(0);
  const [estimatedBudget, setEstimatedBudget] = useState<number>(0);
  const [resultTableRows, setResultTableRows] = useState<ITableRow[]>([]);

  const [roiMatrix, setRoiMatrix] = useState<number[][]>([]);
  const [initialAndFinalConsumptionMatrix, setInitialAndFinalConsumptionMatrix] = useState<
    number[][]
  >([]);
  const [resultCharts, setResultCharts] = useState<ChartProps[][]>([]);
  const [updateDatas, setUpdateDatas] = useState<object>({});

  //   energy gain
  useEffect(() => {
    setEnergyGain((electricityConsumptionDedicatedForLighting * energyGainRateLedConversion) / 100);
  }, [electricityConsumptionDedicatedForLighting, energyGainRateLedConversion]);

  //   co2 gain
  useEffect(() => {
    setCo2Gain(customRound(energyGain * 1000 * CARBON_CONTENT_PER_KWH_ELECTRICITY?.value, -2));
  }, [energyGain, CARBON_CONTENT_PER_KWH_ELECTRICITY?.value]);

  //   estimated financial gain
  useEffect(() => {
    setEstimatedFinancialGain(
      customRoundDown(energyGain * AVERAGE_ELECTRICITY_PRICE_PER_MWH?.value, -2),
    );
  }, [energyGain, AVERAGE_ELECTRICITY_PRICE_PER_MWH?.value]);

  //   roi
  useEffect(() => {
    setRoi(estimatedBudget / estimatedFinancialGain);
  }, [estimatedBudget, estimatedFinancialGain]);

  //   estimated budget
  useEffect(() => {
    setEstimatedBudget(4000 + electricityConsumptionDedicatedForLighting * 400);
  }, [electricityConsumptionDedicatedForLighting]);

  const [allowRequest, setAllowRequest] = useState<boolean>(false);
  //   forms
  useEffect(() => {
    setForms([
      {
        onChange: () => setAllowRequest(true),
        header: <FormTitle>Remplissez les champs suivants :</FormTitle>,
        fields: [
          {
            label: "Consommation d'électicité dédiée à l'éclairage",
            name: '',
            type: 'textfield',
            textType: 'number',
            value: electricityConsumptionDedicatedForLighting,
            unit: 'MWh/an',
            onChange: (e) => setElectricityConsumptionDedicatedForLighting(Number(e)),
          },
          {
            label:
              'Taux de gain énergétique conversion LED (un gain de 60% est généralement observé)',
            name: '',
            type: 'textfield',
            textType: 'number',
            value: energyGainRateLedConversion,
            unit: '%',
            onChange: (e) => setEnergyGainRateLedConversion(Number(e)),
          },
        ],
      },
    ]);
  }, [electricityConsumptionDedicatedForLighting, energyGainRateLedConversion]);

  //   result table rows
  useEffect(() => {
    setResultTableRows([
      {
        label: 'Gain énergétique',
        unit: 'MWh/an',
        value: `${Math.round(energyGain)}`,
      },
      {
        label: 'Gain CO2',
        unit: 'kg CO2/an',
        value: `${co2Gain}`,
      },
      {
        label: 'Gain financier estimé',
        unit: '€/an',
        value: `${estimatedFinancialGain}`,
      },
      {
        label: 'Enveloppe budgétaire estimée',
        unit: '€',
        value: `${estimatedBudget}`,
      },
      {
        label: 'ROI pour cette action (le ROI n’inclut pas les primes et aides (CEE,…))',
        unit: 'ans',
        value: `${roi}`,
      },
    ]);
  }, [energyGain, co2Gain, estimatedFinancialGain, roi, estimatedBudget]);

  //   initial and final consumption matrix
  useEffect(() => {
    const initial = electricityConsumptionDedicatedForLighting;
    const final = electricityConsumptionDedicatedForLighting - energyGain;

    setInitialAndFinalConsumptionMatrix([[initial], [final]]);
  }, [electricityConsumptionDedicatedForLighting, energyGain]);

  //   roi matrix
  useEffect(() => {
    const investment = -estimatedBudget;
    const initialRealizedEconomy = 0;
    let tmpMatrix: number[][] = [
      [initialRealizedEconomy, estimatedFinancialGain],
      [
        investment + initialRealizedEconomy,
        investment + initialRealizedEconomy + estimatedFinancialGain,
      ],
    ];
    const yearsCount = 8;
    for (let i = 2; i < yearsCount; i++) {
      tmpMatrix[0] = [
        ...tmpMatrix[0],
        tmpMatrix[0][i - 1] * (1 + NATURAL_ELECTRICITY_TRENDS?.value / 100),
      ];
      tmpMatrix[1] = [...tmpMatrix[1], tmpMatrix[1][i - 1] + tmpMatrix[0][i]];
    }
    setRoiMatrix(tmpMatrix);
  }, [estimatedBudget, estimatedFinancialGain, NATURAL_ELECTRICITY_TRENDS?.value]);

  //   result charts
  useEffect(() => {
    setResultCharts([
      [
        {
          chartType: 'bar',
          chartTitle: 'Retour sur investissement pour cette action',
          datas: {
            labels: roiMatrix[1] && roiMatrix[1]?.map((roi, index) => `Année ${index}`),
            datasets: [
              {
                data: roiMatrix[1] && roiMatrix[1],
                backgroundColor: roiMatrix[1]?.map(
                  (roi, index) => `${roi < 0 ? COLORS.LIGHT_BLUE : COLORS.BRIGHT_BLUE}`,
                ),
              },
            ],
          },
          defaultUnit: '€',
          yUnit: '€',
        },
      ],
      [
        {
          chartType: 'bar',
          chartTitle: "Gain énergétique sur l'éclairage",
          labels: ['Initial', 'Final'],
          labelsBackgroundColors: [COLORS.BRIGHT_BLUE, COLORS.DARK_BLUE],
          labelsColors: [COLORS.WHITE, COLORS.WHITE],
          datas: {
            labels: ['Consommation (MWh/an)'],
            datasets: [
              {
                label: 'Initial',
                data: initialAndFinalConsumptionMatrix[0],
                backgroundColor: [COLORS.BRIGHT_BLUE],
                maxBarThickness: 50,
              },
              {
                label: 'Final',
                data: initialAndFinalConsumptionMatrix[1],
                backgroundColor: [COLORS.DARK_BLUE],
                maxBarThickness: 50,
              },
            ],
          },
          yUnit: 'Mwh/an',
          defaultUnit: 'Mwh/an',
          options: {
            plugins: {
              tooltip: {
                enabled: true,
                callbacks: {
                  title: (xDatapoint) => {
                    return 'title';
                  },
                  label: (yDatapoint) => {
                    return 'value';
                  },
                },
              },
            },
          },
        },
      ],
    ]);
  }, [roiMatrix, initialAndFinalConsumptionMatrix]);

  useEffect(() => {
    const sim = getSimulatorByCode('LED_LIGHTING');
    if (sim) {
      setElectricityConsumptionDedicatedForLighting(
        Number(sim?.datas?.electricityConsumptionDedicatedForLighting),
      );
      setEnergyGainRateLedConversion(Number(sim?.datas?.energyGainRateLedConversion));
    }
    // eslint-disable-next-line
  }, [simulators]);

  useEffect(() => {
    if (userToken) {
      const newDatas = {
        electricityConsumptionDedicatedForLighting,
        energyGainRateLedConversion,
      };

      setUpdateDatas(newDatas);
    }
  }, [
    userToken,
    energyGain,
    estimatedFinancialGain,
    electricityConsumptionDedicatedForLighting,
    energyGainRateLedConversion,
    co2Gain,
  ]);

  const updateUserSimulatorDatasFunc = () => {
    // Your logic to update the user simulator datas
    allowRequest &&
      updateDatas &&
      updateUserSimulatorDatas(userToken, 'LED_LIGHTING', updateDatas).then(() => {
        editSimulatorDatas('LED_LIGHTING', updateDatas);
      });
  };

  useDelayedUpdate(updateUserSimulatorDatasFunc, [updateDatas], 250);

  return (
    <LedLightingContext.Provider
      value={{
        electricityConsumptionDedicatedForLighting,
        energyGainRateLedConversion,
        forms,
        energyGain,
        co2Gain,
        estimatedFinancialGain,
        roi,
        resultTableRows,
        roiMatrix,
        initialAndFinalConsumptionMatrix,
        resultCharts,
        energyGainMwhPerYear: energyGain,
        kgCo2EmissionAvoidedPerYear: co2Gain,
        financialGainPerYear: estimatedFinancialGain,
        estimatedBudget: estimatedBudget,
        estimatedRoi: roi,
      }}
    >
      {children}
    </LedLightingContext.Provider>
  );
};

export { LedLightingContext, LedLightingProvider };
