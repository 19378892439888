import Form from '../../../components/form/Form';
import { IForm } from '../../../types/app';
import { SimulatorFormsWrapper } from './SimulatorForms.styled';

export interface SimulatorFormsProps {
  forms: Array<IForm.IForm>;
  onChange?: (e: any) => any;
  disabled?: boolean;
}

const SimulatorForms: React.FC<SimulatorFormsProps> = ({ forms, onChange, disabled }) => {
  return (
    <SimulatorFormsWrapper>
      {forms &&
        forms.map((form, key) => {
          return (
            <Form
              disabled={form.disabled || disabled}
              key={key}
              fields={form.fields}
              onChange={(updatedFields) => {
                form.onChange && form.onChange(updatedFields);
                onChange && onChange(updatedFields);
              }}
              header={form.header}
              className={form.className}
            />
          );
        })}
    </SimulatorFormsWrapper>
  );
};
export default SimulatorForms;
