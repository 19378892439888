import { useEffect } from 'react';
import { IComponentWithChildren } from '../../types/app';
import { useLocation } from 'react-router-dom';

const ScrollToTop: React.FC<IComponentWithChildren> = ({ children }) => {
  const location = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return <>{children}</>;
};

export default ScrollToTop;
