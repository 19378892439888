import { ExportUsersListToExcelWrapper } from './ExportUsersListToExcel.styled';
import { ICONS } from '../../../../../global';

export interface UsersListRowExportProps {
  id: string;
  email: string;
  wantToBeContacted: boolean;
  wantResultReport: boolean;
  creationDate: string;
  firstname: string;
  lastname: string;
  phoneNumber: string;
  company: string;
  jobPosition: string;
  simulatorLink: string;
  department: string;
}

export interface ExportUsersListToExcelProps {
  onExport: () => Promise<void>;
}

const ExportUsersListToExcel: React.FC<ExportUsersListToExcelProps> = ({ onExport }) => {
  return (
    <ExportUsersListToExcelWrapper
      onClick={() => {
        onExport && onExport();
      }}
    >
      <span className="icon">{ICONS.DOWNLOAD_ARROW.icon}</span>
      <span className="label">Tout exporter en fichier excel</span>
    </ExportUsersListToExcelWrapper>
  );
};

export default ExportUsersListToExcel;
