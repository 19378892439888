import { useParams } from 'react-router-dom';
import AdminPageLayout from '../../layout/AdminPageLayout';
import { EditReferenceDatasWrapper } from './EditReferenceDatas.styled';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { IReferenceData } from '../../../../types/app';
import Button from '../../../../components/button/Button';
import { ICONS, ROUTES } from '../../../../global';
import { AdminReferenceDatasApi } from '../../../../api/Admin.ReferenceDatas.api';
import { AdminContext, AdminContextProps } from '../../../../context/admin/AdminContext';
import MultipleColumnsTable from '../../../../components/table/multiple-columns-table/MultipleColumnsTable';
import TextField from '../../../../components/form/text-field/TextField';
import { COLORS } from '../../../../styles/constants/colors';
import Icon from '../../../../components/icon/Icon';
import toast from 'react-hot-toast';
import { ModalContext, ModalContextProps } from '../../../../context/ModalContext';
import DefaultModalLayout from '../../../../components/Modal/default-Layout/DefaultModalLayout';
import useCustomNavigate from '../../../../hooks/useCustomNavigate';

export interface EditReferenceDatasProps {}

const EditReferenceDatas: React.FC<EditReferenceDatasProps> = () => {
  const { version_id } = useParams();
  const navigate = useCustomNavigate();
  const { admin } = useContext(AdminContext) as AdminContextProps;
  const { updateModalConf } = useContext(ModalContext) as ModalContextProps;

  const [referenceDatas, setReferenceDatas] = useState<IReferenceData[]>();
  const [newReferenceDatasState, setNewReferenceDatasState] = useState<IReferenceData[]>();
  const [firstRender, setFirstRender] = useState<boolean>(false);

  // const newReferenceDatasRef = useRef<IReferenceData[]>();

  const initReferenceDatas = useCallback(
    async (version: number) => {
      if (!version || isNaN(version)) return;
      try {
        const res: IReferenceData[] | undefined =
          await AdminReferenceDatasApi.getReferenceDatasByVersionId(
            version,
            admin?.token as string,
          );
        if (!res) return;
        setReferenceDatas(res);
      } catch (err) {
        console.error(err);
      }
    },
    [admin],
  );

  useEffect(() => {
    if (!version_id) return;
    initReferenceDatas(Number(version_id));
  }, [initReferenceDatas, version_id]);

  useEffect(() => {
    if (referenceDatas && Array.isArray(referenceDatas) && !firstRender) {
      setNewReferenceDatasState([...(referenceDatas as IReferenceData[])]);
      setFirstRender(true);
    }
  }, [referenceDatas, firstRender]);

  const editReferenceDataValue = (index: number, field: string, newValue: string) => {
    if (!newReferenceDatasState) return;
    setNewReferenceDatasState([
      ...newReferenceDatasState.map((item, key) =>
        key === index
          ? {
              ...item,
              [field]: newValue,
            }
          : item,
      ),
    ]);
  };

  const checkReferenceDataHasBeenModified = (
    index: number,
    fieldsToCheck: string[],
  ): boolean | undefined => {
    if (!referenceDatas || !newReferenceDatasState) return;
    return (
      fieldsToCheck.find((field: any) => {
        return (
          `${(referenceDatas[index] as any)[field]}` !==
          `${(newReferenceDatasState[index] as any)[field]}`
        );
      }) !== undefined
    );
  };

  const resetReferenceData = (index: number): void => {
    if (!referenceDatas) return;
    setNewReferenceDatasState(
      newReferenceDatasState?.map((ref, key) => {
        return key === index
          ? (referenceDatas as any)?.find((item: IReferenceData, key2: number) => key2 === index)
          : ref;
      }),
    );
  };

  const ConfirmationPopup = ({ index }: { index: number }) => {
    const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);

    const handleChangesSave = async (index: number) => {
      try {
        setIsLoadingUpdate(true);
        if (!newReferenceDatasState || !admin) return;
        const newRef = newReferenceDatasState[index];
        const res = await AdminReferenceDatasApi.updateReferenceDataDetailsById(
          admin?.token,
          newRef.id,
          newRef.description,
          newRef.value,
          newRef.unit,
          newRef.source,
          newRef.comment,
        );
        if (res === true) {
          referenceDatas &&
            setReferenceDatas([
              ...referenceDatas?.map((ref, key) =>
                key === index ? newReferenceDatasState[index] : ref,
              ),
            ]);
          toast.success('Modification enregistrée avec succès');
        } else
          toast.error("Erreur lors de l'enregistrement des modifications", {
            id: 'edit',
          });
      } catch (err) {
        console.error(err);
        toast.error("Erreur lors de l'enregistrement des modifications", {
          id: 'edit',
        });
      } finally {
        updateModalConf();
        setIsLoadingUpdate(false);
      }
    };

    return (
      <DefaultModalLayout
        showCloseIcon
        title="Confirmer la modification"
        cta={{
          label: 'Confirmer',
          isLoading: isLoadingUpdate,
          onClick: async () => {
            await handleChangesSave(index);
          },
        }}
      >
        Souhaitez-vous réellement enregistrer vos modifications ? Cette action est irréversible.
      </DefaultModalLayout>
    );
  };

  const onSaveChanges = async (index: number) => {
    updateModalConf({
      component: <ConfirmationPopup index={index} />,
    });
  };

  return (
    <AdminPageLayout title={`Admin - Données de références - Version ${version_id}`}>
      <EditReferenceDatasWrapper>
        <Button
          theme="light"
          onClick={() => {
            navigate(`${ROUTES.ADMIN.INDEX}${ROUTES.ADMIN.REFERENCE_DATAS.LIST}`);
          }}
        >
          {'< Retour'}
        </Button>
        {newReferenceDatasState && newReferenceDatasState.length > 0 && (
          <div className="table">
            <MultipleColumnsTable
              columnsTitles={['Libellé', 'Valeur', 'Unité', 'Source', 'Commentaire', 'Actions']}
              rows={newReferenceDatasState?.map((referenceData, index) => {
                return {
                  values: [
                    <p className='label'>{newReferenceDatasState[index].description}</p>,
                    <TextField
                      value={`${newReferenceDatasState[index].value}`}
                      placeholder="Entrez une valeur ..."
                      onChange={(e) => {
                        editReferenceDataValue(index, 'value', e);
                      }}
                      type="number"
                      controlled
                    />,
                    <TextField
                      value={`${newReferenceDatasState[index].unit}`}
                      placeholder="Entrez une valeur ..."
                      onChange={(e) => {
                        editReferenceDataValue(index, 'unit', e);
                      }}
                      controlled
                    />,
                    <TextField
                      value={`${newReferenceDatasState[index].source}`}
                      placeholder="Entrez une valeur ..."
                      onChange={(e) => {
                        editReferenceDataValue(index, 'source', e);
                      }}
                      controlled
                    />,
                    <TextField
                      value={`${newReferenceDatasState[index].comment}`}
                      placeholder="Entrez une valeur ..."
                      onChange={(e) => {
                        editReferenceDataValue(index, 'comment', e);
                      }}
                      controlled
                    />,
                    <div className="actions">
                      {newReferenceDatasState && referenceDatas && (
                        <>
                          <Button
                            backgroundColor={COLORS.GREEN}
                            disabled={
                              !checkReferenceDataHasBeenModified(index, [
                                'description',
                                'value',
                                'unit',
                                'source',
                                'comment',
                              ])
                            }
                            onClick={async () => {
                              if (!referenceDatas) return;
                              if (!referenceDatas[index] || !newReferenceDatasState[index]) return;
                              await onSaveChanges(index);
                            }}
                          >
                            Enregistrer
                          </Button>
                          {checkReferenceDataHasBeenModified(index, [
                            'description',
                            'value',
                            'unit',
                            'source',
                            'comment',
                          ]) && (
                            <Icon
                              onClick={() => {
                                resetReferenceData(index);
                              }}
                              icon={ICONS.RESET}
                            />
                          )}
                        </>
                      )}
                    </div>,
                  ],
                };
              })}
            />
          </div>
        )}
      </EditReferenceDatasWrapper>
    </AdminPageLayout>
  );
};

export default EditReferenceDatas;
