import { TagWrapper } from './Tag.styled';

export interface TagProps {
  label: any;
  color?: string;
  backgroundColor?: string;
  className?: string;
}

const Tag: React.FC<TagProps> = ({ label, color, backgroundColor, className }) => {
  return (
    <TagWrapper className={className} color={color} backgroundcolor={backgroundColor}>
      {label}
    </TagWrapper>
  );
};

export default Tag;
