import { useContext, useEffect, useState } from 'react';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import { IComponentWithChildren, INavigation } from '../../types/app';
import { PageLayoutWrapper } from './PageLayout.styled';
import { useLocation } from 'react-router-dom';
import { getNavigationFromRoute, testShowDownloadPdf } from '../../utils';
import ScrollToTop from '../scroll-to-top/ScrollToTop';
import { NavigationContext, NavigationContextProps } from '../../context/NavigationContext';
import {
  SimulatorsContext,
  SimulatorsContextProps,
} from '../../context/simulators/SimulatorsContext';
import { UserContext, UserContextProps } from '../../context/user/UserContext';
import { ROUTES } from '../../global';
import {
  SimulatorsSummaryContext,
  SimulatorsSummaryContextProps,
} from '../../context/simulators/summary/SimulatorsSummaryContext';

const PageLayout: React.FC<IComponentWithChildren> = ({ children }) => {
  const location = useLocation();
  const [currentNavigation, setCurrentNavigation] = useState<INavigation>();
  const { setOpened } = useContext(NavigationContext) as NavigationContextProps;
  const { simulators } = useContext(SimulatorsContext) as SimulatorsContextProps;
  const { isAdminView, user } = useContext(UserContext) as UserContextProps;
  const { contexts } = useContext(SimulatorsSummaryContext) as SimulatorsSummaryContextProps;

  const [showDownloadPdf, setShowDownloadPdf] = useState<boolean>(false);

  useEffect(() => {
    contexts && setShowDownloadPdf(testShowDownloadPdf(simulators, contexts));
  }, [simulators, contexts]);

  useEffect(() => {
    setCurrentNavigation(getNavigationFromRoute(location.pathname));
    setOpened(false);
    // eslint-disable-next-line
  }, [location.pathname]);

  return (
    <PageLayoutWrapper>
      <ScrollToTop>
        <Navbar
          title={currentNavigation?.iconInformations && currentNavigation.iconInformations.name}
          icon={currentNavigation?.iconInformations && currentNavigation.iconInformations}
          isViewerOnly={user?.details?.isViewerOnly}
          showPdfDownload={
            showDownloadPdf &&
            (window.location.pathname === ROUTES.DASHBOARD || window.location.href === ROUTES.HOME)
          }
        />
        <Sidebar
          className="sidebar"
          isAdminView={isAdminView}
          isViewerOnly={user?.details?.isViewerOnly}
        />
        <div className="page-layout-children">{children}</div>
      </ScrollToTop>
    </PageLayoutWrapper>
  );
};

export default PageLayout;
