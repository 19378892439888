import { createContext, useState } from 'react';
import { IComponentWithChildren } from '../types/app';
export interface LoadingContextProps {
  isLoading: boolean;
  setIsLoading: (e: boolean) => void;
}

const LoadingContext = createContext<LoadingContextProps | undefined>(undefined);

const LoadingProvider: React.FC<IComponentWithChildren> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <LoadingContext.Provider
      value={{
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

export { LoadingContext, LoadingProvider };
