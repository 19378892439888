import { useContext } from 'react';
import { ICONS } from '../../../global';
import { IComponentWithChildren } from '../../../types/app';
import Button from '../../button/Button';
import { ModalTitle } from '../Modal.styled';
import { DefaultModalLayoutWrapper } from './DefaultModalLayout.styled';
import { ModalContext, ModalContextProps } from '../../../context/ModalContext';
import Progress from '../../progress/Progress';

export interface DefaultModalLayoutProps extends IComponentWithChildren {
  title?: string;
  showCloseIcon?: boolean;
  cta?: {
    icon?: any;
    label?: string;
    onClick?: () => void;
    disable?: boolean;
    isLoading?: boolean;
  };
}

const DefaultModalLayout: React.FC<DefaultModalLayoutProps> = ({
  title,
  showCloseIcon,
  cta,
  children,
}) => {
  const { updateModalConf } = useContext(ModalContext) as ModalContextProps;
  return (
    <DefaultModalLayoutWrapper>
      <ModalTitle>
        {title}{' '}
        {showCloseIcon && (
          <span
            className="icon"
            onClick={() => {
              updateModalConf();
            }}
          >
            {ICONS.MENU_CLOSE.icon}
          </span>
        )}
      </ModalTitle>
      <div className="content">
        {children}
        {cta && (
          <>
            {!cta?.isLoading ? (
              <>
                {!cta.icon ? (
                  <Button
                    onClick={() => {
                      cta.onClick && !cta.disable && cta.onClick();
                    }}
                    disabled={cta.disable}
                    theme="dark"
                    label={cta?.label}
                    hasIcon
                  ></Button>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                <Progress />
              </>
            )}
          </>
        )}
      </div>
    </DefaultModalLayoutWrapper>
  );
};

export default DefaultModalLayout;
