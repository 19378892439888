import { DashboardReferenceInformationsWrapper } from './DashboardReferenceInformations.styled';
import SingleReferenceInformations, {
  SingleReferenceInformationsProps,
} from './single-reference-informations/SingleReferenceInformations';

export interface DashboardReferenceInformationsProps {
  electricityInformations: SingleReferenceInformationsProps;
  gasInformations: SingleReferenceInformationsProps;
}

const DashboardReferenceInformations: React.FC<DashboardReferenceInformationsProps> = ({
  electricityInformations,
  gasInformations,
}) => {
  return (
    <DashboardReferenceInformationsWrapper>
      <div className="title">Informations de référence</div>
      <div className="informations">
        <SingleReferenceInformations
          price={electricityInformations.price}
          trends={electricityInformations.trends}
        />
        <SingleReferenceInformations
          price={gasInformations.price}
          trends={gasInformations.trends}
        />
      </div>
    </DashboardReferenceInformationsWrapper>
  );
};

export default DashboardReferenceInformations;
