import toast from 'react-hot-toast';
import { ICONS } from '../../global';
import { CopyWrapper } from './Copy.styled';

export interface CopyProps {
  text?: string;
  disabled?: boolean;
}

const Copy: React.FC<CopyProps> = ({ text, disabled }) => {
  return (
    <CopyWrapper
      className={`icon-copy ${disabled && 'disabled'}`}
      onClick={() => {
        if (!text || disabled) return;
        navigator.clipboard.writeText(text);
        toast.success('Copié dans le presse papier');
      }}
    >
      {ICONS.COPY.icon}
    </CopyWrapper>
  );
};

export default Copy;
