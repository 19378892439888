import { createContext, useEffect, useState } from 'react';
import { IComponentWithChildren } from '../types/app';

export interface NavigationContextProps {
  opened: boolean;
  setOpened: (e: boolean) => void;
  toggleOpen: any;
}

const NavigationContext = createContext<NavigationContextProps | undefined>(undefined);
const NavigationProvider: React.FC<IComponentWithChildren> = ({ children }) => {
  const [opened, setOpened] = useState(false);

  const toggleOpen = () => {
    setOpened(!opened);
  };

  useEffect(() => {
    setOpened(false);
  }, []);

  return (
    <NavigationContext.Provider
      value={{
        opened,
        setOpened,
        toggleOpen,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export { NavigationContext, NavigationProvider };
