import styled from 'styled-components';
import { COLORS } from '../../styles/constants/colors';
import { BODY } from '../../styles/helpers/typography';
import { BREAKPOINTS } from '../../styles/constants/devices';
import { IconWrapper } from '../icon/Icon.styled';

const SidebarWrapper = styled.div`
  position: fixed;
  display: flex;
  top: 60px;
  bottom: 0px;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  border: 1px solid #0028541a;
  z-index: 100;
  background-color: ${COLORS.WHITE};
  padding-top: 20px;

  @media (${BREAKPOINTS.S}) {
    &.closed {
      display: none;
    }
  }

  @media (${BREAKPOINTS.L}) {
    display: flex !important;
    width: max-content;
    max-width: 60px;
  }

  .center-sidebar {
    display: flex;
    flex-direction: column;
    gap: 15px;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .bottom-sidebar {
    padding-bottom: 20px;
    margin: 0 7px !important;

    .wrapper {
      display: flex;
      align-items: center;
      padding: 5px;
      gap: 15px;
      border-radius: 10px;
      background: ${COLORS.LIGHT_BLUE}80;
      width: 100%;

      ${IconWrapper} {
        background-color: ${COLORS.WHITE};
        border-radius: 10px;
        width: 18px;
      }

      .menu-name {
        padding-right: 5px;
      }
    }

    @media (${BREAKPOINTS.S}) {
      width: 95% !important;
    }

    @media (${BREAKPOINTS.L}) {
      width: max-content !important;
    }
  }

  .menu-name {
    user-select: none;
    transition: 0.2s linear;
    ${BODY.S_REGULAR}

    @media (${BREAKPOINTS.L}) {
      display: none;
    }

    &:hover {
      color: ${COLORS.BRIGHT_BLUE};
      cursor: pointer;
    }
  }

  .separator {
    height: 1px;
    border-top: 1px solid #0028541a;
    margin: 5px 15px;
    display: flex;
    padding-left: 10px;

    @media (${BREAKPOINTS.S}) {
      display: none;
    }

    @media (${BREAKPOINTS.L}) {
      display: flex;
    }
  }

  @media (${BREAKPOINTS.L}) {
    &:hover {
      @media (hover: hover) and (pointer: fine) {
        max-width: max-content;

        .menu-name {
          display: block;
        }

        .separator {
          width: 80%;
        }
      }
    }
  }

  .nav-menu {
    width: max-content;
    display: flex;
    gap: 10px;
    align-items: center;
    margin: 0 10px;
    user-select: none;
  }
`;

export { SidebarWrapper };
