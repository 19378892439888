import { createContext, useContext, useEffect, useState } from 'react';
import { IComponentWithChildren, IForm, ISimulatorRequiredDatas } from '../../types/app';
import { FormTitle } from '../../components/form/Form.styled';
import { ITableRow } from '../../components/table/Table';
import { customRound, customRoundDown, customRoundUp } from '../../utils';
import { ReferenceDatasContext, ReferenceDatasContextProps } from '../ReferenceDatasContext';
import { ChartProps } from '../../components/chart/Chart';
import { COLORS } from '../../styles/constants/colors';
import { SimulatorsContext, SimulatorsContextProps } from './SimulatorsContext';
import { UserContext, UserContextProps } from '../user/UserContext';

import useDelayedUpdate from '../../hooks/useDelayedUpdate';

export interface CompressedAirContextProps extends ISimulatorRequiredDatas {
  leakRateBeforeIntervention: number;
  leakRateAfterIntervention: number;
  electricityDedicatedToCompressedAir: number;
  //
  reducedPowerConsuption: number;
  estimatedEmissionReductions: number;
  estimatedFinancialGain: number;
  averageRoi: number;
  estimatedAmountForWorkAndAudit: number;
  totalCompressedAirElectricityConsumption: number;
  totalCompressedAirCo2: number;
  auditReductionElectrictyConsumption: number;
  auditReductionCo2: number;
  finalElectricityConsumption: number;
  finalCo2Consumption: number;
  //
  auditAndReparationsCost: number;
  roiAuditMatrix: Array<Array<number>>;

  //
  forms: IForm.IForm[];
  resultTableRows: Array<ITableRow>;
  resultCharts: Array<Array<ChartProps>>;
  setLeakRateBeforeIntervention: (e: any) => any;
  setLeakRateAfterIntervention: (e: any) => any;
  setElectricityDedicatedToCompressedAir: (e: any) => any;
  //
  setReducedPowerConsumption: (e: any) => any;
  setEstimatedEmissionReductions: (e: any) => any;
  setEstimatedFinancialGain: (e: any) => any;
  setAverageRoi: (e: any) => any;
  setEstimatedAmountForWorkAndAudit: (e: any) => any;
  setTotalCompressedAirElectricityConsumption: (e: any) => any;
  setTotalCompressedAirCo2: (e: any) => any;
  setAuditReductionElectrictyConsumption: (e: any) => any;
  setAuditReductionCo2: (e: any) => any;
  setFinalElectricityConsumption: (e: any) => any;
  setFinalCo2Consumption: (e: any) => any;
  //
  setAuditAndReparationsCost: (e: any) => any;
  setRoiAuditMatrix: (e: any) => any;
}

const CompressedAirContext = createContext<CompressedAirContextProps | undefined>(undefined);
const CompressedAirProvider: React.FC<IComponentWithChildren> = ({ children }) => {
  const {
    CARBON_CONTENT_PER_KWH_ELECTRICITY,
    AVERAGE_ELECTRICITY_PRICE_PER_MWH,
    NATURAL_ELECTRICITY_TRENDS,
  } = useContext(ReferenceDatasContext) as ReferenceDatasContextProps;
  const { getSimulatorByCode, simulators, editSimulatorDatas } = useContext(
    SimulatorsContext,
  ) as SimulatorsContextProps;
  const { userToken, updateUserSimulatorDatas } = useContext(UserContext) as UserContextProps;

  const [leakRateBeforeIntervention, setLeakRateBeforeIntervention] = useState(0);
  const [leakRateAfterIntervention, setLeakRateAfterIntervention] = useState(0);
  const [electricityDedicatedToCompressedAir, setElectricityDedicatedToCompressedAir] = useState(0);
  const [reducedPowerConsuption, setReducedPowerConsumption] = useState(0);
  const [estimatedEmissionReductions, setEstimatedEmissionReductions] = useState(0);
  const [estimatedFinancialGain, setEstimatedFinancialGain] = useState(0);
  const [averageRoi, setAverageRoi] = useState(0);
  const [estimatedAmountForWorkAndAudit, setEstimatedAmountForWorkAndAudit] = useState(0);

  const [totalCompressedAirElectricityConsumption, setTotalCompressedAirElectricityConsumption] =
    useState(0);
  const [totalCompressedAirCo2, setTotalCompressedAirCo2] = useState(0);
  const [auditReductionElectrictyConsumption, setAuditReductionElectrictyConsumption] = useState(0);
  const [auditReductionCo2, setAuditReductionCo2] = useState(0);
  const [finalElectricityConsumption, setFinalElectricityConsumption] = useState(0);
  const [finalCo2Consumption, setFinalCo2Consumption] = useState(0);

  const [forms, setForms] = useState<IForm.IForm[]>([]);
  const [resultTableRows, setResultTableRows] = useState<Array<ITableRow>>([]);
  const [resultCharts, setResultCharts] = useState<Array<Array<ChartProps>>>([]);

  const [auditAndReparationsCost, setAuditAndReparationsCost] = useState(0);
  const [roiAuditMatrix, setRoiAuditMatrix] = useState<Array<Array<number>>>([]);
  const [updateDatas, setUpdateDatas] = useState<object>({});
  const [allowRequest, setAllowRequest] = useState<boolean>(false);
  // Form
  useEffect(() => {
    setForms([
      {
        onChange: () => setAllowRequest(true),
        header: <FormTitle>Remplissez les champs suivants :</FormTitle>,
        fields: [
          {
            label:
              'Taux de fuite avant intervention (Généralement 35% pour une installation ancienne)',
            name: 'Leak rate before intervention',
            type: 'textfield',
            textType: 'number',
            value: leakRateBeforeIntervention,
            unit: '%',
            onChange: (e) => setLeakRateBeforeIntervention(e),
          },
          {
            label: 'Taux de fuite après intervention (Généralement 10% après travaux correctifs)',
            name: 'Leak rate after intervention',
            type: 'textfield',
            textType: 'number',
            value: leakRateAfterIntervention,
            unit: '%',
            onChange: (e) => setLeakRateAfterIntervention(e),
          },
          {
            label: "Électricité dédiée à l'air comprimé",
            name: 'Electricity DedicatedTo Compressed Air',
            type: 'textfield',
            textType: 'number',
            value: electricityDedicatedToCompressedAir,
            unit: 'MWh/an',
            onChange: (e) => setElectricityDedicatedToCompressedAir(e),
          },
        ],
      },
    ]);
  }, [leakRateBeforeIntervention, leakRateAfterIntervention, electricityDedicatedToCompressedAir]);

  // Reducted Power Consumption
  useEffect(() => {
    setReducedPowerConsumption(
      electricityDedicatedToCompressedAir *
        (1 - (1 - leakRateBeforeIntervention / 100) / (1 - leakRateAfterIntervention / 100)),
    );
  }, [leakRateBeforeIntervention, leakRateAfterIntervention, electricityDedicatedToCompressedAir]);

  // Estimated Emission Reductions
  useEffect(() => {
    setEstimatedEmissionReductions(
      customRound(reducedPowerConsuption * 1000 * CARBON_CONTENT_PER_KWH_ELECTRICITY?.value, -2),
    );
  }, [reducedPowerConsuption, CARBON_CONTENT_PER_KWH_ELECTRICITY]);

  // Estimated financial gains
  useEffect(() => {
    setEstimatedFinancialGain(
      customRoundDown(reducedPowerConsuption * AVERAGE_ELECTRICITY_PRICE_PER_MWH?.value, -2),
    );
  }, [reducedPowerConsuption, AVERAGE_ELECTRICITY_PRICE_PER_MWH]);

  useEffect(() => {
    setAverageRoi(estimatedAmountForWorkAndAudit / estimatedFinancialGain);
  }, [estimatedAmountForWorkAndAudit, estimatedFinancialGain]);

  // Estimated Amount for work and audit
  useEffect(() => {
    setEstimatedAmountForWorkAndAudit(
      customRoundUp(2500 + electricityDedicatedToCompressedAir * 60, -3),
    );
  }, [electricityDedicatedToCompressedAir]);

  // Total compressed air for electricity consumption
  useEffect(() => {
    setTotalCompressedAirElectricityConsumption(electricityDedicatedToCompressedAir);
  }, [electricityDedicatedToCompressedAir]);

  // Total compressed air for co2
  useEffect(() => {
    setTotalCompressedAirCo2(
      totalCompressedAirElectricityConsumption * CARBON_CONTENT_PER_KWH_ELECTRICITY?.value,
    );
  }, [totalCompressedAirElectricityConsumption, CARBON_CONTENT_PER_KWH_ELECTRICITY?.value]);

  // Audit reduction of electricity consumption
  useEffect(() => {
    setAuditReductionElectrictyConsumption(reducedPowerConsuption);
  }, [reducedPowerConsuption]);

  // Audit reduction of co2
  useEffect(() => {
    setAuditReductionCo2(estimatedEmissionReductions / 1000);
  }, [estimatedEmissionReductions]);

  // final electricity consumption
  useEffect(() => {
    setFinalElectricityConsumption(
      totalCompressedAirElectricityConsumption - auditReductionElectrictyConsumption,
    );
  }, [totalCompressedAirElectricityConsumption, auditReductionElectrictyConsumption]);

  // final co2 consumption
  useEffect(() => {
    setFinalCo2Consumption(totalCompressedAirCo2 - auditReductionCo2);
  }, [totalCompressedAirCo2, auditReductionCo2]);

  // Audit and reparations cost
  useEffect(() => {
    setAuditAndReparationsCost(-estimatedAmountForWorkAndAudit);
  }, [estimatedAmountForWorkAndAudit]);

  // roi audit matrix
  useEffect(() => {
    const realisedEconomy = 0;
    const yearsCount = 6;
    const tmpRoiAuditMatrix: Array<Array<number>> = [[]];
    tmpRoiAuditMatrix[0] = [
      realisedEconomy,
      reducedPowerConsuption *
        AVERAGE_ELECTRICITY_PRICE_PER_MWH?.value *
        (1 + NATURAL_ELECTRICITY_TRENDS?.value / 100),
    ];
    tmpRoiAuditMatrix[1] = [auditAndReparationsCost + tmpRoiAuditMatrix[0][0]];
    for (let i = 1; i < yearsCount; i++) {
      if (i > 1) {
        tmpRoiAuditMatrix[0] = [
          ...tmpRoiAuditMatrix[0],
          tmpRoiAuditMatrix[0][i - 1] * (1 + NATURAL_ELECTRICITY_TRENDS?.value / 100),
        ];
      }
      tmpRoiAuditMatrix[1] = [
        ...tmpRoiAuditMatrix[1],
        tmpRoiAuditMatrix[1][i - 1] + tmpRoiAuditMatrix[0][i],
      ];
    }
    setRoiAuditMatrix(tmpRoiAuditMatrix);
  }, [
    reducedPowerConsuption,
    AVERAGE_ELECTRICITY_PRICE_PER_MWH?.value,
    NATURAL_ELECTRICITY_TRENDS?.value,
    auditAndReparationsCost,
  ]);

  // Result table rows
  useEffect(() => {
    setResultTableRows([
      {
        label: 'Réduction de consommation électrique',
        value: `${reducedPowerConsuption}`,
        unit: 'MWh/an',
      },
      {
        label: "Baisse d'émissions estimée",
        value: `${estimatedEmissionReductions}`,
        unit: 'kg CO2/an',
      },
      {
        label: 'Gain financier estimé',
        value: `${estimatedFinancialGain}`,
        unit: '€/an',
      },
      {
        label: 'Montant estimé : Travaux et audit',
        value: `${estimatedAmountForWorkAndAudit}`,
        unit: '€',
      },
      {
        label: 'ROI moyen pour cette action (le ROI n’inclut pas les primes et aides (CEE,…))',
        value: `${averageRoi}`,
        unit: 'ans',
      },
    ]);
  }, [
    reducedPowerConsuption,
    estimatedEmissionReductions,
    estimatedFinancialGain,
    averageRoi,
    estimatedAmountForWorkAndAudit,
    setResultTableRows,
  ]);

  // Result charts
  useEffect(() => {
    setResultCharts([
      [
        {
          chartType: 'bar',
          chartTitle: "Évolution du taux de fuite suite à l'intervention",
          datas: {
            labels: [
              ['Taux de fuite avant', 'intervention'],
              ['Taux de fuite après', 'intervention'],
            ],
            datasets: [
              {
                data: [leakRateBeforeIntervention, leakRateAfterIntervention],
                backgroundColor: [COLORS.BRIGHT_BLUE, COLORS.LIGHT_BLUE],
              },
            ],
          },
          options: {
            plugins: {
              tooltip: {
                callbacks: {
                  title: (e) => '',
                  label: (e) => `${e.raw} %`,
                },
              },
            },
          },
          defaultUnit: '%',
          yUnit: '%',
        },
        {
          chartType: 'bar',
          chartTitle: "Économies réalisables suite à l'intervention",
          labels: ['Consommation finale', 'Réduction audit'],
          labelsBackgroundColors: [COLORS.BRIGHT_BLUE, COLORS.DARK_BLUE],
          labelsColors: [COLORS.WHITE, COLORS.WHITE],
          datas: {
            labels: [['Conso elec (MWh/an)'], 'CO2 (t/an)'],
            datasets: [
              {
                data: [finalElectricityConsumption, finalCo2Consumption],
                maxBarThickness: 50,

                backgroundColor: [COLORS.BRIGHT_BLUE],
              },
              {
                data: [auditReductionElectrictyConsumption, auditReductionCo2],
                maxBarThickness: 50,

                backgroundColor: [COLORS.DARK_BLUE],
              },
            ],
          },
          options: {
            responsive: true,
            scales: {
              x: {
                stacked: true,
              },
              y: {
                stacked: true,
              },
            },
          },
        },
      ],
      [
        {
          chartType: 'bar',
          chartTitle: 'Retour sur investissement estimé',
          datas: {
            labels: roiAuditMatrix[1] && roiAuditMatrix[1].map((value, index) => `Année ${index}`),
            datasets: [
              {
                data: roiAuditMatrix[1] || [],
                maxBarThickness: 45,

                backgroundColor: roiAuditMatrix[1]?.map((value) =>
                  value > 0 ? COLORS.BRIGHT_BLUE : COLORS.LIGHT_BLUE,
                ),
              },
            ],
          },
          defaultUnit: '€',
          yUnit: '€',
          options: {},
        },
      ],
    ]);
  }, [
    leakRateAfterIntervention,
    leakRateBeforeIntervention,
    finalElectricityConsumption,
    finalCo2Consumption,
    auditReductionElectrictyConsumption,
    auditReductionCo2,
    roiAuditMatrix,
    setResultCharts,
  ]);

  useEffect(() => {
    const sim = getSimulatorByCode('COMPRESSED_AIR');
    if (sim) {
      setLeakRateBeforeIntervention(Number(sim?.datas?.leakRateBeforeIntervention));
      setLeakRateAfterIntervention(Number(sim?.datas?.leakRateAfterIntervention));
      setElectricityDedicatedToCompressedAir(
        Number(sim?.datas?.electricityDedicatedToCompressedAir),
      );
    }
  }, [simulators, getSimulatorByCode]);

  useEffect(() => {
    if (userToken) {
      const newDatas = {
        leakRateBeforeIntervention,
        leakRateAfterIntervention,
        electricityDedicatedToCompressedAir,
      };

      setUpdateDatas(newDatas);
    }
  }, [
    userToken,
    leakRateBeforeIntervention,
    leakRateAfterIntervention,
    electricityDedicatedToCompressedAir,
  ]);

  const updateUserSimulatorDatasFunc = () => {
    // Your logic to update the user simulator datas
    allowRequest &&
      updateDatas &&
      updateUserSimulatorDatas(userToken, 'COMPRESSED_AIR', updateDatas).then(() => {
        editSimulatorDatas('COMPRESSED_AIR', updateDatas);
      });
  };

  useDelayedUpdate(updateUserSimulatorDatasFunc, [updateDatas], 250);

  return (
    <CompressedAirContext.Provider
      value={{
        leakRateBeforeIntervention,
        leakRateAfterIntervention,
        electricityDedicatedToCompressedAir,
        forms,
        resultTableRows,
        resultCharts,
        reducedPowerConsuption,
        estimatedEmissionReductions,
        estimatedFinancialGain,
        averageRoi,
        estimatedAmountForWorkAndAudit,
        totalCompressedAirElectricityConsumption,
        totalCompressedAirCo2,
        auditReductionElectrictyConsumption,
        auditReductionCo2,
        finalElectricityConsumption,
        finalCo2Consumption,
        auditAndReparationsCost,
        roiAuditMatrix,
        energyGainMwhPerYear: reducedPowerConsuption,
        kgCo2EmissionAvoidedPerYear: estimatedEmissionReductions,
        financialGainPerYear: estimatedFinancialGain,
        estimatedRoi: averageRoi,
        estimatedBudget: estimatedAmountForWorkAndAudit,
        setReducedPowerConsumption,
        setEstimatedEmissionReductions,
        setEstimatedFinancialGain,
        setAverageRoi,
        setEstimatedAmountForWorkAndAudit,
        setTotalCompressedAirElectricityConsumption,
        setTotalCompressedAirCo2,
        setAuditReductionElectrictyConsumption,
        setAuditReductionCo2,
        setFinalElectricityConsumption,
        setFinalCo2Consumption,
        setLeakRateBeforeIntervention,
        setLeakRateAfterIntervention,
        setElectricityDedicatedToCompressedAir,
        setAuditAndReparationsCost,
        setRoiAuditMatrix,
      }}
    >
      {children}
    </CompressedAirContext.Provider>
  );
};

export { CompressedAirContext, CompressedAirProvider };
