import { createContext, useState } from 'react';
import { IAdmin, IComponentWithChildren } from '../../types/app';

export interface AdminContextProps {
  admin: IAdmin | undefined;
  setAdmin: (admin: IAdmin | undefined) => void;
}

const AdminContext = createContext<AdminContextProps | undefined>(undefined);

const AdminProvider: React.FC<IComponentWithChildren> = ({ children }) => {
  const [admin, setAdmin] = useState<IAdmin | undefined>(undefined);

  return (
    <AdminContext.Provider
      value={{
        admin,
        setAdmin,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export { AdminContext, AdminProvider };
