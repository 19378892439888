import { useCallback, useContext } from 'react';
import Button from '../../../components/button/Button';
import Card from '../../../components/card/Card';
import { ICONS } from '../../../global';
import { ContactUsCardWrapper } from './ContactUsCard.styled';
import { ModalContext, ModalContextProps } from '../../../context/ModalContext';
import PopupContactUs, { IContactForm } from './popup/contact-form/PopupContactUs';
import { UserContext, UserContextProps } from '../../../context/user/UserContext';

export interface ContactUsCardProps {}
const ContactUsCard: React.FC<ContactUsCardProps> = () => {
  const { updateModalConf } = useContext(ModalContext) as ModalContextProps;
  const { user } = useContext(UserContext) as UserContextProps;

  const onChangeForm = useCallback((e: IContactForm) => {}, []);

  return (
    <ContactUsCardWrapper>
      <Card title={<>{ICONS.QUESTION_MARK.icon} Pour aller plus loin</>}>
        <p className="contact-us-card-description">
          Vous avez des questions ? Vous souhaitez échanger avec un de nos conseiller sur votre
          rapport ?
        </p>
        <Button
          theme="dark"
          onClick={() => {
            updateModalConf({
              component: (
                <PopupContactUs
                  form={{
                    lastname: (user?.details?.lastname !== 'null' && user?.details?.lastname) || '',
                    firstname:
                      (user?.details?.firstname !== 'null' && user?.details?.firstname) || '',
                    company:
                      (user?.details?.companyName !== 'null' && user?.details?.companyName) || '',
                    email: (user?.details?.email !== 'null' && user?.details?.email) || '',
                    jobPosition:
                      (user?.details?.jobPosition !== 'null' && user?.details?.jobPosition) || '',
                    number:
                      (user?.details?.phoneNumber !== 'null' && user?.details?.phoneNumber) || '',
                    department:
                      (user?.details?.department !== 'null' && user?.details?.department) || '',
                  }}
                  onChange={(e) => onChangeForm(e)}
                />
              ),
            });
          }}
        >
          Contactez-nous
        </Button>
      </Card>
    </ContactUsCardWrapper>
  );
};

export default ContactUsCard;
