import styled from 'styled-components';
import { COLORS } from '../../styles/constants/colors';
import { BODY } from '../../styles/helpers/typography';

const InfoWrapper = styled.div`
  position: relative;
  display: block;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  padding: 1px 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  ${BODY.XS_REGULAR}

  &:hover {
    cursor: pointer;
  }

  &.default-color {
    background-color: transparent;
    border: 2px solid ${COLORS.DARK_BLUE};
    color: ${COLORS.DARK_BLUE};
    font-weight: 500;
  }

  &.blue-color {
    background-color: ${COLORS.BRIGHT_BLUE};
    border: 2px solid ${COLORS.BRIGHT_BLUE};
    color: ${COLORS.WHITE};
    font-weight: 500;
  }

  &.children {
    width: auto;
    height: auto;
    &:hover {
      cursor: pointer;
    }
  }
`;

export { InfoWrapper };
