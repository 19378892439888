import { useContext, useEffect, useRef, useState } from 'react';
import PageLayout from '../../layout/page-layout/PageLayout';
import { DashboardWrapper } from './Dashboard.styled';
import MyIndustrialSiteCard from './my-industrial-site-card/MyIndustrialSiteCard';
import DashboardReferenceInformations from './reference-informations/DashboardReferenceInformations';
import {
  ReferenceDatasContext,
  ReferenceDatasContextProps,
} from '../../context/ReferenceDatasContext';
import SimulatorsList from './simulators-list/SimulatorsList';
import { APP_TITLE, RESPONSABILITY_NOTE, SIMULATORS_NAMES } from '../../global';
import {
  SimulatorsContext,
  SimulatorsContextProps,
} from '../../context/simulators/SimulatorsContext';
import AxesToDeployCard from './axes-to-deploy-card/AxesToDeployCard';
import ContactUsCard from './contact-us-card/ContactUsCard';
import { UserContext, UserContextProps } from '../../context/user/UserContext';
import { Loader } from '../../App.styled';
import { LoadingContext, LoadingContextProps } from '../../context/LoadingContext';
import useDelayedUpdate from '../../hooks/useDelayedUpdate';
import Progress from '../../components/progress/Progress';
import { IsViewerOnlyProps } from '../../types/app';
import Notification from '../../components/notification/Notification';
import { onQuitViewMode } from '../../utils';
import {
  SimulatorsSummaryContext,
  SimulatorsSummaryContextProps,
} from '../../context/simulators/summary/SimulatorsSummaryContext';

const Dashboard: React.FC<IsViewerOnlyProps> = ({ isViewerOnly }) => {
  const context = useContext(ReferenceDatasContext) as ReferenceDatasContextProps;
  const { simulators } = useContext(SimulatorsContext) as SimulatorsContextProps;
  const { contexts } = useContext(SimulatorsSummaryContext) as SimulatorsSummaryContextProps;
  const { loadingUserDatas, user } = useContext(UserContext) as UserContextProps;
  const { isLoading } = useContext(LoadingContext) as LoadingContextProps;
  const [showPage, setShowPage] = useState<boolean>(false);

  const [fixmeTop, setFixmeTop] = useState(0);
  const [currentScroll, setCurrentScroll] = useState(0);
  const extraInformationsRef = useRef<any>(null);

  useEffect(() => {
    const handleScroll = () => {
      setCurrentScroll(window.scrollY);
    };

    const handleResize = () => {
      setFixmeTop((document.getElementById('extra-informations') as any)?.offsetTop - 61);
    };

    setFixmeTop((document.getElementById('extra-informations') as any)?.offsetTop - 61);

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, [fixmeTop]);

  useEffect(() => {
    document.title = `Tableau de bord - ${APP_TITLE}`;
  }, []);

  useDelayedUpdate(
    () => {
      if (!loadingUserDatas && !isLoading) setShowPage(true);
    },
    [isLoading, loadingUserDatas],
    1000,
  );

  return (
    <PageLayout>
      {showPage && !loadingUserDatas && !isLoading ? (
        <DashboardWrapper data-aos="fade-down">
          {isViewerOnly && (
            <Notification
              type="info"
              message={
                <div className="viewer-only-notification">
                  Vous êtes actuellement en mode vue de la simulation {user?.details?.token}{' '}
                  {`( ID : ${user?.details?.id} ) `}. Cliquez{' '}
                  <span
                    className="quit-view-mode"
                    onClick={() => {
                      onQuitViewMode();
                    }}
                  >
                    ici
                  </span>{' '}
                  pour revenir sur votre session actuelle.
                </div>
              }
            />
          )}
          <div className="first-section">
            <MyIndustrialSiteCard isViewerOnly={isViewerOnly} />
            {context.AVERAGE_ELECTRICITY_PRICE_PER_MWH &&
              context.AVERAGE_GAS_PRICE_PER_MWH &&
              context.NATURAL_GAS_TRENDS &&
              context.NATURAL_ELECTRICITY_TRENDS && (
                <DashboardReferenceInformations
                  electricityInformations={{
                    price: {
                      title: "Prix de l'électricité",
                      value: context.AVERAGE_ELECTRICITY_PRICE_PER_MWH.value,
                      unit: context.AVERAGE_ELECTRICITY_PRICE_PER_MWH.unit,
                    },
                    trends: {
                      title: "Tendance d'évolution",
                      value: context.NATURAL_ELECTRICITY_TRENDS.value,
                      unit: context.NATURAL_ELECTRICITY_TRENDS.unit,
                    },
                  }}
                  gasInformations={{
                    price: {
                      title: 'Prix du gaz',
                      value: context.AVERAGE_GAS_PRICE_PER_MWH.value,
                      unit: context.AVERAGE_GAS_PRICE_PER_MWH.unit,
                    },
                    trends: {
                      title: "Tendance d'évolution",
                      value: context.NATURAL_GAS_TRENDS.value,
                      unit: context.NATURAL_GAS_TRENDS.unit,
                    },
                  }}
                />
              )}
          </div>
          <div className="second-section">
            <div className="second-section-title">
              Mes axes d'efficacité énergétique et de décarbonation, éligibles CEE
            </div>
            <div className="second-section-content">
              {simulators && contexts && (
                <SimulatorsList
                  simulators={Object.values(simulators).filter(
                    (simulator) => simulator.name !== SIMULATORS_NAMES.INDUSTRIAL_SITE.name,
                  )}
                  contexts={contexts}
                />
              )}
              <div
                className="extra-informations"
                id="extra-informations"
                ref={extraInformationsRef}
              >
                <div
                  id="extra-informations-content-wrapper"
                  className={`${
                    currentScroll + 60 >= fixmeTop && 'test-fix-extra-informations-top'
                  }`}
                >
                  {contexts && <AxesToDeployCard contexts={contexts} simulators={simulators} />}
                  {!isViewerOnly && <ContactUsCard />}
                  <p className="extra-explications">{RESPONSABILITY_NOTE}</p>
                </div>
              </div>
            </div>
          </div>
        </DashboardWrapper>
      ) : (
        <>
          <Loader>
            <Progress />
          </Loader>
        </>
      )}
    </PageLayout>
  );
};

export default Dashboard;
