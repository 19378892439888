import styled from 'styled-components';
import { COLORS } from '../../../../styles/constants/colors';
import { TextFieldWrapper } from '../../../../components/form/text-field/TextField.styled';
import { BODY } from '../../../../styles/helpers/typography';
import { BREAKPOINTS } from '../../../../styles/constants/devices';
import { ModalTitle } from '../../../../components/Modal/Modal.styled';

const UserInfosWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 100%;
  max-height: 80vh;

  ${ModalTitle} {
    justify-content: center;
    background-color: ${COLORS.LIGHT_BLUE}90;
    padding: 20px 10px;
  }

  .informations {
    color: ${COLORS.DARK_BLUE};
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 25px;

    @media (${BREAKPOINTS.L}) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    .single-info {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    ${TextFieldWrapper} {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .label {
        ${BODY.S_SEMI_BOLD}
        text-align: start;
      }

      .input-wrapper {
        background: transparent;

        input {
          background: transparent;
        }
      }

      &.null {
        .input-wrapper {
          border-color: ${COLORS.DARK_GREY}30;

          input {
            color: ${COLORS.DARK_GREY}30;
          }
        }

        .label {
          color: ${COLORS.DARK_GREY}70;
        }
      }
    }

    .sim-link {
      margin-top: 10px;
      grid-column-end: span 2;
      ${TextFieldWrapper} {
        justify-content: start;

        .input-wrapper {
          flex: 1;
        }
      }
    }
  }
`;

export { UserInfosWrapper };
