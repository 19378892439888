import { ChartWrapper } from './Chart.styled';
import { Line, Pie, Doughnut, Bar } from 'react-chartjs-2';
import {
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  RadialLinearScale,
  BarElement,
  Chart as ChartJs,
  ChartData,
  ChartOptions,
  Tooltip,
} from 'chart.js';
import { ChartType } from '../../types/app';
import { useEffect, useState } from 'react';
import { customRound, formatNumber } from '../../utils';

ChartJs.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  RadialLinearScale,
  BarElement,
  Tooltip,
);

export interface ChartProps {
  chartType: ChartType;
  orientation?: 'horizontal' | 'vertical';
  options?: ChartOptions<ChartType>;
  datas?: ChartData<ChartType>;
  labelsBackgroundColors?: Array<string>;
  labelsColors?: Array<string>;
  labels?: Array<any>;
  className?: string;
  chartTitle?: string;
  defaultUnit?: string;
  getRef?: (e: any) => any;
  yUnit?: string;
}

const Chart: React.FC<ChartProps> = ({
  chartType,
  datas,
  options,
  className,
  labels,
  labelsBackgroundColors,
  labelsColors,
  chartTitle,
  defaultUnit,
  getRef,
  yUnit,
}) => {
  const [optionsState, setOptionsState] = useState<ChartOptions<ChartType>>();

  useEffect(() => {
    setOptionsState({
      ...options,
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        tooltip: {
          callbacks: {
            title: (e) => {
              return e[0].label.replaceAll(',', ' ');
            },
            label: (e) => `${formatNumber(customRound(e.raw as number, 2))} ${defaultUnit || ''}`,
            ...options?.plugins?.tooltip?.callbacks?.title,
            ...options?.plugins?.tooltip?.callbacks?.label,
          },
        },
      },
      scales: {
        y: {
          ticks: {
            font: {
              size: 10,
            },
            callback: (label) => {
              return !isNaN(Number(label))
                ? formatNumber(label) + `${yUnit ? ` ${yUnit}` : ''}`
                : label;
            },
            ...(options?.scales && options.scales.y?.ticks),
          },
          ...(options?.scales && options.scales.y),
        },
        x: {
          ...(options?.scales && options.scales.x),
          ticks: {
            font: {
              size: 10,
            },
            ...(options?.scales && options.scales.x?.ticks),
          },
        },
      },
    });
  }, [options, defaultUnit, yUnit]);

  const handleLoad = (chartInstance: any) => {
    getRef && getRef(chartInstance);
  };

  return (
    <ChartWrapper className={className}>
      {chartTitle && <h5 className="chart-title">{chartTitle}</h5>}
      {chartType === 'bar' && (
        <>
          <Bar
            className="chart"
            data={{
              ...(datas as ChartData<'bar'>),
              datasets: [
                ...((datas as ChartData<'bar'>).datasets &&
                  (datas as ChartData<'bar'>).datasets.map((dataset) => {
                    return {
                      ...dataset,
                      maxBarThickness: 50,
                    };
                  })),
              ],
            }}
            options={optionsState}
            ref={(reference) => handleLoad(reference)}
          />
        </>
      )}
      {chartType === 'doughnut' && (
        <Doughnut
          className="chart"
          data={datas as ChartData<'doughnut'>}
          options={optionsState}
          ref={(reference) => handleLoad(reference)}
        />
      )}
      {chartType === 'line' && (
        <Line
          className="chart"
          data={datas as ChartData<'line'>}
          options={optionsState as ChartOptions<'line'>}
          ref={(reference) => handleLoad(reference)}
        />
      )}
      {chartType === 'pie' && (
        <>
          <Pie
            className="chart"
            data={datas as ChartData<'pie'>}
            options={optionsState}
            ref={(reference) => handleLoad(reference)}
          />
        </>
      )}
      {labels && (
        <div className="chart-labels">
          {labels.map((label, key) => {
            return (
              <span
                style={{
                  backgroundColor: labelsBackgroundColors && labelsBackgroundColors[key],
                  color: labelsColors && labelsColors[key],
                }}
                className="chart-label"
                key={key}
              >
                {label}
              </span>
            );
          })}
        </div>
      )}
    </ChartWrapper>
  );
};

export default Chart;
