import { ReactComponent as IconHome } from './assets/icons/icon-home.svg';
import { ReactComponent as IconSeparator } from './assets/icons/icon-separator.svg';
import { ReactComponent as IconIndustrialSite } from './assets/icons/icon-industrial-site.svg';
import { ReactComponent as IconMonitoring } from './assets/icons/icon-monitoring.svg';
import { ReactComponent as IconCompressedAir } from './assets/icons/icon-compressed-air-leak.svg';
import { ReactComponent as IconHeatRecovery } from './assets/icons/icon-heat-recovery.svg';
import { ReactComponent as IconRoomAirDestratification } from './assets/icons/icon-room-air-destratification.svg';
import { ReactComponent as IconMicroModulatingBurner } from './assets/icons/icon-micro-modulating-burner.svg';
import { ReactComponent as IconInsulatingMattresses } from './assets/icons/icon-insulating-mattresses.svg';
import { ReactComponent as IconLedLighting } from './assets/icons/icon-led-lighting.svg';
import { ReactComponent as IconHeatPump } from './assets/icons/icon-heat-pump.svg';
import { ReactComponent as IconElectricSpeedVariation } from './assets/icons/icon-electric-speed-variation.svg';
import { ReactComponent as IconHighPerformanceEngines } from './assets/icons/icon-high-performance-engines.svg';
import { ReactComponent as IconMotorDrive } from './assets/icons/icon-motor-drive.svg';
import { ReactComponent as IconReset } from './assets/icons/icon-reset.svg';
import { ReactComponent as IconArrow } from './assets/icons/icon-arrow.svg';
import { ReactComponent as IconSelect } from './assets/icons/icon-select.svg';
import { ReactComponent as IconMenuOpen } from './assets/icons/icon-menu-open.svg';
import { ReactComponent as IconMenuClose } from './assets/icons/icon-menu-close.svg';
import { ReactComponent as IconQuestionMark } from './assets/icons/icon-question-mark.svg';
import { ReactComponent as IconEstimatedGain } from './assets/icons/icon-estimated-gain.svg';
import { ReactComponent as IconEnergyEconomy } from './assets/icons/icon-energy-economy.svg';
import { ReactComponent as IconDecreasedEmission } from './assets/icons/icon-decreased-emission.svg';
import { ReactComponent as IconDownload } from './assets/icons/icon-download.svg';
import { ReactComponent as IconCEE } from './assets/icons/icon-cee.svg';
import { ReactComponent as IconCheck } from './assets/icons/icon-check.svg';
import { ReactComponent as IconDownloadArrow } from './assets/icons/icon-download-arrow.svg';
import { ReactComponent as IconEdit } from './assets/icons/icon-edit.svg';
import { ReactComponent as IconUsers } from './assets/icons/icon-users.svg';
import { ReactComponent as IconReferenceDatas } from './assets/icons/icon-reference-datas.svg';
import { ReactComponent as IconCopy } from './assets/icons/icon-copy.svg';
import { ReactComponent as IconLogout } from './assets/icons/icon-logout.svg';
import { ReactComponent as IconBack } from './assets/icons/icon-back.svg';

import jsonReferenceDatas from './data-reference-v1.0.json';
import { IIcon, INavigation, SIMULATORS_TYPE } from './types/app';

const APP_TITLE = 'Actemium Energie';

const REFERENCE_DATAS_LOCALSTORAGE = 'REFERENCE_DATAS';

const REFERENCE_FIELD_NAMES: {
  [x: string]: keyof typeof jsonReferenceDatas;
} = {
  ELECTRICITY_CONSUMPTIONS: 'average_anual_electricity_consumption_of_industrial_site',
  ELECTRICITY_PRICE: 'average_electricity_price',
  AVERAGE_GAS_PRICE: 'average_gas_price',
  RECOVERABLE_ENERGY_PERCENTAGE_COMPRESSED_AIR: 'recoverable_energy_percentage_compressed_air',
  ELECTRICITY_DISSIPATED_HEAT_PERCENTAGE_CHILLER: 'electricity_dissipated_heat_percentage_chiller',
  CARBON_CONTENT_PER_KWH_ELECTRICITY: 'carbon_content_per_kWh_electricity',
  CARBON_CONTENT_PER_KWH_GAZ: 'carbon_content_per_kWh_gas',
  AVERAGE_GAS_PRICE_PER_MWH: 'average_gas_price_per_MWh',
  AVERAGE_ELECTRICITY_PRICE_PER_MWH: 'average_electricity_price_per_MWh',
  NATURAL_GAS_TRENDS: 'natural_gas_trends',
  NATURAL_ELECTRICITY_TRENDS: 'natural_electricity_trends',
  TEST: 'natural_electricity_trends2',
};

const API = {
  SEC: 'actemium',
  BASE_URL:
    process.env.REACT_APP_ENV === 'staging'
      ? 'https://actemiumsimulback.docker.site-check.me/api/v1'
      : process.env.REACT_APP_ENV === 'production'
      ? 'https://monsimulateurdecarbonation.actemium.fr/api/v1'
      : 'http://127.0.0.1:3003/api/v1',
  ROUTES: {
    AUTH: {
      GENERATE_USER_TOKEN: '/generate-token',
      GENERAGE_VIEW_ONLY_USER_TOKEN: '/generate-view-only-token',
      AUTHENTICATE_ADMIN: '/admin/auth',
    },
    USER: {
      ACTIONS: {
        DELETE: '/user/delete',
      },
      DETAILS: {
        GET: '/user',
        UPDATE: '/user/edit',
      },
      SIMULATOR_DATAS: {
        UPDATE: '/simulator-datas/edit',
      },
      REFERENCE_DATAS: {
        UPDATE: '/reference-datas/edit',
        REINITIALIZE: '/reference-datas/reinitialize',
      },
    },
    ADMIN: {
      DETAILS: {
        GET: '/admin/details',
      },
      USERS: {
        GET: '/admin/users',
        CONTACT_STATE: {
          UPDATE: '/admin/user/contact-state',
        },
        REPORT_STATE: {
          UPDATE: '/admin/user/report-state',
        },
      },
      REFERENCE_DATAS: {
        VERSIONS: {
          GET: '/admin/reference-versions',
          SET_CURRENT: '/admin/reference-versions/set-current',
        },
        GET: '/admin/reference-datas',
        UPDATE: '/admin/reference-datas/edit',
      },
    },
  },
};

console.log('Environment', process.env.REACT_APP_ENV);
console.log('Base url', API.BASE_URL);

const ROUTES = {
  HOME: '/',
  LANDING: 'landing',
  DASHBOARD: '/tableau-de-bord',
  SIMULATOR: {
    INDEX: '/simulateur',
    INDUSTRIAL_SITE: '/simulateur/mon-site-industriel',
    MONITORING: '/simulateur/monitoring-energetique',
    COMPRESSED_AIR: '/simulateur/fuite-d-air-comprime',
    HEAT_RECOVERY: '/simulateur/recuperation-de-chaleur',
    ROOM_AIR_DESTRATIFICATION: '/simulateur/destratication-d-air-des-locaux',
    MICRO_MODULATING_BURNER: '/simulateur/bruleur-micro-modulant',
    INSULATING_MATTRESSES: '/simulateur/matelas-isolants-points-singuliers',
    LED_LIGHTING: '/simulateur/eclairage-led-et-detection',
    HEAT_PUMP: '/simulateur/pompe-a-chaleur',
    ELECTRIC_SPEED_VARIATION: '/simulateur/variation-electrique-de-vitesse',
    HIGH_PERFORMANCE_ENGINES: '/simulateur/moteurs-performants',
    MOTOR_DRIVE: '/simulateur/moto-variateur',
    NOT_FOUND: '404',
  },
  DETAILED_REPORT: '/rapport',
  NOT_FOUND: '404',
  RESET: '/reset',
  VIEW: {
    INDEX: '/view',
  },
  ADMIN: {
    LOGIN: '/admin/authentification',
    INDEX: '/admin',
    HOME: '/',
    USERS: {
      LIST: '/simulations',
    },
    REFERENCE_DATAS: {
      LIST: '/donnees_de_reference',
      EDIT: '/donnees_de_reference/modifier',
    },
  },
};

const SIMULATORS_NAMES = {
  INDUSTRIAL_SITE: {
    name: 'INDUSTRIAL_SITE',
    title: 'Mon site industriel',
  },
  MONITORING: {
    name: 'MONITORING',
    title: 'Monitoring énergétique',
  },
  COMPRESSED_AIR: {
    name: 'COMPRESSED_AIR',
    title: "Fuite d'air comprimé",
  },
  HEAT_RECOVERY: {
    name: 'HEAT_RECOVERY',
    title: 'Récupération de chaleur',
  },
  ROOM_AIR_DESTRATIFICATION: {
    name: 'ROOM_AIR_DESTRATIFICATION',
    title: "Destratification d'air des locaux",
  },
  MICRO_MODULATING_BURNER: {
    name: 'MICRO_MODULATING_BURNER',
    title: 'Brûleur micro modulant',
  },
  INSULATING_MATTRESSES: {
    name: 'INSULATING_MATTRESSES',
    title: 'Matelas isolants - Points singuliers',
  },
  LED_LIGHTING: {
    name: 'LED_LIGHTING',
    title: 'Éclairage LED et détection',
  },
  HEAT_PUMP: {
    name: 'HEAT_PUMP',
    title: 'Pompe à chaleur',
  },
  ELECTRIC_SPEED_VARIATION: {
    name: 'ELECTRIC_SPEED_VARIATION',
    title: 'Variation électrique de vitesse',
  },
  HIGH_PERFORMANCE_ENGINES: {
    name: 'HIGH_PERFORMANCE_ENGINES',
    title: 'Moteurs performants',
  },
  MOTOR_DRIVE: {
    name: 'MOTOR_DRIVE',
    title: 'Moto-variateur',
  },
};

const ICON_NAMES = {
  HOME: 'Accueil',
  DASHBOARD: 'Tableau de bord',
  RESET: 'Réinitialiser toutes les données',
  ARROW: 'fleche',
  MENU_OPEN: 'Menu open',
  MENU_CLOSE: 'Menu close',
};

const ICONS: { [x: string]: IIcon } = {
  USERS: {
    icon: <IconUsers />,
    name: 'Simulations',
  },
  REFERENCE_DATAS: {
    icon: <IconReferenceDatas />,
    name: 'Données de références',
  },
  ADMIN_HOME: {
    icon: <IconHome />,
    name: ICON_NAMES.HOME,
  },
  HOME: {
    icon: <IconHome />,
    name: ICON_NAMES.HOME,
  },
  DASHBOARD: {
    icon: undefined,
    name: ICON_NAMES.DASHBOARD,
  },
  SEPARATOR: {
    icon: <IconSeparator />,
    name: '',
  },
  INDUSTRIAL_SITE: {
    icon: <IconIndustrialSite />,
    name: SIMULATORS_NAMES.INDUSTRIAL_SITE.title,
  },
  MONITORING: {
    icon: <IconMonitoring />,
    name: SIMULATORS_NAMES.MONITORING.title,
  },
  COMPRESSED_AIR: {
    icon: <IconCompressedAir />,
    name: SIMULATORS_NAMES.COMPRESSED_AIR.title,
  },
  HEAT_RECOVERY: {
    icon: <IconHeatRecovery />,
    name: SIMULATORS_NAMES.HEAT_RECOVERY.title,
  },
  ROOM_AIR_DESTRATIFICATION: {
    icon: <IconRoomAirDestratification />,
    name: SIMULATORS_NAMES.ROOM_AIR_DESTRATIFICATION.title,
  },
  MICRO_MODULATING_BURNER: {
    icon: <IconMicroModulatingBurner />,
    name: SIMULATORS_NAMES.MICRO_MODULATING_BURNER.title,
  },
  INSULATING_MATTRESSES: {
    icon: <IconInsulatingMattresses />,
    name: SIMULATORS_NAMES.INSULATING_MATTRESSES.title,
  },
  LED_LIGHTING: {
    icon: <IconLedLighting />,
    name: SIMULATORS_NAMES.LED_LIGHTING.title,
  },
  HEAT_PUMP: {
    icon: <IconHeatPump />,
    name: SIMULATORS_NAMES.HEAT_PUMP.title,
  },
  ELECTRIC_SPEED_VARIATION: {
    icon: <IconElectricSpeedVariation />,
    name: SIMULATORS_NAMES.ELECTRIC_SPEED_VARIATION.title,
  },
  HIGH_PERFORMANCE_ENGINES: {
    icon: <IconHighPerformanceEngines />,
    name: SIMULATORS_NAMES.HIGH_PERFORMANCE_ENGINES.title,
  },
  MOTOR_DRIVE: {
    icon: <IconMotorDrive />,
    name: SIMULATORS_NAMES.MOTOR_DRIVE.title,
  },
  RESET: {
    icon: <IconReset />,
    name: ICON_NAMES.RESET,
  },
  ARROW: {
    icon: <IconArrow />,
    name: ICON_NAMES.ARROW,
  },
  SELECT: {
    icon: <IconSelect />,
    name: '',
  },
  MENU_OPEN: {
    icon: <IconMenuOpen />,
    name: '',
  },
  MENU_CLOSE: {
    icon: <IconMenuClose />,
    name: '',
  },
  QUESTION_MARK: {
    icon: <IconQuestionMark />,
    name: '',
  },
  ESTIMATED_GAIN: {
    icon: <IconEstimatedGain />,
    name: '',
  },
  ENERGY_ECONOMY: {
    icon: <IconEnergyEconomy />,
    name: '',
  },
  DECREASED_EMISSION: {
    icon: <IconDecreasedEmission />,
    name: '',
  },
  DOWNLOAD: {
    icon: <IconDownload />,
    name: '',
  },
  CEE: {
    icon: <IconCEE />,
    name: '',
  },
  CHECK: {
    icon: <IconCheck />,
    name: '',
  },
  DOWNLOAD_ARROW: {
    icon: <IconDownloadArrow />,
    name: '',
  },
  EDIT: {
    icon: <IconEdit />,
    name: '',
  },
  COPY: {
    icon: <IconCopy />,
    name: '',
  },
  LOGOUT: {
    icon: <IconLogout />,
    name: 'Se déconnecter',
  },
  BACK: {
    icon: <IconBack />,
    name: 'Retour',
  },
};

const fromNavArrayToObject = (array: INavigation[]) => {
  let res: { [x: string]: INavigation } = {};
  for (let i = 0; i < array.length; i++) {
    res = {
      ...res,
      [array[i].iconInformations?.name as string]: {
        iconInformations: array[i].iconInformations,
        route: array[i].route,
      },
    };
  }
  return res;
};

const simulatorsNavigations: { [x: string]: INavigation } = fromNavArrayToObject(
  Object.values(SIMULATORS_NAMES).map((value: any) => {
    const simulatorRoute: any = ROUTES.SIMULATOR;
    const valueName: any = value.name;
    const route: any = simulatorRoute[valueName];
    return {
      iconInformations: ICONS[value.name] as IIcon,
      route: route,
    } as INavigation;
  }),
);

const NAVIGATION: {
  [x: string]: INavigation;
} = {
  HOME: {
    route: ROUTES.HOME,
    iconInformations: ICONS.DASHBOARD,
  },
  DASHBOARD: {
    route: ROUTES.DASHBOARD,
    iconInformations: ICONS.DASHBOARD,
  },
  ...simulatorsNavigations,
  RESET: {
    route: ROUTES.RESET,
    iconInformations: ICONS.RESET,
  },
};

const ADMIN_NAVIGATION: {
  [x: string]: INavigation;
} = {
  USERS: {
    route: `${ROUTES.ADMIN.INDEX}${ROUTES.ADMIN.USERS.LIST}`,
    iconInformations: ICONS.USERS,
  },
  REFERENCE_DATAS: {
    route: `${ROUTES.ADMIN.INDEX}${ROUTES.ADMIN.REFERENCE_DATAS.LIST}`,
    iconInformations: ICONS.REFERENCE_DATAS,
  },
};

export type SIMULATOR_LABELS_TYPE = 'Efficacité énergétique' | 'Décarbonation';

const DEFAULT_SIMULATORS: SIMULATORS_TYPE = {
  [SIMULATORS_NAMES.INDUSTRIAL_SITE.name]: {
    icon: ICONS.INDUSTRIAL_SITE,
    labels: [],
    name: SIMULATORS_NAMES.INDUSTRIAL_SITE.name,
    route: ROUTES.SIMULATOR.INDUSTRIAL_SITE,
    explications:
      "La saisie de ces données est recommandée pour personnaliser les résultats et évaluer les gains possibles en matière d'efficacité énergétique.",
  },
  [SIMULATORS_NAMES.MONITORING.name]: {
    icon: ICONS.MONITORING,
    labels: ['Efficacité énergétique', 'Décarbonation'],
    name: SIMULATORS_NAMES.MONITORING.name,
    route: ROUTES.SIMULATOR.MONITORING,
    explications:
      "La présence de compteurs mesurant la puissance des flux énergétiques distribués aux utilités et process permet de connaître les consommations de chacun et, dans le temps, de suivre ses tendances et les dérives. Une fois en possession des ces informations, l'exploitant peut identifier les usages énergétiques les plus consommateurs et agir en priorité sur ceux-ci pour réduire sa facture énergétique et suivre sa performance énergétique sur long terme.",
    isEligibleForCEE: true,
  },
  [SIMULATORS_NAMES.COMPRESSED_AIR.name]: {
    icon: ICONS.COMPRESSED_AIR,
    labels: ['Efficacité énergétique'],
    name: SIMULATORS_NAMES.COMPRESSED_AIR.name,
    route: ROUTES.SIMULATOR.COMPRESSED_AIR,
    explications:
      "L'audit d'air comprimé permet d'identifier les fuites sur le réseau afin de procéder à leur réparation. Dans l'industrie, on trouve un taux de fuite moyen de 35%.",
    isEligibleForCEE: true,
  },
  [SIMULATORS_NAMES.HEAT_RECOVERY.name]: {
    icon: ICONS.HEAT_RECOVERY,
    labels: ['Efficacité énergétique', 'Décarbonation'],
    name: SIMULATORS_NAMES.HEAT_RECOVERY.name,
    route: ROUTES.SIMULATOR.HEAT_RECOVERY,
    explications: `
    La récupération de chaleur sur les groupes froids et de production d'air comprimé peut permettre de faire des économies d'énergie substentielles. En effet, un groupe de production d'air comprimé dissipe en large partie de l'énergie électrique qu'il reçoit sous forme de chaleur. Pour un groupe froid, les calories extraites pour produire du froid peuvent également être valorisées. La chaleur valorisable pour un groupe froid représente environ le double de l'énergie électrique qui lui est fournie. Cette énergie thermique peut être valorisée et être réutilisée pour le procédé industriel ou le chauffage des locaux.
    `,
    isEligibleForCEE: true,
  },
  [SIMULATORS_NAMES.ROOM_AIR_DESTRATIFICATION.name]: {
    icon: ICONS.ROOM_AIR_DESTRATIFICATION,
    labels: ['Efficacité énergétique', 'Décarbonation'],
    name: SIMULATORS_NAMES.ROOM_AIR_DESTRATIFICATION.name,
    route: ROUTES.SIMULATOR.ROOM_AIR_DESTRATIFICATION,
    explications:
      "La destratification de l'air des locaux permet de réaliser d'importantes économies d'énergie. Les gains sont d'autant plus importants lorsque la hauteur sous plafond est importante. La destratification permet d'homogénéiser la température et les gains peuvent alors atteindre 25% sur la consommation d'énergie liée au chauffage.",
  },
  [SIMULATORS_NAMES.MICRO_MODULATING_BURNER.name]: {
    icon: ICONS.MICRO_MODULATING_BURNER,
    labels: [],
    name: SIMULATORS_NAMES.MICRO_MODULATING_BURNER.name,
    route: ROUTES.SIMULATOR.MICRO_MODULATING_BURNER,
    explications:
      "L'installation d'un bruleur micro modulant permet d'augmenter le rendement d'une chaudière en modulant la puissance du bruleur. Cela évite que celui-ci enchaine des cycles d'arrêt / démarrage qui peuvent l'user prématurément et qui induisent une baisse de rendement non négligeable. La mise en place d'un brûleur micro modulant permet de diminuer jusqu'à 5% les consommations de gaz.",
    isEligibleForCEE: true,
  },
  [SIMULATORS_NAMES.INSULATING_MATTRESSES.name]: {
    icon: ICONS.INSULATING_MATTRESSES,
    labels: [],
    name: SIMULATORS_NAMES.INSULATING_MATTRESSES.name,
    route: ROUTES.SIMULATOR.INSULATING_MATTRESSES,
    explications:
      "L'utilisation d'eau chaude ou de vapeur est très courante dans les procédés industriels. L'isolation des points singuliers tels que les vannes, brides ou robinets permet de diminuer les pertes thermiques dans le réseau et ainsi de diminuer les consomations énergétiques. L'isolation des points singuliers d'un réseau peut permettre d'économiser jusqu'à 5% d'énergie selon le nombre de points présents et les températures du fluide.",
    isEligibleForCEE: true,
  },
  [SIMULATORS_NAMES.LED_LIGHTING.name]: {
    icon: ICONS.LED_LIGHTING,
    labels: ['Efficacité énergétique', 'Décarbonation'],
    name: SIMULATORS_NAMES.LED_LIGHTING.name,
    route: ROUTES.SIMULATOR.LED_LIGHTING,
    explications:
      "La conversion de l'éclairage classique à incandescence ou à tube fluorocompacte vers de l'éclairage LED est un incontournable de l'éfficacité énergétique. L'éclairage LED permet de réduire de 60% (tube fluorocompacte) à 90% (lampe à incandescence) les consommations d'énergies associées à l'éclairage.",
    isEligibleForCEE: true,
  },
  [SIMULATORS_NAMES.HEAT_PUMP.name]: {
    icon: ICONS.HEAT_PUMP,
    labels: ['Efficacité énergétique', 'Décarbonation'],
    name: SIMULATORS_NAMES.HEAT_PUMP.name,
    route: ROUTES.SIMULATOR.HEAT_PUMP,
    explications:
      "L'intégration d'une pompe à chaleur permet de remplacer les chaudières gaz pour la production de chaleur à basse, moyenne et haute température.",
    isEligibleForCEE: true,
  },
  [SIMULATORS_NAMES.ELECTRIC_SPEED_VARIATION.name]: {
    icon: ICONS.ELECTRIC_SPEED_VARIATION,
    labels: ['Efficacité énergétique', 'Décarbonation'],
    name: SIMULATORS_NAMES.ELECTRIC_SPEED_VARIATION.name,
    route: ROUTES.SIMULATOR.ELECTRIC_SPEED_VARIATION,
    explications:
      "L'installation d'un variateur de vitesse sur un moteur permet de le protéger des conséquences à long terme du démarrage brutal à l'allumage. Il permet aussi de piloter un moteur en optimisant sa vitesse, ce qui conduit à des économies d'énergies. Enfin, la protection offerte par un variateur de vitesse contre la surintensité du démarrage augmente la durée de vie du matériel et réduit les coûts de maintenance.",
    isEligibleForCEE: true,
  },
  [SIMULATORS_NAMES.HIGH_PERFORMANCE_ENGINES.name]: {
    icon: ICONS.HIGH_PERFORMANCE_ENGINES,
    labels: ['Efficacité énergétique', 'Décarbonation'],
    name: SIMULATORS_NAMES.HIGH_PERFORMANCE_ENGINES.name,
    route: ROUTES.SIMULATOR.HIGH_PERFORMANCE_ENGINES,
    explications:
      "Remplacer un moteur électrique par un autre d'une classe IE supérieure (donc de rendement supérieur) permet de diminuer la comsommation énergetique.",
    isEligibleForCEE: true,
  },
  [SIMULATORS_NAMES.MOTOR_DRIVE.name]: {
    icon: ICONS.MOTOR_DRIVE,
    labels: ['Efficacité énergétique', 'Décarbonation'],
    name: SIMULATORS_NAMES.MOTOR_DRIVE.name,
    route: ROUTES.SIMULATOR.MOTOR_DRIVE,
    explications:
      "L'action conjointe du replacement d'un moteur par un moteur d'une classe supérieure et l'ajout d'un variateur engendre une economie d'énergie supérieure à celle d'une action isolée.",
    isEligibleForCEE: true,
  },
};

const RESPONSABILITY_NOTE = `Les résultats présentés correspondent à des simulations répondant à des
hypothèses générales et sont donnés à titre indicatif et informatif. Ils ne
constituent ni une offre commerciale, ni un engagement de la part d’Actemium.
Actemium décline toute responsabilité quant à l’exactitude des résultats.`;

export {
  REFERENCE_DATAS_LOCALSTORAGE,
  API,
  REFERENCE_FIELD_NAMES,
  ICONS,
  ROUTES,
  NAVIGATION,
  ADMIN_NAVIGATION,
  DEFAULT_SIMULATORS,
  SIMULATORS_NAMES,
  APP_TITLE,
  RESPONSABILITY_NOTE,
};
