import { useEffect, useState } from 'react';
import { SelectBoxWrapper, SelectOptionsWrapper } from './SelectBox.styled';
import { IForm } from '../../../types/app';
import { ICONS } from '../../../global';

export interface SelectBoxProps {
  className?: string;
  label?: string;
  placeholder?: string;
  value?: string;
  unit?: string;
  onChange?(newOptions: Array<IForm.IOption>): void;
  options?: Array<IForm.IOption>;
  disabled?: boolean;
}

const SelectBox: React.FC<SelectBoxProps> = ({
  label,
  placeholder,
  className,
  // value,
  unit,
  onChange,
  options,
  disabled,
}) => {
  const [open, setOpen] = useState(false);
  // const [currentValue, setCurrentValue] = useState(value);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1);

  useEffect(() => {
    options?.forEach((option, index) => {
      if (option.selected) setSelectedOptionIndex(index);
    });
  }, [options]);

  const toggleOpen = () => {
    if (!disabled) setOpen(!open);
  };

  const updateSelect = (indexSelected: number) => {
    if (options) {
      let newOptions = options.map((option, index) => {
        if (index === indexSelected) return { ...option, selected: true };
        return { ...option, selected: false };
      });
      setOpen(false);
      onChange && onChange(newOptions);
    }
  };

  return (
    <SelectBoxWrapper
      className={`${className} ${open ? 'open' : ''} ${disabled ? 'disabled' : ''}`}
    >
      {label && (
        <div className="label">
          {`${label} `}
          {!!unit && `(${unit})`}
        </div>
      )}
      <SelectOptionsWrapper>
        <div
          className={`select-value ${disabled ? 'disabled' : ''}`}
          onClick={() => {
            toggleOpen();
          }}
          tabIndex={0}
        >
          {options && selectedOptionIndex >= 0 ? (
            options[selectedOptionIndex].label
          ) : (
            <span className="placeholder">{placeholder}</span>
          )}
          <span className="select-icon-value">{ICONS.SELECT.icon}</span>
        </div>
        {options && options.length > 0 && (
          <div className="select-options">
            {options.map((option, key) => {
              return (
                <div
                  key={key}
                  className={`option ${option.selected ? 'selected' : ''}`}
                  onClick={() => {
                    setSelectedOptionIndex(key);
                    updateSelect(key);
                  }}
                >
                  {option.label}
                </div>
              );
            })}
          </div>
        )}
      </SelectOptionsWrapper>
      {/* <select name="" id="">
        <option value="">opt1</option>
        <option value="">opt2</option>
        <option value="">opt3</option>
      </select> */}
    </SelectBoxWrapper>
  );
};

export default SelectBox;
