import { CSSProperties } from 'styled-components';
import { IComponentWithChildren } from '../../types/app';
import { CardColor, CardTitle, CardWrapper } from './Card.styled';

export interface CardProps extends IComponentWithChildren {
  styles?: CSSProperties;
  highLightOnHover?: boolean;
  theme?: CardColor;
  title?: any;
  className?: any;
}

const Card: React.FC<CardProps> = ({ children, styles, theme, title, className }) => {
  return (
    <CardWrapper styles={{ ...styles, theme }} className={className}>
      {title && <CardTitle>{title}</CardTitle>}
      <div className="card-children">{children}</div>
    </CardWrapper>
  );
};

export default Card;
