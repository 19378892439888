import { createContext, useContext, useEffect, useState } from 'react';
import { IComponentWithChildren, IForm, ISimulatorRequiredDatas } from '../../types/app';
import { ReferenceDatasContext, ReferenceDatasContextProps } from '../ReferenceDatasContext';
import { SimulatorsContext, SimulatorsContextProps } from './SimulatorsContext';
import { UserContext, UserContextProps } from '../user/UserContext';

import useDelayedUpdate from '../../hooks/useDelayedUpdate';
import { ITableRow } from '../../components/table/Table';
import { ChartProps } from '../../components/chart/Chart';
import { COLORS } from '../../styles/constants/colors';
import { FormTitle } from '../../components/form/Form.styled';

export interface MonitoringContextProps extends ISimulatorRequiredDatas {
  monitoredPower: number;
  numberOfMeasuringPoints: number;
  operatingTime: number;
  consumedEnergy: number;
  averageEnergyEconomizedAfterME: number;
  reducedConsumption: number;
  estimatedReductionInEmissions: number;
  estimatedFinancialGainFromEnergySavings: number;
  investmentSolutionME: number;
  roi: number;
  roiMatrix: Array<Array<number>>;
  forms: IForm.IForm[];
  resultTableRows: ITableRow[];
  resultCharts: ChartProps[][];
  setMonitoredPower: (e: number) => any;
  setNumberOfMeasuringPoints: (e: number) => any;
  setOperatingTime: (e: number) => any;
  setConsumedEnergy: (e: number) => any;
  setAverageEnergyEconomizedAfterME: (e: number) => any;
  setReducedConsumption: (e: number) => any;
  setEstimatedReductionInEmissions: (e: number) => any;
  setEstimatedFinancialGainFromEnergySavings: (e: number) => any;
  setInvestmentSolutionME: (e: number) => any;
  setRoi: (e: number) => any;
}

const MonitoringContext = createContext<MonitoringContextProps | undefined>(undefined);
const MonitoringProvider: React.FC<IComponentWithChildren> = ({ children }) => {
  const {
    CARBON_CONTENT_PER_KWH_GAZ,
    CARBON_CONTENT_PER_KWH_ELECTRICITY,
    AVERAGE_GAS_PRICE_PER_MWH,
    AVERAGE_ELECTRICITY_PRICE_PER_MWH,
  } = useContext(ReferenceDatasContext) as ReferenceDatasContextProps;

  const { userToken, updateUserSimulatorDatas } = useContext(UserContext) as UserContextProps;
  const { simulators, getSimulatorByCode, editSimulatorDatas } = useContext(
    SimulatorsContext,
  ) as SimulatorsContextProps;
  const [monitoredPower, setMonitoredPower] = useState(0);
  const [numberOfMeasuringPoints, setNumberOfMeasuringPoints] = useState(0);
  const [operatingTime, setOperatingTime] = useState(0);
  const [fields, setFields] = useState<Array<IForm.IField>>();
  const [forms, setForms] = useState<IForm.IForm[]>([]);
  const [consumedEnergy, setConsumedEnergy] = useState(0);
  const [averageEnergyEconomizedAfterME, setAverageEnergyEconomizedAfterME] = useState(0);
  const [reducedConsumption, setReducedConsumption] = useState(0);
  const [estimatedReductionInEmissions, setEstimatedReductionInEmissions] = useState(0);
  const [estimatedFinancialGainFromEnergySavings, setEstimatedFinancialGainFromEnergySavings] =
    useState(0);
  const [investmentSolutionME, setInvestmentSolutionME] = useState(0);
  const [roi, setRoi] = useState(0);
  const [roiMatrix, setRoiMatrix] = useState<Array<Array<number>>>([[]]);
  const [updateDatas, setUpdateDatas] = useState<object>({});
  const [resultTableRows, setResultTableRows] = useState<ITableRow[]>([]);
  const [resultCharts, setResultCharts] = useState<ChartProps[][]>([]);

  // Consumed energy
  useEffect(() => {
    setConsumedEnergy(monitoredPower * operatingTime);
  }, [monitoredPower, operatingTime, setConsumedEnergy]);

  // Economized energy after ME
  useEffect(() => {
    setAverageEnergyEconomizedAfterME((consumedEnergy * 3) / 100);
  }, [consumedEnergy, setAverageEnergyEconomizedAfterME]);

  // reduced consumption
  useEffect(() => {
    setReducedConsumption(consumedEnergy - averageEnergyEconomizedAfterME);
  }, [consumedEnergy, averageEnergyEconomizedAfterME, setReducedConsumption]);

  // reduction in emission
  useEffect(() => {
    setEstimatedReductionInEmissions(
      (averageEnergyEconomizedAfterME *
        (CARBON_CONTENT_PER_KWH_GAZ?.value + CARBON_CONTENT_PER_KWH_ELECTRICITY?.value)) /
        2,
    );
  }, [
    averageEnergyEconomizedAfterME,
    CARBON_CONTENT_PER_KWH_ELECTRICITY?.value,
    CARBON_CONTENT_PER_KWH_GAZ?.value,
    setEstimatedReductionInEmissions,
  ]);

  // estimated financial gain
  useEffect(() => {
    setEstimatedFinancialGainFromEnergySavings(
      (averageEnergyEconomizedAfterME *
        (AVERAGE_GAS_PRICE_PER_MWH?.value + AVERAGE_ELECTRICITY_PRICE_PER_MWH?.value)) /
        2,
    );
  }, [
    averageEnergyEconomizedAfterME,
    AVERAGE_GAS_PRICE_PER_MWH?.value,
    AVERAGE_ELECTRICITY_PRICE_PER_MWH?.value,
    setEstimatedFinancialGainFromEnergySavings,
  ]);

  // investment solution
  useEffect(() => {
    setInvestmentSolutionME(numberOfMeasuringPoints * (1000 + 1000 + 260));
  }, [numberOfMeasuringPoints, setInvestmentSolutionME]);

  // ROI
  useEffect(() => {
    setRoi(investmentSolutionME / estimatedFinancialGainFromEnergySavings);
  }, [investmentSolutionME, estimatedFinancialGainFromEnergySavings, setRoi]);

  // ROI Matrix
  useEffect(() => {
    const investment = -investmentSolutionME;
    let yearsCount = 10;
    const realisedEconomy = 0;
    let tmpRoiMatrix: Array<Array<number>> = [[]];
    tmpRoiMatrix[0] = [realisedEconomy, estimatedFinancialGainFromEnergySavings];
    tmpRoiMatrix[1] = [tmpRoiMatrix[0][0] + investment];

    // First row of the matrix
    for (let i = 2; i < yearsCount; i++) {
      tmpRoiMatrix[0] = [...tmpRoiMatrix[0], tmpRoiMatrix[0][i - 1] * (1 + 8 / 100)];
    }
    // Second row of the matrix
    for (let i = 1; i < yearsCount; i++) {
      tmpRoiMatrix[1] = [...tmpRoiMatrix[1], tmpRoiMatrix[1][i - 1] + tmpRoiMatrix[0][i]];
    }
    setRoiMatrix(tmpRoiMatrix);
  }, [investmentSolutionME, estimatedFinancialGainFromEnergySavings, setRoiMatrix]);

  useEffect(() => {
    setFields([
      {
        label: 'Puissance monitorée',
        name: 'Monitored power',
        type: 'textfield',
        textType: 'number',
        value: monitoredPower,
        unit: 'MW',
        onChange: (e) => setMonitoredPower(e),
      },
      {
        label: 'Nombre de compteurs',
        name: 'Number of measuring points',
        type: 'textfield',
        textType: 'number',
        value: numberOfMeasuringPoints,
        unit: 'Compteurs',
        onChange: (e) => setNumberOfMeasuringPoints(e),
      },
      {
        label:
          'Temps de fonctionnement (le nombre d’heure correspond au temps de production monitoré)',
        name: 'Operating time',
        type: 'textfield',
        textType: 'number',
        value: operatingTime,
        unit: 'heures/an',
        onChange: (e) => setOperatingTime(e),
      },
    ]);
  }, [monitoredPower, numberOfMeasuringPoints, operatingTime]);

  const [allowRequest, setAllowRequest] = useState<boolean>(false);
  useEffect(() => {
    setForms([
      {
        header: (
          <>
            <FormTitle>Remplissez les champs suivants :</FormTitle>
          </>
        ),
        fields: (fields && fields) || [],
        onChange: (updatedFields) => {
          setAllowRequest(true);
          setFields(updatedFields);
        },
      },
    ]);
  }, [fields]);

  useEffect(() => {
    setResultTableRows([
      {
        label: 'Energie consommée (référence)',
        value: `${consumedEnergy}`,
        unit: 'MWh/an',
      },
      {
        label: "Economie d'énergie moyenne après ME",
        value: `${averageEnergyEconomizedAfterME}`,
        unit: 'MWh/an',
      },
      {
        label: 'Consommation réduite',
        value: `${reducedConsumption}`,
        unit: 'MWh/an',
      },
      {
        label: "Baisse d'émissions estimée",
        value: `${estimatedReductionInEmissions}`,
        unit: 'T CO2/an',
      },
      {
        label: "Gain financier estimé grâce à l'économie d'énergie",
        value: `${estimatedFinancialGainFromEnergySavings}`,
        unit: '€/an',
      },
      {
        label: 'Investissement solution ME',
        value: `${investmentSolutionME}`,
        unit: '€',
      },
      {
        label: 'ROI (le ROI n’inclut pas les primes et aides (CEE,…))',
        value: `${roi}`,
        unit: 'ans',
      },
      // {
      //   label: 'ROI',
      //   value: `${roi * 12}`,
      //   unit: 'mois',
      // },
    ]);
  }, [
    consumedEnergy,
    averageEnergyEconomizedAfterME,
    reducedConsumption,
    estimatedReductionInEmissions,
    estimatedFinancialGainFromEnergySavings,
    investmentSolutionME,
    roi,
  ]);

  useEffect(() => {
    setResultCharts([
      [
        {
          chartType: 'bar',
          chartTitle: 'Gains énergétiques MWh/an',
          datas: {
            labels: [['Energie consommée (référence)'], 'Consommation réduite'],
            datasets: [
              {
                data: [consumedEnergy, reducedConsumption],
                backgroundColor: [COLORS.BRIGHT_BLUE, COLORS.DARK_BLUE],
              },
            ],
          },
          yUnit: 'MWh/an',
          // defaultUnit: 'MWh/an',
        },
      ],
      [
        {
          chartType: 'bar',
          chartTitle:
            "Retour sur investissement après la mise en place d'actions de perfomances énergétique avec inflation de 8%",
          datas: {
            labels: ['Année 0', 'Année 1', 'Année 2', 'Année 3', 'Année 4', 'Année 5'],
            datasets: [
              {
                data: roiMatrix[1] && roiMatrix[1],
                backgroundColor:
                  roiMatrix[1] &&
                  roiMatrix[1].map((value) => (value > 0 ? COLORS.BRIGHT_BLUE : COLORS.LIGHT_BLUE)),
              },
            ],
          },
          defaultUnit: '€',
          yUnit: '€',
        },
      ],
    ]);
  }, [consumedEnergy, reducedConsumption, roiMatrix]);

  useEffect(() => {
    const sim = getSimulatorByCode('MONITORING');
    if (sim) {
      setMonitoredPower(Number(sim?.datas?.monitoredPower));
      setNumberOfMeasuringPoints(Number(sim?.datas?.numberOfMeasuringPoints));
      setOperatingTime(Number(sim?.datas?.operatingTime));
    }
    // eslint-disable-next-line
  }, [simulators]);

  const updateUserSimulatorDatasFunc = () => {
    // Your logic to update the user simulator datas
    allowRequest &&
      updateDatas &&
      updateUserSimulatorDatas(userToken, 'MONITORING', updateDatas).then(() => {
        editSimulatorDatas('MONITORING', updateDatas);
      });
  };

  useEffect(() => {
    if (userToken) {
      const newDatas = {
        monitoredPower: Number(monitoredPower),
        numberOfMeasuringPoints: Number(numberOfMeasuringPoints),
        operatingTime: Number(operatingTime),
      };

      setUpdateDatas(newDatas);
    }
  }, [
    monitoredPower,
    numberOfMeasuringPoints,
    operatingTime,
    averageEnergyEconomizedAfterME,
    estimatedFinancialGainFromEnergySavings,
    estimatedReductionInEmissions,
    userToken,
  ]);

  useDelayedUpdate(updateUserSimulatorDatasFunc, [updateDatas], 250);

  return (
    <MonitoringContext.Provider
      value={{
        monitoredPower,
        numberOfMeasuringPoints,
        operatingTime,
        consumedEnergy,
        averageEnergyEconomizedAfterME,
        reducedConsumption,
        estimatedReductionInEmissions,
        estimatedFinancialGainFromEnergySavings,
        investmentSolutionME,
        roi,
        roiMatrix,
        forms,
        resultTableRows,
        resultCharts,
        energyGainMwhPerYear: averageEnergyEconomizedAfterME,
        kgCo2EmissionAvoidedPerYear: estimatedReductionInEmissions * 1000,
        estimatedRoi: roi,
        financialGainPerYear: estimatedFinancialGainFromEnergySavings,
        estimatedBudget: investmentSolutionME,
        setMonitoredPower,
        setNumberOfMeasuringPoints,
        setOperatingTime,
        setConsumedEnergy,
        setAverageEnergyEconomizedAfterME,
        setReducedConsumption,
        setEstimatedReductionInEmissions,
        setEstimatedFinancialGainFromEnergySavings,
        setInvestmentSolutionME,
        setRoi,
      }}
    >
      {children}
    </MonitoringContext.Provider>
  );
};
export { MonitoringContext, MonitoringProvider };
