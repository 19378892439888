import { useCallback, useContext, useEffect, useState } from 'react';
import AdminPageLayout from '../../layout/AdminPageLayout';
import { UsersListWrapper } from './UsersList.styled';
import { AdminUsersApi } from '../../../../api/Admin.Users.api';
import { AdminContext, AdminContextProps } from '../../../../context/admin/AdminContext';
import { IPagination, IUserDetails } from '../../../../types/app';
import MultipleColumnsTable from '../../../../components/table/multiple-columns-table/MultipleColumnsTable';
import { ICONS } from '../../../../global';
import Copy from '../../../../components/copy/Copy';
import { ModalContext, ModalContextProps } from '../../../../context/ModalContext';
import UserInfos from '../info/UserInfos';
import Progress from '../../../../components/progress/Progress';
import Icon from '../../../../components/icon/Icon';
import { checkTextNull, formatDateString } from '../../../../utils';
import { Loader } from '../../../../App.styled';
import Button from '../../../../components/button/Button';
import SelectBox, { SelectBoxProps } from '../../../../components/form/select-box/SelectBox';
import { COLORS } from '../../../../styles/constants/colors';
import ExportUsersListToExcel, {
  UsersListRowExportProps,
} from './export-to-excel/ExportUsersListToExcel';
import XLSX, { ColInfo } from 'sheetjs-style';
import * as FileSaver from 'file-saver';
import TextField from '../../../../components/form/text-field/TextField';

const FILTERS_CODES = {
  EMPTY: {
    label: 'Sélectionnez un filtre ...',
    code: '',
  },
  WANT_TO_BE_CONTACTED: {
    label: 'Souhaitant être contactés',
    code: 'want_to_be_contacted',
  },
  WANT_FULL_REPORT: {
    label: 'Souhaitant obtenir une analyse de leurs résultats',
    code: 'want_full_report',
  },
};

const defaultFilter: SelectBoxProps = {
  options: [
    {
      label: FILTERS_CODES.EMPTY.label,
      value: FILTERS_CODES.EMPTY.code,
      selected: true,
    },
    {
      label: FILTERS_CODES.WANT_TO_BE_CONTACTED.label,
      value: FILTERS_CODES.WANT_TO_BE_CONTACTED.code,
    },
    {
      label: FILTERS_CODES.WANT_FULL_REPORT.label,
      value: FILTERS_CODES.WANT_FULL_REPORT.code,
    },
  ],
};

const getFilterStringsFromFilters = (filters: SelectBoxProps[]): string[] => {
  let tmpFilters: string[] = [];
  filters.forEach((filter) => {
    filter.options?.forEach((option) => {
      if (option.selected === true) tmpFilters.push(option.value);
    });
  });
  return tmpFilters;
};

const defaultPagination: IPagination = {
  currentPage: 0,
  rowsCount: 10,
};

const UsersList: React.FC = () => {
  const { admin } = useContext(AdminContext) as AdminContextProps;
  const { updateModalConf } = useContext(ModalContext) as ModalContextProps;

  const [users, setUsers] = useState<IUserDetails[]>([]);
  const [loadingUserFetch, setLoadingUserFetch] = useState<boolean>(true);
  const [currentPagination, setCurrentPagination] = useState<IPagination>();
  const [filters, setFilters] = useState<SelectBoxProps[]>([defaultFilter]);
  const [emailSearch, setEmailSearch] = useState<string>('');

  const initUsersList = useCallback(
    async (filters?: string[], pagination?: IPagination, email?: string) => {
      try {
        setLoadingUserFetch(true);
        const res = await AdminUsersApi.getUsersList(
          admin?.token as string,
          filters,
          pagination,
          false,
          email,
        );
        if (res) {
          const userList = res.userList;
          const pagination = res.pagination;
          setUsers(userList);
          setCurrentPagination(pagination);
          setLoadingUserFetch(false);
        }
      } catch (err) {}
    },
    [admin],
  );

  const onRowsPerPageChange = (rows: number) => {
    const newPagination: IPagination = {
      rowsCount: rows,
      currentPage: 0,
      pagesCount: currentPagination?.pagesCount,
      totalCount: currentPagination?.totalCount,
    };
    setCurrentPagination(newPagination);
    initUsersList(getFilterStringsFromFilters(filters), newPagination, emailSearch);
  };

  const onPageChange = (page: number) => {
    const newPagination: IPagination = {
      currentPage: page,
      rowsCount: currentPagination?.rowsCount || defaultPagination.rowsCount,
      pagesCount: currentPagination?.pagesCount,
      totalCount: currentPagination?.totalCount,
    };
    setCurrentPagination(newPagination);
    initUsersList(getFilterStringsFromFilters(filters), newPagination, emailSearch);
  };

  const onApplyFilters = () => {
    const tmpFilters: string[] = getFilterStringsFromFilters(filters);
    initUsersList(tmpFilters, currentPagination, emailSearch);
  };

  const addFilter = () => {
    setFilters([...filters, defaultFilter]);
  };

  const removeFilter = (index: number) => {
    setFilters(filters.filter((filter, key) => key !== index));
  };

  useEffect(() => {
    try {
      initUsersList(getFilterStringsFromFilters([defaultFilter]), defaultPagination);
    } catch (err) {}
  }, [admin, initUsersList]);

  const openUserInfos = (details: IUserDetails) => {
    updateModalConf({
      component: <UserInfos details={details} />,
    });
  };

  const exportToExcel = async (rows: UsersListRowExportProps[]) => {
    type COLUMN_TITLES_TYPE =
      | 'Identifiant'
      | 'Adresse email'
      | 'Souhaitant être contacté '
      | 'Souhaitant obtenir une analyse de ses résultats'
      | 'Date de création'
      | 'Nom'
      | 'Prénom'
      | 'Numero de téléphone'
      | 'Entreprise'
      | 'Poste occupé'
      | 'Département'
      | 'Lien vers simulateur';

    const COLUMN_TITLES: COLUMN_TITLES_TYPE[] = [
      'Identifiant',
      'Adresse email',
      'Souhaitant être contacté ',
      'Souhaitant obtenir une analyse de ses résultats',
      'Date de création',
      'Nom',
      'Prénom',
      'Numero de téléphone',
      'Entreprise',
      'Poste occupé',
      'Département',
      'Lien vers simulateur',
    ];

    const formatedRows = rows.map((row) => {
      const formattedRow: Record<string, any> = {};

      COLUMN_TITLES.forEach((title) => {
        switch (title) {
          case 'Identifiant':
            formattedRow[title] = row.id;
            break;
          case 'Adresse email':
            formattedRow[title] = !checkTextNull(row.email) ? row.email : '';
            break;
          case 'Souhaitant être contacté ':
            formattedRow[title] = row.wantToBeContacted ? 'Oui' : 'Non';
            break;
          case 'Souhaitant obtenir une analyse de ses résultats':
            formattedRow[title] = row.wantResultReport ? 'Oui' : 'Non';
            break;
          case 'Date de création':
            formattedRow[title] = !checkTextNull(row.creationDate) ? row.creationDate : '';
            break;
          case 'Nom':
            formattedRow[title] = !checkTextNull(row.lastname) ? row.lastname : '';
            break;
          case 'Prénom':
            formattedRow[title] = !checkTextNull(row.firstname) ? row.firstname : '';
            break;
          case 'Numero de téléphone':
            formattedRow[title] = !checkTextNull(row.phoneNumber) ? row.phoneNumber : '';
            break;
          case 'Entreprise':
            formattedRow[title] = !checkTextNull(row.company) ? row.company : '';
            break;
          case 'Poste occupé':
            formattedRow[title] = !checkTextNull(row.jobPosition) ? row.jobPosition : '';
            break;
          case 'Lien vers simulateur':
            formattedRow[title] = !checkTextNull(row.simulatorLink) ? row.simulatorLink : '';
            break;
          case 'Département':
            formattedRow[title] = !checkTextNull(row.department) ? row.department : '';
            break;
          default:
            break;
        }
      });

      return formattedRow;
    });

    const ws = XLSX.utils.json_to_sheet(formatedRows);

    const fileType =
      'application/vnd.openxmlformats-officedocuments.speadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    let objectMaxLength: number[] = [];
    const numberOfColumns = COLUMN_TITLES.length;
    for (let i = 0; i < formatedRows.length; i++) {
      const obj = formatedRows[i];
      for (let k = 0; k < numberOfColumns; k++) {
        Object.values(obj).forEach((value, key) => {
          const defaultLength = COLUMN_TITLES[key].length;
          if (key === k) {
            if (value && value.length) {
              if (!objectMaxLength[key]) {
                objectMaxLength[key] = value.length;
              } else {
                if (objectMaxLength[key] < value.length) {
                  objectMaxLength[key] = value.length;
                }
              }
            } else {
              if (!objectMaxLength[key]) {
                objectMaxLength[key] = defaultLength;
              }
            }
            if (objectMaxLength[key] < defaultLength) objectMaxLength[key] = defaultLength;
          }
        });
      }
    }

    const cols: ColInfo[] = objectMaxLength.map((maxLength) => {
      return {
        width: maxLength,
      };
    });
    ws['!cols'] = [...cols];

    const wb: XLSX.WorkBook = {
      Sheets: {
        data: ws,
      },
      SheetNames: ['data'],
    };

    const excelBuffer = XLSX.write(wb, {
      bookType: 'xlsx',
      type: 'array',
    });

    const data = new Blob([excelBuffer], {
      type: fileType,
    });
    FileSaver.saveAs(
      data,
      `simulateurs_${formatDateString(new Date().toISOString(), '-')}${fileExtension}`,
    );
  };

  const onExportToExcel = async () => {
    if (!admin?.token) return;
    const users = await AdminUsersApi.getUsersList(
      admin?.token,
      [],
      {
        currentPage: 0,
        rowsCount: -1,
      },
      true,
    );
    const list = users?.userList;
    if (list && list?.length > 0) {
      exportToExcel(
        list?.map((user) => {
          return {
            id: `${user.id}`,
            email: user.email,
            wantToBeContacted: !!user.wantToBeContacted,
            wantResultReport: !!user.wantFullReport,
            creationDate: formatDateString(user.creationDate as string),
            firstname: user.firstname,
            lastname: user.lastname,
            phoneNumber: user.phoneNumber,
            company: user.companyName,
            jobPosition: user.jobPosition,
            department: user.department,
            simulatorLink: `${window.location.host}/view?vt=${user.token}&uid=${user.id}`,
          } as UsersListRowExportProps;
        }) as UsersListRowExportProps[],
      );
    }
  };

  return (
    <AdminPageLayout title="Admin - Simulations">
      <UsersListWrapper>
        {/* <div className="title">Liste des simulations</div> */}
        <div className="actions">
          <div className="filters">
            {filters &&
              filters?.map((filter, key) => {
                return (
                  <div className="single-filter" key={key}>
                    {filters.length > 1 && (
                      <div className="filter-prefix">
                        <div className="filter-count">{key + 1}</div>
                      </div>
                    )}
                    <SelectBox
                      key={key}
                      {...filter}
                      onChange={(e) => {
                        let tmpFilters = [...filters];
                        tmpFilters[key] = {
                          ...tmpFilters[key],
                          options: e,
                        };
                        setFilters([...tmpFilters]);
                      }}
                    />
                    {filters.length > 1 && (
                      <div className="filter-suffix">
                        <Button
                          className="remove-filter"
                          onClick={() => {
                            removeFilter(key);
                          }}
                        >
                          {ICONS.MENU_CLOSE.icon}
                        </Button>
                      </div>
                    )}{' '}
                  </div>
                );
              })}
          </div>
          <div className="cta">
            <div className="add-filter">
              <Button
                backgroundColor={`${COLORS.DARK_BLUE}`}
                label="+"
                className={`${
                  filters[filters.length - 1].options?.find((option) => option.selected)?.value ===
                  FILTERS_CODES.EMPTY.code
                    ? 'disabled'
                    : ''
                }`}
                disabled={
                  filters[filters.length - 1].options?.find((option) => option.selected)?.value ===
                  FILTERS_CODES.EMPTY.code
                }
                onClick={() => addFilter()}
              />
            </div>
            <div
              className="apply"
              onClick={() => {
                onApplyFilters();
              }}
            >
              <Button theme="light">
                <span>Appliquer les filtres</span>
              </Button>
            </div>
          </div>
        </div>
        <div className="search-by-email-container">
          <TextField
            placeholder="Recherche par adresse email ..."
            value={emailSearch}
            controlled
            onChange={(e) => {
              setEmailSearch(e);
            }}
          />
          <Button
            label="Rechercher"
            onClick={() => {
              onApplyFilters();
            }}
          />
        </div>
        <ExportUsersListToExcel
          onExport={async () => {
            await onExportToExcel();
          }}
        />
        <div className="list">
          {!loadingUserFetch ? (
            <>
              <MultipleColumnsTable
                pagination={currentPagination}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
                columnsTitles={[
                  'Identifiant',
                  'Adresse email',
                  'Souhaitant être contacté',
                  'Souhaitant obtenir une analyse de ses résultats',
                  'Date de création',
                  'Informations détaillées',
                ]}
                rows={[
                  ...users.map((user) => {
                    let wantToBeContacted =
                      !!user?.wantToBeContacted && user?.wantToBeContacted > 0;
                    let wantFullReport = !!user?.wantFullReport && user?.wantFullReport > 0;
                    const creationDate = formatDateString(user.creationDate as string);
                    const isToday = formatDateString(new Date().toISOString()) === creationDate;

                    return {
                      label: `${user?.id}`,
                      values: [
                        <b>{user.id}</b>,
                        <div className="email">
                          {user.email ? (
                            <>
                              {user.email} <Copy text={user.email as string} />
                            </>
                          ) : (
                            '-'
                          )}
                        </div>,
                        <div className="flag-wrapper">
                          <span
                            data-text-success={`Oui`}
                            data-text-fail="Non"
                            className={`${wantToBeContacted && 'true'} flag`}
                          />
                          {/* {wantToBeContacted ? <Copy text={user.email as string} /> : <span />} */}
                        </div>,
                        <div className="flag-wrapper">
                          <span
                            data-text-success={`Oui`}
                            data-text-fail="Non"
                            className={`${wantFullReport && 'true'} flag`}
                          />
                        </div>,
                        <div className="creation-date">
                          {isToday ? "Aujourd'hui" : creationDate}
                        </div>,
                        <Icon
                          className="icon-info"
                          icon={ICONS.REFERENCE_DATAS}
                          onClick={() => {
                            openUserInfos(user);
                          }}
                        />,
                      ],
                    };
                  }),
                ]}
              />
            </>
          ) : (
            <Loader>
              <Progress />
            </Loader>
          )}
        </div>
      </UsersListWrapper>
    </AdminPageLayout>
  );
};

export default UsersList;
