import { NavbarWrapper } from './Navbar.styled';
import Logo from '../../assets/images/logo.png';
import { IIcon, IsViewerOnlyProps } from '../../types/app';
import Icon from '../icon/Icon';
import { ICONS, ROUTES } from '../../global';
import { useContext } from 'react';
import { NavigationContext, NavigationContextProps } from '../../context/NavigationContext';
import { UserContext, UserContextProps } from '../../context/user/UserContext';
import useCustomNavigate from '../../hooks/useCustomNavigate';
import Tag from '../tag/Tag';
import { COLORS } from '../../styles/constants/colors';

export interface NavbarProps extends IsViewerOnlyProps {
  title?: string;
  icon?: IIcon;
  showPdfDownload?: boolean;
}

const Navbar: React.FC<NavbarProps> = ({ icon, title, showPdfDownload, isViewerOnly }) => {
  const { opened, toggleOpen } = useContext(NavigationContext) as NavigationContextProps;
  // const { simulators } = useContext(SimulatorsContext) as SimulatorsContextProps;
  const navigate = useCustomNavigate();
  const { isAdminView } = useContext(UserContext) as UserContextProps;

  return (
    <NavbarWrapper>
      <div
        className="logo"
        tabIndex={0}
        onClick={() =>
          navigate(
            `${isAdminView ? `${ROUTES.ADMIN.INDEX}${ROUTES.ADMIN.USERS.LIST}` : ROUTES.DASHBOARD}`,
          )
        }
      >
        <img src={Logo} alt="" />
      </div>
      <div className="nav" tabIndex={0}>
        <div className="nav-left">
          {icon?.icon && <Icon icon={icon} className="icon" selected />}
          <div className="title">
            {title}{' '}
            {isViewerOnly && (
              <Tag
                backgroundColor={COLORS.LIGHT_BLUE}
                color={COLORS.DARK_BLUE}
                label={
                  <div className="view-mode-tag">
                    <span>Mode vue</span>
                  </div>
                }
              />
            )}
          </div>
        </div>
        {showPdfDownload === true && (
          <div className="download-report-nav" onClick={() => navigate(ROUTES.DETAILED_REPORT)}>
            {!isViewerOnly
              ? 'Télécharger mon rapport détaillé'
              : 'Télécharger son rapport détaillé'}
          </div>
        )}
        <div
          className="nav-right"
          onClick={() => {
            toggleOpen();
          }}
        >
          {opened ? ICONS.MENU_CLOSE.icon : ICONS.MENU_OPEN.icon}
        </div>
      </div>
    </NavbarWrapper>
  );
};

export default Navbar;
