import styled from 'styled-components';
import { COLORS } from '../../../styles/constants/colors';
import { BODY } from '../../../styles/helpers/typography';

const TextFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  position: relative;
  ${BODY.S_REGULAR}

  .required {
    color: red;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: ${COLORS.DARK_BLUE};
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px transparent;
  }

  .input-wrapper {
    background-color: white;
    border: 1px solid ${COLORS.LIGHT_BLUE};
    padding: 12px 16px 12px 16px;
    border-radius: 8px;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    ${BODY.S_REGULAR}

    input {
      outline: none;
      flex: 1;
      border: none;
      color: ${COLORS.DARK_BLUE};
      font-family: JOST;
    }

    .unit {
      right: 20px;
      top: 55%;
      ${BODY.S_SEMI_BOLD}
    }

    &.disabled {
      input {
        background-color: transparent !important;
      }
    }
  }
`;

export { TextFieldWrapper };
