import styled, { CSSProperties, css } from 'styled-components';
import { COLORS } from '../../styles/constants/colors';
import { kebabCase } from '../../utils';
import { BODY } from '../../styles/helpers/typography';

export type CardColor = 'yellow' | 'blue' | 'darkBlue' | 'brightBlue';

export interface CardWrapperProps extends CSSProperties {
  theme?: CardColor;
}

const CardTitle = styled.h2`
  ${BODY.XL_MEDIUM}
  color: ${COLORS.DARK_BLUE};
  margin: 0;
  padding: 25px 25px 0 25px;
  border-radius: 20px 20px 0 0;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const CardWrapper = styled.div<{ styles?: CardWrapperProps }>`
  width: auto;
  min-height: 270px;
  max-width: 100%;
  position: relative;
  border: 1px solid ${COLORS.LIGHT_BLUE};
  border-radius: 20px;

  ${({ styles }) =>
    styles &&
    css`
      ${styles.theme === 'yellow' &&
      `
        border: 1px solid ${COLORS.GREEN}90;
        background: linear-gradient(0deg, rgb(198 210 0 / 0%), rgb(198 210 0 / 0%)),linear-gradient(40deg, rgb(198 210 0 / 60%) -84.72%, rgba(255, 255, 255, 0) 68.57%),linear-gradient(0deg, #FFFFFF, #FFFFFF);
    `}
      ${styles.theme === 'darkBlue' &&
      `
        border: none;
        background: ${COLORS.DARK_BLUE};
        color: ${COLORS.WHITE};
    `}
    ${styles.theme === 'brightBlue' &&
      `
        border: none;
        background: ${COLORS.BRIGHT_BLUE};
        color: ${COLORS.WHITE};
    `}
    `}

  ${({ ...rest }: any) => `
  ${Object.keys(rest.styles)
    .map((key) => `${kebabCase(key)}: ${rest.styles[key]};`)
    .join('\n')}
  `}

  .card-children {
    padding: 0 24px 24px 24px;
  }
`;

export { CardTitle, CardWrapper };
