import { useContext } from 'react';
import { ISimulator } from '../../../../types/app';
import SimulatorLayout from '../../layout/SimulatorLayout';
import { HighPerformanceEnginesWrapper } from './HighPerformanceEngines.styled';
import {
  HighPerformanceEnginesContext,
  HightPerformanceEnginesContextProps,
} from '../../../../context/simulators/HighPerformanceEnginesContext';

export interface HighPerformanceEnginesProps {
  simulator: ISimulator;
}

const HighPerformanceEngines: React.FC<HighPerformanceEnginesProps> = ({ simulator }) => {
  const { forms, resultTableRows, resultCharts } = useContext(
    HighPerformanceEnginesContext,
  ) as HightPerformanceEnginesContextProps;

  return (
    <HighPerformanceEnginesWrapper>
      <SimulatorLayout
        simulator={simulator}
        forms={forms}
        resultTableRows={resultTableRows}
        resultCharts={resultCharts}
      />
    </HighPerformanceEnginesWrapper>
  );
};

export default HighPerformanceEngines;
