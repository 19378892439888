import styled from 'styled-components';
import { BREAKPOINTS } from '../../../styles/constants/devices';

const SimulatorFormsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .reference-datas-form {
    .form-fields {
      @media (${BREAKPOINTS.S}) {
        display: flex;
      }

      @media (${BREAKPOINTS.XXL}) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-flow: row;
      }
    }
  }
`;

export { SimulatorFormsWrapper };
