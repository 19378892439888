import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { ITableRow, TableProps } from '../../../../components/table/Table';
import { COLORS } from '../../../../styles/constants/colors';
import React, { useEffect, useState } from 'react';

const styles = StyleSheet.create({
  table: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
  },
  tableColumn: {
    display: 'flex',
    flexDirection: 'row',
    gap: 15,
    alignItems: 'center',
    borderTop: `1px solid ${COLORS.LIGHT_BLUE}`,
    borderLeft: `1px solid ${COLORS.LIGHT_BLUE}`,
    borderRight: `1px solid ${COLORS.LIGHT_BLUE}`,
  },
  tableRow: {
    width: '45%',
    fontSize: 10,
    padding: 12,
  },
  highlightTableColumn: {
    display: 'flex',
    flexDirection: 'row',
    gap: 15,
    alignItems: 'center',
    border: `1px solid ${COLORS.DARK_BLUE}`,
    backgroundColor: COLORS.DARK_BLUE,
    color: COLORS.WHITE,
  },
  highlightTableRow: {
    width: '45%',
    fontWeight: 'bold',
    fontSize: 10,
    padding: 12,
  },
});

export interface PdfTableProps extends TableProps {
  highlightRows?: ITableRow[];
}

const PdfTable: React.FC<PdfTableProps> = ({ rows, header, highlightRows }) => {
  const [highlightRowsState, setHighlightRowsState] = useState<ITableRow[]>([]);

  useEffect(() => {
    highlightRows &&
      setHighlightRowsState(highlightRows.filter((row) => row.label && row.label !== ''));
  }, [highlightRows]);

  return (
    <View style={styles.table}>
      {header && (
        <View
          style={{
            ...styles.tableColumn,
            padding: 10,
            color: COLORS.DARK_BLUE,
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
          }}
        >
          <Text
            style={{
              width: 20,
              height: 20,
              backgroundColor: COLORS.BRIGHT_BLUE,
              borderRadius: 5,
            }}
          />
          <Text
            style={{
              fontSize: 12,
            }}
          >
            {header}
          </Text>
        </View>
      )}
      {rows &&
        rows.map((row, key) => {
          return (
            <View
              key={key}
              style={{
                ...styles.tableColumn,
                ...(key === rows.length - 1 && {
                  borderBottom: `1px solid ${COLORS.LIGHT_BLUE}`,
                  ...((!highlightRowsState || highlightRowsState.length <= 0) && {
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                  }),
                }),
              }}
            >
              <Text style={styles.tableRow}>{row.label}</Text>
              <Text
                style={{
                  ...styles.tableRow,
                  borderLeft: `1px solid ${COLORS.LIGHT_BLUE}`,
                }}
              >
                {Number(Math.round(Number(row.value) * 100) / 100).toLocaleString('fr')} {row.unit}
              </Text>
            </View>
          );
        })}
      {highlightRowsState &&
        highlightRowsState.map((highlightedRow, key) => {
          return (
            <React.Fragment key={key}>
              {highlightedRow.label && highlightedRow.unit && highlightedRow.value ? (
                <View
                  key={key}
                  style={{
                    ...styles.highlightTableColumn,
                    ...(key === highlightRowsState.length - 1 && {
                      borderBottomLeftRadius: 16,
                      borderBottomRightRadius: 16,
                    }),
                  }}
                >
                  <Text style={styles.highlightTableRow}>{highlightedRow.label}</Text>
                  <Text
                    style={{
                      ...styles.highlightTableRow,
                      borderLeft: `1px solid ${COLORS.LIGHT_BLUE}`,
                    }}
                  >
                    {Number(Math.round(Number(highlightedRow.value) * 100) / 100).toLocaleString(
                      'fr',
                    )}{' '}
                    {highlightedRow.unit}
                  </Text>
                </View>
              ) : (
                <></>
              )}
            </React.Fragment>
          );
        })}
    </View>
  );
};

export default PdfTable;
