import styled from 'styled-components';
import { COLORS } from '../../styles/constants/colors';

const SwitcherWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  .toggle-switch {
    position: relative;
    width: 60px;
    height: 25px;
    background-color: ${COLORS.BRIGHT_BLUE};
    border-radius: 40px;
    padding: 1px;
    transition: 0.1s linear;
    cursor: pointer;

    .switch-ball {
      height: 100%;
      width: 45%;
      border-radius: 50%;
      transform: translateX(120%);
      background-color: ${COLORS.WHITE};
      transition: 0.1s linear;
    }

    &.disabled {
      background-color: ${COLORS.BRIGHT_BLUE}25;

      .switch-ball {
        transform: translateX(2%);
      }
    }
  }

  .switch-label {
    color: ${COLORS.DARK_BLUE};
  }
`;

export { SwitcherWrapper };
