import { useNavigate, useLocation } from 'react-router-dom';

const useCustomNavigate = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const customNavigate = (targetRoute: string) => {
    const queryParams = new URLSearchParams(location.search);
    const newRouteWithQuery = `${targetRoute}?${queryParams.toString()}`;

    navigate(newRouteWithQuery);
  };

  return customNavigate;
};

export default useCustomNavigate;
