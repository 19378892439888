import styled from 'styled-components';
import { COLORS } from '../../../styles/constants/colors';
import { BREAKPOINTS } from '../../../styles/constants/devices';
import { BODY } from '../../../styles/helpers/typography';

const NavDetailedReportWrapper = styled.div`
  position: fixed;
  border-bottom: 1px solid #e6eaee;
  top: 0;
  left: 0;
  right: 0;
  min-height: 60px;
  display: flex;
  z-index: 1000;
  padding: 0 20px;
  background-color: ${COLORS.WHITE};

  .logo-wrapper {
    width: 60px;
    display: flex;
    align-items: center;
    padding-right: 15px;
    margin-right: 20px;

    @media (${BREAKPOINTS.L}) {
      border-right: 1px solid #0028541a;
    }
  }

  .logo {
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    padding-right: 10px;

    &:hover {
      cursor: pointer;
    }

    img {
      width: 40px;
    }
  }

  .nav-left {
    display: flex;
    gap: 20px;
    align-items: center;
    color: ${COLORS.DARK_BLUE};

    .results {
      display: flex;
      align-items: center;
      gap: 20px;
      flex-wrap: wrap;
      padding: 20px 0;

      @media (${BREAKPOINTS.S}) {
        display: none;
      }

      @media (${BREAKPOINTS.L}) {
        display: flex;
      }

      .title {
        ${BODY.XL_MEDIUM}
      }

      .gain {
        display: flex;
        align-items: center;
        gap: 10px;
        ${BODY.M_MEDIUM}

        @media (${BREAKPOINTS.M}) {
          font-size: 15px;
        }
        .icon {
          display: flex;
          align-items: center;
          color: ${COLORS.GREEN};
        }
      }
    }
  }

  .nav-right {
    .icon {
      cursor: pointer;
      position: absolute;
      right: 15px;
      top: 50%;
      width: 35px;
      height: 35px;
      transform: translateY(-50%);
      transition: 0.2s linear;
      &:hover {
        color: ${COLORS.BRIGHT_BLUE};
      }

      svg {
        width: 35px;
        height: 35px;
      }
    }
  }
`;

export { NavDetailedReportWrapper };
