import { ICONS } from '../../global';
import { IButtonTheme, IComponentWithChildren } from '../../types/app';
import { ButtonWrapper } from './Button.styled';

export interface ButtonProps extends IComponentWithChildren {
  hasIcon?: boolean;
  className?: string;
  theme?: IButtonTheme;
  color?: string;
  backgroundColor?: string;
  label?: string;
  onClick?: () => void;
  disabled?: boolean;
  icon?: any;
}

const Button: React.FC<ButtonProps> = ({
  label,
  className,
  theme,
  color,
  backgroundColor,
  onClick,
  hasIcon,
  children,
  disabled,
  icon,
}) => {
  return (
    <ButtonWrapper
      onClick={() => onClick && onClick()}
      color={color}
      backgroundcolor={backgroundColor}
      className={className}
      theme={theme}
      hasicon={hasIcon ? 'true' : ''}
      disabled={disabled}
    >
      {children ? (
        children
      ) : (
        <>
          {/* {leftIcon && <span className="button-icon button-icon-left">{leftIcon}</span>} */}
          <span className="button-label">{label}</span>
          {hasIcon && <span className="button-icon">{icon ? icon : ICONS.ARROW.icon}</span>}
          {/* {rightIcon && <span className="button-icon button-icon-right">{rightIcon}</span>} */}
        </>
      )}
    </ButtonWrapper>
  );
};

export default Button;
