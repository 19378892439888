import TableWrapper from './Tables.styled';

export interface ITableRow {
  label: string;
  value?: string;
  unit?: string;
}

export interface TableProps {
  rows: Array<ITableRow>;
  header?: any;
  highlightRow?: ITableRow;
}

const Table: React.FC<TableProps> = ({ rows, header, highlightRow }) => {
  return (
    <TableWrapper>
      {header && (
        <thead>
          <tr>
            <th colSpan={2}>{header}</th>
          </tr>
        </thead>
      )}
      {rows && (
        <tbody>
          {rows.map((row, key) => {
            return (
              <tr
                key={key}
                className={`${key === rows.length - 1 && !highlightRow ? 'last-tr' : ''}`}
              >
                <td className="left-td">{row.label}</td>
                <td className="right-td">
                  {row.value &&
                    !isNaN(Number(row.value)) &&
                    `${(Math.round(Number(row.value) * 100) / 100).toLocaleString('fr')} ${
                      row.unit
                    }`}
                </td>
              </tr>
            );
          })}
          {highlightRow && (
            <tr className="highlight last-tr">
              <td className="left-td">{highlightRow.label}</td>
              <td className="right-td">
                {highlightRow.value &&
                  !isNaN(Number(highlightRow.value)) &&
                  `${(Math.round(Number(highlightRow.value) * 100) / 100).toLocaleString('fr')} ${
                    highlightRow.unit
                  }`}
              </td>
            </tr>
          )}
        </tbody>
      )}
    </TableWrapper>
  );
};

export default Table;
