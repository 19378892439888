import styled from 'styled-components';
import { BREAKPOINTS } from '../../../styles/constants/devices';

const AdminPageLayoutWrapper = styled.div`
  height: 100%;
  width: 100%;

  .page {
    padding-top: 63px;
    min-height: 100vh;
    background-color: #f9fcff;
    overflow: auto;

    @media (${BREAKPOINTS.S}) {
      padding-left: 20px;
      padding-right: 20px;
    }

    @media (${BREAKPOINTS.L}) {
      padding-left: 125px;
      padding-top: 63px;
      padding-right: 0;
    }
  }
`;

export { AdminPageLayoutWrapper };
