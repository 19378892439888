import styled from 'styled-components';
import { H3_SEMI_BOLD } from '../../../styles/helpers/typography';
import { COLORS } from '../../../styles/constants/colors';

const DashboardReferenceInformationsWrapper = styled.div`
  border-radius: 8px;
  padding: 32px 24px 32px 24px;
  background: linear-gradient(0deg, #0084ed, #0084edb0),
    linear-gradient(180deg, rgba(204, 228, 255, 0.4) 0%, rgba(204, 228, 255, 0) 86.24%);
  color: ${COLORS.WHITE};

  .title {
    ${H3_SEMI_BOLD}
    margin-bottom: 20px;
  }

  .informations {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

export { DashboardReferenceInformationsWrapper };
