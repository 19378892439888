import Button from '../../../components/button/Button';
import { ISimulator } from '../../../types/app';
import { SimulatorBrowseAxeWrapper } from './SimulatorBrowseAxe.styled';
import { ROUTES } from '../../../global';
import useCustomNavigate from '../../../hooks/useCustomNavigate';
import { useContext } from 'react';
import { UserContext, UserContextProps } from '../../../context/user/UserContext';

export interface SimulatorBrowseAxeProps {
  onClick?: () => void;
  previousSimulator?: ISimulator;
  nextSimulator?: ISimulator;
  disabled?: boolean;
}
const SimulatorBrowseAxe: React.FC<SimulatorBrowseAxeProps> = ({
  onClick,
  nextSimulator,
  previousSimulator,
  disabled,
}) => {
  const navigate = useCustomNavigate();
  const { user } = useContext(UserContext) as UserContextProps;

  return (
    <SimulatorBrowseAxeWrapper>
      {previousSimulator && (
        <div className="previous-axe-cta cta">
          <Button
            label="Axe précedent"
            theme="transparent"
            onClick={() => {
              navigate(previousSimulator?.route);
            }}
            disabled={disabled}
          />
        </div>
      )}
      {nextSimulator ? (
        <div className="next-axe-cta cta">
          <Button
            label="Axe suivant"
            theme="light"
            onClick={() => {
              navigate(nextSimulator?.route);
            }}
            disabled={disabled}
          />
        </div>
      ) : (
        <div className="">
          <Button
            label={`${
              !user?.details?.isViewerOnly
                ? 'Vers mon rapport détaillé'
                : 'Vers son rapport détaillé'
            }`}
            theme="light"
            onClick={() => {
              navigate(ROUTES.DETAILED_REPORT);
            }}
            disabled={disabled}
          />
        </div>
      )}
    </SimulatorBrowseAxeWrapper>
  );
};

export default SimulatorBrowseAxe;
