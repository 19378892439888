import styled from 'styled-components';
import { COLORS } from '../../styles/constants/colors';
import { BODY } from '../../styles/helpers/typography';

const NotificationWrapper = styled.div`
  border: 1px solid grey;
  padding: 12px 16px 12px 16px;
  border-radius: 6px;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  ${BODY.S_SEMI_BOLD}


  &.info {
    border-color: ${COLORS.INFO};
    color: ${COLORS.INFO};
  }

  &.warning {
    border-color: ${COLORS.WARNING};
    color: ${COLORS.WARNING};
  }

  .message {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .close-icon {
    width: 20px;
    height: 20px;
    display: flex;

    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export { NotificationWrapper };
