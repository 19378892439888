import Switcher from '../../../components/switcher/Switcher';
import { FiltersDetailedReportType } from '../DetailedReport';
import { FiltersDetailedReportWrapper } from './FiltersDetailedReport.styled';

export interface FiltersDetailedReportProps {
  filters: {
    label: string;
    name: FiltersDetailedReportType;
  }[];
  currentFilter: FiltersDetailedReportType;
  onChange: (e: FiltersDetailedReportType) => void;
  hideGraphs?: boolean;
  onToggleGraphsView?: (e: boolean) => void;
}

const FiltersDetailedReport: React.FC<FiltersDetailedReportProps> = ({
  filters,
  currentFilter,
  onChange,
  hideGraphs,
  onToggleGraphsView,
}) => {
  return (
    <FiltersDetailedReportWrapper>
      <div className="filters">
        {filters &&
          filters.map((filter, key) => {
            return (
              <div
                key={key}
                className={`filter ${filter.name === currentFilter ? 'active' : ''}`}
                onClick={() => {
                  onChange && onChange(filter.name);
                }}
              >
                {filter.label}
              </div>
            );
          })}
      </div>
      <Switcher
        label="Masquer les graphiques"
        disabled={hideGraphs}
        onSwitch={() => {
          onToggleGraphsView && onToggleGraphsView(!hideGraphs);
        }}
      />
    </FiltersDetailedReportWrapper>
  );
};

export default FiltersDetailedReport;
