import styled from 'styled-components';
import { COLORS } from '../../styles/constants/colors';
import { BODY, H2, H3_SEMI_BOLD } from '../../styles/helpers/typography';
import { BREAKPOINTS } from '../../styles/constants/devices';

const LandingWrapper = styled.div`
  position: relative;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: ${COLORS.WHITE};
  // padding: 40px 30px;
  display: flex;
  width: 95%;
  margin: 10px auto;
  gap: 20px;
  flex-direction: column;

  .landing-left {
    position: relative;
    display: flex;
    background-color: ${COLORS.LIGHT_BLUE}50;
    flex-direction: column;
    height: 50vh;
    align-items: start;
    padding: 50px;
    max-width: 100%;
    border-radius: 30px;
    gap: 10vh;

    .logo {
      display: flex;
      align-items: end;

      img {
        &.logo-img {
          width: 60px;
          height: 40px;
        }
        &.logo-txt {
          width: 175px;
          max-width: 100%;
          padding-bottom: 5px;
        }
      }
    }

    .title {
      ${H3_SEMI_BOLD}
      max-width: 100%;
      color: ${COLORS.DARK_BLUE};

      .description {
        ${BODY.M_REGULAR}
      }
      z-index: 2;
    }

    .illustration {
      position: absolute;
      bottom: 0;
      right: 0;
      display: flex;
      justify-content: end;
      width: 100%;

      img {
        width: 50%;
        min-width: 200px;
        max-width: 400px;
      }
    }
  }

  @media screen and (${BREAKPOINTS.L}) {
    padding: 40px 30px;
    flex-direction: row;

    .landing-left {
      width: 45%;
      gap: 11vh;
      height: 75vh;

      .title {
        ${H2}
        max-width: 60%;
      }

      .illustration {
        img {
          width: 100%;
          max-width: 400px;
        }
      }
    }
  }

  @media screen and (${BREAKPOINTS.XXL}) {
    .landing-left {
      width: 45%;
      gap: 13vh;

      .illustration {
        img {
          max-width: 455px;
        }
      }
    }
  }

  .landing-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 10px;

    @media screen and (${BREAKPOINTS.L}) {
      padding: 50px 50px 50px 50px;
    }

    @media screen and (${BREAKPOINTS.XXL}) {
      padding: 100px 50px 50px 50px;
    }

    .numerated-list {
      counter-reset: list-number;
      position: relative;

      .item {
        display: flex;
        gap: 22px;
        align-items: center;
        position: relative;
        flex: 1;

        &:before {
          counter-increment: list-number;
          content: counter(list-number);
          min-width: 48px;
          min-height: 48px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background-color: #0178d6;
          border-radius: 50%;
          color: ${COLORS.WHITE};
          z-index: 2;
        }

        .title {
          ${BODY.XL_MEDIUM}
        }

        .description {
          ${BODY.M_REGULAR}
        }
      }
    }
  }

  .start-simulation-cta {
    width: max-content;

    .button-icon {
      color: #014289;
    }
  }

  @media screen and (max-width: 425px) {
    .start-simulation-cta {
      width: 100%;
    }
  }
`;

// div.numerated-list div:before {
//   counter-increment: list-number;
//   content: counter(list-number);
//   margin-right: 10px;
//   width: 35px;
//   height: 35px;
//   display: inline-flex;
//   align-items: center;
//   justify-content: center;
//   background-color: #0178d6;
//   border-radius: 50%;
//   color: ${COLORS.WHITE};
// }
export { LandingWrapper };
