import styled from 'styled-components';
import { ChartWrapper } from '../../../../components/chart/Chart.styled';
import { FormTitle } from '../../../../components/form/Form.styled';

const ReferenceDatasForm = styled.div`
  ${FormTitle} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    .actions {
      display: flex;
      gap: 15px;
    }
  }
`;

const IndustrialSiteWrapper = styled.div`
  ${ChartWrapper} {
  }
`;

export { IndustrialSiteWrapper, ReferenceDatasForm };
