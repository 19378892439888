import { useContext } from 'react';
import { ISimulator } from '../../../../types/app';
import SimulatorLayout from '../../layout/SimulatorLayout';
import { ElectricSpeedVariationWrapper } from './ElectricSpeedVariation.styled';
import {
  ElectricSpeedVariationContext,
  ElectricSpeedVariationContextProps,
} from '../../../../context/simulators/ElectricSpeedVariationContext';

export interface ElectricSpeedVariationProps {
  simulator: ISimulator;
}

const ElectricSpeedVariation: React.FC<ElectricSpeedVariationProps> = ({ simulator }) => {
  const { forms, resultTableRows, resultCharts } = useContext(
    ElectricSpeedVariationContext,
  ) as ElectricSpeedVariationContextProps;

  return (
    <ElectricSpeedVariationWrapper>
      <SimulatorLayout
        simulator={simulator}
        forms={forms}
        resultTableRows={resultTableRows}
        resultCharts={resultCharts}
      />
    </ElectricSpeedVariationWrapper>
  );
};

export default ElectricSpeedVariation;
