import { useEffect } from 'react';
import { ViewWrapper } from './View.styled';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../global';
import { UserApi } from '../../api/User.api';
import { getViewOnlyUserTokenFromLocalstorage } from '../../utils';

const View: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('vt');
    const userId = queryParams.get('uid');
    if (!token && !userId) {
      window.location.href = ROUTES.HOME;
      return;
    }
    UserApi.generateViewOnlyUserToken(userId as string, token as string)
      .then((res) => {
        if (!res) throw new Error('Token not defined');
        localStorage.setItem('tmpSkipIntro', localStorage.getItem('skipIntro') || '0');
        localStorage.setItem('skipIntro', '1');
        localStorage.setItem(getViewOnlyUserTokenFromLocalstorage(), res);
        window.location.href = ROUTES.HOME;
      })
      .catch((err) => {
        console.log(err);
        navigate(ROUTES.HOME);
      });
  }, [location, navigate]);

  return <ViewWrapper></ViewWrapper>;
};

export default View;
