import styled from 'styled-components';
import { BODY } from '../../../../../styles/helpers/typography';
import { COLORS } from '../../../../../styles/constants/colors';

const PopupSuccessContactWrapper = styled.div`
  .body {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    height: 100%;
    text-align: center;
    color: ${COLORS.DARK_BLUE};
    z-index: 2;

    .photo {
      position: relative;
      width: max-content;

      img {
        &.olivier {
          width: 110px;
        }
      }
      .icon-check {
        position: absolute;
        top: -10px;
        right: -10px;
      }
    }
    .small-title {
      ${BODY.M_REGULAR}
      max-width: 50ch;
      text-align: start;
    }
  }
`;

export { PopupSuccessContactWrapper };
