import { createContext, useContext, useEffect, useState } from 'react';
import { IComponentWithChildren, IForm, ISimulatorRequiredDatas } from '../../types/app';
import { ITableRow } from '../../components/table/Table';
import { customRoundDown, getValueFrom2DMatrix } from '../../utils';
import { ReferenceDatasContext, ReferenceDatasContextProps } from '../ReferenceDatasContext';
import { ChartProps } from '../../components/chart/Chart';
import { COLORS } from '../../styles/constants/colors';
import { FormTitle } from '../../components/form/Form.styled';
import { SimulatorsContext, SimulatorsContextProps } from './SimulatorsContext';
import { UserContext, UserContextProps } from '../user/UserContext';

import useDelayedUpdate from '../../hooks/useDelayedUpdate';

export interface InsulatingMattressesContextProps extends ISimulatorRequiredDatas {
  naturalGasConsumedForHeatProduction: number;
  numberOfMattressesToBeInstalled: number;
  averageDNOfMattressesToBeInstalled: number;
  averageNetworkTemperature: number;
  forms: IForm.IForm[];

  reductionOfEnergyConsumption: number;
  co2EmissionsAvoided: number;
  estimatedFinancialGain: number;
  roi: number;
  estimatedBudget: number;
  resultTableRows: ITableRow[];

  dnMatrix: number[][];
  temperatureCoefficientMatrix: number[][];
  actionMatrix: number[][];
  roiMatrix: number[][];
  resultCharts: ChartProps[][];
}

const InsulatingMattressesContext = createContext<InsulatingMattressesContextProps | undefined>(
  undefined,
);

const InsulatingMattressesProvider: React.FC<IComponentWithChildren> = ({ children }) => {
  const { CARBON_CONTENT_PER_KWH_GAZ, AVERAGE_GAS_PRICE_PER_MWH, NATURAL_GAS_TRENDS } = useContext(
    ReferenceDatasContext,
  ) as ReferenceDatasContextProps;
  const { getSimulatorByCode, simulators, editSimulatorDatas } = useContext(
    SimulatorsContext,
  ) as SimulatorsContextProps;
  const { userToken, updateUserSimulatorDatas } = useContext(UserContext) as UserContextProps;
  const [naturalGasConsumedForHeatProduction, setNaturalGasConsumedForHeatProduction] =
    useState<number>(0);
  const [numberOfMattressesToBeInstalled, setNumberOfMattressesToBeInstalled] = useState<number>(0);
  const [averageDNOfMattressesToBeInstalled, setAverageDNOfMattressesToBeInstalled] =
    useState<number>(0);
  const [averageNetworkTemperature, setAverageNetworkTemperature] = useState<number>(0);
  const [forms, setForms] = useState<IForm.IForm[]>([]);

  const [reductionOfEnergyConsumption, setReductionOfEnergyConsumption] = useState<number>(0);
  const [co2EmissionsAvoided, setCo2EmissionsAvoided] = useState<number>(0);
  const [estimatedFinancialGain, setEstimatedFinancialGain] = useState<number>(0);
  const [roi, setRoi] = useState<number>(0);
  const [estimatedBudget, setEstimatedBudget] = useState<number>(0);
  const [resultTableRows, setResultTableRows] = useState<ITableRow[]>([]);

  const [dnMatrix, setDnMatrix] = useState<number[][]>([]);
  const [temperatureCoefficientMatrix, setTemperatureCoefficientMatrix] = useState<number[][]>([]);

  const [actionMatrix, setActionMatrix] = useState<number[][]>([]);
  const [roiMatrix, setRoiMatrix] = useState<number[][]>([]);
  const [resultCharts, setResultCharts] = useState<ChartProps[][]>([]);
  const [updateDatas, setUpdateDatas] = useState<object>({});

  // reduction of energy consumption
  useEffect(() => {
    let dnValue = getValueFrom2DMatrix(dnMatrix, 0, 1, averageDNOfMattressesToBeInstalled);
    let temperatureCoefficientValue = getValueFrom2DMatrix(
      temperatureCoefficientMatrix,
      0,
      1,
      averageNetworkTemperature,
    );
    setReductionOfEnergyConsumption(
      numberOfMattressesToBeInstalled * dnValue * temperatureCoefficientValue * 8,
    );
  }, [
    numberOfMattressesToBeInstalled,
    averageDNOfMattressesToBeInstalled,
    averageNetworkTemperature,
    dnMatrix,
    temperatureCoefficientMatrix,
  ]);

  // co2 emissions avoided
  useEffect(() => {
    setCo2EmissionsAvoided(reductionOfEnergyConsumption * CARBON_CONTENT_PER_KWH_GAZ?.value);
  }, [CARBON_CONTENT_PER_KWH_GAZ?.value, reductionOfEnergyConsumption]);

  // estimated financial gain
  useEffect(() => {
    setEstimatedFinancialGain(
      customRoundDown(reductionOfEnergyConsumption * AVERAGE_GAS_PRICE_PER_MWH?.value, -2),
    );
  }, [reductionOfEnergyConsumption, AVERAGE_GAS_PRICE_PER_MWH?.value]);

  // roi
  useEffect(() => {
    setRoi(estimatedBudget / estimatedFinancialGain);
  }, [estimatedFinancialGain, estimatedBudget]);

  // estimated budget
  useEffect(() => {
    setEstimatedBudget(
      5000 + (numberOfMattressesToBeInstalled * 275 * averageDNOfMattressesToBeInstalled) / 50,
    );
  }, [numberOfMattressesToBeInstalled, averageDNOfMattressesToBeInstalled]);

  // DN matrix
  useEffect(() => {
    setDnMatrix([
      [25, 50, 80, 100, 150],
      [0.5, 1, 2, 3, 3.57],
    ]);
  }, []);

  // temperature coefficient matrix
  useEffect(() => {
    setTemperatureCoefficientMatrix([
      [60, 80, 100, 120, 140, 160, 180, 200],
      [0.04, 0.09, 0.16, 0.25333, 0.346666, 0.44, 0.63, 0.79],
    ]);
  }, []);

  // action matrix
  useEffect(() => {
    let tmpMatrix: number[][] = [
      [0, 0],
      [0, 0],
      [0, 0],
      [0, 0],
    ];

    tmpMatrix[0] = [
      naturalGasConsumedForHeatProduction,
      naturalGasConsumedForHeatProduction * CARBON_CONTENT_PER_KWH_GAZ?.value,
    ];
    tmpMatrix[1] = [reductionOfEnergyConsumption, co2EmissionsAvoided / 1000];
    tmpMatrix[2] = [tmpMatrix[0][0] - tmpMatrix[1][0], tmpMatrix[0][1] - tmpMatrix[1][1]];
    tmpMatrix[3] = [tmpMatrix[1][0], tmpMatrix[1][1]];

    setActionMatrix(tmpMatrix);
  }, [
    naturalGasConsumedForHeatProduction,
    CARBON_CONTENT_PER_KWH_GAZ?.value,
    reductionOfEnergyConsumption,
    co2EmissionsAvoided,
  ]);

  // roi matrix
  useEffect(() => {
    const investment = -estimatedBudget;
    const initialRealizedEconomy = 0;
    let tmpMatrix: number[][] = [
      [initialRealizedEconomy, estimatedFinancialGain],
      [
        investment + initialRealizedEconomy,
        investment + initialRealizedEconomy + estimatedFinancialGain,
      ],
    ];
    const yearsCount = 7;
    for (let i = 2; i < yearsCount; i++) {
      tmpMatrix[0] = [...tmpMatrix[0], tmpMatrix[0][i - 1] * (1 + NATURAL_GAS_TRENDS?.value / 100)];
      tmpMatrix[1] = [...tmpMatrix[1], tmpMatrix[1][i - 1] + tmpMatrix[0][i]];
    }
    setRoiMatrix(tmpMatrix);
  }, [estimatedBudget, estimatedFinancialGain, NATURAL_GAS_TRENDS?.value]);

  const [allowRequest, setAllowRequest] = useState<boolean>(false);
  // Forms
  useEffect(() => {
    setForms([
      {
        onChange: () => setAllowRequest(true),
        header: <FormTitle>Remplissez les champs suivants :</FormTitle>,
        fields: [
          {
            label: 'Gaz naturel consommé pour la production de chaleur',
            name: '',
            type: 'textfield',
            textType: 'number',
            value: naturalGasConsumedForHeatProduction,
            unit: 'MWh/an',
            onChange: (e) => setNaturalGasConsumedForHeatProduction(Number(e)),
          },
          {
            label: 'Nombre de matelas à Installer / de points singuliers',
            name: '',
            type: 'textfield',
            textType: 'number',
            value: numberOfMattressesToBeInstalled,
            unit: '',
            onChange: (e) => setNumberOfMattressesToBeInstalled(Number(e)),
          },
          {
            label: 'DN moyen des matelas à Installer',
            name: '',
            type: 'selectbox',
            value: averageDNOfMattressesToBeInstalled,
            unit: 'DN',
            options: [
              ...[25, 50, 80, 100, 150].map((number) => {
                return {
                  label: `${number}`,
                  selected: `${averageDNOfMattressesToBeInstalled}` === `${number}`,
                  value: number,
                };
              }),
            ],
            onChange: (e) =>
              setAverageDNOfMattressesToBeInstalled(
                e?.find((val: any) => val?.selected === true)?.value,
              ),
          },
          {
            label: 'Température moyenne du réseau',
            name: '',
            type: 'textfield',
            textType: 'number',
            value: averageNetworkTemperature,
            unit: '°C',
            onChange: (e) => setAverageNetworkTemperature(Number(e)),
          },
        ],
      },
    ]);
  }, [
    naturalGasConsumedForHeatProduction,
    numberOfMattressesToBeInstalled,
    averageDNOfMattressesToBeInstalled,
    averageNetworkTemperature,
  ]);

  // result table rows
  useEffect(() => {
    setResultTableRows([
      {
        label: 'Réduction de consommation énergétique',
        unit: 'MWh/an',
        value: `${reductionOfEnergyConsumption}`,
      },
      {
        label: 'Emissions de CO2 évitées',
        unit: 'T CO2/an',
        value: `${co2EmissionsAvoided}`,
      },
      {
        label: 'Gain financier estimé (pour un chauffage au gaz naturel',
        unit: '€/an',
        value: `${estimatedFinancialGain}`,
      },
      {
        label: 'Enveloppe budgétaire estimée',
        unit: '€',
        value: `${estimatedBudget}`,
      },
      {
        label: 'ROI pour cette action (le ROI n’inclut pas les primes et aides (CEE,…))',
        unit: 'ans',
        value: `${roi}`,
      },
    ]);
  }, [
    reductionOfEnergyConsumption,
    co2EmissionsAvoided,
    estimatedFinancialGain,
    roi,
    estimatedBudget,
  ]);

  // Result charts
  useEffect(() => {
    setResultCharts([
      [
        {
          chartType: 'bar',
          chartTitle: 'Economies et émissions évitées',
          labels: ['Etat final', "Réalisation de l'action"],
          labelsColors: [COLORS.WHITE, COLORS.WHITE],
          labelsBackgroundColors: [COLORS.BRIGHT_BLUE, COLORS.DARK_BLUE],
          datas: {
            labels: ['Energie (MWh)', 'CO2 (tonnes)'],
            datasets: [
              {
                label: '',
                data: actionMatrix[2],
                backgroundColor: [COLORS.BRIGHT_BLUE],
                maxBarThickness: 50,
              },
              {
                label: '',
                data: actionMatrix[3],
                backgroundColor: [COLORS.DARK_BLUE],
                maxBarThickness: 50,
              },
            ],
          },
          options: {
            responsive: true,
            scales: {
              x: {
                stacked: true,
              },
              y: {
                stacked: true,
              },
            },
          },
        },
      ],
      [
        {
          chartType: 'bar',
          chartTitle: 'Retour sur investissement',
          datas: {
            labels: roiMatrix[1] && [...roiMatrix[1]?.map((roi, index) => `Année ${index}`)],
            datasets: [
              {
                data: roiMatrix[1],
                backgroundColor:
                  roiMatrix[1] &&
                  roiMatrix[1]?.map(
                    (roi, index) => `${roi < 0 ? COLORS.LIGHT_BLUE : COLORS.BRIGHT_BLUE}`,
                  ),
              },
            ],
          },
          defaultUnit: '€',
          yUnit: '€',
          options: {
            responsive: true,
          },
        },
      ],
    ]);
  }, [actionMatrix, roiMatrix]);

  useEffect(() => {
    const sim = getSimulatorByCode('INSULATING_MATTRESSES');
    if (sim) {
      setNaturalGasConsumedForHeatProduction(
        Number(sim?.datas?.naturalGasConsumedForHeatProduction),
      );
      setNumberOfMattressesToBeInstalled(Number(sim?.datas?.numberOfMattressesToBeInstalled));
      setAverageDNOfMattressesToBeInstalled(Number(sim?.datas?.averageDNOfMattressesToBeInstalled));
      setAverageNetworkTemperature(Number(sim?.datas?.averageNetworkTemperature));
    }
    // eslint-disable-next-line
  }, [simulators]);

  useEffect(() => {
    if (userToken) {
      const newDatas = {
        naturalGasConsumedForHeatProduction,
        numberOfMattressesToBeInstalled,
        averageDNOfMattressesToBeInstalled,
        averageNetworkTemperature,
      };

      setUpdateDatas(newDatas);
    }
  }, [
    userToken,
    naturalGasConsumedForHeatProduction,
    numberOfMattressesToBeInstalled,
    averageDNOfMattressesToBeInstalled,
    averageNetworkTemperature,
    reductionOfEnergyConsumption,
    estimatedFinancialGain,
    co2EmissionsAvoided,
  ]);

  const updateUserSimulatorDatasFunc = () => {
    // Your logic to update the user simulator datas
    allowRequest &&
      updateDatas &&
      updateUserSimulatorDatas(userToken, 'INSULATING_MATTRESSES', updateDatas).then(() => {
        editSimulatorDatas('INSULATING_MATTRESSES', updateDatas);
      });
  };

  useDelayedUpdate(updateUserSimulatorDatasFunc, [updateDatas], 250);

  return (
    <InsulatingMattressesContext.Provider
      value={{
        naturalGasConsumedForHeatProduction,
        numberOfMattressesToBeInstalled,
        averageDNOfMattressesToBeInstalled,
        averageNetworkTemperature,
        forms,
        reductionOfEnergyConsumption,
        co2EmissionsAvoided,
        estimatedFinancialGain,
        roi,
        estimatedBudget,
        resultTableRows,
        dnMatrix,
        temperatureCoefficientMatrix,
        actionMatrix,
        roiMatrix,
        resultCharts,
        energyGainMwhPerYear: reductionOfEnergyConsumption,
        kgCo2EmissionAvoidedPerYear: co2EmissionsAvoided * 1000,
        financialGainPerYear: estimatedFinancialGain,
        estimatedRoi: roi,
      }}
    >
      {children}
    </InsulatingMattressesContext.Provider>
  );
};

export { InsulatingMattressesContext, InsulatingMattressesProvider };
