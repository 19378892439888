import axios from 'axios';
import { API, DEFAULT_SIMULATORS, ICONS } from '../global';
import {
  IIcon,
  IReferenceData,
  ISIMULATORS_NAME,
  ISimulator,
  IUser,
  IUserDetails,
} from '../types/app';
import { IReferenceDatas } from '../context/ReferenceDatasContext';

const generateUserToken = async (): Promise<string | undefined> => {
  try {
    const res = await axios.get(`${API.BASE_URL}${API.ROUTES?.AUTH.GENERATE_USER_TOKEN}`);
    const token = res?.data?.datas?.token;
    return token || undefined;
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

const generateViewOnlyUserToken = async (
  id: number | string,
  token: string,
): Promise<string | undefined> => {
  try {
    const res = await axios.post(
      `${API.BASE_URL}${API.ROUTES?.AUTH.GENERAGE_VIEW_ONLY_USER_TOKEN}`,
      {
        id: id,
        token: token,
      },
    );
    const jwtToken = res.data?.datas?.token;
    return jwtToken || undefined;
  } catch (err) {
    console.error(err);
    throw new Error('Could not generate token');
  }
};

const formatReferenceDatas = (referenceDatas: Array<any>): IReferenceDatas => {
  let res: IReferenceDatas = {} as IReferenceDatas;

  referenceDatas.forEach((ref) => {
    res = {
      ...res,
      [ref?.code]: {
        referenceDataId: ref?.reference_data_id,
        value: ref?.value,
        defaultValue: ref?.default_value,
        unit: ref?.unit,
        source: ref?.source,
        comment: ref?.comment,
        description: ref?.description,
      } as IReferenceData,
    } as IReferenceDatas;
  });

  return res;
};

const getSimulatorsIconFromKey = (key: ISIMULATORS_NAME): IIcon => {
  return ICONS[key];
};

const getParsedData = (data: any) => {
  try {
    return JSON.parse(data);
  }catch{
    return data
  }
}

const formatSimulatorDatas = (simulatorDatas: Array<any>): ISimulator[] => {
  return simulatorDatas.map((sim) => {
    const datas = getParsedData(sim?.datas);
    // console.log('API DATAS', sim?.datas);
    // console.log('Formated data s',datas);
    // console.log('--------------------------------');
    
    return {
      code: sim?.code,
      icon: getSimulatorsIconFromKey(sim?.code),
      labels: DEFAULT_SIMULATORS[sim?.code]?.labels,
      name: sim?.name,
      route: DEFAULT_SIMULATORS[sim?.code]?.route,
      explications: DEFAULT_SIMULATORS[sim?.code]?.explications,
      isEligibleForCEE: DEFAULT_SIMULATORS[sim?.code]?.isEligibleForCEE,
      datas: datas,
    };
  });
};

const getUserDetails = async (userToken: string): Promise<IUser | undefined> => {
  try {
    const res = await axios.get(`${API.BASE_URL}${API.ROUTES?.USER?.DETAILS?.GET}`, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });
    const datas = res?.data?.datas;
    const details = datas?.userDetails;
    const referenceDatas = datas?.referenceDatas;
    const simulatorDatas = datas?.simulatorDatas;
    return {
      details: {
        id: details?.id,
        token: details?.token,
        email: details?.email,
        firstname: details?.firstname,
        lastname: details?.lastname,
        phoneNumber: details?.phoneNumber,
        companyName: details.companyName,
        jobPosition: details?.jobPosition,
        department: details?.department,
        wantToBeContacted: details?.wantToBeContacted || 0,
        wantFullReport: details?.wantFullReport || 0,
        contacted: details?.contacted || 0,
        gaveFullReport: details?.gaveFullReport || 0,
        isViewerOnly: details?.isViewer || false,
      },
      referenceDatas: formatReferenceDatas(referenceDatas),
      simulatorDatas: formatSimulatorDatas(simulatorDatas),
    };
  } catch (err) {
    console.error(err);
    throw new Error('Could not get User details');
  }
};

const updateUserDetails = async (userToken: string, details: IUserDetails) => {
  try {
    const res = await axios.post(
      `${API.BASE_URL}${API.ROUTES?.USER?.DETAILS?.UPDATE}`,
      {
        ...details,
      },
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      },
    );
    if (res) {
      const datas = res?.data?.datas;
      const newDetails = datas?.userDetails;
      if (newDetails) {
        return newDetails;
      }
    }
  } catch (err) {
    console.error(err);
    throw new Error('Could not update user details');
  }
};

const updateUserSimulatorDatas = async (
  userToken: string,
  code: ISIMULATORS_NAME,
  newDatas: object,
) => {
  try {
    if (!userToken) return;
    const res = await axios.post(
      `${API.BASE_URL}${API.ROUTES?.USER?.SIMULATOR_DATAS?.UPDATE}`,
      {
        simulatorCode: code,
        datas: {
          ...newDatas,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      },
    );
    return res.data?.datas;
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

const updateUserReferenceData = async (
  jwtToken: string,
  referenceDataId: number,
  value: number,
) => {
  try {
    if (!jwtToken) return;
    const res = await axios.post(
      `${API.BASE_URL}${API.ROUTES.USER.REFERENCE_DATAS.UPDATE}`,
      {
        referenceDataId: referenceDataId,
        value: value,
      },
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      },
    );
    return res.data?.datas;
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

const reinitializeUserReferenceDatas = async (jwtToken: string) => {
  try {
    if (!jwtToken) return;
    const res = await axios.post(
      `${API.BASE_URL}${API.ROUTES.USER.REFERENCE_DATAS.REINITIALIZE}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      },
    );
    return res.data?.datas;
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

const deleteDatas = async (jwtToken: string) => {
  try {
    if (!jwtToken) return;
    const res = await axios.post(
      `${API.BASE_URL}${API.ROUTES.USER.ACTIONS.DELETE}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      },
    );
    return res.data?.datas;
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

const updateUserContactedState = async (adminToken: string, userId: number, bool: boolean) => {
  try {
    if (!adminToken) return;
    const res = await axios.post(
      `${API.BASE_URL}${API.ROUTES.ADMIN.USERS.CONTACT_STATE.UPDATE}`,
      {
        userId: userId,
        bool: bool,
      },
      {
        headers: {
          Authorization: `Bearer ${adminToken}`,
        },
      },
    );
    return res.data?.datas;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const updateUserFullReportState = async (adminToken: string, userId: number, bool: boolean) => {
  try {
    if (!adminToken) return;
    const res = await axios.post(
      `${API.BASE_URL}${API.ROUTES.ADMIN.USERS.REPORT_STATE.UPDATE}`,
      {
        userId: userId,
        bool: bool,
      },
      {
        headers: {
          Authorization: `Bearer ${adminToken}`,
        },
      },
    );
    return res.data?.datas;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const UserApi = {
  generateUserToken,
  generateViewOnlyUserToken,
  getUserDetails,
  updateUserDetails,
  updateUserSimulatorDatas,
  updateUserReferenceData,
  reinitializeUserReferenceDatas,
  deleteDatas,
  updateUserContactedState,
  updateUserFullReportState,
};

export { UserApi };
