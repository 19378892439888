import { formatNumber } from '../../../../../utils';
import { EstimatedGainsWrapper } from './EstimatedGains.styled';

export interface EstimatedGainsProps {
  energy?: {
    value?: number;
    unit?: string;
  };
  money?: {
    value?: number;
    unit?: string;
  };
  backgroundColor?: string;
  valuesColor?: string;
}

const EstimatedGains: React.FC<EstimatedGainsProps> = ({
  energy,
  money,
  backgroundColor,
  valuesColor,
}) => {
  return (
    <EstimatedGainsWrapper backgroundcolor={backgroundColor} valuescolor={valuesColor}>
      <div className="estimated-gains-title">Gains estimés</div>
      <div className="estimated-gains">
        <div className="estimated-energy-gains gains">
          <span className="value">{formatNumber(energy?.value || 0)}</span>
          <span className="unit">{energy?.unit}</span>
        </div>
        <div className="estimated-money-gains gains">
          <span className="value">{formatNumber(money?.value || 0)}</span>
          <span className="unit">{money?.unit}</span>
        </div>
      </div>
    </EstimatedGainsWrapper>
  );
};

export default EstimatedGains;
