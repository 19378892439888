import { ChartProps } from '../../../components/chart/Chart';
import { ITableRow } from '../../../components/table/Table';
import SimulatorBrowseAxe from '../buttons/SimulatorBrowseAxe';
import SimulatorExplications from '../explications/SimulatorExplications';
import SimulatorForms from '../forms/SimulatorForms';
import SimulatorLeft from '../left/SimulatorLeft';
import SimulatorResults from '../results/SimulatorResults';
import { IForm, ISIMULATORS_NAME, ISimulator } from '../../../types/app';
import { SimulatorLayoutWrapper } from './SimuatorLayout.styled';
import { useContext, useEffect, useState } from 'react';
import {
  SimulatorsContext,
  SimulatorsContextProps,
} from '../../../context/simulators/SimulatorsContext';
import { LoadingContext, LoadingContextProps } from '../../../context/LoadingContext';
import { UserContext, UserContextProps } from '../../../context/user/UserContext';
import Notification from '../../../components/notification/Notification';
import { onQuitViewMode } from '../../../utils';

export interface SimulatorLayoutProps {
  simulator: ISimulator;
  forms: Array<IForm.IForm>;
  resultTableRows: Array<ITableRow>;
  resultCharts: Array<Array<ChartProps>>;
}

const SimulatorLayout: React.FC<SimulatorLayoutProps> = ({
  simulator,
  forms,
  resultTableRows,
  resultCharts,
}) => {
  const { getNextAndPreviousSimulators } = useContext(SimulatorsContext) as SimulatorsContextProps;

  const [nextAndPreviousSimulators, setNextAndPreviousSimulator] = useState<{
    previous: ISimulator | undefined;
    next: ISimulator | undefined;
  }>();

  useEffect(() => {
    setNextAndPreviousSimulator(getNextAndPreviousSimulators(simulator?.name as ISIMULATORS_NAME));
    // eslint-disable-next-line
  }, [simulator]);

  const { isLoading } = useContext(LoadingContext) as LoadingContextProps;
  const { user } = useContext(UserContext) as UserContextProps;

  return (
    <SimulatorLayoutWrapper>
      <SimulatorLeft>
        <SimulatorExplications explications={simulator.explications} />
        {user?.details?.isViewerOnly && (
          <Notification
            type="info"
            message={
              <div className="viewer-only-notification">
                Vous êtes actuellement en mode vue de la simulation {user?.details?.token}{' '}
                {`( ID : ${user?.details?.id} ) `}. Cliquez{' '}
                <span
                  className="quit-view-mode"
                  onClick={() => {
                    onQuitViewMode();
                  }}
                >
                  ici
                </span>{' '}
                pour revenir sur votre session actuelle.
              </div>
            }
          />
        )}
        <SimulatorForms forms={forms} disabled={user?.details?.isViewerOnly} />
        <SimulatorBrowseAxe
          nextSimulator={nextAndPreviousSimulators?.next}
          previousSimulator={nextAndPreviousSimulators?.previous}
          disabled={isLoading}
        />
      </SimulatorLeft>
      <SimulatorResults
        isViewerOnly={user?.details?.isViewerOnly}
        tableRows={resultTableRows}
        charts={resultCharts}
        simulator={simulator}
      />
    </SimulatorLayoutWrapper>
  );
};

export default SimulatorLayout;
