import { NotificationWrapper } from './Notification.styled';
import { ICONS } from '../../global';
import Info from '../info/Info';

export interface NotificationProps {
  message: any;
  type: 'warning' | 'danger' | 'info';
  showCloseIcon?: boolean;
}

const Notification: React.FC<NotificationProps> = ({ message, type, showCloseIcon }) => {
  return (
    <NotificationWrapper className={`${type}`}>
      <span className="message">
        <span className="notification-icon">
          {type === 'info' ? (
            <Info toolTip="" color="blue" id="viewer-only-notification" />
          ) : (
            type === 'warning' && ''
          )}
        </span>
        {message}
      </span>
      {showCloseIcon && <span className="close-icon">{ICONS.MENU_CLOSE.icon}</span>}
    </NotificationWrapper>
  );
};

export default Notification;
