import styled, { css } from 'styled-components';
import { CardColor, CardTitle, CardWrapper } from '../../../../components/card/Card.styled';
import { ButtonWrapper } from '../../../../components/button/Button.styled';
import { BODY } from '../../../../styles/helpers/typography';
import { COLORS } from '../../../../styles/constants/colors';

const SingleSimulatorWrapper = styled.div<{ theme?: CardColor }>`
  position: relative;

  .tags {
    display: flex;
    gap: 10px;
  }

  .simulator-card-status {
    position: absolute;
    bottom: 24px;
    left: 24px;
    right: 24px;

    p {
      font-family: 'Jost';
      margin: 0;
    }
  }

  ${CardWrapper} {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: ${COLORS.WHITE};

    &:hover {
      cursor: pointer;
      ${({ theme }) =>
        theme &&
        css`
          ${theme === 'yellow' &&
          css`
            border: 1px solid ${COLORS.GREEN};
          `}
          ${theme === 'blue' &&
          css`
            border: 1px solid ${COLORS.BRIGHT_BLUE};
          `}
        `}
    }
  }

  ${CardTitle} {
    display: flex;
    align-items: center;
    gap: 15px;

    svg {
      width: 24px;
      height: 24px;
      color: ${COLORS.BRIGHT_BLUE};

      ${({ theme }) =>
        theme &&
        css`
          ${theme === 'yellow' &&
          css`
            color: ${COLORS.DARK_BLUE};
          `}
        `}
    }
  }

  ${ButtonWrapper} {
    width: max-content;
    padding: 10px 12px 10px 12px;
    ${BODY.S_REGULAR}
    color: ${COLORS.DARK_BLUE};
    background-color: ${COLORS.WHITE};
    border: 1px solid rgba(0, 40, 84, 0.2);

    &:hover {
      opacity: 1;
    }

    ${({ theme }) =>
      theme &&
      css`
        ${theme === 'yellow' &&
        css`
          border: 1px solid ${COLORS.GREEN};
        `}
      `}
  }
`;

export { SingleSimulatorWrapper };
