import { ModalTitle } from '../../../../components/Modal/Modal.styled';
import Copy from '../../../../components/copy/Copy';
import TextField from '../../../../components/form/text-field/TextField';
import { IUserDetails } from '../../../../types/app';
import { checkTextNull } from '../../../../utils';
import { UserInfosWrapper } from './UserInfos.styled';

export interface UserInfosProps {
  details: IUserDetails;
}

const notProvided = 'Non fourni';

const showInfo = (text?: string) => {
  if (!checkTextNull(text)) return text;
  return notProvided;
};

const UserInfos: React.FC<UserInfosProps> = ({ details }) => {
  return (
    <UserInfosWrapper>
      <ModalTitle>Informations détaillés - {details.id}</ModalTitle>
      <div className="informations">
        <div className="single-info">
          <TextField
            value={showInfo(details.lastname)}
            disabled
            label="Nom"
            className={`${checkTextNull(details.lastname) && 'null'}`}
          />
          <Copy text={details.lastname} disabled={checkTextNull(details.lastname)} />
        </div>
        <div className="single-info">
          <TextField
            value={showInfo(details.firstname)}
            disabled
            label="Prénom"
            className={`${checkTextNull(details.firstname) && 'null'}`}
          />
          <Copy text={details.firstname} disabled={checkTextNull(details.firstname)} />
        </div>
        <div className="single-info">
          <TextField
            value={showInfo(details.email)}
            disabled
            label="Email"
            className={`${checkTextNull(details.email) && 'null'}`}
          />
          <Copy text={details.email} disabled={checkTextNull(details.email)} />
        </div>
        <div className="single-info">
          <TextField
            value={showInfo(details.phoneNumber)}
            disabled
            label="Numero de téléphone"
            className={`${checkTextNull(details.phoneNumber) && 'null'}`}
          />
          <Copy text={details.phoneNumber} disabled={checkTextNull(details.phoneNumber)} />
        </div>
        <div className="single-info">
          <TextField
            value={showInfo(details.companyName)}
            disabled
            label="Entreprise"
            className={`${checkTextNull(details.companyName) && 'null'}`}
          />
          <Copy text={details.companyName} disabled={checkTextNull(details.companyName)} />
        </div>
        <div className="single-info">
          <TextField
            value={showInfo(details.department)}
            disabled
            label="Département"
            className={`${checkTextNull(details.department) && 'null'}`}
          />
          <Copy text={details.department} disabled={checkTextNull(details.department)} />
        </div>
        <div className="single-info">
          <TextField
            value={showInfo(details.jobPosition)}
            disabled
            label="Poste occupé"
            className={`${checkTextNull(details.jobPosition) && 'null'}`}
          />
          <Copy text={details.jobPosition} disabled={checkTextNull(details.jobPosition)} />
        </div>
        <div className="single-info sim-link">
          <TextField
            value={`${window.location.host}/view?vt=${details.token}&uid=${details.id}`}
            disabled
            label="Lien vers son simulateur"
            className={`${checkTextNull(details.token) && 'null'}`}
          />
          <Copy text={`${window.location.host}/view?vt=${details.token}&uid=${details.id}`} />
        </div>
      </div>
    </UserInfosWrapper>
  );
};

export default UserInfos;
