import styled, { css } from 'styled-components';
import { COLORS } from '../../styles/constants/colors';

const bright_icon = css`
  color: ${COLORS.BRIGHT_BLUE};
`;

const IconWrapper = styled.div`
  display: flex;
  animation: 3s linear;
  padding: 10px 10px;
  border-radius: 5px;

  color: ${COLORS.BLUE};
  width: 20px;

  svg {
    width: 20px;
  }

  &:hover {
    cursor: pointer;
    ${bright_icon}
  }

  &.selected {
    ${bright_icon}
    background-color: #d3e6fc80;
  }
`;

export { IconWrapper };
