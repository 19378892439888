import { useContext, useEffect, useState } from 'react';
import PageLayout from '../../layout/page-layout/PageLayout';
import { ISimulator } from '../../types/app';
import { useLocation } from 'react-router-dom';
import {
  SimulatorsContext,
  SimulatorsContextProps,
} from '../../context/simulators/SimulatorsContext';
import IndustrialSite from './simulators/industrial-site/IndustrialSite';
import Monitoring from './simulators/monitoring/Monitoring';
import { APP_TITLE, SIMULATORS_NAMES } from '../../global';
import CompressedAir from './simulators/compressed-air/CompressedAir';
import HeatRecovery from './simulators/heat-recovery/HeatRecovery';
import RoomAirDestratification from './simulators/room-air-destratification/RoomAirDestratification';
import MicroModulatingBurner from './simulators/micro-modulating-burner/MicroModulatingBurner';
import InsulatingMattresses from './simulators/insulating-mattresses/InsulatingMattresses';
import LedLighting from './simulators/led-lighting/LedLighting';
import HeatPump from './simulators/heat-pump/HeatPump';
import ElectricSpeedVariation from './simulators/electric-speed-variation/ElectricSpeedVariation';
import HighPerformanceEngines from './simulators/high-performance-engines/HighPerformanceEngines';
import MotorDrive from './simulators/motor-drive/MotorDrive';

const Simulator: React.FC = () => {
  const [simulator, setSimulator] = useState<ISimulator>();
  const { simulators } = useContext(SimulatorsContext) as SimulatorsContextProps;

  const location = useLocation();
  useEffect(() => {
    const tmpSimulator = simulators.find((simulator) => simulator.route === location.pathname);
    tmpSimulator && setSimulator(tmpSimulator);
  }, [location.pathname, simulators]);

  const [simulatorsComponents, setSimulatorsComponents] = useState<{
    [x: string]: {
      name: string;
      component: React.ReactElement;
    };
  }>();

  useEffect(() => {
    document.title = simulator?.name
      ? `${simulator?.name} - ${APP_TITLE}`
      : `Chargement - ${APP_TITLE} ...`;
  }, [simulator]);

  useEffect(() => {
    simulator &&
      setSimulatorsComponents({
        INDUSTRIAL_SITE: {
          name: SIMULATORS_NAMES.INDUSTRIAL_SITE.name,
          component: <IndustrialSite simulator={simulator} />,
        },
        MONITORING: {
          name: SIMULATORS_NAMES.MONITORING.name,
          component: <Monitoring simulator={simulator} />,
        },
        COMPRESSED_AIR: {
          name: SIMULATORS_NAMES.COMPRESSED_AIR.name,
          component: <CompressedAir simulator={simulator} />,
        },
        HEAT_RECOVERY: {
          name: SIMULATORS_NAMES.HEAT_RECOVERY.name,
          component: <HeatRecovery simulator={simulator} />,
        },
        [SIMULATORS_NAMES.ROOM_AIR_DESTRATIFICATION.name]: {
          name: SIMULATORS_NAMES.ROOM_AIR_DESTRATIFICATION.name,
          component: <RoomAirDestratification simulator={simulator} />,
        },
        [SIMULATORS_NAMES.MICRO_MODULATING_BURNER.name]: {
          name: SIMULATORS_NAMES.MICRO_MODULATING_BURNER.name,
          component: <MicroModulatingBurner simulator={simulator} />,
        },
        [SIMULATORS_NAMES.INSULATING_MATTRESSES.name]: {
          name: SIMULATORS_NAMES.INSULATING_MATTRESSES.name,
          component: <InsulatingMattresses simulator={simulator} />,
        },
        [SIMULATORS_NAMES.LED_LIGHTING.name]: {
          name: SIMULATORS_NAMES.LED_LIGHTING.name,
          component: <LedLighting simulator={simulator} />,
        },
        [SIMULATORS_NAMES.HEAT_PUMP.name]: {
          name: SIMULATORS_NAMES.HEAT_PUMP.name,
          component: <HeatPump simulator={simulator} />,
        },
        [SIMULATORS_NAMES.ELECTRIC_SPEED_VARIATION.name]: {
          name: SIMULATORS_NAMES.HEAT_PUMP.name,
          component: <ElectricSpeedVariation simulator={simulator} />,
        },
        [SIMULATORS_NAMES.HIGH_PERFORMANCE_ENGINES.name]: {
          name: SIMULATORS_NAMES.HIGH_PERFORMANCE_ENGINES.name,
          component: <HighPerformanceEngines simulator={simulator} />,
        },
        [SIMULATORS_NAMES.MOTOR_DRIVE.name]: {
          name: SIMULATORS_NAMES.MOTOR_DRIVE.name,
          component: <MotorDrive simulator={simulator} />,
        },
      });
  }, [simulator]);

  return (
    <PageLayout>
      {simulatorsComponents && simulatorsComponents[simulator?.code as any]?.component}
    </PageLayout>
  );
};
export default Simulator;
