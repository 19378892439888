import styled from 'styled-components';
import { COLORS } from '../../styles/constants/colors';

const CopyWrapper = styled.span`
  width: 20px;
  height: 20px;
  color: ${COLORS.DARK_BLUE};
  padding: 8px;
  border-radius: 4px;
  transition: all 0.1s ease-out;
  display: flex;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    cursor: pointer;
    background-color: ${COLORS.LIGHT_BLUE}80;
  }

  &.disabled {
    color: ${COLORS.DARK_GREY}60;

    &:hover {
      cursor: not-allowed !important;
      background-color: transparent;
    }
  }
`;

export { CopyWrapper };
