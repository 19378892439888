import styled from 'styled-components';
import { MyIndustrialSiteCardWrapper } from './my-industrial-site-card/MyIndustrialSiteCard.styled';
import { DashboardReferenceInformationsWrapper } from './reference-informations/DashboardReferenceInformations.styled';
import { BODY, H4 } from '../../styles/helpers/typography';
import { BREAKPOINTS } from '../../styles/constants/devices';
import { SimulatorsListWrapper } from './simulators-list/SimulatorsList.styled';
import { SingleSimulatorWrapper } from './simulators-list/single-simulator/SingleSimulator.styled';

const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding-top: 37px;

  @media (${BREAKPOINTS.L}) {
    padding-right: 50px;
  }

  .first-section {
    display: flex;
    box-shadow: 0 13px 35px 0 #e9f3ff;
    border-radius: 8px;
    position: relative;
    gap: 30px;
    background: #e9f3ff85;
    flex-direction: column;

    @media (${BREAKPOINTS.L}) {
      flex-direction: row;
      ${MyIndustrialSiteCardWrapper} {
        width: 70%;
      }

      ${DashboardReferenceInformationsWrapper} {
        width: 30%;
      }
    }
  }

  .second-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 50px;

    .second-section-title {
      ${H4}
      margin-bottom: 20px;
    }

    .second-section-content {
      display: flex;
      gap: 30px;
      justify-content: space-between;
      flex-direction: column;
      flex-wrap: wrap;

      ${SimulatorsListWrapper} {
        max-width: 100%;
        // width: 70%;

        @media screen and (max-width: 1300px) {
          display: flex;
          flex-direction: column;
          // width: 60%;

          ${SingleSimulatorWrapper} {
            width: 100%;
          }
        }
      }

      .extra-informations {
        flex: 1;
        position: relative;

        #extra-informations-content-wrapper {
          display: flex;
          flex-direction: column;
          gap: 20px;
          top: 0;
          max-width: 100%;

          &.fix-extra-informations-top {
            position: fixed;
            // top: 80px;
            width: 500px;
            max-width: 100%;
          }

          .extra-explications {
            ${BODY.S_REGULAR}
            opacity: 50%;
          }
        }
      }

      @media (${BREAKPOINTS.L}) {
        flex-direction: row;

        ${SimulatorsListWrapper} {
          width: 55%;
        }
      }

      @media (${BREAKPOINTS.XL}) {
        flex-direction: row;

        ${SimulatorsListWrapper} {
          width: 67%;
        }
      }
    }
  }
`;

export { DashboardWrapper };
