import { createContext, useContext, useEffect, useState } from 'react';
import { IComponentWithChildren, IForm, ISimulatorRequiredDatas } from '../../types/app';
import { ReferenceDatasContext, ReferenceDatasContextProps } from '../ReferenceDatasContext';
import { ITableRow } from '../../components/table/Table';
import { ChartProps } from '../../components/chart/Chart';
import { FormTitle } from '../../components/form/Form.styled';
import { customRound, customRoundDown } from '../../utils';
import { COLORS } from '../../styles/constants/colors';
import { SimulatorsContext, SimulatorsContextProps } from './SimulatorsContext';
import { UserContext, UserContextProps } from '../user/UserContext';

import useDelayedUpdate from '../../hooks/useDelayedUpdate';

export interface MicroModulatingBurnerContextProps extends ISimulatorRequiredDatas {
  gasConsumedForHeatProduction: number;
  forms: IForm.IForm[];
  energyGain: number;
  co2EmissionsAvoided: number;
  estimatedFinancialGain: number;
  roi: number;
  estimatedBudget: number;
  resultTableRows: ITableRow[];
  roiMatrix: number[][];
  actionMatrix: number[][];
  resultCharts: ChartProps[][];
}

const MicroModulatingBurnerContext = createContext<MicroModulatingBurnerContextProps | undefined>(
  undefined,
);

const MicroModulatingBurnerProvider: React.FC<IComponentWithChildren> = ({ children }) => {
  const { CARBON_CONTENT_PER_KWH_GAZ, AVERAGE_GAS_PRICE_PER_MWH, NATURAL_GAS_TRENDS } = useContext(
    ReferenceDatasContext,
  ) as ReferenceDatasContextProps;
  const { userToken, updateUserSimulatorDatas } = useContext(UserContext) as UserContextProps;
  const { getSimulatorByCode, simulators, editSimulatorDatas } = useContext(
    SimulatorsContext,
  ) as SimulatorsContextProps;

  const [gasConsumedForHeatProduction, setGasConsumedForHeatProduction] = useState<number>(0);
  const [forms, setForms] = useState<IForm.IForm[]>([]);

  const [energyGain, setEnergyGain] = useState<number>(0);
  const [co2EmissionsAvoided, setCo2EmissionsAvoided] = useState<number>(0);
  const [estimatedFinancialGain, setEstimatedFinancialGain] = useState<number>(0);
  const [roi, setRoi] = useState<number>(0);
  const [estimatedBudget, setEstimatedBudget] = useState<number>(0);

  const [resultTableRows, setResultTableRows] = useState<ITableRow[]>([]);

  const [roiMatrix, setRoiMatrix] = useState<number[][]>([]);
  const [actionMatrix, setActionMatrix] = useState<number[][]>([]);
  const [resultCharts, setResultCharts] = useState<ChartProps[][]>([]);
  const [updateDatas, setUpdateDatas] = useState<object>({});

  const [allowRequest, setAllowRequest] = useState<boolean>(false);
  //   Forms
  useEffect(() => {
    setForms([
      {
        onChange: () => setAllowRequest(true),
        header: <FormTitle>Remplissez le champ suivant :</FormTitle>,
        fields: [
          {
            label: 'Gaz consommé pour la production de chaleur',
            name: '',
            type: 'textfield',
            textType: 'number',
            unit: 'MWh PCI/an',
            value: gasConsumedForHeatProduction,
            onChange: (e) => setGasConsumedForHeatProduction(e),
          },
        ],
      },
    ]);
  }, [gasConsumedForHeatProduction]);

  // energy gain
  useEffect(() => {
    setEnergyGain(gasConsumedForHeatProduction * 0.05);
  }, [gasConsumedForHeatProduction]);

  //   co2 emissions avoided
  useEffect(() => {
    setCo2EmissionsAvoided(energyGain * CARBON_CONTENT_PER_KWH_GAZ?.value);
  }, [energyGain, CARBON_CONTENT_PER_KWH_GAZ?.value]);

  //   estimated financial gain
  useEffect(() => {
    setEstimatedFinancialGain(customRoundDown(energyGain * AVERAGE_GAS_PRICE_PER_MWH?.value, -2));
  }, [energyGain, AVERAGE_GAS_PRICE_PER_MWH?.value]);

  //   roi
  useEffect(() => {
    setRoi(estimatedBudget / estimatedFinancialGain);
  }, [estimatedBudget, estimatedFinancialGain]);

  //   estimated budget
  useEffect(() => {
    setEstimatedBudget(customRound(9000 + gasConsumedForHeatProduction * 6, -2));
  }, [gasConsumedForHeatProduction]);

  //   result table rows
  useEffect(() => {
    setResultTableRows([
      {
        label: 'Gain énergétique estimé',
        value: `${Math.round(energyGain)}`,
        unit: 'MWh/an',
      },
      {
        label: 'Emissions de CO2 évitées',
        value: `${co2EmissionsAvoided}`,
        unit: 'T CO2/an',
      },
      {
        label: 'Gain financier estimé',
        value: `${estimatedFinancialGain}`,
        unit: '€/an',
      },
      {
        label: 'Enveloppe budgétaire estimée',
        value: `${estimatedBudget}`,
        unit: '€',
      },
      {
        label: 'ROI pour cette action (le ROI n’inclut pas les primes et aides (CEE,…))',
        value: `${roi}`,
        unit: 'ans',
      },
    ]);
  }, [energyGain, co2EmissionsAvoided, estimatedFinancialGain, roi, estimatedBudget]);

  //   roi matrix
  useEffect(() => {
    const investment: number = -estimatedBudget;
    const initialRealizedEconomy: number = 0;
    let tmpMatrix: number[][] = [
      [0, 0],
      [0, 0],
    ];

    tmpMatrix[0] = [initialRealizedEconomy, estimatedFinancialGain];
    tmpMatrix[1] = [investment + tmpMatrix[0][0]];

    const yearsCount = 8;
    for (let i = 1; i < yearsCount; i++) {
      if (i > 1) {
        tmpMatrix[0] = [
          ...tmpMatrix[0],
          tmpMatrix[0][i - 1] * (1 + NATURAL_GAS_TRENDS?.value / 100),
        ];
      }
      tmpMatrix[1] = [...tmpMatrix[1], tmpMatrix[1][i - 1] + tmpMatrix[0][i]];
    }
    setRoiMatrix(tmpMatrix);
  }, [estimatedBudget, estimatedFinancialGain, NATURAL_GAS_TRENDS?.value]);

  //   action matrix
  useEffect(() => {
    const tmpMatrix: number[][] = [
      [0, 0],
      [0, 0],
      [0, 0],
      [0, 0],
    ];
    tmpMatrix[0][0] = gasConsumedForHeatProduction;
    tmpMatrix[0][1] = tmpMatrix[0][0] * CARBON_CONTENT_PER_KWH_GAZ?.value;

    tmpMatrix[1][0] = energyGain;
    tmpMatrix[1][1] = tmpMatrix[1][0] * CARBON_CONTENT_PER_KWH_GAZ?.value;

    tmpMatrix[2][0] = tmpMatrix[0][0] - tmpMatrix[1][0];
    tmpMatrix[2][1] = tmpMatrix[0][1] - tmpMatrix[1][1];

    tmpMatrix[3][0] = tmpMatrix[1][0];
    tmpMatrix[3][1] = tmpMatrix[1][1];

    setActionMatrix(tmpMatrix);
  }, [gasConsumedForHeatProduction, energyGain, CARBON_CONTENT_PER_KWH_GAZ?.value]);

  //   resultCharts
  useEffect(() => {
    setResultCharts([
      [
        {
          chartType: 'bar',
          chartTitle: 'Retour sur investissement estimé pour cette action',
          datas: {
            labels: roiMatrix[1]?.map((roi, index) => `Année ${index}`),
            datasets: [
              {
                data: roiMatrix[1] || [],
                backgroundColor: roiMatrix[1]?.map(
                  (roi, index) => `${roi < 0 ? COLORS.LIGHT_BLUE : COLORS.BRIGHT_BLUE}`,
                ),
              },
            ],
          },
          defaultUnit: '€',
          yUnit: '€',
          options: {
            responsive: true,
          },
        },
      ],
      [
        {
          chartType: 'bar',
          chartTitle: "Economies realisées suite à l'intervention",
          labels: ['Etat final', "Réalisation de l'action"],
          labelsColors: [COLORS.WHITE, COLORS.WHITE],
          labelsBackgroundColors: [COLORS.BRIGHT_BLUE, COLORS.DARK_BLUE],
          datas: {
            labels: ['Energie (MWh)', 'CO2 (tonnes)'],
            datasets: [
              {
                label: '',
                data: actionMatrix[2],
                backgroundColor: COLORS.BRIGHT_BLUE,
                maxBarThickness: 50,
              },
              {
                label: '',
                data: actionMatrix[3],
                backgroundColor: COLORS.DARK_BLUE,
                maxBarThickness: 50,
              },
            ],
          },
          options: {
            responsive: true,
            scales: {
              x: {
                stacked: true,
              },
              y: {
                stacked: true,
              },
            },
          },
        },
      ],
    ]);
  }, [roiMatrix, actionMatrix]);
  useEffect(() => {
    const sim = getSimulatorByCode('MICRO_MODULATING_BURNER');
    if (sim) {
      setGasConsumedForHeatProduction(Number(sim?.datas?.gasConsumedForHeatProduction));
    }
    // eslint-disable-next-line
  }, [simulators]);

  useEffect(() => {
    if (userToken) {
      const newDatas = {
        gasConsumedForHeatProduction,
      };
      setUpdateDatas(newDatas);
    }
  }, [
    userToken,
    energyGain,
    estimatedFinancialGain,
    gasConsumedForHeatProduction,
    co2EmissionsAvoided,
  ]);

  const updateUserSimulatorDatasFunc = () => {
    // Your logic to update the user simulator datas
    allowRequest &&
      updateDatas &&
      updateUserSimulatorDatas(userToken, 'MICRO_MODULATING_BURNER', updateDatas).then(() => {
        editSimulatorDatas('MICRO_MODULATING_BURNER', updateDatas);
      });
  };

  useDelayedUpdate(updateUserSimulatorDatasFunc, [updateDatas], 250);

  return (
    <MicroModulatingBurnerContext.Provider
      value={{
        gasConsumedForHeatProduction,
        forms,
        energyGain,
        co2EmissionsAvoided,
        estimatedFinancialGain,
        roi,
        estimatedBudget,
        resultTableRows,
        roiMatrix,
        actionMatrix,
        resultCharts,
        energyGainMwhPerYear: energyGain,
        kgCo2EmissionAvoidedPerYear: co2EmissionsAvoided * 1000,
        financialGainPerYear: estimatedFinancialGain,
        estimatedRoi: roi,
      }}
    >
      {children}
    </MicroModulatingBurnerContext.Provider>
  );
};

export { MicroModulatingBurnerContext, MicroModulatingBurnerProvider };
