import { createContext, useContext, useEffect, useState } from 'react';
import { IComponentWithChildren, IForm, ISimulatorRequiredDatas } from '../../types/app';
import { FormTitle } from '../../components/form/Form.styled';
import { ITableRow } from '../../components/table/Table';
import { ReferenceDatasContext, ReferenceDatasContextProps } from '../ReferenceDatasContext';
import { customRoundDown, customRoundUp, formatNumber } from '../../utils';
import { ChartProps } from '../../components/chart/Chart';
import { COLORS } from '../../styles/constants/colors';
import { SimulatorsContext, SimulatorsContextProps } from './SimulatorsContext';
import { UserContext, UserContextProps } from '../user/UserContext';

import useDelayedUpdate from '../../hooks/useDelayedUpdate';

export interface HeatPumpContextProps extends ISimulatorRequiredDatas {
  thermalEnergyRequirements: number;
  forms: IForm.IForm[];

  equivalentElectricalEnergyPAC: number;
  consumptionReduction: number;
  estimatedReductionInEmissions: number;
  estimatedFinancialGain: number;
  roi: number;
  estimatedBudget: number;
  resultTableRows: ITableRow[];

  resultCharts: ChartProps[][];
}

const HeatPumpContext = createContext<HeatPumpContextProps | undefined>(undefined);

const HeatPumpProvider: React.FC<IComponentWithChildren> = ({ children }) => {
  const {
    CARBON_CONTENT_PER_KWH_ELECTRICITY,
    CARBON_CONTENT_PER_KWH_GAZ,
    AVERAGE_ELECTRICITY_PRICE_PER_MWH,
    AVERAGE_GAS_PRICE_PER_MWH,
    NATURAL_ELECTRICITY_TRENDS,
  } = useContext(ReferenceDatasContext) as ReferenceDatasContextProps;
  const { getSimulatorByCode, simulators, editSimulatorDatas } = useContext(
    SimulatorsContext,
  ) as SimulatorsContextProps;
  const { userToken, updateUserSimulatorDatas } = useContext(UserContext) as UserContextProps;

  const [thermalEnergyRequirements, setThermalEnergyRequirements] = useState<number>(0);
  const [forms, setForms] = useState<IForm.IForm[]>([]);

  const [equivalentElectricalEnergyPAC, setEquivalentElectricalEnergyPAC] = useState<number>(0);
  const [consumptionReduction, setConsumptionReduction] = useState<number>(0);
  const [estimatedReductionInEmissions, setEstimatedReductionInEmissions] = useState<number>(0);
  const [estimatedFinancialGain, setEstimatedFinancialGain] = useState<number>(0);
  const [roi, setRoi] = useState<number>(0);
  const [estimatedBudget, setEstimatedBudget] = useState<number>(0);
  const [resultTableRows, setResultTableRows] = useState<ITableRow[]>([]);

  const [roiMatrix, setRoiMatrix] = useState<number[][]>([]);

  const [resultCharts, setResultCharts] = useState<ChartProps[][]>([]);
  const [updateDatas, setUpdateDatas] = useState<object>({});

  const [allowRequest, setAllowRequest] = useState<boolean>(false);

  // Forms
  useEffect(() => {
    setForms([
      {
        onChange: () => setAllowRequest(true),
        header: <FormTitle>Remplissez le champ suivant :</FormTitle>,
        fields: [
          {
            label: 'Besoin en énergie thermique',
            name: '',
            type: 'textfield',
            value: thermalEnergyRequirements,
            onChange: (e) => setThermalEnergyRequirements(Number(e)),
            unit: 'MWh/an',
          },
        ],
      },
    ]);
  }, [thermalEnergyRequirements]);

  // equivalent Electrical energy PAC
  useEffect(() => {
    setEquivalentElectricalEnergyPAC(thermalEnergyRequirements / 2.4);
  }, [thermalEnergyRequirements]);

  // consumption reduction
  useEffect(() => {
    setConsumptionReduction(thermalEnergyRequirements - equivalentElectricalEnergyPAC);
  }, [equivalentElectricalEnergyPAC, thermalEnergyRequirements]);

  // estimated reduction in emissions
  useEffect(() => {
    setEstimatedReductionInEmissions(
      thermalEnergyRequirements * CARBON_CONTENT_PER_KWH_GAZ?.value -
        equivalentElectricalEnergyPAC * CARBON_CONTENT_PER_KWH_ELECTRICITY?.value,
    );
  }, [
    thermalEnergyRequirements,
    equivalentElectricalEnergyPAC,
    CARBON_CONTENT_PER_KWH_ELECTRICITY?.value,
    CARBON_CONTENT_PER_KWH_GAZ?.value,
  ]);

  // estimated financial gain
  useEffect(() => {
    setEstimatedFinancialGain(
      customRoundDown(
        thermalEnergyRequirements * AVERAGE_GAS_PRICE_PER_MWH?.value -
          equivalentElectricalEnergyPAC * AVERAGE_ELECTRICITY_PRICE_PER_MWH?.value,
        -2,
      ),
    );
  }, [
    thermalEnergyRequirements,
    AVERAGE_GAS_PRICE_PER_MWH?.value,
    equivalentElectricalEnergyPAC,
    AVERAGE_ELECTRICITY_PRICE_PER_MWH?.value,
  ]);

  // roi
  useEffect(() => {
    setRoi(estimatedBudget / estimatedFinancialGain);
  }, [estimatedBudget, estimatedFinancialGain]);

  // estimated budget
  useEffect(() => {
    setEstimatedBudget(customRoundUp(6000 + thermalEnergyRequirements * 60, -3));
  }, [thermalEnergyRequirements]);

  // result table rows
  useEffect(() => {
    setResultTableRows([
      {
        label: 'Energie électrique équivalente PAC',
        unit: 'MWh/an',
        value: `${equivalentElectricalEnergyPAC}`,
      },
      {
        label: 'Réduction de consommation',
        unit: 'MWh/an',
        value: `${consumptionReduction}`,
      },
      {
        label: "Baisse d'émissions estimée",
        unit: 'T CO2/an',
        value: `${estimatedReductionInEmissions}`,
      },
      {
        label: 'Gain financier estimé',
        unit: '€/an',
        value: `${estimatedFinancialGain}`,
      },
      {
        label: 'Enveloppe budgétaire estimée',
        unit: '€',
        value: `${estimatedBudget}`,
      },
      {
        label: 'ROI pour cette action (le ROI n’inclut pas les primes et aides (CEE,…))',
        unit: 'ans',
        value: `${roi}`,
      },
    ]);
  }, [
    equivalentElectricalEnergyPAC,
    consumptionReduction,
    estimatedReductionInEmissions,
    estimatedFinancialGain,
    roi,
    estimatedBudget,
  ]);

  // Roi matrix
  useEffect(() => {
    const investment = -estimatedBudget;
    const initialRealisedEconomy = 0;
    const firstYearRoi = investment + initialRealisedEconomy;

    let tmpMatrix: number[][] = [
      [initialRealisedEconomy, estimatedFinancialGain],
      [firstYearRoi, estimatedFinancialGain + firstYearRoi],
    ];

    const yearsCount = 7;
    for (let i = 2; i < yearsCount; i++) {
      tmpMatrix[0] = [
        ...tmpMatrix[0],
        tmpMatrix[0][i - 1] * (1 + NATURAL_ELECTRICITY_TRENDS?.value / 100),
      ];
      tmpMatrix[1] = [...tmpMatrix[1], tmpMatrix[1][i - 1] + tmpMatrix[0][i]];
    }
    setRoiMatrix(tmpMatrix);
  }, [estimatedBudget, NATURAL_ELECTRICITY_TRENDS?.value, estimatedFinancialGain]);

  // Result charts
  useEffect(() => {
    setResultCharts([
      [
        {
          chartType: 'bar',
          chartTitle: 'Economies réalisées',
          datas: {
            labels: [
              ['Réduction de consommation', '(MWh/an)'],
              ["Baisse d'émission estimées", '(Tonnes CO2/an)'],
            ],
            datasets: [
              {
                data: [consumptionReduction, estimatedReductionInEmissions],
                backgroundColor: [COLORS.BRIGHT_BLUE],
                maxBarThickness: 50,
              },
            ],
          },
          options: {
            plugins: {
              tooltip: {
                callbacks: {
                  title: () => '',
                },
              },
            },
          },
        },
      ],
      [
        {
          chartType: 'bar',
          chartTitle: 'Bilan énergétique',
          datas: {
            labels: [
              ['Energie électrique utilisée', '(MWh/an)'],
              ['Energie thermique consommée (gaz)', '(MWh/an)'],
            ],
            datasets: [
              {
                data: [thermalEnergyRequirements, equivalentElectricalEnergyPAC],
                backgroundColor: [COLORS.BRIGHT_BLUE],
                maxBarThickness: 50,
                indexAxis: 'y',
              },
            ],
          },
          defaultUnit: 'MWh/an',
          options: {
            indexAxis: 'y',
            scales: {
              x: {
                ticks: {
                  callback: (label) => {
                    return !isNaN(Number(label)) ? formatNumber(label) + ' Mwh/an' : label;
                  },
                },
              },
              y: {
                ticks: {},
              },
            },
          },
        },
      ],
      [
        {
          chartType: 'bar',
          chartTitle: 'Retour sur investissement',
          datas: {
            labels: roiMatrix[1] && roiMatrix[1]?.map((roi, index) => `Année ${index}`),
            datasets: [
              {
                data: (roiMatrix[1] && roiMatrix[1]) || [],
                backgroundColor: roiMatrix[1]?.map(
                  (roi, index) => `${roi < 0 ? COLORS.LIGHT_BLUE : COLORS.BRIGHT_BLUE}`,
                ),
              },
            ],
          },
          defaultUnit: '€',
          yUnit: '€',
        },
      ],
    ]);
  }, [
    consumptionReduction,
    estimatedReductionInEmissions,
    thermalEnergyRequirements,
    equivalentElectricalEnergyPAC,
    roiMatrix,
  ]);

  useEffect(() => {
    const sim = getSimulatorByCode('HEAT_PUMP');
    if (sim) {
      setThermalEnergyRequirements(Number(sim?.datas?.thermalEnergyRequirements));
    }
    // eslint-disable-next-line
  }, [simulators]);

  useEffect(() => {
    if (userToken) {
      const newDatas = {
        thermalEnergyRequirements,
      };

      setUpdateDatas(newDatas);
    }
  }, [
    userToken,
    thermalEnergyRequirements,
    consumptionReduction,
    estimatedFinancialGain,
    estimatedReductionInEmissions,
  ]);

  const updateUserSimulatorDatasFunc = () => {
    // Your logic to update the user simulator datas
    allowRequest &&
      updateDatas &&
      updateUserSimulatorDatas(userToken, 'HEAT_PUMP', updateDatas).then(() => {
        editSimulatorDatas('HEAT_PUMP', updateDatas);
      });
  };

  useDelayedUpdate(updateUserSimulatorDatasFunc, [updateDatas], 250);

  return (
    <HeatPumpContext.Provider
      value={{
        thermalEnergyRequirements,
        forms,
        equivalentElectricalEnergyPAC,
        consumptionReduction,
        estimatedReductionInEmissions,
        estimatedFinancialGain,
        roi,
        resultTableRows,
        resultCharts,
        energyGainMwhPerYear: consumptionReduction,
        kgCo2EmissionAvoidedPerYear: estimatedReductionInEmissions * 1000,
        financialGainPerYear: estimatedFinancialGain,
        estimatedBudget: estimatedBudget,
        estimatedRoi: roi,
      }}
    >
      {children}
    </HeatPumpContext.Provider>
  );
};

export { HeatPumpContext, HeatPumpProvider };
