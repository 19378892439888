import { useEffect, useState } from 'react';
// import toast from 'react-hot-toast';

type UpdateFunction = () => any;
type Dependencies = any[];

function useDelayedUpdate(
  updateFunction: UpdateFunction,
  dependencies: Dependencies,
  delay: number,
) {
  const [isPendingUpdate, setIsPendingUpdate] = useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    const handleUpdate = () => {
      if (isPendingUpdate) {
        clearTimeout(timeoutId);
      }

      setIsPendingUpdate(true);
      timeoutId = setTimeout(() => {
        setIsPendingUpdate(false);
        updateFunction();
        // toast.success('Changement effectué avec succès');
      }, delay);
    };

    handleUpdate();

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line
  }, [...dependencies]);

  return isPendingUpdate;
}

export default useDelayedUpdate;
