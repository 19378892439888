import { IIcon } from '../../../types/app';
import { formatNumber } from '../../../utils';
import { TotalTitleWrapper } from './TotalTitle.styled';

export interface TotalTitleProps {
  icon: IIcon;
  title: string;
  value: number;
  unit: string;
}

const TotalTitle: React.FC<TotalTitleProps> = ({ icon, title, value, unit }) => {
  return (
    <TotalTitleWrapper>
      <div className="section total-title-left">
        {icon && <div className="icon">{icon?.icon}</div>}
        {title && <div className="title">{title}</div>}
      </div>
      <div className="section total-title-right">
        {icon && <div className="value">{formatNumber(value)}</div>}
        {title && <div className="unit">{unit}</div>}
      </div>
    </TotalTitleWrapper>
  );
};

export default TotalTitle;
