import styled from 'styled-components';
import { H5 } from '../../../styles/helpers/typography';
import { SimulatorResultsWrapper } from '../results/SimulatorResults.styled';
import { SimulatorLeftWrapper } from '../left/SimulatorLeft.styled';

const SimulatorLayoutWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;

  gap: 20px;

  .title {
    ${H5}
    margin: 0;
  }

  ${SimulatorResultsWrapper}, ${SimulatorLeftWrapper} {
    max-height: 85vh;
    overflow-y: auto;
  }

  @media screen and (max-width: 1440px) {
    ${SimulatorResultsWrapper} {
      .charts {
        .chart-row {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  @media screen and (max-width: 1080px) {
    display: flex;
    flex-direction: column;

    ${SimulatorResultsWrapper}, ${SimulatorLeftWrapper} {
      max-height: none;
      overflow-y: auto;
    }
  }
`;

export { SimulatorLayoutWrapper };
