import Button from '../../../components/button/Button';
import { ICONS, ROUTES } from '../../../global';
import { MyIndustrialSiteCardWrapper } from './MyIndustrialSiteCard.styled';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import {
  IndustrialSiteContext,
  IndustrialSiteContextProps,
} from '../../../context/simulators/IndustrialSiteContext';
import Info from '../../../components/info/Info';
import { IsViewerOnlyProps } from '../../../types/app';
import Lottie from 'lottie-react-web';

import animationData from '../../../assets/lotties/lottie-factory.json';

const MyIndustrialSiteCard: React.FC<IsViewerOnlyProps> = ({ isViewerOnly }) => {
  const navigate = useNavigate();
  const { annualElectricityConsumption, annualGazConsumption } = useContext(
    IndustrialSiteContext,
  ) as IndustrialSiteContextProps;

  return (
    <MyIndustrialSiteCardWrapper>
      <div className="title">
        {ICONS.INDUSTRIAL_SITE.icon} {ICONS.INDUSTRIAL_SITE.name}{' '}
        <Info
          toolTip={
            '“Mon site industriel” permet d’ajouter des données personnalisées afin que les résultats puissent correspondre au mieux à la situation d’un site particulier.'
          }
          place="bottom"
          color="blue"
        />
      </div>
      <div className="description">
        La saisie de cette donnée permet d’adapter les résultats à votre situation énergétique.
        <br />
        Elle permet de réaliser une situation de référence pour estimer les gains réalisables.
      </div>
      {!annualGazConsumption && !annualElectricityConsumption ? (
        <Button
          label={
            !isViewerOnly ? 'Démarrer la simulation de mon site' : 'Consulter ses informations'
          }
          theme="light"
          hasIcon
          onClick={() => {
            navigate(ROUTES.SIMULATOR.INDUSTRIAL_SITE);
          }}
        />
      ) : (
        <Button
          label={!isViewerOnly ? 'Mettre à jour mes informations' : 'Consulter ses informations'}
          theme="transparent"
          hasIcon
          onClick={() => {
            navigate(ROUTES.SIMULATOR.INDUSTRIAL_SITE);
          }}
        />
      )}
      <div className="industrial-site-illustration">
        <Lottie
          options={{
            animationData: animationData,
            loop: true,
            autoplay: true,
          }}
          width="100%"
          height="100%"
        />
      </div>
    </MyIndustrialSiteCardWrapper>
  );
};

export default MyIndustrialSiteCard;
