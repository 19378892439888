import styled from 'styled-components';
import { BODY } from '../../../styles/helpers/typography';
import { COLORS } from '../../../styles/constants/colors';

const SelectOptionsWrapper = styled.div`
  .select-value {
    background-color: ${COLORS.WHITE};
    border: 1px solid ${COLORS.LIGHT_BLUE};
    outline: none;
    padding: 12px 16px 12px 16px;
    border-radius: 8px;
    position: relative;
    max-width: 100%;
    ${BODY.S_REGULAR}

    &:hover {
      cursor: pointer;
    }

    .select-icon-value {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
      ${BODY.S_SEMI_BOLD}
    }

    &.disabled {
      background-color: transparent;

      &:hover {
        cursor: not-allowed !important;
      }
    }
  }

  .select-options {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 3;
    border-radius: 8px;
    background-color: ${COLORS.WHITE};
    padding: 10px;

    .option {
      background-color: ${COLORS.WHITE};
      outline: none;
      padding: 12px 16px 12px 16px;
      position: relative;
      width: 90%;
      ${BODY.S_REGULAR}

      &:hover {
        cursor: pointer;
        background-color: ${COLORS.LIGHT_BLUE}50;
      }

      &:active {
        background-color: ${COLORS.LIGHT_BLUE}60;
      }

      &.selected {
        background-color: ${COLORS.LIGHT_BLUE}60;
      }
    }
  }
`;

const SelectBoxWrapper = styled.div`
  user-select: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  position: relative;
  transition: 0.3s linear;
  ${BODY.S_REGULAR}

  .unit {
    position: absolute;
    right: 20px;
    top: 55%;
    ${BODY.S_SEMI_BOLD}
  }

  .placeholder {
    color: ${COLORS.DARK_BLUE};
    opacity: 50%;
  }

  ${SelectOptionsWrapper} {
    .select-options {
      margin-top: 5px;
      display: none;
      overflow: hidden;
      max-height: 175px;
      overflow-y: auto;
    }
  }

  &.open {
    ${SelectOptionsWrapper} {
      .select-options {
        display: block;
        height: max-content;
        border: 1px solid ${COLORS.LIGHT_BLUE};
        border-top: none;
      }
    }
  }
`;

export { SelectBoxWrapper, SelectOptionsWrapper };
