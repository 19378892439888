import { ModalTitle } from '../../Modal/Modal.styled';
import { ReinitializePopupWrapper } from './ReinitializePopup.styled';

import { ModalContext, ModalContextProps } from '../../../context/ModalContext';
import { ICONS } from '../../../global';
import { useContext } from 'react';
// import { UserContext, UserContextProps } from '../../../context/user/UserContext';
import Button from '../../button/Button';
// import { UserApi } from '../../../api/User.api';
import { LoadingContext, LoadingContextProps } from '../../../context/LoadingContext';
import Progress from '../../progress/Progress';

const ReinitializePopup: React.FC = () => {
  // const { userToken } = useContext(UserContext) as UserContextProps;
  const { updateModalConf } = useContext(ModalContext) as ModalContextProps;
  const { setIsLoading, isLoading } = useContext(LoadingContext) as LoadingContextProps;

  return (
    <ReinitializePopupWrapper>
      <ModalTitle>
        <p>Êtes-vous sûr de vouloir réinitialiser vos données ?</p>
        <span className="close-icon" onClick={() => updateModalConf()}>
          {ICONS.MENU_CLOSE?.icon}
        </span>
      </ModalTitle>
      <div className="content">
        <p>
          Vous vous apprêtez à remettre à zéro toutes les données saisies dans le simulateur. Si
          vous voulez continuer appuyez sur confirmer.
        </p>
        {!isLoading ? (
          <Button
            theme="dark"
            onClick={async () => {
              setIsLoading(true);
              // await UserApi.deleteDatas(userToken);
              setIsLoading(false);
              localStorage.clear();
              window.location.reload();
            }}
            hasIcon
            label="Confirmer"
          />
        ) : (
          <Progress />
        )}
      </div>
    </ReinitializePopupWrapper>
  );
};

export default ReinitializePopup;
