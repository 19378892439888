// import { ICONS, ROUTES } from '../../../global';
import { SimulatorsListWrapper } from './SimulatorsList.styled';
import SingleSimulator from './single-simulator/SingleSimulator';
import { ISIMULATORS_NAME, ISimulator, ISimulatorRequiredDatas } from '../../../types/app';
import { SIMULATORS_NAMES } from '../../../global';
import React from 'react';

export interface SimulatorsListProps {
  simulators: Array<ISimulator>;
  contexts: {
    [x in ISIMULATORS_NAME]: any;
  };
}

const SimulatorsList: React.FC<SimulatorsListProps> = ({ simulators, contexts }) => {
  return (
    <SimulatorsListWrapper>
      {simulators &&
        simulators.map((simulator, key) => {
          return (
            <React.Fragment key={key}>
              {simulator?.code !== SIMULATORS_NAMES.INDUSTRIAL_SITE?.name && (
                <SingleSimulator
                  datas={{
                    energyGainMwhPerYear: (
                      contexts[simulator?.code as ISIMULATORS_NAME] as ISimulatorRequiredDatas
                    ).energyGainMwhPerYear,
                    kgCo2EmissionAvoidedPerYear: (
                      contexts[simulator?.code as ISIMULATORS_NAME] as ISimulatorRequiredDatas
                    ).kgCo2EmissionAvoidedPerYear,
                    financialGainPerYear: (
                      contexts[simulator?.code as ISIMULATORS_NAME] as ISimulatorRequiredDatas
                    ).financialGainPerYear,
                    estimatedBudget: (
                      contexts[simulator?.code as ISIMULATORS_NAME] as ISimulatorRequiredDatas
                    ).estimatedBudget,
                    estimatedRoi: (
                      contexts[simulator?.code as ISIMULATORS_NAME] as ISimulatorRequiredDatas
                    ).estimatedRoi,
                  }}
                  simulator={simulator}
                  key={key}
                />
              )}
            </React.Fragment>
          );
        })}
    </SimulatorsListWrapper>
  );
};

export default SimulatorsList;
