import { useContext } from 'react';
import { ISimulator } from '../../../../types/app';
import SimulatorLayout from '../../layout/SimulatorLayout';
import { MicroModulatingBurnerWrapper } from './MicroModulatingBurner.styled';
import {
  MicroModulatingBurnerContext,
  MicroModulatingBurnerContextProps,
} from '../../../../context/simulators/MicroModulatingBurnerContext';

export interface MicroModulatingBurnerProps {
  simulator: ISimulator;
}

const MicroModulatingBurner: React.FC<MicroModulatingBurnerProps> = ({ simulator }) => {
  const { forms, resultTableRows, resultCharts } = useContext(
    MicroModulatingBurnerContext,
  ) as MicroModulatingBurnerContextProps;

  return (
    <MicroModulatingBurnerWrapper>
      <SimulatorLayout
        simulator={simulator}
        forms={forms}
        resultTableRows={resultTableRows}
        resultCharts={resultCharts}
      />
    </MicroModulatingBurnerWrapper>
  );
};

export default MicroModulatingBurner;
