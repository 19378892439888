import styled from 'styled-components';
import { BREAKPOINTS } from '../../../styles/constants/devices';

const SimulatorLeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;

  @media (${BREAKPOINTS.S}) {
    padding: 20px 10px;
  }

  @media (${BREAKPOINTS.L}) {
    padding: 30px 20px;
  }
`;

export { SimulatorLeftWrapper };
