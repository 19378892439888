import { useContext, useEffect, useRef, useState } from 'react';
import Chart, { ChartProps } from '../../../components/chart/Chart';
import Table, { ITableRow } from '../../../components/table/Table';
import { ICONS } from '../../../global';
import { SimulatorResultsWrapper } from './SimulatorResults.styled';
import { ModalContext, ModalContextProps } from '../../../context/ModalContext';
import { pdf } from '@react-pdf/renderer';
import PdfSimulatorResults from './pdf/PdfSimulatorResults';
import { saveAs } from 'file-saver';
import { ISimulator, ISimulatorResultChart, IsViewerOnlyProps } from '../../../types/app';
import { isValidEmail, snakeCase } from '../../../utils';
import DefaultModalLayout from '../../../components/Modal/default-Layout/DefaultModalLayout';
import TextField from '../../../components/form/text-field/TextField';
import { UserContext, UserContextProps } from '../../../context/user/UserContext';

export interface SimulatorResultsProps extends IsViewerOnlyProps {
  tableRows: Array<ITableRow>;
  charts: Array<Array<ChartProps>>;
  simulator: ISimulator;
}

const SimulatorResults: React.FC<SimulatorResultsProps> = ({
  tableRows,
  charts,
  simulator,
  isViewerOnly,
}) => {
  const [allowExport, setAllowExport] = useState<boolean>(false);
  const { updateModalConf } = useContext(ModalContext) as ModalContextProps;
  const { user } = useContext(UserContext) as UserContextProps;
  const [emailState, setEmailState] = useState(user?.details?.email);

  const graphsRefs = useRef<ISimulatorResultChart[]>([]);

  useEffect(() => {
    setEmailState(user?.details?.email);
  }, [user]);

  const ExportPopup: React.FC<{ email: string }> = ({ email }) => {
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const { updateModalConf } = useContext(ModalContext) as ModalContextProps;

    const handleDownloadPdf = async () => {
      setIsDownloading(true);
      const formatedCharts: ISimulatorResultChart[] = graphsRefs.current.map((graph) => {
        return {
          ...graph,
          b64Image: graph.ref.toBase64Image(),
        };
      });
      const blob = await pdf(
        <PdfSimulatorResults
          resultTablesRows={tableRows}
          simulator={simulator}
          charts={formatedCharts}
        />,
      ).toBlob();
      saveAs(blob, `resultats_${snakeCase(simulator.name)}.pdf`);
      setIsDownloading(false);
      updateModalConf();
    };

    return (
      <>
        <DefaultModalLayout
          title={`Exporter ${!isViewerOnly ? 'mes' : 'ses'} résultats`}
          showCloseIcon
          cta={{
            label: `Exporter ${!isViewerOnly ? 'mes' : 'ses'} résultats`,
            disable: !isValidEmail(email || ''),
            onClick: async () => {
              await handleDownloadPdf();
            },
            isLoading: isDownloading,
          }}
        >
          {!isViewerOnly && (
            <p>Pour exporter vos résultats, nous avons besoin de votre adresse e-mail.</p>
          )}
          <TextField
            label="Email"
            placeholder="Votre Email"
            value={emailState}
            onChange={(e) => {
              setEmailState(e);
              updateModalConf({
                component: <ExportPopup email={e} />,
              });
            }}
            disabled={isViewerOnly}
          />
        </DefaultModalLayout>
      </>
    );
  };

  const handleClickExport = async () => {
    try {
      updateModalConf({
        component: <ExportPopup email={emailState as string} />,
      });
    } catch (err) {
      console.error('Error generating PDF:', err);
      // toast.error('Error generating PDF');
    } finally {
      // setIsDownloadingReport(false);
    }
  };

  useEffect(() => {
    setAllowExport(false);
  }, []);

  useEffect(() => {
    if (tableRows) {
      const test = tableRows.find((row) => isNaN(Number(row.value)) || !row.value);
      if (!test) setAllowExport(true);
      else setAllowExport(false);
    }
  }, [tableRows]);

  return (
    <SimulatorResultsWrapper>
      <div className="results">
        <h5 className="title">
          <p>Mes résultats</p>
          <div
            className={`export ${allowExport && 'allow-export'}`}
            onClick={() => {
              if (!allowExport) return;
              handleClickExport();
            }}
          >
            <span className="icon">{ICONS.DOWNLOAD_ARROW.icon}</span>
            <p>{`Exporter ${!isViewerOnly ? 'mes' : 'ses'} résultats`}</p>
          </div>
        </h5>
        <Table rows={tableRows} />
      </div>
      {charts && charts.length > 0 && (
        <div className="charts">
          {charts.map((charts2, key) => {
            return (
              <div
                key={key}
                className={`chart-row ${charts2.length < 2 ? 'single-child' : 'double-child'}`}
              >
                {charts2.map((chart, key2) => {
                  return (
                    <Chart
                      key={key2}
                      {...chart}
                      getRef={(e) =>
                        graphsRefs.current.push({
                          chart: chart,
                          ref: e,
                        })
                      }
                    />
                  );
                })}
              </div>
            );
          })}
        </div>
      )}
    </SimulatorResultsWrapper>
  );
};

export default SimulatorResults;
