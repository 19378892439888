import styled from 'styled-components';
import { COLORS } from '../../../styles/constants/colors';

const MultipleColumnsTableWrapper = styled.table`
  width: 100%;
  background-color: ${COLORS.WHITE};
  border-radius: 16px;
  border: 1px solid ${COLORS.LIGHT_BLUE};
  border-spacing: 0;

  * {
    font-family: 'Jost' !important;
  }

  thead {
    tr {
      th {
        border-bottom: 1px solid ${COLORS.LIGHT_BLUE};
        padding: 10px;
        border-right: 1px solid ${COLORS.LIGHT_BLUE};
      }
    }
  }

  tr {
    &.last-tr {
      td {
        border-bottom: none;
      }
    }
  }

  td {
    border-bottom: 1px solid ${COLORS.LIGHT_BLUE};
    padding: 8px 12px 8px 12px;
    border-right: 1px solid ${COLORS.LIGHT_BLUE};
    text-align: center;

    &.last {
      border-right: none;
    }
  }
`;

export { MultipleColumnsTableWrapper };
