import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import { COLORS } from '../../../../styles/constants/colors';

const styles = StyleSheet.create({
  chartImage: {
    border: `1pt solid ${COLORS.LIGHT_BLUE}`,
    padding: '8pt',
    borderRadius: 15,
    margin: '0 auto',
    width: '100%',
    maxHeight: '35%',
    backgroundColor: 'white',
  },
  image: {
    maxWidth: '1000pt',
    maxHeight: '300pt',
    display: 'flex',
    margin: '0 auto',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  chartTitle: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: '20pt',
    fontWeight: 'bold',
  },
  chartLabels: {
    marginTop: '5pt',
    fontSize: 8,
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
    justifyContent: 'center',
  },
  chartLabel: {
    borderRadius: 10,
    padding: '2pt 8pt 5pt 8pt',
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export interface PdfChartProps {
  chartTitle: string;
  chartImage: string;
  chartLabels: string[];
  chartLabelBackgroundColors: string[];
}

const PdfChart: React.FC<PdfChartProps> = ({
  chartTitle,
  chartImage,
  chartLabels,
  chartLabelBackgroundColors,
}) => {
  return (
    <>
      <View style={styles.chartImage}>
        <Text style={styles.chartTitle}>{chartTitle}</Text>
        <Image
          style={{
            ...styles.image,
          }}
          src={`${chartImage}`}
        />
        <View style={styles.chartLabels}>
          {chartLabels &&
            chartLabels?.map((label, index) => {
              return (
                <Text
                  key={index}
                  style={{
                    ...styles.chartLabel,
                    backgroundColor: `${(chartLabelBackgroundColors as any)[index]}`,
                  }}
                >
                  {label}
                </Text>
              );
            })}
        </View>
      </View>
    </>
  );
};

export default PdfChart;
