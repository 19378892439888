import styled from 'styled-components';
import { BODY, H3_SEMI_BOLD } from '../../../styles/helpers/typography';
import { ButtonWrapper } from '../../../components/button/Button.styled';
import { COLORS } from '../../../styles/constants/colors';
import { BREAKPOINTS } from '../../../styles/constants/devices';

const MyIndustrialSiteCardWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding: 20px;
  max-width: 100%;
  // width: max-content;
  position: relative;
  background-color: ${COLORS.WHITE};
  border-radius: 10px;

  .title {
    ${H3_SEMI_BOLD}
    display: flex;
    gap: 10px;
    align-items: center;
    width: max-content;
    flex-wrap: wrap;
    max-width: 100%;

    svg {
      width: 30px;
      height: 30px;
    }
  }

  .description {
    margin-bottom: 20px;
    ${BODY.M_REGULAR}
  }

  ${ButtonWrapper} {
    width: max-content;
    z-index: 2;
    ${BODY.M_MEDIUM}
    font-weight: bolder;

    @media (min-width: 450px) {
      ${BODY.L_SEMI_BOLD}
    }
  }

  .industrial-site-illustration {
    position: absolute;
    bottom: 0;
    right: 0;
    display: none;
    max-width: 290px;

    @media (${BREAKPOINTS.L}) {
      display: flex;
    }
  }

  @media (${BREAKPOINTS.L}) {
    padding: 45px 0 60px 45px;
  }
`;

export { MyIndustrialSiteCardWrapper };
