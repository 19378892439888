import { useContext } from 'react';
import { ISimulator } from '../../../../types/app';
import SimulatorLayout from '../../layout/SimulatorLayout';
import { CompressedAirWrapper } from './CompressedAir.styled';
import {
  CompressedAirContext,
  CompressedAirContextProps,
} from '../../../../context/simulators/CompressedAirContext';

export interface CompressedAirProps {
  simulator: ISimulator;
}

const CompressedAir: React.FC<CompressedAirProps> = ({ simulator }) => {
  const { forms, resultTableRows, resultCharts } = useContext(
    CompressedAirContext,
  ) as CompressedAirContextProps;

  return (
    <CompressedAirWrapper>
      {simulator && (
        <SimulatorLayout
          forms={forms}
          resultTableRows={resultTableRows}
          resultCharts={resultCharts}
          simulator={simulator}
        />
      )}
    </CompressedAirWrapper>
  );
};

export default CompressedAir;
