import styled from 'styled-components';
import { SingleSimulatorWrapper } from './single-simulator/SingleSimulator.styled';
import { CardWrapper } from '../../../components/card/Card.styled';

const SimulatorsListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns with equal width */
  gap: 30px;
  align-items: flex-start; /* Align items vertically at the start */

  // @media screen and (max-width: 1024px) {
  //   display: flex;
  //   flex-direction: column;
  //   width: 100%;

  //   ${SingleSimulatorWrapper} {
  //     width: 100%;

  //     ${CardWrapper} {
  //       width: 100%;
  //     }
  //   }
  // }

  ${SingleSimulatorWrapper} {
    margin-bottom: 20px;
  }
`;

export { SimulatorsListWrapper };
