import styled, { css } from 'styled-components';
import { BODY } from '../../styles/helpers/typography';
import { COLORS } from '../../styles/constants/colors';
import { IButtonTheme } from '../../types/app';

const ButtonWrapper = styled.button<{
  color?: string;
  backgroundcolor?: string;
  theme?: IButtonTheme;
  hasicon?: string;
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  border: none;
  ${BODY.S_MEDIUM};
  border-radius: 50px;
  background-color: ${COLORS.BRIGHT_BLUE};
  color: ${COLORS.WHITE};
  user-select: none;
  position: relative;
  font-weight: bold;
  transition: 0.1s linear;
  max-width: 100%;
  font-family: 'Jost';

  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }

  &:active {
    opacity: 1;
  }

  .button-label {
    text-align: start;
    font-family: 'Jost';
  }

  ${({ theme, hasicon }) =>
    theme &&
    css`
      ${theme === 'transparent' &&
      `
        background-color: ${COLORS.TRANSPARENT};
        color: ${COLORS.DARK_BLUE};
        border: 1px solid ${COLORS.DARK_BLUE};

        &:hover {
          cursor: pointer;
          opacity: 1;
          background-color: ${COLORS.DARK_BLUE};
          color: ${COLORS.WHITE};

          ${
            hasicon &&
            `
            .button-icon {
              color: ${COLORS.DARK_BLUE};
              background-color: ${COLORS.WHITE}
            }
          `
          }
        }
      `}
      ${theme === 'dark' &&
      `
        background-color: ${COLORS.DARK_BLUE};
        color: ${COLORS.WHITE};
        // border: 1px solid ${COLORS.BLUE_GRAY};
      `}

      ${theme === 'light' &&
      `
        background-color: ${COLORS.BRIGHT_BLUE};
        color: ${COLORS.WHITE};
        border: 1px solid ${COLORS.BRIGHT_BLUE};
      `}
    `}

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}

  ${({ backgroundcolor }) =>
    backgroundcolor &&
    css`
      background-color: ${backgroundcolor};
    `}
  ${({ hasicon, theme }) =>
    hasicon &&
    css`
      justify-content: space-between;
      .button-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        border-radius: 50px;
        width: 17px;
        height: 17px;
      }

      padding-right: 10px;
      ${theme === 'transparent' &&
      `
        .button-icon {
          background-color: ${COLORS.DARK_BLUE};
          color: ${COLORS.WHITE};
        }
      `}

      ${theme === 'dark' &&
      `
        .button-icon {
          background-color: ${COLORS.WHITE};
          color: ${COLORS.BLUE_GRAY};
        }
      `}

      ${theme === 'light' &&
      `
        .button-icon {
          background-color: ${COLORS.WHITE};
          color: ${COLORS.BRIGHT_BLUE};
        }
      `}
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed !important;
      pointer-events: all !important;

      &:hover {
        opacity: 0.5;
      }
    `}
`;

export { ButtonWrapper };
