import { useCallback, useContext, useEffect, useState } from 'react';
import MultipleColumnsTable from '../../../../components/table/multiple-columns-table/MultipleColumnsTable';
import AdminPageLayout from '../../layout/AdminPageLayout';
import { ReferenceDatasListWrapper } from './ReferenceDatasList.styled';
import { AdminReferenceDatasApi } from '../../../../api/Admin.ReferenceDatas.api';
import { AdminContext, AdminContextProps } from '../../../../context/admin/AdminContext';
import { IReferenceVersion } from '../../../../types/app';
import { formatDateString } from '../../../../utils';
import Icon from '../../../../components/icon/Icon';
import { ICONS, ROUTES } from '../../../../global';
import Button from '../../../../components/button/Button';
import Progress from '../../../../components/progress/Progress';
import Info from '../../../../components/info/Info';
import useCustomNavigate from '../../../../hooks/useCustomNavigate';
import Tag from '../../../../components/tag/Tag';

const ReferenceDatasList: React.FC = () => {
  const { admin } = useContext(AdminContext) as AdminContextProps;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [referenceVersions, setReferenceVersions] = useState<IReferenceVersion[]>();

  const navigate = useCustomNavigate();

  const initReferenceVersions = useCallback(async () => {
    if (admin) {
      try {
        setIsLoading(true);
        const newReferenceVersions = await AdminReferenceDatasApi.getReferenceVersions(admin.token);
        setReferenceVersions(newReferenceVersions);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }
  }, [admin]);

  useEffect(() => {
    initReferenceVersions();
  }, [initReferenceVersions]);

  const onClickEditIcon = async (version_id: number) => {
    navigate(`${ROUTES.ADMIN.INDEX}${ROUTES.ADMIN.REFERENCE_DATAS.EDIT}/${version_id}`);
  };

  return (
    <AdminPageLayout title="Admin - Données de références">
      <ReferenceDatasListWrapper>
        {/* <div className="new-reference-data-version">
          <Button theme="transparent" hasIcon label="Créer une nouvelle version" icon={'+'} />
        </div> */}
        {referenceVersions && !isLoading && (
          <MultipleColumnsTable
            columnsTitles={['id', 'Status', 'Actions', 'Date de création', 'Choix de version']}
            rows={referenceVersions.map((ref) => {
              return {
                values: [
                  <b>{ref.id}</b>,
                  <div className="version-wrapper">
                    <div
                      className={`version-flag ${
                        ref.isCurrent ? 'is-current-version' : 'not-current-version'
                      }`}
                    />{' '}
                    <div
                      className={`version-flag-txt ${
                        ref.isCurrent ? 'is-current-version' : 'not-current-version'
                      }`}
                    >
                      {ref.isCurrent ? 'Version actuelle' : ''}
                    </div>
                  </div>,
                  <div className="actions">
                    <div className="edit">
                      <Info toolTip="Modifier">
                        <Icon
                          icon={ICONS.EDIT}
                          onClick={() => {
                            onClickEditIcon(ref.id);
                          }}
                        />
                      </Info>
                    </div>
                  </div>,
                  `${formatDateString(ref.creationDate)}`,
                  <div className="set-as-current">
                    {!ref.isCurrent ? (
                      <>
                        {!isLoading ? (
                          <Button
                            theme="light"
                            onClick={async () => {
                              setIsLoading(true);
                              await AdminReferenceDatasApi.setCurrentVersion(
                                ref.id,
                                `${admin?.token}`,
                              );
                              setIsLoading(false);
                              await initReferenceVersions();
                            }}
                          >
                            Choisir comme version actuelle
                          </Button>
                        ) : (
                          <Progress />
                        )}
                      </>
                    ) : (
                      <Tag
                        label={'Version actuelle'}
                        className="is-current-version"
                      />
                    )}
                  </div>,
                ],
              };
            })}
          />
        )}
      </ReferenceDatasListWrapper>
    </AdminPageLayout>
  );
};

export default ReferenceDatasList;
