const COLORS = {
  GREEN: '#C6D200',
  BLUE: '#014289',
  DARK_BLUE: '#002854',
  BRIGHT_BLUE: '#0084ED',
  LIGHT_BLUE: '#D3E6FC',
  WHITE: '#FFFFFF',
  BLUE_GRAY: '#84A3BD',
  SKY_BLUE: '#E9F3FF',
  DARK_GREY: '#5A5A5A',
  BLACK: '#231916',
  TRANSPARENT: 'transparent',
  GAZ_BG: '#0084ED',
  GAZ_LABEL: '#FFFFFF',
  ELECTRICITY_BG: '#C6D20090',
  ELECTRICITY_LABEL: '#002854',
  TOTAL_GAZ_ELECTRICITY_BG: '#5A5A5A',
  TOTAL_GAZ_ELECTRICITY_LABEL: '#FFFFFF',
  WARNING: '#E45407',
  INFO: '#0084ED',
};

export { COLORS };
