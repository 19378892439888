import { SxProps, TablePagination, Theme } from '@mui/material';
import { IPagination } from '../../../types/app';
import { MultipleColumnsTableWrapper } from './MultipleColumnsTable.styled';
import { COLORS } from '../../../styles/constants/colors';
import { ChangeEvent, MouseEvent } from 'react';

export interface MultipleColumnsTableProps {
  columnsTitles: string[];
  rows?: {
    label?: string;
    values?: any[];
  }[];
  header?: any;
  pagination?: IPagination;
  onRowsPerPageChange?: (value: number) => void;
  onPageChange?: (value: number) => void;
}

const tablePaginationStyles: SxProps<Theme> = {
  color: COLORS.DARK_BLUE,
  borderRadius: 3,
  fontWeight: 'bolder',
};

const MultipleColumnsTable: React.FC<MultipleColumnsTableProps> = ({
  columnsTitles,
  rows,
  header,
  pagination,
  onRowsPerPageChange,
  onPageChange,
}) => {
  const tablePaginationProps = {
    count: pagination?.totalCount,
    page: pagination?.currentPage,
    component: 'div',
    rowsPerPage: pagination?.rowsCount,
    ...(pagination && {
      labelRowsPerPage: `Nombre de lignes à afficher`,
      labelDisplayedRows: () => `Page ${pagination.currentPage + 1}`,
    }),
    showFirstButton: true,
    showLastButton: true,
    rowsPerPageOptions: [10, 20, 50, 100],
    onRowsPerPageChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newRowsPerPage = e.target.value;
      onRowsPerPageChange && onRowsPerPageChange(Number(newRowsPerPage));
    },
    onPageChange: (e: MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
      onPageChange && onPageChange(Number(page));
    },
  };

  return (
    <MultipleColumnsTableWrapper>
      <thead>
        {rows && rows.length > 0 && pagination && (
          <tr>
            <td colSpan={columnsTitles.length}>
              <TablePagination sx={tablePaginationStyles} {...(tablePaginationProps as any)} />
            </td>
          </tr>
        )}
        <tr>{columnsTitles && columnsTitles.map((title, key) => <th key={key}>{title}</th>)}</tr>
      </thead>
      <tbody>
        {rows &&
          rows.length > 0 &&
          rows.map((row, key) => {
            return (
              <tr
                key={key}
                className={`${key === rows.length - 1 && !pagination ? 'last-tr' : ''}`}
              >
                {row?.values &&
                  row?.values?.map((value, index) => {
                    return (
                      <td
                        className={`${
                          row?.values?.length && index === row?.values?.length - 1 && 'last'
                        }`}
                        key={index}
                      >
                        {value}
                      </td>
                    );
                  })}
              </tr>
            );
          })}
        {!rows ||
          (rows.length <= 0 && (
            <tr className="no-rows-to-show">
              <td colSpan={columnsTitles.length}>Pas de ligne à afficher...</td>
            </tr>
          ))}
        {rows && rows.length > 0 && pagination && (
          <tr className="last-tr">
            <td colSpan={columnsTitles.length}>
              <TablePagination style={tablePaginationStyles} {...(tablePaginationProps as any)} />
            </td>
          </tr>
        )}
      </tbody>
    </MultipleColumnsTableWrapper>
  );
};

export default MultipleColumnsTable;
