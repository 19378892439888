import styled from 'styled-components';
import { BODY } from '../../../styles/helpers/typography';
import { COLORS } from '../../../styles/constants/colors';

const SimulatorExplicationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .description {
    ${BODY.M_REGULAR}
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 4.5em;
    white-space: normal;
  }

  .more {
    margin: 0;
    width: max-content;
    color: ${COLORS.DARK_BLUE};
    text-decoration: underline;
    ${BODY.M_BOLD}

    &:hover {
      cursor: pointer;
    }
  }
`;

const FullExplications = styled.div`
  ${BODY.S_REGULAR}
`;

export { SimulatorExplicationsWrapper, FullExplications };
