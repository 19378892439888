import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { COLORS } from '../../../../styles/constants/colors';
import { ITableRow } from '../../../../components/table/Table';

import fontMedium from '../../../detailed-report/pdf/fonts/Jost-Medium.ttf';
import fontBold from '../../../detailed-report/pdf/fonts/Jost-Bold.ttf';
import PdfTable from '../../../detailed-report/pdf/pdf-table/PdfTable';

import Logo from '../../../../assets/images/logo-actemium.png';
import { ISimulator, ISimulatorResultChart } from '../../../../types/app';
import PdfChart from '../../../detailed-report/pdf/pdf-chart/PdfChart';
import { RESPONSABILITY_NOTE } from '../../../../global';

Font.register({
  family: 'Jost',
  fonts: [
    {
      src: fontMedium,
    },
    {
      src: fontBold,
      fontWeight: 'bold',
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: COLORS.WHITE,
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    width: '100%',
    height: '100%',
    padding: 20,
    color: COLORS.DARK_BLUE,
    fontFamily: 'Jost',
  },
  titlePage: {
    height: '95vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 30,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
  },
  logo: {
    width: 170,
    maxWwidth: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  detailedResults: {
    display: 'flex',
    gap: 20,
    marginBottom: 10,
  },
  detailedResultsTitle: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    position: 'absolute',
    top: 5,
    left: 5,
  },
  note: {
    fontSize: 11,
    textAlign: 'center',
    color: `${COLORS.DARK_BLUE}80`,
    margin: '20px 0',
    fontWeight: 'normal',
    backgroundColor: 'white',
    borderRadius: 16,
    padding: 20,
    border: `1pt solid ${COLORS.LIGHT_BLUE}`,
  },
});

export interface PdfSimulatorResultsProps {
  resultTablesRows: ITableRow[];
  simulator: ISimulator;
  charts: ISimulatorResultChart[];
}

const PdfSimulatorResults: React.FC<PdfSimulatorResultsProps> = ({
  resultTablesRows,
  simulator,
  charts,
}) => {
  return (
    <Document title="Resutats de votre simulation">
      <Page size="A4" style={styles.page}>
        <View style={styles.detailedResults}>
          <View style={styles.detailedResultsTitle}>
            <Image style={styles.logo} src={Logo} />
          </View>
        </View>
        {/* Grand titre */}
        <View style={styles.titlePage}>
          <Text style={styles.title}>Résultats - {simulator.name}</Text>
        </View>
        <View>
          <PdfTable header={<Text>{simulator.name}</Text>} rows={resultTablesRows} />
        </View>
        <View break />
        {charts &&
          charts.map((chart, key) => {
            return (
              <PdfChart
                key={key}
                chartImage={`${chart.b64Image}`}
                chartLabelBackgroundColors={chart.chart.labelsBackgroundColors || []}
                chartLabels={chart.chart.labels || []}
                chartTitle={chart.chart.chartTitle || ''}
              />
            );
          })}
        <Text style={styles.note}>{RESPONSABILITY_NOTE}</Text>
      </Page>
    </Document>
  );
};

export default PdfSimulatorResults;
