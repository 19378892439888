import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Dashboard from './pages/dashboard/Dashboard';
import { ROUTES } from './global';
import Simulator from './pages/simulator/Simulator';
import { ReferenceDatasProvider } from './context/ReferenceDatasContext';
import Landing from './pages/landing/Landing';
import { useContext, useEffect } from 'react';
import { skipIntro } from './utils';
import { SimulatorsProvider } from './context/simulators/SimulatorsContext';
import { IndustrialSiteProvider } from './context/simulators/IndustrialSiteContext';
import { MonitoringProvider } from './context/simulators/MonitoringContext';
import { CompressedAirProvider } from './context/simulators/CompressedAirContext';
import { HeatRecoveryProvider } from './context/simulators/HeatRecoveryContext';
import { RoomAirDestratificationProvider } from './context/simulators/RoomAirDestratificationContext';
import { MicroModulatingBurnerProvider } from './context/simulators/MicroModulatingBurnerContext';
import { NavigationProvider } from './context/NavigationContext';
import { InsulatingMattressesProvider } from './context/simulators/InsulatingMattressesContext';
import { LedLightingProvider } from './context/simulators/LedLightingContext';
import { HeatPumpProvider } from './context/simulators/HeatPumpContext';
import NotFound from './pages/404/NotFound';
import { ElectricSpeedVariationProvider } from './context/simulators/ElectricSpeedVariationContext';
import { HighPerformanceEnginesProvider } from './context/simulators/HighPerformanceEnginesContext';
import { MotorDriveProvider } from './context/simulators/MotorDriveContext';
import { UserContext, UserContextProps } from './context/user/UserContext';
import DetailedReport from './pages/detailed-report/DetailedReport';
import Modal from './components/Modal/Modal';
import { ModalProvider } from './context/ModalContext';
import { Toaster } from 'react-hot-toast';
import AOS from 'aos';
import { LoadingProvider } from './context/LoadingContext';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import AuthAdmin from './pages/admin/auth/AuthAdmin';
import Admin from './pages/admin/Admin';
import View from './pages/view/View';
import { SimulatorsSummaryProvider } from './context/simulators/summary/SimulatorsSummaryContext';

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAdminView, user } = useContext(UserContext) as UserContextProps;

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    if (!skipIntro() && !isAdminView) {
      const urlSearch = new URLSearchParams(location.search);
      const nextRoute = urlSearch.get('_next');
      if (location.pathname === ROUTES.HOME && nextRoute)
        navigate(`${ROUTES.HOME}?_next=${nextRoute}`);
      else if (location.pathname.includes(ROUTES.ADMIN.INDEX)) {
        return;
      } else if (location.pathname.includes(ROUTES.VIEW.INDEX)) return;
      else {
        navigate(`${ROUTES.HOME}?_next=${location.pathname}`);
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <ReferenceDatasProvider>
      <SimulatorsProvider>
        <IndustrialSiteProvider>
          <MonitoringProvider>
            <CompressedAirProvider>
              <HeatRecoveryProvider>
                <RoomAirDestratificationProvider>
                  <MicroModulatingBurnerProvider>
                    <InsulatingMattressesProvider>
                      <LedLightingProvider>
                        <HeatPumpProvider>
                          <ElectricSpeedVariationProvider>
                            <HighPerformanceEnginesProvider>
                              <MotorDriveProvider>
                                <SimulatorsSummaryProvider>
                                  <LoadingProvider>
                                    <NavigationProvider>
                                      <ModalProvider>
                                        <Modal />
                                        <Toaster />
                                        <ReactTooltip id="my-tooltip" />
                                        <Routes>
                                          <Route
                                            path={ROUTES.ADMIN.LOGIN}
                                            element={<AuthAdmin />}
                                          />
                                          <Route
                                            path={`${ROUTES.ADMIN.INDEX}/*`}
                                            element={<Admin />}
                                          />
                                          <Route path={ROUTES.HOME} element={<Landing />} />
                                          <Route path={ROUTES.LANDING} element={<Landing />} />
                                          <Route
                                            path={ROUTES.DASHBOARD}
                                            element={
                                              <Dashboard
                                                isViewerOnly={user?.details?.isViewerOnly}
                                              />
                                            }
                                          />
                                          <Route
                                            path={`${ROUTES.SIMULATOR.INDEX}/:name`}
                                            element={<Simulator />}
                                          />
                                          <Route
                                            path={ROUTES.DETAILED_REPORT}
                                            element={
                                              <DetailedReport
                                                isViewerOnly={user?.details?.isViewerOnly}
                                              />
                                            }
                                          />
                                          <Route path={ROUTES.VIEW.INDEX} element={<View />} />
                                          <Route path="*" element={<NotFound />} />
                                        </Routes>
                                      </ModalProvider>
                                    </NavigationProvider>
                                  </LoadingProvider>
                                </SimulatorsSummaryProvider>
                              </MotorDriveProvider>
                            </HighPerformanceEnginesProvider>
                          </ElectricSpeedVariationProvider>
                        </HeatPumpProvider>
                      </LedLightingProvider>
                    </InsulatingMattressesProvider>
                  </MicroModulatingBurnerProvider>
                </RoomAirDestratificationProvider>
              </HeatRecoveryProvider>
            </CompressedAirProvider>
          </MonitoringProvider>
        </IndustrialSiteProvider>
      </SimulatorsProvider>
    </ReferenceDatasProvider>
  );
}

export default App;
