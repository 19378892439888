import styled from 'styled-components';
import { COLORS } from '../../../styles/constants/colors';
import { BODY } from '../../../styles/helpers/typography';

const FiltersDetailedReportWrapper = styled.div`
  border-bottom: 1px solid ${COLORS.DARK_BLUE}50;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  .filters {
    display: flex;
    gap: 20px;

    .filter {
      ${BODY.M_REGULAR}
      color: ${COLORS.DARK_BLUE}60;
      cursor: pointer;
      position: relative;
      transition: 0.2s linear;
      display: flex;
      align-items: center;

      &.active,
      &:hover {
        color: ${COLORS.BRIGHT_BLUE};

        &:after {
          position: absolute;
          content: '';
          width: 100%;
          height: 1px;
          border-bottom: 3px solid ${COLORS.BRIGHT_BLUE};
          bottom: -12px;
          left: 0;
        }
      }
    }
  }
`;

export { FiltersDetailedReportWrapper };
