import styled from 'styled-components';
import { CardTitle, CardWrapper } from '../../../components/card/Card.styled';
import { COLORS } from '../../../styles/constants/colors';
import { BODY } from '../../../styles/helpers/typography';
import { BREAKPOINTS } from '../../../styles/constants/devices';

const AxesToDeployCardWrapper = styled.div`
  ${CardWrapper} {
    background-color: ${COLORS.WHITE};
    border: 1px solid #0084ed60;
    box-shadow: 1px 4px 14px 6px #dae8f9;

    .card-children {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }

    ${CardTitle} {
      color: ${COLORS.DARK_BLUE};
      background-color: ${COLORS.LIGHT_BLUE}90;
      padding: 24px;
      ${BODY.L_SEMI_BOLD}
    }

    .estimated-economy-and-gain {
      display: flex;
      gap: 10px;
      justify-content: space-between;
      padding: 10px 25px;

      .subtitle {
        ${BODY.M_MEDIUM}
      }

      .estimations {
        display: flex;
        flex-direction: column;
        gap: 0px;
        padding: 0 10px;

        .empty-value {
          width: 20px;
          height: 3px;
          background-color: ${COLORS.BRIGHT_BLUE};
        }

        .value {
          ${BODY.XL_SEMI_BOLD}
          font-size: 25px;
          letter-spacing: 0em;
          text-align: left;
          color: ${COLORS.BRIGHT_BLUE};
          margin: 0;
          max-width: 100%;
          white-space: initial;
          word-break: break-word;
          hyphens: initial;
          overflow-wrap: break-word;

          @media (${BREAKPOINTS.XXL}) {
            font-size: 32px;
          }
        }

        .estimation-unit {
          ${BODY.L_REGULAR}
        }
      }
    }

    .show-filters-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      flex-wrap: wrap;
      gap: 20px;

      .select-filter {
        width: 200px;
      }
    }
    
    .axes-to-deploy {
      display: flex;
      flex-direction: column;
      gap: 10px;
      background-color: ${COLORS.LIGHT_BLUE}90;
      padding: 5px 25px 25px 25px;

      .single-axe {
        background-color: ${COLORS.WHITE};
        padding: 10px;
        display: flex;
        justify-content: space-between;
        border-radius: 1.6px;

        .axe-name {
          .key {
            color: ${COLORS.BRIGHT_BLUE};
            ${BODY.L_SEMI_BOLD}
            margin-right: 10px;
          }
        }

        &:first-child {
          width: 90%;
        }

        &:nth-child(2) {
          width: 80%;
        }

        &:nth-child(3) {
          width: 70%;
        }
      }
    }
  }
`;

export { AxesToDeployCardWrapper };
