import { ModalTitle } from '../../../../../components/Modal/Modal.styled';
import { ICONS } from '../../../../../global';
import { PopupContactUsWrapper } from './PopupContactUs.styled';
import Logo from '../../../../../assets/images/logo.png';
import TextField from '../../../../../components/form/text-field/TextField';
import { useContext, useEffect, useState } from 'react';
import { ModalContext, ModalContextProps } from '../../../../../context/ModalContext';
import Button from '../../../../../components/button/Button';
import { isValidEmail } from '../../../../../utils';
import toast from 'react-hot-toast';
import { UserApi } from '../../../../../api/User.api';
import { UserContext, UserContextProps } from '../../../../../context/user/UserContext';
import Progress from '../../../../../components/progress/Progress';
import PopupSuccessContact from '../contact-success/PopupSuccessContact';
import { IUserDetails } from '../../../../../types/app';
import SelectBox from '../../../../../components/form/select-box/SelectBox';
import { DEPARTMENTS } from './departments.datas';

export interface IContactForm {
  lastname?: string;
  firstname?: string;
  email?: string;
  number?: string;
  company?: string;
  jobPosition?: string;
  department?: string;
}

export interface IContact {
  form?: IContactForm;
  onChange?: (e: IContactForm) => void;
}

export const PopupContactUs: React.FC<IContact> = ({ form, onChange }) => {
  const { updateModalConf } = useContext(ModalContext) as ModalContextProps;
  const { userToken, initUserDatas } = useContext(UserContext) as UserContextProps;
  const [lastNameState, setLastNameState] = useState<string>(form?.lastname || '');
  const [firstNameState, setFirstNameState] = useState<string>(form?.firstname || '');
  const [emailState, setEmailState] = useState<string>(form?.email || '');
  const [numberState, setNumberState] = useState<string>(form?.number || '');
  const [companyState, setCompanyState] = useState<string>(form?.company || '');
  const [departmentState, setDepartmentState] = useState<string>(form?.department || '');
  const [jobPositionState, setJobPositionState] = useState<string>(form?.jobPosition || '');

  const [sendingContactInfos, setSendingContactInfos] = useState<boolean>(false);

  const isValidForm = () => {
    return !!(
      lastNameState &&
      firstNameState &&
      emailState &&
      isValidEmail(emailState) &&
      numberState
    );
  };

  useEffect(() => {
    onChange &&
      onChange({
        company: companyState,
        email: emailState,
        firstname: firstNameState,
        jobPosition: jobPositionState,
        lastname: lastNameState,
        number: numberState,
        department: departmentState,
      });
  }, [
    companyState,
    emailState,
    firstNameState,
    jobPositionState,
    lastNameState,
    numberState,
    departmentState,
    onChange,
  ]);

  const onConfirm = async () => {
    if (isValidForm()) {
      setSendingContactInfos(true);
      let newUserDetails: IUserDetails = {
        firstname: firstNameState,
        lastname: lastNameState,
        email: emailState,
        phoneNumber: numberState,
        ...(jobPositionState && {
          jobPosition: jobPositionState,
        }),
        ...(companyState && {
          companyName: companyState,
        }),
        ...(departmentState && {
          department: departmentState,
        }),
        wantToBeContacted: 1,
      };
      await UserApi.updateUserDetails(userToken, newUserDetails);
      await initUserDatas(userToken);
      updateModalConf({
        component: <PopupSuccessContact />,
      });
      setSendingContactInfos(false);
    } else {
      toast.error('Veuillez remplir le formulaire correctement');
    }
  };

  return (
    <PopupContactUsWrapper>
      <ModalTitle>
        <p>Échangez sur vos résultats avec votre conseiller dédié !</p>
        <span className="close-icon" onClick={() => updateModalConf()}>
          {ICONS.MENU_CLOSE?.icon}
        </span>
      </ModalTitle>
      <div className="contact-header">
        <img src={Logo} alt="olivier" className="olivier-img" />
        <div className="explanations">
          « Vous allez obtenir votre rapport complet. Vous pouvez échanger avec nos experts et
          bénéficier d'une discussion sur votre projet. Un email de confirmation vous sera envoyé
          avec un récapitulatif des informations partagées. »
        </div>
      </div>
      <form
        className="contact-form"
        onSubmit={(e) => {
          e.preventDefault();
          onConfirm();
        }}
      >
        <div className="form-col names">
          <TextField
            type="text"
            label="Nom"
            value={lastNameState}
            onChange={(e) => {
              setLastNameState(e);
            }}
            placeholder="Votre nom"
            controlled
            required
          />
          <TextField
            type="text"
            label="Prénom"
            value={firstNameState}
            onChange={(e) => {
              setFirstNameState(e);
            }}
            placeholder="Votre prénom"
            controlled
            required
          />
        </div>
        <div className="form-col contacts">
          <TextField
            type="text"
            label="Email"
            value={emailState}
            placeholder="Votre Email"
            onChange={(e) => setEmailState(e)}
            controlled
            required
          />
          <TextField
            type="text"
            label="Numéro de téléphone"
            value={numberState}
            onChange={(e) => {
              setNumberState(e);
            }}
            placeholder="Votre numéro de téléphone"
            controlled
            required
          />
        </div>
        <div className="form-col job">
          <TextField
            type="text"
            label="Nom de votre entreprise"
            value={companyState}
            onChange={(e) => {
              setCompanyState(e);
            }}
            placeholder="Nom de votre entreprise"
            controlled
          />
          <SelectBox
            label="Département"
            options={DEPARTMENTS.map((item) => {
              return {
                label: item.name,
                selected: departmentState === item.name,
                value: item.name,
              };
            })}
            placeholder="Votre département"
            onChange={(e) => {
              setDepartmentState(e.find((item) => item.selected === true)?.label as string);
            }}
          />
          {/* <TextField
            type="text"
            label="Département"
            value={departmentState}
            onChange={(e) => {
              setDepartmentState(e);
            }}
            placeholder="Votre département"
            controlled
          /> */}
        </div>
        <div className="form-col job-position">
          <TextField
            type="text"
            label="Poste occupé"
            value={jobPositionState}
            onChange={(e) => {
              setJobPositionState(e);
            }}
            placeholder="Votre poste"
            controlled
          />
        </div>
        {!sendingContactInfos ? (
          <Button
            className={`${!isValidForm() && 'disabled'}`}
            hasIcon
            label="Soumettre"
            theme="dark"
            disabled={!isValidForm()}
          />
        ) : (
          <Progress />
        )}
      </form>
    </PopupContactUsWrapper>
  );
};

export default PopupContactUs;
