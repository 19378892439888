import { createContext, useContext, useEffect, useState } from 'react';
import { IComponentWithChildren, IForm, ISimulatorRequiredDatas } from '../../types/app';
import { FormTitle } from '../../components/form/Form.styled';
import { ITableRow } from '../../components/table/Table';
import { ReferenceDatasContext, ReferenceDatasContextProps } from '../ReferenceDatasContext';
import { customRound, customRoundDown } from '../../utils';
import { ChartProps } from '../../components/chart/Chart';
import { COLORS } from '../../styles/constants/colors';
import { SimulatorsContext, SimulatorsContextProps } from './SimulatorsContext';
import { UserContext, UserContextProps } from '../user/UserContext';

import useDelayedUpdate from '../../hooks/useDelayedUpdate';

export interface RoomAirDestratificationContextProps extends ISimulatorRequiredDatas {
  energyDedicatedToSpaceHeating: number;
  buildingHeight: number;
  surfaceArea: number;
  forms: Array<IForm.IForm>;

  energyGain: number;
  co2EmissionsAvoided: number;
  estimatedFinancialSavings: number;
  averageRoi: number;
  estimatedBudget: number;
  resultTableRows: Array<ITableRow>;

  energyMatrix: number[][];
  actionMatrix: number[][];
  resultCharts: ChartProps[][];
}

const RoomAirDestratificationContext = createContext<
  RoomAirDestratificationContextProps | undefined
>(undefined);

const RoomAirDestratificationProvider: React.FC<IComponentWithChildren> = ({ children }) => {
  const { CARBON_CONTENT_PER_KWH_GAZ, AVERAGE_GAS_PRICE_PER_MWH, NATURAL_GAS_TRENDS } = useContext(
    ReferenceDatasContext,
  ) as ReferenceDatasContextProps;
  const { getSimulatorByCode, simulators, editSimulatorDatas } = useContext(
    SimulatorsContext,
  ) as SimulatorsContextProps;
  const { userToken, updateUserSimulatorDatas } = useContext(UserContext) as UserContextProps;

  const [energyDedicatedToSpaceHeating, setEnergyDedicatedToSpaceHeating] = useState(0);
  const [buildingHeight, setBuildingHeight] = useState(0);
  const [surfaceArea, setSurfaceArea] = useState(0);
  const [forms, setForms] = useState<Array<IForm.IForm>>([]);

  const [energyGain, setEnergyGain] = useState(0);
  const [co2EmissionsAvoided, setCo2EmissionsAvoided] = useState(0);
  const [estimatedFinancialSavings, setEstimatedFinancialSavings] = useState(0);
  const [averageRoi, setAverageRoi] = useState(0);
  const [estimatedBudget, setEstimatedBudget] = useState(0);
  const [resultTableRows, setResultTableRows] = useState<Array<ITableRow>>([]);

  const [energyMatrix, setEnergyMatrix] = useState<Array<Array<number>>>([
    [0, 0],
    [0, 0],
  ]);
  const [actionMatrix, setActionMatrix] = useState<number[][]>([]);
  const [roiMatrix, setRoiMatrix] = useState<number[][]>([]);
  const [resultCharts, setResultCharts] = useState<Array<Array<ChartProps>>>([]);

  const [buildingHeightsReferences, setBuildingHeightsReferences] = useState<
    Array<{ height: number; gain: number }>
  >([]);

  const [updateDatas, setUpdateDatas] = useState<object>({});

  //   building heights references
  useEffect(() => {
    let gainIncrement = 1.42857142857143;
    let loopCount = 0;
    let arrRes: Array<{ height: number; gain: number }> = [];
    for (let i = 4; i < 13; i += 0.5) {
      let gain = 0;
      if (i === 4) gain = 0;
      else {
        if (i > 7.5) {
          gainIncrement = 1.5;
        }
        gain = gainIncrement * loopCount - 0.5;
        if (i === 7.5) gain = 10;
      }
      arrRes.push({
        height: i,
        gain: gain,
      });
      loopCount++;
    }
    setBuildingHeightsReferences([...arrRes]);
  }, []);

  const [allowRequest, setAllowRequest] = useState<boolean>(false);

  //   Forms
  useEffect(() => {
    const buildingHeightOptions: Array<IForm.IOption> = buildingHeightsReferences.map(
      (reference) => {
        return {
          label: `${reference.height}`,
          selected: reference.height === buildingHeight,
          value: reference.gain,
        };
      },
    );

    setForms([
      {
        onChange: () => setAllowRequest(true),
        header: <FormTitle>Remplissez les champs suivants :</FormTitle>,
        fields: [
          {
            label: 'Energie dédiée au chauffage des locaux',
            name: '',
            type: 'textfield',
            textType: 'number',
            value: energyDedicatedToSpaceHeating,
            unit: 'MWh/an',
            onChange: (e: number) => setEnergyDedicatedToSpaceHeating(e),
          },
          {
            label: 'Hauteur du batiment',
            name: '',
            type: 'selectbox',
            textType: 'number',
            value: buildingHeight,
            unit: 'm',
            options: buildingHeightOptions,
            onChange: (e: IForm.IOption[]) =>
              setBuildingHeight(Number(e.find((option) => option.selected)?.label)),
          },
          {
            label: 'Surface concernée',
            name: '',
            type: 'textfield',
            textType: 'number',
            value: surfaceArea,
            unit: 'm²',
            onChange: (e: number) => setSurfaceArea(e),
          },
        ],
      },
    ]);
  }, [energyDedicatedToSpaceHeating, buildingHeight, surfaceArea, buildingHeightsReferences]);

  //   energy gain
  useEffect(() => {
    setEnergyGain(
      ((buildingHeightsReferences.find((ref) => ref.height === buildingHeight)?.gain || 0) *
        energyDedicatedToSpaceHeating) /
        100,
    );
  }, [energyDedicatedToSpaceHeating, buildingHeight, buildingHeightsReferences]);

  //   co2 emissions avoided
  useEffect(() => {
    setCo2EmissionsAvoided(energyGain * CARBON_CONTENT_PER_KWH_GAZ?.value);
  }, [energyGain, CARBON_CONTENT_PER_KWH_GAZ?.value]);

  //   Estimated financial saving
  useEffect(() => {
    setEstimatedFinancialSavings(
      customRoundDown(energyGain * AVERAGE_GAS_PRICE_PER_MWH?.value, -2),
    );
  }, [energyGain, AVERAGE_GAS_PRICE_PER_MWH?.value]);

  // average roi
  useEffect(() => {
    setAverageRoi(estimatedBudget / estimatedFinancialSavings);
  }, [estimatedBudget, estimatedFinancialSavings]);

  //   Estimated budget
  useEffect(() => {
    setEstimatedBudget(
      customRound(5000 + energyDedicatedToSpaceHeating * 50 + surfaceArea * 3, -2),
    );
  }, [energyDedicatedToSpaceHeating, surfaceArea]);

  //   Result rows
  useEffect(() => {
    setResultTableRows([
      {
        label: 'Gain énergétique',
        unit: 'MWh/an',
        value: `${energyGain}`,
      },
      {
        label: 'Emissions de CO2 évitées (chauffage au gaz)',
        unit: 'T CO2/an',
        value: `${co2EmissionsAvoided}`,
      },
      {
        label: 'Gain financier estimé (pour un chauffage au gaz)',
        unit: '€/an',
        value: `${estimatedFinancialSavings}`,
      },
      {
        label: 'Enveloppe budgétaire estimée',
        unit: '€',
        value: `${estimatedBudget}`,
      },
      {
        label: 'ROI moyen pour cette action (le ROI n’inclut pas les primes et aides (CEE,…))',
        unit: 'ans',
        value: `${averageRoi}`,
      },
    ]);
  }, [energyGain, co2EmissionsAvoided, estimatedFinancialSavings, averageRoi, estimatedBudget]);

  // Energy matrix
  useEffect(() => {
    let tmpMatrix: number[][] = [
      [0, 0],
      [0, 0],
    ];
    tmpMatrix[0][0] = (energyDedicatedToSpaceHeating / surfaceArea) * 1000;
    tmpMatrix[0][1] = (1000 * (energyDedicatedToSpaceHeating - energyGain)) / surfaceArea;
    tmpMatrix[1][0] = 110;
    tmpMatrix[1][1] = 110;
    setEnergyMatrix(tmpMatrix);
  }, [energyDedicatedToSpaceHeating, surfaceArea, energyGain]);

  //   action matrix
  useEffect(() => {
    let tmpMatrix: number[][] = [
      [0, 0],
      [0, 0],
      [0, 0],
      [0, 0],
    ];
    tmpMatrix[0][0] = Number(energyDedicatedToSpaceHeating);
    tmpMatrix[0][1] = tmpMatrix[0][0] * CARBON_CONTENT_PER_KWH_GAZ?.value;

    tmpMatrix[1][0] = energyGain;
    tmpMatrix[1][1] = tmpMatrix[1][0] * CARBON_CONTENT_PER_KWH_GAZ?.value;

    tmpMatrix[2][0] = tmpMatrix[0][0] - tmpMatrix[1][0];
    tmpMatrix[2][1] = tmpMatrix[0][1] - tmpMatrix[1][1];

    tmpMatrix[3][0] = tmpMatrix[1][0];
    tmpMatrix[3][1] = tmpMatrix[1][1];
    setActionMatrix(tmpMatrix);
  }, [energyDedicatedToSpaceHeating, CARBON_CONTENT_PER_KWH_GAZ?.value, energyGain]);

  //   Roi matrix
  useEffect(() => {
    const investment = -estimatedBudget;
    const initialRealizedEconomy = 0;
    const firstYearEconomy = estimatedFinancialSavings;
    let tmpMatrix: number[][] = [
      [0, firstYearEconomy],
      [investment + initialRealizedEconomy, investment + firstYearEconomy],
    ];
    const yearsCount = 8;
    for (let i = 2; i <= yearsCount; i++) {
      tmpMatrix[0] = [...tmpMatrix[0], tmpMatrix[0][i - 1] * (1 + NATURAL_GAS_TRENDS?.value / 100)];
      tmpMatrix[1] = [...tmpMatrix[1], tmpMatrix[0][i] + tmpMatrix[1][i - 1]];
    }
    setRoiMatrix(tmpMatrix);
  }, [estimatedBudget, estimatedFinancialSavings, NATURAL_GAS_TRENDS?.value]);

  // charts
  useEffect(() => {
    setResultCharts([
      [
        {
          chartType: 'bar',
          chartTitle: 'Consommation spécifique de chauffage',
          labels: ['Energie spécifique', 'Consommation spécifique de référence'],
          labelsBackgroundColors: [COLORS.DARK_BLUE, COLORS.BRIGHT_BLUE],
          labelsColors: [COLORS.WHITE, COLORS.WHITE],
          datas: {
            labels: ['Initial', 'Final'],
            datasets: [
              {
                label: '',
                data: energyMatrix[0],
                backgroundColor: [COLORS.DARK_BLUE],
              },
              {
                label: '',
                data: energyMatrix[1],
                backgroundColor: [COLORS.BRIGHT_BLUE],
              },
            ],
          },
          defaultUnit: 'MWh/an',
          yUnit: 'MWh/an',
        },
        {
          chartType: 'bar',
          chartTitle: "Gain énergétiques et baisse d'émissions associées au projet",
          labels: ['Etat final', "Réalisation de l'action"],
          labelsBackgroundColors: [COLORS.BRIGHT_BLUE, COLORS.DARK_BLUE],
          labelsColors: [COLORS.WHITE, COLORS.WHITE],
          datas: {
            labels: [['Energie (MWh)'], 'CO2 (tonnes)'],
            datasets: [
              {
                label: '',
                data: actionMatrix[2],
                backgroundColor: [COLORS.BRIGHT_BLUE],
                maxBarThickness: 50,
              },
              {
                label: '',
                data: actionMatrix[3],
                backgroundColor: [COLORS.DARK_BLUE],
                maxBarThickness: 50,
              },
            ],
          },
          // defaultUnit: 'MWh/an',
          options: {
            responsive: true,
            scales: {
              x: {
                stacked: true,
              },
              y: {
                stacked: true,
              },
            },
          },
        },
      ],
      [
        {
          chartType: 'bar',
          chartTitle: 'Retour sur investissement pour cette action',
          datas: {
            labels: roiMatrix[1]?.map((roi, index) => `Année ${index}`),
            datasets: [
              {
                data: roiMatrix[1],
                backgroundColor: roiMatrix[1]?.map((roi) =>
                  roi > 0 ? COLORS.BRIGHT_BLUE : COLORS.LIGHT_BLUE,
                ),
              },
            ],
          },
          defaultUnit: '€',
          yUnit: '€',
          options: {
            responsive: true,
          },
        },
      ],
    ]);
  }, [energyMatrix, actionMatrix, roiMatrix]);

  useEffect(() => {
    const sim = getSimulatorByCode('ROOM_AIR_DESTRATIFICATION');
    if (sim) {
      setEnergyDedicatedToSpaceHeating(Number(sim?.datas?.energyDedicatedToSpaceHeating));
      setBuildingHeight(Number(sim?.datas?.buildingHeight));
      setSurfaceArea(Number(sim?.datas?.surfaceArea));
    }
    // eslint-disable-next-line
  }, [simulators]);

  useEffect(() => {
    if (userToken) {
      const newDatas = {
        energyDedicatedToSpaceHeating,
        buildingHeight,
        surfaceArea,
      };

      setUpdateDatas(newDatas);
    }
  }, [
    userToken,
    energyGain,
    estimatedFinancialSavings,
    co2EmissionsAvoided,
    energyDedicatedToSpaceHeating,
    buildingHeight,
    surfaceArea,
  ]);

  const updateUserSimulatorDatasFunc = () => {
    // Your logic to update the user simulator datas
    allowRequest &&
      updateDatas &&
      updateUserSimulatorDatas(userToken, 'ROOM_AIR_DESTRATIFICATION', updateDatas).then(() => {
        editSimulatorDatas('ROOM_AIR_DESTRATIFICATION', updateDatas);
      });
  };

  useDelayedUpdate(updateUserSimulatorDatasFunc, [updateDatas], 250);

  return (
    <RoomAirDestratificationContext.Provider
      value={{
        energyDedicatedToSpaceHeating,
        buildingHeight,
        surfaceArea,
        forms,
        energyGain,
        co2EmissionsAvoided,
        estimatedFinancialSavings,
        averageRoi,
        resultTableRows,
        energyMatrix,
        actionMatrix,
        resultCharts,
        energyGainMwhPerYear: energyGain,
        kgCo2EmissionAvoidedPerYear: co2EmissionsAvoided * 1000,
        financialGainPerYear: estimatedFinancialSavings,
        estimatedRoi: averageRoi,
        estimatedBudget: estimatedBudget,
      }}
    >
      {children}
    </RoomAirDestratificationContext.Provider>
  );
};

export { RoomAirDestratificationContext, RoomAirDestratificationProvider };
