import { createContext, useContext, useEffect, useState } from 'react';
import { IComponentWithChildren, IForm, ISimulatorRequiredDatas } from '../../types/app';
import { FormTitle } from '../../components/form/Form.styled';
import { ITableRow } from '../../components/table/Table';
import { ReferenceDatasContext, ReferenceDatasContextProps } from '../ReferenceDatasContext';
import { customRoundDown, customRoundUp, formatNumber } from '../../utils';
import { ChartProps } from '../../components/chart/Chart';
import { COLORS } from '../../styles/constants/colors';
import { SimulatorsContext, SimulatorsContextProps } from './SimulatorsContext';
import { UserContext, UserContextProps } from '../user/UserContext';

import useDelayedUpdate from '../../hooks/useDelayedUpdate';

export interface HeatRecoveryContextProps extends ISimulatorRequiredDatas {
  electricalEnergyConsumedByChillers: number;
  electricalEnergyConsumedForCompressedAirProduction: number;
  forms: IForm.IForm[];
  //
  totalRecoverableThermalEnergy: number;
  co2EmissionAvoided: number;
  estimatedFinancialGain: number;
  averageRoi: number;
  estimatedBudget: number;
  resultTableRows: ITableRow[];
  resultCharts: Array<Array<ChartProps>>;
  //
  recoverableThermalEnergyByChillers: number;
  recoverableThermalEnergyByCompressedAir: number;
  recoverableEnergyByChillersAndCompressedAir: number;
  chillersAndCompressedAirMatrix: Array<Array<number>>;
  roiAuditMatrix: Array<Array<number>>;
  //
  setElectricalEnergyConsumedByChillers: (e: any) => any;
  setElectricalEnergyConsumedForCompressedAirProduction: (e: any) => any;
  setForms: (e: any) => any;
  //
  setTotalRecoverableThermalEnergy: (e: any) => any;
  setCo2EmissionAvoided: (e: any) => any;
  setEstimatedFinancialGain: (e: any) => any;
  setAverageRoi: (e: any) => any;
  setEstimatedBudget: (e: any) => any;
  setResultTableRows: (e: any) => any;
  setResultCharts: (e: any) => any;
  //
  setRecoverableThermalEnergyByChillers: (e: any) => any;
  setRecoverableThermalEnergyByCompressedAir: (e: any) => any;
  setRecoverableEnergyByChillersAndCompressedAir: (e: any) => any;
  setChillersAndCompressedAirMatrix: (e: any) => any;
  setRoiAuditMatrix: (e: any) => any;
}

const HeatRecoveryContext = createContext<HeatRecoveryContextProps | undefined>(undefined);
const HeatRecoveryProvider: React.FC<IComponentWithChildren> = ({ children }) => {
  const { CARBON_CONTENT_PER_KWH_GAZ, AVERAGE_GAS_PRICE_PER_MWH, NATURAL_GAS_TRENDS } = useContext(
    ReferenceDatasContext,
  ) as ReferenceDatasContextProps;
  const { userToken, updateUserSimulatorDatas } = useContext(UserContext) as UserContextProps;
  const { simulators, editSimulatorDatas, getSimulatorByCode } = useContext(
    SimulatorsContext,
  ) as SimulatorsContextProps;

  const [electricalEnergyConsumedByChillers, setElectricalEnergyConsumedByChillers] = useState(0);
  const [
    electricalEnergyConsumedForCompressedAirProduction,
    setElectricalEnergyConsumedForCompressedAirProduction,
  ] = useState(0);
  const [forms, setForms] = useState<IForm.IForm[]>([]);

  const [totalRecoverableThermalEnergy, setTotalRecoverableThermalEnergy] = useState(0);
  const [co2EmissionAvoided, setCo2EmissionAvoided] = useState(0);
  const [estimatedFinancialGain, setEstimatedFinancialGain] = useState(0);
  const [averageRoi, setAverageRoi] = useState(0);
  const [estimatedBudget, setEstimatedBudget] = useState(0);

  const [recoverableThermalEnergyByChillers, setRecoverableThermalEnergyByChillers] = useState(0);
  const [recoverableThermalEnergyByCompressedAir, setRecoverableThermalEnergyByCompressedAir] =
    useState(0);
  const [
    recoverableEnergyByChillersAndCompressedAir,
    setRecoverableEnergyByChillersAndCompressedAir,
  ] = useState(0);
  const [chillersAndCompressedAirMatrix, setChillersAndCompressedAirMatrix] = useState<
    Array<Array<number>>
  >([[]]);

  const [roiAuditMatrix, setRoiAuditMatrix] = useState<Array<Array<number>>>([]);

  const [resultCharts, setResultCharts] = useState<Array<Array<ChartProps>>>([]);

  const [resultTableRows, setResultTableRows] = useState<ITableRow[]>([]);

  const [updateDatas, setUpdateDatas] = useState<object>({});

  //   Total recoverable thermal energy
  useEffect(() => {
    setTotalRecoverableThermalEnergy(
      2.9 * 0.6 * electricalEnergyConsumedByChillers +
        electricalEnergyConsumedForCompressedAirProduction * 0.6 * 0.6,
    );
  }, [electricalEnergyConsumedByChillers, electricalEnergyConsumedForCompressedAirProduction]);

  //   co2 emission avoided
  useEffect(() => {
    setCo2EmissionAvoided(totalRecoverableThermalEnergy * CARBON_CONTENT_PER_KWH_GAZ?.value);
  }, [totalRecoverableThermalEnergy, CARBON_CONTENT_PER_KWH_GAZ?.value]);

  //   financial gain
  useEffect(() => {
    setEstimatedFinancialGain(
      customRoundDown(totalRecoverableThermalEnergy * AVERAGE_GAS_PRICE_PER_MWH?.value, -2),
    );
  }, [totalRecoverableThermalEnergy, AVERAGE_GAS_PRICE_PER_MWH?.value]);

  //   ROI
  // ??
  useEffect(() => {
    setAverageRoi(estimatedBudget / estimatedFinancialGain);
  }, [estimatedBudget, estimatedFinancialGain]);

  //   estimated budget
  useEffect(() => {
    setEstimatedBudget(customRoundUp(15000 + totalRecoverableThermalEnergy * 200, -3));
  }, [totalRecoverableThermalEnergy]);

  //   recoverable thermal energy
  // chillers
  useEffect(() => {
    setRecoverableThermalEnergyByChillers(electricalEnergyConsumedByChillers * 2.9 * 0.8);
  }, [electricalEnergyConsumedByChillers]);
  // Compressed air
  useEffect(() => {
    setRecoverableThermalEnergyByCompressedAir(
      electricalEnergyConsumedForCompressedAirProduction * 0.8 * 0.8,
    );
  }, [electricalEnergyConsumedForCompressedAirProduction]);

  //   total
  useEffect(() => {
    setRecoverableEnergyByChillersAndCompressedAir(
      recoverableThermalEnergyByChillers + recoverableThermalEnergyByCompressedAir,
    );
  }, [recoverableThermalEnergyByChillers, recoverableThermalEnergyByCompressedAir]);

  //   chillers and compressed air matrix
  useEffect(() => {
    let tmpChillersAndCompressedAirMatrix: Array<Array<number>> = [[]];
    tmpChillersAndCompressedAirMatrix = [
      [Number(electricalEnergyConsumedByChillers), Number(recoverableThermalEnergyByChillers)],
      [
        Number(electricalEnergyConsumedForCompressedAirProduction),
        Number(recoverableThermalEnergyByCompressedAir),
      ],
      [
        Number(electricalEnergyConsumedByChillers) +
          Number(electricalEnergyConsumedForCompressedAirProduction),
        Number(recoverableEnergyByChillersAndCompressedAir),
      ],
    ];
    setChillersAndCompressedAirMatrix(tmpChillersAndCompressedAirMatrix);
  }, [
    electricalEnergyConsumedByChillers,
    electricalEnergyConsumedForCompressedAirProduction,
    recoverableThermalEnergyByCompressedAir,
    recoverableThermalEnergyByChillers,
    recoverableEnergyByChillersAndCompressedAir,
  ]);

  //   ROI Audit matrix
  useEffect(() => {
    const auditAndReparationCost = -estimatedBudget;
    const initialEconomyRealized = 0;
    const yearCount = 5;

    let tmpRoiAuditMatrix: Array<Array<number>> = [];
    const roiYear0 = auditAndReparationCost + initialEconomyRealized;
    tmpRoiAuditMatrix[0] = [initialEconomyRealized, estimatedFinancialGain];
    tmpRoiAuditMatrix[1] = [roiYear0, tmpRoiAuditMatrix[0][1] + roiYear0];

    for (let i = 2; i <= yearCount; i++) {
      tmpRoiAuditMatrix[0] = [
        ...tmpRoiAuditMatrix[0],
        tmpRoiAuditMatrix[0][i - 1] * (1 + NATURAL_GAS_TRENDS?.value / 100),
      ];
      tmpRoiAuditMatrix[1] = [
        ...tmpRoiAuditMatrix[1],
        tmpRoiAuditMatrix[0][i] + tmpRoiAuditMatrix[1][i - 1],
      ];
    }

    setRoiAuditMatrix(tmpRoiAuditMatrix);
  }, [estimatedBudget, estimatedFinancialGain, NATURAL_GAS_TRENDS?.value]);

  const [allowRequest, setAllowRequest] = useState<boolean>(false);

  // Forms
  useEffect(() => {
    setForms([
      {
        onChange: () => setAllowRequest(true),
        header: <FormTitle>Remplissez les champs suivants :</FormTitle>,
        fields: [
          {
            label: 'Energie électrique consommée par les groupes froids',
            name: 'electrical energy consumed by chillers',
            type: 'textfield',
            unit: 'MWh/an',
            value: electricalEnergyConsumedByChillers,
            onChange: (e) => setElectricalEnergyConsumedByChillers(e),
          },
          {
            label: "Energie électrique consommée pour la production d'air comprimé",
            name: 'electrical energy consumed for compressed air production',
            type: 'textfield',
            unit: 'MWh/an',
            value: electricalEnergyConsumedForCompressedAirProduction,
            onChange: (e) => setElectricalEnergyConsumedForCompressedAirProduction(e),
          },
        ],
      },
    ]);
  }, [electricalEnergyConsumedByChillers, electricalEnergyConsumedForCompressedAirProduction]);

  //   Result table rows
  useEffect(() => {
    setResultTableRows([
      {
        label: 'Energie thermique valorisable totale',
        value: `${totalRecoverableThermalEnergy}`,
        unit: 'MWh/an',
      },
      {
        label: 'Emissions de CO2 évitées (Par rapport au gaz naturel)',
        value: `${co2EmissionAvoided}`,
        unit: 'Tonnes CO2/an',
      },
      {
        label: 'Gain financier estimé (Par rapport au gaz naturel)',
        value: `${estimatedFinancialGain}`,
        unit: '€/an',
      },
      {
        label: 'Enveloppe budgétaire estimée',
        value: `${estimatedBudget}`,
        unit: '€',
      },
      {
        label: 'ROI moyen pour cette action (le ROI n’inclut pas les primes et aides (CEE,…))',
        value: `${averageRoi}`,
        unit: 'ans',
      },
    ]);
  }, [
    totalRecoverableThermalEnergy,
    co2EmissionAvoided,
    estimatedFinancialGain,
    averageRoi,
    estimatedBudget,
  ]);

  //   Result charts
  useEffect(() => {
    setResultCharts([
      [
        {
          chartType: 'bar',
          chartTitle: 'Bilan énergétique de la récupération de chaleur sur les équipements',
          labels: ['Energie électrique consommée', 'Energie thermique valorisable'],
          labelsColors: [COLORS.WHITE, COLORS.WHITE],
          labelsBackgroundColors: [COLORS.BRIGHT_BLUE, COLORS.DARK_BLUE],
          datas: {
            labels: ['Total', 'Air comprimé', 'Groupe froid'],
            datasets: [
              {
                indexAxis: 'y',
                data: [
                  (chillersAndCompressedAirMatrix[2] && chillersAndCompressedAirMatrix[2][1]) || 0,
                  (chillersAndCompressedAirMatrix[1] && chillersAndCompressedAirMatrix[1][1]) || 0,
                  (chillersAndCompressedAirMatrix[0] && chillersAndCompressedAirMatrix[0][1]) || 0,
                ],
                backgroundColor: COLORS.DARK_BLUE,
                maxBarThickness: 50,
              },
              {
                indexAxis: 'y',
                data: [
                  (chillersAndCompressedAirMatrix[2] && chillersAndCompressedAirMatrix[2][0]) || 0,
                  (chillersAndCompressedAirMatrix[1] && chillersAndCompressedAirMatrix[1][0]) || 0,
                  (chillersAndCompressedAirMatrix[0] && chillersAndCompressedAirMatrix[0][0]) || 0,
                ],
                backgroundColor: COLORS.BRIGHT_BLUE,
                maxBarThickness: 50,
              },
            ],
          },
          defaultUnit: 'MWh/an',
          options: {
            indexAxis: 'y',
            responsive: true,
            scales: {
              x: {
                ticks: {
                  callback: (label) => {
                    return !isNaN(Number(label)) ? formatNumber(label) + ' MWh/an' : label;
                  },
                },
              },
              y: {
                ticks: {
                  font: {
                    size: 10,
                  },
                },
              },
            },
          },
        },
      ],
      [
        {
          chartType: 'bar',
          chartTitle: 'Retour sur investissement estimé',
          datas: {
            labels: roiAuditMatrix[1]
              ? roiAuditMatrix[1].map((value, index) => `Année ${index}`)
              : [],
            datasets: [
              {
                data: roiAuditMatrix[1] || [],
                backgroundColor: roiAuditMatrix[1]?.map((value) =>
                  value > 0 ? COLORS.BRIGHT_BLUE : COLORS.LIGHT_BLUE,
                ),
                // maxBarThickness: 50,
              },
            ],
          },
          options: {
            responsive: true,
          },
          defaultUnit: '€',
          yUnit: '€',
        },
      ],
    ]);
  }, [chillersAndCompressedAirMatrix, roiAuditMatrix]);

  useEffect(() => {
    const sim = getSimulatorByCode('HEAT_RECOVERY');
    if (sim) {
      setElectricalEnergyConsumedByChillers(Number(sim?.datas?.electricalEnergyConsumedByChillers));
      setElectricalEnergyConsumedForCompressedAirProduction(
        Number(sim?.datas?.electricalEnergyConsumedForCompressedAirProduction),
      );
    }
    // eslint-disable-next-line
  }, [simulators]);

  useEffect(() => {
    if (userToken) {
      const newDatas = {
        electricalEnergyConsumedByChillers,
        electricalEnergyConsumedForCompressedAirProduction,
      };
      setUpdateDatas(newDatas);
    }
  }, [
    userToken,
    electricalEnergyConsumedByChillers,
    electricalEnergyConsumedForCompressedAirProduction,
    totalRecoverableThermalEnergy,
    estimatedFinancialGain,
    co2EmissionAvoided,
  ]);

  const updateUserSimulatorDatasFunc = () => {
    // Your logic to update the user simulator datas
    allowRequest &&
      updateDatas &&
      updateUserSimulatorDatas(userToken, 'HEAT_RECOVERY', updateDatas).then(() => {
        editSimulatorDatas('HEAT_RECOVERY', updateDatas);
      });
  };

  useDelayedUpdate(updateUserSimulatorDatasFunc, [updateDatas], 250);

  return (
    <HeatRecoveryContext.Provider
      value={{
        electricalEnergyConsumedByChillers,
        electricalEnergyConsumedForCompressedAirProduction,
        forms,
        totalRecoverableThermalEnergy,
        co2EmissionAvoided,
        estimatedFinancialGain,
        averageRoi,
        resultTableRows,
        recoverableThermalEnergyByChillers,
        recoverableThermalEnergyByCompressedAir,
        recoverableEnergyByChillersAndCompressedAir,
        chillersAndCompressedAirMatrix,
        resultCharts,
        roiAuditMatrix,
        energyGainMwhPerYear: totalRecoverableThermalEnergy,
        kgCo2EmissionAvoidedPerYear: co2EmissionAvoided * 1000,
        financialGainPerYear: estimatedFinancialGain,
        estimatedRoi: averageRoi,
        estimatedBudget: estimatedBudget,
        setElectricalEnergyConsumedByChillers,
        setElectricalEnergyConsumedForCompressedAirProduction,
        setForms,
        setTotalRecoverableThermalEnergy,
        setCo2EmissionAvoided,
        setEstimatedFinancialGain,
        setEstimatedBudget,
        setAverageRoi,
        setResultTableRows,
        setRecoverableThermalEnergyByChillers,
        setRecoverableThermalEnergyByCompressedAir,
        setRecoverableEnergyByChillersAndCompressedAir,
        setChillersAndCompressedAirMatrix,
        setResultCharts,
        setRoiAuditMatrix,
      }}
    >
      {children}
    </HeatRecoveryContext.Provider>
  );
};

export { HeatRecoveryContext, HeatRecoveryProvider };
