import { useContext, useEffect, useState } from 'react';
import { AuthAdminWrapper } from './AuthAdmin.styled';
import TextField from '../../../components/form/text-field/TextField';
import Button from '../../../components/button/Button';
import Logo from '../../../assets/images/logo-actemium.png';
import Progress from '../../../components/progress/Progress';
import { authenticateAdmin } from '../../../api/Admin.api';
import toast from 'react-hot-toast';
import { getAdminTokenFromLocalstorage } from '../../../utils';
import { APP_TITLE, ROUTES } from '../../../global';
import { UserContext, UserContextProps } from '../../../context/user/UserContext';

const AuthAdmin: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [isLoadingAuth, setIsLoadingAuth] = useState<boolean>(false);
  const { setIsAdminView } = useContext(UserContext) as UserContextProps;

  const auth = async () => {
    try {
      setIsLoadingAuth(true);
      const admin = await authenticateAdmin(username, password);
      if (!admin) throw new Error('Admin not found');
      if (admin.token) {
        localStorage.setItem(getAdminTokenFromLocalstorage(), admin.token);
        setIsAdminView(true);
        window.location.href = `${ROUTES.ADMIN.INDEX}${ROUTES.ADMIN.USERS.LIST}`;
      }
    } catch (err) {
      toast.error("Erreur lors de l'authentication, nom d'utilisateur ou mot de passe incorrect");
    } finally {
      setIsLoadingAuth(false);
    }
  };

  useEffect(() => {
    document.title = `Admin | Authentification - ${APP_TITLE}`;
  }, []);

  return (
    <AuthAdminWrapper>
      <img src={Logo} alt="logo" className="logo" />
      <div className="auth-wrapper">
        <h4>Espace admin - Actemium</h4>
        <TextField
          controlled
          value={username}
          onChange={(e) => setUsername(e)}
          label="Nom d'utilisateur"
          type="text"
          placeholder="Nom d'utilisateur"
        />
        <TextField
          controlled
          value={password}
          onChange={(e) => setPassword(e)}
          label="Mot de passe"
          type="password"
          placeholder="Mot de passe"
        />
        {!isLoadingAuth ? (
          <Button
            hasIcon
            label="Se connecter en tant qu'admin"
            theme="light"
            onClick={() => {
              auth();
            }}
          />
        ) : (
          <>
            <Progress />
          </>
        )}
      </div>
    </AuthAdminWrapper>
  );
};

export default AuthAdmin;
