import styled from 'styled-components';

const ExportUsersListToExcelWrapper = styled.div`
  width: max-content;
  display: flex;
  align-items: center;
  gap: 2px;

  &:hover {
    cursor: pointer;
  }

  .icon {
    display: flex;
    align-items: center;
  }

  .label {
    text-decoration: underline;
  }
`;

export { ExportUsersListToExcelWrapper };
