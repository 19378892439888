import styled from 'styled-components';
import { COLORS } from '../../../styles/constants/colors';
import { BODY, H4 } from '../../../styles/helpers/typography';

const TotalTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${COLORS.BRIGHT_BLUE};
  color: ${COLORS.WHITE};
  padding: 15px;
  border-radius: 12px;
  ${BODY.XL_SEMI_BOLD}

  .section {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .icon {
    background: ${COLORS.WHITE};
    color: ${COLORS.BRIGHT_BLUE};
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .total-title-right {
    ${H4}
  }
`;

export { TotalTitleWrapper };
