import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { IComponentWithChildren, ISimulator, ISIMULATORS_NAME } from '../../types/app';
import { UserContext, UserContextProps } from '../user/UserContext';
import { sortSimulators } from '../../utils';

export interface SimulatorsContextProps {
  simulators: Array<ISimulator>;
  getNextAndPreviousSimulators: (name: ISIMULATORS_NAME) => {
    previous: ISimulator | undefined;
    next: ISimulator | undefined;
  };
  engineClassesMatrix: number[][];
  getSimulatorByCode: (code: ISIMULATORS_NAME) => ISimulator | undefined;
  editSimulatorDatas: (code: ISIMULATORS_NAME, newDatas: object) => any;
}

const desiredOrder: ISIMULATORS_NAME[] = [
  'INDUSTRIAL_SITE',
  'MONITORING',
  'COMPRESSED_AIR',
  'HEAT_RECOVERY',
  'ROOM_AIR_DESTRATIFICATION',
  'MICRO_MODULATING_BURNER',
  'INSULATING_MATTRESSES',
  'LED_LIGHTING',
  'HEAT_PUMP',
  'ELECTRIC_SPEED_VARIATION',
  'HIGH_PERFORMANCE_ENGINES',
  'MOTOR_DRIVE',
];

const SimulatorsContext = createContext<SimulatorsContextProps | undefined>(undefined);
const SimulatorsProvider: React.FC<IComponentWithChildren> = ({ children }) => {
  const { user, userToken } = useContext(UserContext) as UserContextProps;
  const [engineClassesMatrix, setEngineClassesMatrix] = useState<number[][]>([]);
  const [simulators, setSimulators] = useState<Array<ISimulator>>([]);

  useEffect(() => {
    if (user?.simulatorDatas) {
      try {
        setSimulators(sortSimulators(user?.simulatorDatas, desiredOrder));
      } catch {
        setSimulators(user?.simulatorDatas);
      }
    }
  }, [user]);

  const getNextAndPreviousSimulators = (
    name: ISIMULATORS_NAME,
  ): {
    previous: ISimulator | undefined;
    next: ISimulator | undefined;
  } => {
    let previousIndex = simulators.findIndex((sim) => sim.name === name) - 1;
    let nextIndex = previousIndex + 2;

    return {
      previous: simulators[previousIndex] || undefined,
      next: simulators[nextIndex] || undefined,
    };
  };

  useEffect(() => {
    setEngineClassesMatrix([
      [0.5234, 0.0278, 0.0773, 0.2412],
      [-5.0499, -1.9247, -1.8951, -2.3608],
      [17.418, 10.4395, 9.2984, 8.446],
      [74.3171, 80.9761, 83.7025, 86.8321],
    ]);
  }, []);

  const getSimulatorByCode = useCallback(
    (code: ISIMULATORS_NAME): ISimulator | undefined => {
      return simulators.find((sim) => sim.code === code);
    },
    [simulators],
  );

  const editSimulatorDatas = useCallback(
    (code: ISIMULATORS_NAME, newDatas: object) => {
      if (userToken) {
        const simByCode = getSimulatorByCode(code) as ISimulator;
        const newSim: ISimulator = {
          ...simByCode,
          datas: {
            ...newDatas,
          },
        };
        setSimulators(
          simulators?.map((sim) => {
            return sim?.code === code ? newSim : sim;
          }),
        );
      }
    },
    [getSimulatorByCode, userToken, simulators],
  );

  return (
    <SimulatorsContext.Provider
      value={{
        simulators: simulators,
        getNextAndPreviousSimulators: getNextAndPreviousSimulators,
        engineClassesMatrix,
        getSimulatorByCode,
        editSimulatorDatas,
      }}
    >
      {children}
    </SimulatorsContext.Provider>
  );
};

export { SimulatorsContext, SimulatorsProvider };
