import { useContext } from 'react';
import { ISimulator } from '../../../../types/app';
import SimulatorLayout from '../../layout/SimulatorLayout';
import { LedLightingWrapper } from './LedLighting.styled';
import {
  LedLightingContext,
  LedLightingContextProps,
} from '../../../../context/simulators/LedLightingContext';

export interface LedLightingProps {
  simulator: ISimulator;
}

const LedLighting: React.FC<LedLightingProps> = ({ simulator }) => {
  const { forms, resultTableRows, resultCharts } = useContext(
    LedLightingContext,
  ) as LedLightingContextProps;

  return (
    <LedLightingWrapper>
      <SimulatorLayout
        simulator={simulator}
        forms={forms}
        resultTableRows={resultTableRows}
        resultCharts={resultCharts}
      />
    </LedLightingWrapper>
  );
};

export default LedLighting;
