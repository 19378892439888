import { useContext } from 'react';
import { ISimulator } from '../../../../types/app';
import SimulatorLayout from '../../layout/SimulatorLayout';
import { HeatRecoveryWrapper } from './HeatRecovery.styled';
import {
  HeatRecoveryContext,
  HeatRecoveryContextProps,
} from '../../../../context/simulators/HeatRecoveryContext';

export interface HeatRecoveryProps {
  simulator: ISimulator;
}
const HeatRecovery: React.FC<HeatRecoveryProps> = ({ simulator }) => {
  const { forms, resultTableRows, resultCharts } = useContext(
    HeatRecoveryContext,
  ) as HeatRecoveryContextProps;

  //   Total recoverable thermal energy
  return (
    <HeatRecoveryWrapper>
      <SimulatorLayout
        forms={forms}
        resultTableRows={resultTableRows}
        resultCharts={resultCharts}
        simulator={simulator}
      />
    </HeatRecoveryWrapper>
  );
};

export default HeatRecovery;
