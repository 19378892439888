import { useState, useEffect, useContext } from 'react';
import { FullExplications, SimulatorExplicationsWrapper } from './SimulatorExplications.styled';
import { ModalContext, ModalContextProps } from '../../../context/ModalContext';
import DefaultModalLayout from '../../../components/Modal/default-Layout/DefaultModalLayout';

export interface SimulatorExplicationsProps {
  explications?: string;
}

const SimulatorExplications: React.FC<SimulatorExplicationsProps> = ({ explications }) => {
  const [showMore, setShowMore] = useState(false);
  const { updateModalConf } = useContext(ModalContext) as ModalContextProps;

  useEffect(() => {
    const descriptionElement = document.querySelector('.description');

    if (descriptionElement) {
      setShowMore(descriptionElement.scrollHeight > descriptionElement.clientHeight);
    }
  }, [explications]);

  return (
    <SimulatorExplicationsWrapper>
      <h5 className="title">Explications</h5>
      <div className="description">{explications}</div>
      {showMore && (
        <p
          className="more"
          onClick={() => {
            updateModalConf({
              component: (
                <DefaultModalLayout showCloseIcon title="Explications">
                  <FullExplications className="full-explications">{explications}</FullExplications>
                </DefaultModalLayout>
              ),
            });
          }}
        >
          En savoir plus
        </p>
      )}
    </SimulatorExplicationsWrapper>
  );
};

export default SimulatorExplications;
