import axios from 'axios';
import { IPagination, IUserDetails, IUserList } from '../types/app';
import { API } from '../global';

const formatUsersList = (datas: any[]): IUserDetails[] => {
  const res: IUserDetails[] = datas.map((data) => {
    return {
      id: data.id,
      companyName: data.company_name,
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      phoneNumber: data.phone_number,
      jobPosition: data.job_position,
      token: data.token,
      wantToBeContacted: data.want_to_be_contacted,
      wantFullReport: data.want_full_report,
      contacted: data.contacted,
      gaveFullReport: data.gave_full_report,
      creationDate: data.creation_date,
      department: data.department,
    };
  });
  return res;
};
const getUsersList = async (
  token: string,
  filters?: string[],
  pagination?: IPagination,
  asc?: boolean,
  email?: string,
): Promise<IUserList | undefined> => {
  const tmpPagination: IPagination | undefined = pagination;
  try {
    const url = `${API.BASE_URL}${API.ROUTES.ADMIN.USERS.GET}?filter=${
      filters ? filters?.join('&filter=') : ''
    }&rows=${tmpPagination?.rowsCount !== undefined ? tmpPagination.rowsCount : ''}&page=${
      tmpPagination?.currentPage !== undefined ? tmpPagination.currentPage : ''
    }${asc ? '&asc=1' : ''}${email ? `&email=${email}` : ''}`;

    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const userList = formatUsersList(res.data.datas) || [];
    const pagination = res.data.pagination;
    return {
      userList: userList,
      pagination,
    };
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

const AdminUsersApi = {
  getUsersList,
};

export { AdminUsersApi };
