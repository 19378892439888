import { useCallback, useEffect, useState } from 'react';
import Card from '../../../components/card/Card';
import { ISIMULATORS_NAME, ISimulator, ISimulatorRequiredDatas } from '../../../types/app';
import { AxesToDeployCardWrapper } from './AxesToDeployCard.styled';
import { SIMULATORS_NAMES } from '../../../global';
import {
  calculateTotalEnergyValue,
  calculateTotalFinancialValue,
  formatNumber,
} from '../../../utils';
import SelectBox from '../../../components/form/select-box/SelectBox';

export interface AxesToDeployProps {
  simulators?: ISimulator[];
  contexts: {
    [x in ISIMULATORS_NAME]: any;
  };
}

const AxesToDeployCard: React.FC<AxesToDeployProps> = ({ simulators, contexts }) => {
  const [top3Simulators, setTop3Simulators] = useState<ISimulator[]>();
  const [showTop3Simulators, setShowTop3Simulators] = useState<boolean>(false);
  const [currentFilter, setCurrentFilter] = useState<0 | 1>(0);

  const sortTop3SimulatorsByFinancialValue = useCallback((): ISimulator[] => {
    const sortedSimulators = simulators
      ? [
          ...simulators.filter(
            (sim) =>
              sim.code !== SIMULATORS_NAMES.INDUSTRIAL_SITE.name &&
              (contexts[sim?.code as ISIMULATORS_NAME] as ISimulatorRequiredDatas)
                .financialGainPerYear !== 0 &&
              !!(contexts[sim?.code as ISIMULATORS_NAME] as ISimulatorRequiredDatas)
                .financialGainPerYear,
          ),
        ].sort(
          (a: any, b: any) =>
            (contexts[b?.code as ISIMULATORS_NAME] as ISimulatorRequiredDatas)
              ?.financialGainPerYear -
            (contexts[a?.code as ISIMULATORS_NAME] as ISimulatorRequiredDatas)
              ?.financialGainPerYear,
        )
      : [];
    const top3Simulators = sortedSimulators.slice(0, 3);
    return top3Simulators;
  }, [simulators, contexts]);

  const sortTop3SimulatorsByEmissionAvoidedPerYear = useCallback((): ISimulator[] => {
    const sortedSimulators = simulators
      ? [
          ...simulators.filter(
            (sim) =>
              sim.code !== SIMULATORS_NAMES.INDUSTRIAL_SITE.name &&
              (contexts[sim?.code as ISIMULATORS_NAME] as ISimulatorRequiredDatas)
                .kgCo2EmissionAvoidedPerYear !== 0 &&
              !!(contexts[sim?.code as ISIMULATORS_NAME] as ISimulatorRequiredDatas)
                .kgCo2EmissionAvoidedPerYear,
          ),
        ].sort(
          (a: any, b: any) =>
            (contexts[b?.code as ISIMULATORS_NAME] as ISimulatorRequiredDatas)
              ?.kgCo2EmissionAvoidedPerYear -
            (contexts[a?.code as ISIMULATORS_NAME] as ISimulatorRequiredDatas)
              ?.kgCo2EmissionAvoidedPerYear,
        )
      : [];
    const top3Simulators = sortedSimulators.slice(0, 3);
    return top3Simulators;
  }, [simulators, contexts]);

  useEffect(() => {
    if (simulators) {
      currentFilter === 0 && setTop3Simulators(sortTop3SimulatorsByFinancialValue());
      currentFilter === 1 && setTop3Simulators(sortTop3SimulatorsByEmissionAvoidedPerYear());
    }
  }, [
    simulators,
    sortTop3SimulatorsByFinancialValue,
    sortTop3SimulatorsByEmissionAvoidedPerYear,
    currentFilter,
  ]);

  useEffect(() => {
    setShowTop3Simulators(!!(top3Simulators && top3Simulators.length === 3));
  }, [top3Simulators]);

  return (
    <AxesToDeployCardWrapper>
      <Card
        theme="blue"
        title={
          showTop3Simulators ? (
            <div className='show-filters-title'>
              <div className="card-title">Top 3 des axes à déployer</div>
              <div className="select-filter">
                <SelectBox
                  options={[
                    {
                      label: 'En €',
                      selected: currentFilter === 0,
                      value: 0,
                    },
                    {
                      label: 'Tonne de CO2/an',
                      selected: currentFilter === 1,
                      value: 1,
                    },
                  ]}
                  onChange={(e) => {
                    setCurrentFilter(e.find((item) => item.selected)?.value);
                  }}
                />
              </div>
            </div>
          ) : (
            "Pour identifier vos trois axes d'efficacité, démarrez la simulation"
          )
        }
      >
        {showTop3Simulators && (
          <div className="axes-to-deploy">
            {top3Simulators?.map((simulator, key) => {
              return (
                <div className="single-axe" key={key}>
                  <div className="axe-name">
                    <span className="key">{key + 1}</span> {simulator.name}
                  </div>
                  <div className="axe-icon">{simulator?.icon?.icon}</div>
                </div>
              );
            })}
          </div>
        )}
        <div className="estimated-economy-and-gain">
          <div className="estimations estimated-economy">
            <p className="subtitle">Économie estimées en électricité</p>
            {!showTop3Simulators ? (
              <p className="empty-value" />
            ) : (
              <p className="value">
                {formatNumber(calculateTotalEnergyValue(top3Simulators, contexts))}
              </p>
            )}
            <p>MWh/an</p>
          </div>
          <div className="estimations estimated-gain">
            <p className="subtitle">Gains annuels estimée en euro</p>
            {!showTop3Simulators ? (
              <p className="empty-value" />
            ) : (
              <p className="value">
                {formatNumber(calculateTotalFinancialValue(top3Simulators, contexts))}
              </p>
            )}
            <p className="estimation-unit">€/an</p>
          </div>
        </div>
      </Card>
    </AxesToDeployCardWrapper>
  );
};

export default AxesToDeployCard;
